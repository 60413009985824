import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useYearDropdown = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [yearList, setYearList] = useState<number[]>([]);
  const [selectYear, setSelectYear] = useState(
    searchParams.get("year") ?? new Date().getFullYear(),
  );
  const [isOpen, setIsOpen] = useState(false);

  const setYearArr = () => {
    const tempYear: number[] = [];

    for (let i = new Date().getFullYear(); i > 2021; i--) {
      tempYear.push(i);
    }

    setYearList(tempYear);
  };

  const handleOpenDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelcetYear = useCallback(
    (year: number) => () => {
      setSelectYear(year);
      setIsOpen(false);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        year: year.toString(),
      });
    },
    [searchParams],
  );

  useEffect(() => {
    const handleCloseDropdown = (e: MouseEvent) => {
      if (!dropdownRef.current) return;

      if (isOpen && !dropdownRef.current.contains(e.target as HTMLElement)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("mousedown", handleCloseDropdown);

    return () => {
      window.addEventListener("mousedown", handleCloseDropdown);
    };
  }, [isOpen]);

  useEffect(() => {
    setYearArr();

    setSearchParams({
      ...Object.fromEntries(searchParams),
      year: selectYear.toString(),
    });
  }, []);

  return {
    isOpen,
    ref: dropdownRef,
    yearList,
    selectYear,
    handleSelcetYear,
    handleOpenDropdown,
  };
};

export default useYearDropdown;
