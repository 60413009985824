import { useEffect, RefObject } from "react";
import throttle from "lodash/throttle";

const useScrollXFixedEl = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const scrollXFixedEl = () => {
      if (!ref.current) return;

      const scrollLeft = document.documentElement.scrollLeft;
      ref.current.style.transform = `translateX(${0 - scrollLeft}px)`;
    };
    const throttleScrollXFixedElement = throttle(scrollXFixedEl, 10);

    window.addEventListener("scroll", throttleScrollXFixedElement);

    return () => {
      window.removeEventListener("scroll", throttleScrollXFixedElement);
    };
  }, []);
};

export default useScrollXFixedEl;
