import React from "react";
import styled from "styled-components";

import { Header } from "components/common/header/index";
import { LAYOUT_TYPE } from "utils/constants";

interface LayoutProps {
  children: React.ReactNode;
  type?: string;
}

const Layout = ({ children, type }: LayoutProps) => {
  return (
    <>
      {type === LAYOUT_TYPE.PUBLIC ? (
        <PublicMain>{children}</PublicMain>
      ) : (
        <>
          <Header />
          <PrivateMain>{children}</PrivateMain>
        </>
      )}
    </>
  );
};

export default Layout;

const PublicMain = styled.main`
  width: 100%;
  padding-bottom: 63px;
`;

const PrivateMain = styled.main`
  min-width: 1340px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;

  @media (max-height: 914px) {
    min-height: 914px;
  }
`;
