import createSagaMiddleware from "redux-saga";
// import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import rootSaga from "sagas/index";
import rootReducer from "./reducer/index";

const persistConfig = {
  key: "root",
  storage, // local storage에 저장 설정
  whitelist: ["persist"], // storage에 저장할 slice 지정
};

const reducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const middlewares =
  process.env.NODE_ENV === "development" ? [sagaMiddleware] : [sagaMiddleware];

const store = configureStore({
  reducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === "development",
});

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export { store, persistor };
