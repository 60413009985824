import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReplhadaIcon } from "assets/icon";

const NotFound = () => {
  const navigate = useNavigate();

  const handleClickBeforePage = () => {
    navigate(-1);
  };

  return (
    <Root>
      <Wrapper>
        <CustomReplhadaIcon />
        <Heading>페이지를 찾을 수 없습니다.</Heading>
        <NotFoundText>
          페이지의 주소가 잘못 입력되었거나, 주소가 변경되어 이용하실 수
          없습니다. <br /> 입력하신 페이지의 주소가 정확한지 확인해 주세요.
        </NotFoundText>
        <BeforePageBtn type="button" onClick={handleClickBeforePage}>
          이전 페이지로
        </BeforePageBtn>
      </Wrapper>
    </Root>
  );
};

export default NotFound;

const Root = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 411px;
`;

const CustomReplhadaIcon = styled(ReplhadaIcon)`
  margin-bottom: 20px;
`;

const Heading = styled.p`
  margin-bottom: 12px;
  font: ${({ theme }) => theme.text.semibold_18};
  text-align: center;
`;

const NotFoundText = styled.p`
  margin-bottom: 20px;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_30};
  text-align: center;
`;

const BeforePageBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 8px 16px;
  font: ${({ theme }) => theme.text.medium_15};
  color: ${({ theme }) => theme.color.gray_30};
`;
