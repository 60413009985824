import React from "react";
import styled from "styled-components";

import { AccountInput } from "components/common/input/index";
import useAccountInput from "components/common/input/accountInput/useAccountInput";
import useLogin from "./hooks/useLogin";
import { LoginCompanyIcon } from "assets/icon/index";

const Login = () => {
  const { register, watch, isActiveLoginBtn, isLoginErr, handleLogin } =
    useLogin();
  const {
    isFocus: isIdFocus,
    handleFocusInput: handleIdFocusInput,
    handleBlurInput: handleIdBlurInput,
  } = useAccountInput(watch("email"));
  const {
    isFocus: isPasswordFocus,
    handleFocusInput: handlePasswordFocusInput,
    handleBlurInput: handlePasswordBlurInput,
  } = useAccountInput(watch("password"));

  return (
    <Root>
      <CustomIcon />
      <FormWrapper onSubmit={handleLogin}>
        <AccountInput
          name="email"
          register={register("email", {
            onBlur: handleIdBlurInput,
          })}
          value={watch("email")}
          isFocus={isIdFocus}
          handleFocusInput={handleIdFocusInput}
        />
        <AccountInput
          name="password"
          register={register("password", {
            onBlur: handlePasswordBlurInput,
          })}
          value={watch("password")}
          isFocus={isPasswordFocus}
          isLoginErr={!!isLoginErr}
          handleFocusInput={handlePasswordFocusInput}
        />
        {isLoginErr && <ErrMsg>아이디 또는 비밀번호가 틀렸습니다.</ErrMsg>}
        <LoginBtn disabled={!isActiveLoginBtn}>로그인</LoginBtn>
      </FormWrapper>
    </Root>
  );
};

export default Login;

const Root = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
`;

const FormWrapper = styled.form`
  width: 400px;
  margin: 0 auto;
`;

const CustomIcon = styled(LoginCompanyIcon)`
  margin: 0 auto 32px auto;
`;

const ErrMsg = styled.span`
  display: block;
  margin-bottom: 16px;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.error};
`;

const LoginBtn = styled.button`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.color.active};
  font: ${({ theme }) => theme.text.semibold_15};
  color: ${({ theme }) => theme.color.white};

  :hover {
    opacity: 0.6;
  }

  :disabled {
    background-color: ${({ theme }) => theme.color.disable};
  }
`;
