import { AxiosResponse } from "axios";
import { put, takeLatest, call } from "redux-saga/effects";

import * as statisticsAction from "store/reducer/statisticsSlice";
import * as API from "../api/service/statisticsApi";
import type { CumulativeDataRow } from "types/statistics";

function* multipleContainerSaga(action: {
  payload: { type: string; year: string };
  type: string;
}) {
  try {
    const { data }: AxiosResponse = yield call(
      API.fetchMultipleContainer,
      action.payload,
    );

    yield put({
      type: statisticsAction.multipleContainerSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: statisticsAction.multipleContainerFailure,
      payload: err,
    });
  }
}

export function* watchMultipleContainer() {
  yield takeLatest(
    statisticsAction.multipleContainer.type,
    multipleContainerSaga,
  );
}

function* serviceAssessmentSaga(action: {
  payload: { type: string; year: string };
  type: string;
}) {
  try {
    const { data }: AxiosResponse = yield call(
      API.fetchServiceAssessment,
      action.payload,
    );

    yield put({
      type: statisticsAction.serviceAssessmentSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: statisticsAction.serviceAssessmentFailure,
      payload: err,
    });
  }
}

export function* watchServiceAssessment() {
  yield takeLatest(
    statisticsAction.serviceAssessment.type,
    serviceAssessmentSaga,
  );
}

type CumulativeDataColumn = { cnt: number; per: string };

interface CumulativeData {
  delay: CumulativeDataColumn;
  lost: CumulativeDataColumn;
  reorder: CumulativeDataColumn;
}

function* cumulativeDataSaga() {
  try {
    const { data }: AxiosResponse<CumulativeData> = yield call(
      API.fetchCumulativeData,
    );

    const cumulativeData: CumulativeDataRow = {
      reorder: [
        { cnt: data.reorder.cnt },
        { per: data.reorder.per },
        { note: "-" },
      ],
      lost: [{ cnt: data.lost.cnt }, { per: data.lost.per }, { note: "-" }],
      delay: [{ cnt: data.delay.cnt }, { per: data.delay.per }, { note: "-" }],
    };

    yield put({
      type: statisticsAction.cumulativeDataSuccess,
      payload: cumulativeData,
    });
  } catch (err) {
    yield put({
      type: statisticsAction.cumulativeDataFailure,
      payload: err,
    });
  }
}

export function* watchCumulativeDataSaga() {
  yield takeLatest(statisticsAction.cumulativeData.type, cumulativeDataSaga);
}
