import { combineReducers } from "redux";

import common from "./commonSlice";
import persist from "./persistSlice";
import auth from "./authSlice";
import dashboard from "./dashboardSlice";
import statistics from "./statisticsSlice";
import toast from "./toastSlice";

const rootReducer = combineReducers({
  // slice 목록
  common,
  persist,
  auth,
  dashboard,
  statistics,
  toast,
});

export default rootReducer;
