import React from "react";
import styled from "styled-components";

import { TriangleIcon } from "assets/icon/index";
import usePagination from "./hooks/usePagination";
import { PageInfo } from "types/common";

interface PaginationProps {
  className?: string;
  pageInfo: PageInfo;
}

const Pagination = ({ className, pageInfo }: PaginationProps) => {
  const { currentPage, totalPages } = pageInfo;

  const {
    handlePrevPage,
    handleNextPage,
    handleClickNum,
    numList,
    isDisabledPrev,
    isDisabledNext,
  } = usePagination(currentPage, totalPages);

  return (
    <Root className={className}>
      <Wrapper>
        {numList.length !== 0 && (
          <>
            <ArrowBtn
              type="button"
              onClick={handlePrevPage}
              disabled={isDisabledPrev}
            >
              <TriangleIcon />
            </ArrowBtn>
            <NumWrapper>
              {numList.map((num, i) => {
                return (
                  <NumBtn
                    key={i}
                    type="button"
                    isCurrentPage={currentPage === num}
                    onClick={handleClickNum(num)}
                  >
                    {num}
                  </NumBtn>
                );
              })}
            </NumWrapper>
            <ArrowBtn
              type="button"
              onClick={handleNextPage}
              disabled={isDisabledNext}
            >
              <CustomLeftDoubleArrowIcon />
            </ArrowBtn>
          </>
        )}
      </Wrapper>
    </Root>
  );
};

const Root = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 0 0 auto;
  border-top: 1px solid ${({ theme }) => theme.color.gray_20};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};

  & > button {
    border-left: 1px solid ${({ theme }) => theme.color.gray_20};
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }
`;

const ArrowBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 36px;
  opacity: 0.7;

  & > svg {
    fill: ${({ theme }) => theme.color.gray_50};
  }

  :hover {
    background-color: ${({ theme }) => theme.color.gray_20};
  }
`;

const CustomLeftDoubleArrowIcon = styled(TriangleIcon)`
  transform: rotate(180deg);
`;

const NumWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }

  & > :last-child {
    border-right: 0;
  }
`;

const NumBtn = styled.button<{ isCurrentPage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font: ${({ theme, isCurrentPage }) =>
    isCurrentPage ? theme.text.semibold_14 : theme.text.regular_14};
  color: ${({ theme, isCurrentPage }) =>
    isCurrentPage ? theme.color.white : theme.color.gray_50};
  background-color: ${({ theme, isCurrentPage }) =>
    isCurrentPage && theme.color.active};
  opacity: 0.7;

  :hover {
    background-color: ${({ theme, isCurrentPage }) =>
      !isCurrentPage && theme.color.gray_20};
  }
`;

export default Pagination;
