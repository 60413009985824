import React from "react";
import styled from "styled-components";

import { ArrowIcon } from "assets/icon";
import useDropdownBtn from "./hooks/useDropdownBtn";

interface DropdownProps {
  list: { [key: string]: string | number };
  status: string | false;
  chageStatus: (orderId: string) => void;
}

const DropdownBtn = ({ list, status, chageStatus }: DropdownProps) => {
  const { isOpen, dropdownRef, handleClickDropdown } = useDropdownBtn();

  const handleChageStatus = (key: string) => () => {
    chageStatus(key);
    handleClickDropdown();
  };

  return (
    <Root ref={dropdownRef}>
      <OpenBtn type="button" onClick={handleClickDropdown} isOpen={isOpen}>
        {status}
        <ArrowIcon />
      </OpenBtn>
      {isOpen && (
        <ListWrapper>
          {Object.entries(list).map(([key, value]) => {
            return (
              <List key={key}>
                <ListBtn type="button" onClick={handleChageStatus(key)}>
                  {value}
                </ListBtn>
              </List>
            );
          })}
        </ListWrapper>
      )}
    </Root>
  );
};

export default DropdownBtn;

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ListWrapper = styled.ul`
  position: absolute;
  top: 20px;
  left: -13px;
  width: 105px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 8px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  z-index: ${({ theme }) => theme.zIndex.MODAL};
`;

const List = styled.li`
  width: 100%;
  height: 36px;
`;

const ListBtn = styled.button`
  width: inherit;
  padding: 8px;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_30};
  text-align: left;

  :hover {
    color: ${({ theme }) => theme.color.active};
    background-color: ${({ theme }) => theme.color.gray_10};
  }
`;

const OpenBtn = styled.button<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_50};

  & > svg {
    width: 14px;
    height: 14px;
    padding-left: 4px;
    transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
  }
`;
