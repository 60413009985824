import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFold: false,
};

const persistSlice = createSlice({
  name: "persist",
  initialState,
  reducers: {},
});
// export const {} = persistSlice.actions;
export default persistSlice.reducer;
