import { useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { changeStatus, uncollected } from "store/reducer/dashboardSlice";
import { RootState } from "store/configureStore";

const useUncollected = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const uncollectedState = useSelector(
    (state: RootState) => state.dashboard.uncollected.data,
  );
  const isLoading = useSelector(
    (state: RootState) => state.dashboard.uncollected.isLoading,
  );
  const isDone = useSelector(
    (state: RootState) => state.dashboard.uncollected.isDone,
  );
  const isDoneChangeStatus = useSelector(
    (state: RootState) => state.dashboard.changeStatus.isDone,
  );

  const chageStatus = useCallback(
    (key: string) => (status: string) => {
      dispatch({ type: changeStatus.type, payload: { key, status } });
    },
    [],
  );

  useEffect(() => {
    dispatch({
      type: uncollected.type,
      payload: { ...Object.fromEntries(searchParams), perPage: 30 },
    });
  }, [searchParams]);

  useEffect(() => {
    if (!isDoneChangeStatus) return;

    dispatch({
      type: uncollected.type,
      payload: { ...Object.fromEntries(searchParams), perPage: 30 },
    });
  }, [isDoneChangeStatus]);

  return {
    uncollectedState,
    isLoading,
    isDone,
    chageStatus,
  };
};

export default useUncollected;
