export const PATH = {
  root: "/",
  expired: "/expired",
  notFound: "/notFound",
  dashboard: "/dashboard",
  live: "/dashboard/live",
  uncollected: "/dashboard/uncollected",
  statistics: "/statistics",
};

export const LAYOUT_TYPE = Object.freeze({
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
});

export const WEEKS = ["일", "월", "화", "수", "목", "금", "토"]; // 일주일 배열
