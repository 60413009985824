import React from "react";
import styled from "styled-components";

interface SectionLayoutProps {
  heading?: string;
  children: React.ReactNode;
}

const SectionLayout = ({ heading, children }: SectionLayoutProps) => {
  return (
    <Root>
      {heading && <Heading>{heading}</Heading>}
      {children}
    </Root>
  );
};

export default SectionLayout;

const Root = styled.section``;

const Heading = styled.h3`
  margin-bottom: 8px;
  font: ${({ theme }) => theme.text.semibold_18};
  color: ${({ theme }) => theme.color.gray_60};
`;
