import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { secondDistrict, thirdDistrict } from "assets/static/sido";
import type { DistrictSelectValue } from "types/common";

const useDistrictDropdown = () => {
  const [searchParams] = useSearchParams();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reg, setReg] = useState<DistrictSelectValue>(
    String(
      searchParams.get("third")
        ? searchParams.get("third")
        : searchParams.get("second")
        ? searchParams.get("second")
        : searchParams.get("first")
        ? searchParams.get("first")
        : "1000000000",
    ),
  );
  const [first, setFirst] = useState(searchParams.get("first") ?? "");
  const [second, setSecond] = useState(searchParams.get("second") ?? "");
  const [third, setThird] = useState(searchParams.get("third") ?? "");

  const secondDistrictList = first ? secondDistrict[parseInt(first)] : {};

  const thirdDistrictList = second
    ? thirdDistrict[parseInt(first)][parseInt(second)]
    : {};

  const handleOpenDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleReset = useCallback(() => {
    setReg("1000000000");
    setFirst("");
    setSecond("");
    setThird("");
  }, []);

  const handleClickFirstDistrict = useCallback(
    (key: string) => () => {
      setFirst(key);
      setSecond("");
    },
    [],
  );

  const handleClickSecondDistrict = useCallback(
    (key: string) => () => {
      setSecond(key);
      setThird("");
    },
    [],
  );

  const handleClickThirdDistrict = useCallback(
    (key: string) => () => {
      setThird(key);
    },
    [],
  );

  const handleClickSubmit = useCallback(() => {
    setIsOpen(false);

    const isNoSelect = first === "" && second === "" && third === "";
    if (isNoSelect) return;

    if (third) {
      setReg(third);
    } else if (second) {
      setReg(second);
    } else if (first) {
      setReg(first);
    }
  }, [first, second, third]);

  useEffect(() => {
    const handleCloseDropdown = (e: MouseEvent) => {
      if (!dropdownRef.current) return;

      if (isOpen && !dropdownRef.current.contains(e.target as HTMLElement)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("mousedown", handleCloseDropdown);

    return () => {
      window.addEventListener("mousedown", handleCloseDropdown);
    };
  }, [isOpen]);

  const getDistrictDropdownProps = ({ ...otherProps } = {}) => ({
    isOpen,
    ref: dropdownRef,
    first,
    second,
    third,
    secondDistrictList,
    thirdDistrictList,
    handleOpenDropdown,
    handleReset,
    handleClickFirstDistrict,
    handleClickSecondDistrict,
    handleClickThirdDistrict,
    handleClickSubmit,
    ...otherProps,
  });

  return {
    reg,
    first,
    second,
    third,
    getDistrictDropdownProps,
    handleReset,
  };
};

export default useDistrictDropdown;
