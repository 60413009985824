import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import type { DistrictSelectValue } from "types/common";

const useFilter = (
  reg: DistrictSelectValue,
  first: string,
  second: string,
  third: string,
  handleDistrictReset: () => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [date, setDate] = useState(searchParams.get("date") ?? "");
  const [searchValue, setSearchValue] = useState(
    searchParams.get("orderId") ?? "",
  );

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSearchParams({
      ...Object.fromEntries(searchParams),
      orderId: searchValue,
    });
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClickSubmit = () => {
    const makeLoc = (first: string, second: string, third: string) => {
      if (third) {
        if (third === second) {
          return 2;
        } else {
          return 3;
        }
      }

      if (second) {
        if (second === first) {
          return 1;
        } else {
          return 2;
        }
      }

      if (first) {
        if (first === "1000000000") {
          return 0;
        } else {
          return 1;
        }
      }

      return 0;
    };

    const makeReg = (first: string, second: string, third: string) => {
      if (third) {
        if (third === second) {
          return second.substr(0, 4);
        } else {
          return third;
        }
      }

      if (second) {
        if (second === first) {
          return first.substr(0, 2);
        } else {
          return second.substr(0, 4);
        }
      }

      if (first) {
        return first.substr(0, 2);
      }
    };

    setSearchParams({
      type: String(searchParams.get("type")),
      ...(searchValue && { orderId: searchValue }),
      ...(searchParams.get("completedSort") && {
        completedSort: String(searchParams.get("completedSort")),
      }),
      ...(searchParams.get("createdSort") && {
        createdSort: String(searchParams.get("createdSort")),
      }),
      loc: String(makeLoc(first, second, third)),
      ...((first || second || third) &&
        first !== "1000000000" && {
          reg: String(makeReg(first, second, third)),
        }),
      ...(first && { first }),
      ...(second && { second }),
      ...(third && { third }),
      ...(date && { date }),
    });
  };

  const handleClickReset = () => {
    setDate("");
    setSearchValue("");
    handleDistrictReset();

    if (searchParams.get("type")) {
      setSearchParams({
        type: String(searchParams.get("type")),
        loc: "0",
      });
    }
  };

  return {
    date,
    setDate,
    searchValue,
    handleSearch,
    handleChangeInput,
    handleClickSubmit,
    handleClickReset,
  };
};

export default useFilter;
