import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: { isOpen: false, modalComponent: null },
};

const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    modalOpen(state, action) {
      state.modal.isOpen = true;
      state.modal.modalComponent = action.payload.modalComponent;
    },
    modalClose(state) {
      state.modal.isOpen = false;
      state.modal.modalComponent = null;
    },
  },
});

export const { modalOpen, modalClose } = common.actions;
export default common.reducer;
