import { all, fork } from "redux-saga/effects";

import { watchLogin, watchLogout } from "./authSaga";
import {
  watchLive,
  watchLiveExcel,
  watchUncollected,
  watchUncollectedExcel,
  watchChangeStatus,
} from "./dashboardSaga";
import {
  watchMultipleContainer,
  watchServiceAssessment,
  watchCumulativeDataSaga,
} from "./statisticsSaga";

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchLive),
    fork(watchLiveExcel),
    fork(watchChangeStatus),
    fork(watchUncollected),
    fork(watchUncollectedExcel),
    fork(watchMultipleContainer),
    fork(watchServiceAssessment),
    fork(watchCumulativeDataSaga),
  ]);
}
