import { useState, useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";

import { getMonthYearDetails, getNewMonthYear } from "utils/helper/monthYear";

const useDatePicker = (initDate: Dayjs) => {
  const initMonthYear = getMonthYearDetails(initDate ?? dayjs());

  const [monthYear, setMonthYear] = useState(initMonthYear);

  const changePrevMonthYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, -1));
  }, []);

  const changeNextMonthYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, 1));
  }, []);

  const changePrevYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, -12));
  }, []);

  const changeNextYear = useCallback(() => {
    setMonthYear((prevData) => getNewMonthYear(prevData, 12));
  }, []);

  const changeCurrentMonthYear = useCallback(() => {
    const currentMonthYear = getMonthYearDetails(dayjs());
    setMonthYear(currentMonthYear);
  }, []);

  const changeMonthYear = useCallback((date: Dayjs | string) => {
    const monthYear = getMonthYearDetails(dayjs(date));
    setMonthYear(monthYear);
  }, []);

  return {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    changePrevYear,
    changeNextYear,
  };
};

export default useDatePicker;
