import React from "react";
import styled from "styled-components";

import { SearchBlackIcon } from "assets/icon";

interface SearchInputProps {
  searchValue: string;
  handleSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput = ({
  searchValue,
  handleSearch,
  handleChangeInput,
}: SearchInputProps) => {
  return (
    <Root onSubmit={handleSearch}>
      <SearchBlackIcon />
      <Input
        placeholder="Order Id로 검색"
        onChange={handleChangeInput}
        value={searchValue}
      />
    </Root>
  );
};

export default SearchInput;

const Root = styled.form`
  display: flex;
  align-items: center;
  width: 470px;
  height: 44px;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 0 20px;

  svg {
    opacity: 0.4;
    fill: ${({ theme }) => theme.color.gray_60};
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  margin-left: 12px;
  border: 0;
  font: ${({ theme }) => theme.text.regular_14};

  :focus {
    outline: none;
  }
`;
