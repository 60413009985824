import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Filter } from "components/dashboard/filter/index";
import { Table } from "components/common/table/index";
import { Pagination } from "components/common/pagination";
import { ExcelBtn } from "components/common/button";
import { RootState } from "store/configureStore";
import { liveXList } from "assets/static/table";
import QrModal from "components/common/modal/QrModal";
import useModal from "hooks/useModal";
import useLive from "./hooks/useLive";

import useExcelBtn from "components/common/button/hooks/useExcelBtn";
import { RatingModal } from "components/common/modal";
import { numberComma } from "utils/helper/formatter";
import { DropdownBtn } from "components/common/dropdown";
import { dashboardStatus } from "assets/static/status";

const Live = () => {
  const { handleClickOpenModal } = useModal();
  const { modalComponent } = useSelector(
    (state: RootState) => state.common.modal,
  );

  const { liveState, isLoading, isDone, chageStatus } = useLive();
  const { isLiveExcelLoading, handleExcelDown } = useExcelBtn();

  return (
    <Root>
      <Filter />
      <CustomTable
        heading={`목록 (${numberComma(liveState?.pageInfo.totalData ?? 0)})`}
        list={liveXList}
        isLoading={isLoading}
      >
        {isLoading ? (
          <CustomLoadingTable list={liveXList} />
        ) : isDone && liveState?.list.length ? (
          liveState.list.map((item, rowI) => {
            return (
              <Table.BodyRow key={rowI} status={item.status}>
                {Object.keys(liveXList).map((key, i) => {
                  switch (key) {
                    case "containers":
                      return (
                        <Table.Td key={key + i}>
                          {item[key].length !== 0 ? (
                            <CountBtn
                              type="button"
                              onClick={handleClickOpenModal({
                                modalComponent: <QrModal data={item[key]} />,
                              })}
                            >
                              {item.containers}
                            </CountBtn>
                          ) : (
                            0
                          )}
                        </Table.Td>
                      );

                    case "rating":
                      if (item[key] !== "-") {
                        return (
                          <Table.Td key={key + i}>
                            <CountBtn
                              type="button"
                              onClick={handleClickOpenModal({
                                modalComponent: (
                                  <RatingModal
                                    rating={item[key]}
                                    data={item.reviewComment as string}
                                  />
                                ),
                              })}
                            >
                              {item[key]}
                            </CountBtn>
                          </Table.Td>
                        );
                      } else {
                        return <Table.Td key={key + i}>{item[key]}</Table.Td>;
                      }

                    case "addr":
                    case "doorlockPassword":
                    case "phone":
                    case "orderId":
                      return (
                        <Table.LeftTd key={key + i}>{item[key]}</Table.LeftTd>
                      );
                    case "status":
                      return (
                        <StatusWrapper key={key + i}>
                          <DropdownBtn
                            list={dashboardStatus}
                            status={typeof item[key] === "string" && item[key]}
                            chageStatus={chageStatus(item['key'] as string)}
                          />
                        </StatusWrapper>
                      );
                    default:
                      return <Table.Td key={key + i}>{item[key]}</Table.Td>;
                  }
                })}
              </Table.BodyRow>
            );
          })
        ) : (
          <Table.NoData />
        )}
      </CustomTable>
      <Wrapper>
        {liveState?.list.length !== 0 && (
          <ExcelBtn
            isLoading={isLiveExcelLoading}
            handleExcelDown={handleExcelDown}
          />
        )}
        {liveState && <Pagination pageInfo={liveState.pageInfo} />}
      </Wrapper>
      {modalComponent}
    </Root>
  );
};

export default Live;

const Root = styled.div`
  display: flex;
  flex-flow: column;
`;

const CustomTable = styled(Table)`
  & > thead > tr,
  & > tbody > tr {
    align-items: center;
    grid-template-columns:
      60px 106px 60px 208px 130px
      500px
      90px 108px 110px 110px 126px 74px 68px 90px;
  }
`;

const CustomLoadingTable = styled(Table.LoadingTable)`
  grid-template-columns:
    60px 106px 60px 208px 130px
    500px
    90px 108px 110px 110px 126px 74px 68px 90px;
`;

const CountBtn = styled.button`
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.blue};
  text-decoration: underline;

  :hover {
    opacity: 0.5;
  }
`;

const StatusWrapper = styled(Table.Td)`
  margin: auto 0;
  padding: 0 12px;
`;


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
