import React, { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";

import { useDialog, useCalendar } from "hooks/index";
import { WEEKS } from "utils/constants";
import Date from "./Date";
import { ArrowIcon } from "assets/icon/index";

const MAX_GRID_CELL = 42;

interface DatePickerProps {
  className?: string;
  initValue: string;
  cb: (newDate: any) => void;
  trigger: ({
    handleToggleDialogOpen,
    isDialogOpen,
  }: {
    handleToggleDialogOpen: (e: React.MouseEvent) => void;
    isDialogOpen: boolean;
  }) => React.ReactNode;
  isDisabledBeforeToday?: boolean;
}

const DatePicker = ({
  className,
  initValue,
  cb,
  trigger,
  isDisabledBeforeToday,
}: DatePickerProps) => {
  const [searchParams] = useSearchParams();

  const { isDialogOpen, dialogRef, handleToggleDialogOpen, handleDialogClose } =
    useDialog();

  const {
    monthYear,
    selectedDate,
    handleSelectDate,
    changePrevMonthYear,
    changeNextMonthYear,
    changeMonthYear,
  } = useCalendar(
    searchParams.get("date") ? dayjs(searchParams.get("date")) : dayjs(),
  );

  const handleClickDate = (date: Dayjs) => () => {
    handleSelectDate(date);
  };

  const handleSubmit = () => {
    if (selectedDate) {
      const newDate = dayjs(selectedDate).format("YYYY-MM-DD");
      typeof cb === "function" && cb(newDate);

      changeMonthYear(selectedDate);
    }

    handleDialogClose();
  };

  const handleReset = () => {
    cb("");
    handleSelectDate("");
    changeMonthYear(dayjs());
  };

  useLayoutEffect(() => {
    if (isDialogOpen) {
      const date = searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : dayjs();
      handleSelectDate(initValue);
      changeMonthYear(selectedDate || date);
    }
  }, [isDialogOpen]);

  return (
    <Root>
      {typeof trigger === "function" &&
        trigger({ handleToggleDialogOpen, isDialogOpen })}
      {isDialogOpen && (
        <DialogRoot
          className={className}
          open={isDialogOpen}
          ref={dialogRef}
          aria-modal="true"
        >
          <Header>
            <Navigation>
              <TimeWrapper>
                <Year>{monthYear.year}년</Year>
                <time>{monthYear.month}월</time>
              </TimeWrapper>
              <ArrowBtnWrapper>
                <button
                  type="button"
                  aria-label="이전 달 변경"
                  onClick={changePrevMonthYear}
                >
                  <LeftArrow />
                </button>
                <button
                  type="button"
                  aria-label="다음 달 변경"
                  onClick={changeNextMonthYear}
                >
                  <RightArrow />
                </button>
              </ArrowBtnWrapper>
            </Navigation>
          </Header>
          <DayWrapper>
            <Weeks>
              {WEEKS.map((day) => {
                return <WeekLi key={day}>{day}</WeekLi>;
              })}
            </Weeks>
            <Dates>
              {[...Array(monthYear.firstDOW)].map((_, i) => (
                <Date
                  key={i}
                  date={monthYear.firstWeekPrevMonthDate.add(i, "d")}
                  disabled
                  handleSelectDate={handleClickDate}
                />
              ))}
              {[...Array(monthYear.lastDate)].map((_, i) => (
                <Date
                  key={i}
                  date={monthYear.startDate.add(i, "d")}
                  selectedDate={selectedDate}
                  isDisabledBeforeToday={isDisabledBeforeToday}
                  handleSelectDate={handleClickDate}
                />
              ))}
              {[
                ...Array(
                  MAX_GRID_CELL - (monthYear.firstDOW + monthYear.lastDate),
                ),
              ].map((_, i) => (
                <Date
                  key={i}
                  date={monthYear.nextMonthStartDate.add(i, "d")}
                  disabled
                  handleSelectDate={handleClickDate}
                />
              ))}
            </Dates>
          </DayWrapper>
          <BtnWrapper>
            <ResetBtn type="button" onClick={handleReset}>
              날짜 초기화
            </ResetBtn>
            <SubmitBtn type="button" onClick={handleSubmit}>
              적용하기
            </SubmitBtn>
          </BtnWrapper>
        </DialogRoot>
      )}
    </Root>
  );
};

export default DatePicker;

const Root = styled.div`
  position: relative;
`;

const DialogRoot = styled.dialog`
  position: absolute;
  top: 49px;
  left: calc(100% - 327px);
  width: 328px;
  height: 429px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 28px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Header = styled.header`
  margin-bottom: 21px;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;

  & > button {
    width: 24px;
    height: 24px;
  }

  time {
    font: ${({ theme }) => theme.text.semibold_18};
    color: ${({ theme }) => theme.color.gray_60};
    display: block;
    text-align: center;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
`;

const ArrowBtnWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

const Year = styled.time`
  display: block;
  margin-right: 4px;
`;

const DayWrapper = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_10};
  padding-bottom: 16px;
`;

const Weeks = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const Dates = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 8px;
`;

const WeekLi = styled.li`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 17px;
  margin-bottom: 6px;
  font: ${({ theme }) => theme.text.medium_12};
  opacity: 0.4;
`;

const LeftArrow = styled(ArrowIcon)`
  transform: rotate(90deg);
`;

const RightArrow = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

const SubmitBtn = styled.button`
  padding: 8px 16px;
  font: ${({ theme }) => theme.text.semibold_14};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.active};

  :hover {
    opacity: 0.6;
  }
`;

const ResetBtn = styled(SubmitBtn)`
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  font: ${({ theme }) => theme.text.medium_14};
  color: ${({ theme }) => theme.color.gray_30};
  background-color: ${({ theme }) => theme.color.white};

  :hover {
    background-color: ${({ theme }) => theme.color.gray_10};
    opacity: 1;
  }
`;
