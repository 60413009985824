import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

interface Toast {
  id: string;
  content: string;
}

interface InitialState {
  toast: Toast[];
}

const initialState: InitialState = {
  toast: [],
};

const toast = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast(state, action) {
      const toast = { ...action.payload, id: uuidv4() };
      state.toast = [toast, ...state.toast];
    },
    removeToast(state, action) {
      const { id } = action.payload;
      state.toast = state.toast.filter((item) => item.id !== id);
    },
  },
});

export const { addToast, removeToast } = toast.actions;
export default toast.reducer;
