import React, { forwardRef, ForwardedRef } from "react";
import styled from "styled-components";

import { ArrowIcon } from "assets/icon/index";
import { firstDistrict, totalSido } from "assets/static/sido";
import type {
  DistrictSelectValue,
  SecondDistrictList,
  ThirdDistrictList,
} from "types/common";

interface DistrictDropdownProps {
  isOpen: boolean;
  reg?: DistrictSelectValue;
  first: string;
  second: string;
  third: string;
  secondDistrictList: SecondDistrictList | undefined;
  thirdDistrictList: ThirdDistrictList | undefined;
  handleOpenDropdown: () => void;
  handleReset: () => void;
  handleClickFirstDistrict: (key: string) => () => void;
  handleClickSecondDistrict: (key: string) => () => void;
  handleClickThirdDistrict: (key: string) => () => void;
  handleClickSubmit: () => void;
}

const DistrictDropdown = (
  {
    isOpen,
    reg,
    first,
    second,
    third,
    secondDistrictList,
    thirdDistrictList,
    handleOpenDropdown,
    handleReset,
    handleClickFirstDistrict,
    handleClickSecondDistrict,
    handleClickThirdDistrict,
    handleClickSubmit,
  }: DistrictDropdownProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const makeSelectValue = (reg: DistrictSelectValue | undefined) => {
    if (reg === undefined) return;

    if (reg === "1000000000") {
      return "전체";
    } else {
      return totalSido[parseInt(reg)];
    }
  };

  return (
    <Root ref={ref}>
      <ValueWrapper type="button" isOpen={isOpen} onClick={handleOpenDropdown}>
        {makeSelectValue(reg)}
        <ArrowIcon />
      </ValueWrapper>
      {isOpen && (
        <DropDownWrapper>
          <DisDrictWrapper>
            <FirstDropDownWrapper>
              {Object.entries(firstDistrict).map(([key, value], i) => {
                return (
                  <li key={i}>
                    <List
                      type="button"
                      isClicked={key === first}
                      onClick={handleClickFirstDistrict(key)}
                    >
                      {value}
                    </List>
                  </li>
                );
              })}
            </FirstDropDownWrapper>
            <SecondDropDownWrapper
              isSelect={
                secondDistrictList !== undefined &&
                Object.keys(secondDistrictList).length !== 0
              }
            >
              {secondDistrictList &&
              Object.keys(secondDistrictList).length !== 0 ? (
                Object.entries(secondDistrictList).map(([key, value], i) => {
                  return (
                    <li key={i}>
                      <List
                        type="button"
                        isClicked={key === second}
                        onClick={handleClickSecondDistrict(key)}
                      >
                        {value}
                      </List>
                    </li>
                  );
                })
              ) : (
                <NoMainDistrict>시,도를 선택해 주세요</NoMainDistrict>
              )}
            </SecondDropDownWrapper>
            <ThirdDropDownWrapper
              isSelect={
                thirdDistrictList !== undefined &&
                Object.keys(thirdDistrictList).length !== 0
              }
            >
              {thirdDistrictList &&
              Object.keys(thirdDistrictList).length !== 0 ? (
                Object.entries(thirdDistrictList).map(([key, value], i) => (
                  <li key={i}>
                    <List
                      type="button"
                      isClicked={key === third}
                      onClick={handleClickThirdDistrict(key)}
                    >
                      {value}
                    </List>
                  </li>
                ))
              ) : (
                <NoMainDistrict>시,군,구를 선택해 주세요</NoMainDistrict>
              )}
            </ThirdDropDownWrapper>
          </DisDrictWrapper>
          <BtnWrapper>
            <CancelBtn type="button" onClick={handleReset}>
              초기화
            </CancelBtn>
            <SubmitBtn type="button" onClick={handleClickSubmit}>
              적용하기
            </SubmitBtn>
          </BtnWrapper>
        </DropDownWrapper>
      )}
    </Root>
  );
};

export default forwardRef(DistrictDropdown);

const Root = styled.div`
  position: relative;
  min-width: 376px;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.NAV};
`;

const ValueWrapper = styled.button<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font: ${({ theme }) => theme.text.regular_15};
  color: ${({ theme }) => theme.color.gray_60};

  & > svg {
    transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
  }
`;

const DropDownWrapper = styled.div`
  position: absolute;
  top: 52px;
  width: 469px;
  background-color: ${({ theme }) => theme.color.white};
`;

const DisDrictWrapper = styled.div`
  display: flex;
`;

const FirstDropDownWrapper = styled.ul`
  width: 188px;
  height: 250px;
  padding: 8px 0 0 9px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  overflow-y: scroll;

  & > li:hover {
    background-color: ${({ theme }) => theme.color.gray_10};
  }
`;

const SecondDropDownWrapper = styled(FirstDropDownWrapper)<{
  isSelect: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-left: 0;
  overflow-y: auto;
  padding: ${({ isSelect }) => (isSelect ? "8px 0 0 9px" : "0")};
`;

const ThirdDropDownWrapper = styled(SecondDropDownWrapper)`
  padding: ${({ isSelect }) => (isSelect ? "8px 0 0 9px" : "0")};
`;

const List = styled.button<{ isClicked: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 8px;
  font: ${({ theme, isClicked }) =>
    isClicked ? theme.text.bold_14 : theme.text.regular_14};
  color: ${({ theme, isClicked }) =>
    isClicked ? theme.color.active : theme.color.gray_30};
`;

const NoMainDistrict = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_30};
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  border-top: 0;
  padding: 12px;
`;

const CancelBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 8px 16px;
  font: ${({ theme }) => theme.text.medium_14};
  color: ${({ theme }) => theme.color.gray_30};

  :hover {
    background-color: ${({ theme }) => theme.color.gray_10};
    opacity: 1;
  }
`;

const SubmitBtn = styled.button`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.color.active};
  font: ${({ theme }) => theme.text.semibold_14};
  color: ${({ theme }) => theme.color.white};

  :hover {
    opacity: 0.6;
  }
`;
