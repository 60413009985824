import React from "react";
import styled from "styled-components";

import XYTable from "components/common/table/XYTable";
import { formatCellWidth } from "utils/helper/formatter";
import useMultipleContainer from "./hooks/useMultipleContainer";
import { YearDropdown } from "components/common/dropdown";
import {
  multipleContainerXList,
  multipleContainerYList,
} from "assets/static/table";

const UsePerformance = () => {
  const { multipleContainerState } = useMultipleContainer();

  return (
    <>
      <YearDropdown />
      {multipleContainerState ? (
        <CustomXYTable xList={multipleContainerXList}>
          {Object.entries(multipleContainerState).map(([key, value], i) => {
            return (
              <XYTable.BodyRow key={key + i}>
                <XYTable.Th>
                  {
                    multipleContainerYList[
                      key as keyof typeof multipleContainerYList
                    ]
                  }
                </XYTable.Th>
                {Object.entries(value).map(([key, value], i) => (
                  <td key={key + i}>{value}</td>
                ))}
              </XYTable.BodyRow>
            );
          })}
        </CustomXYTable>
      ) : (
        <CustomXYTable xList={multipleContainerXList}>
          <CustomLoadingTable
            xlist={multipleContainerXList}
            ylist={multipleContainerYList}
          />
        </CustomXYTable>
      )}
    </>
  );
};

export default UsePerformance;

const CustomXYTable = styled(XYTable)`
  height: auto;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.gray_20};

  & > thead > tr,
  & > tbody > tr {
    grid-template-columns: ${formatCellWidth(285)} repeat(13, 1fr);
    border-left: 1px solid ${({ theme }) => theme.color.gray_20};
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }

  & > tbody > tr:nth-of-type(4) {
    margin-bottom: 14px;
  }

  & > tbody > tr:nth-of-type(5) {
    border-top: 1px solid ${({ theme }) => theme.color.gray_20};
  }

  & > tbody > tr:nth-of-type(1) :last-child,
  & > tbody > tr:nth-of-type(2) :last-child,
  & > tbody > tr:nth-of-type(3) :last-child,
  & > tbody > tr:nth-of-type(4) :last-child,
  & > tbody > tr:nth-of-type(5) :last-child,
  & > tbody > tr:nth-of-type(6) :last-child {
    background-color: ${({ theme }) => theme.color.xyTable_total};
  }
`;

const CustomLoadingTable = styled(XYTable.LoadingTable)`
  grid-template-columns: ${formatCellWidth(285)} repeat(13, 1fr);
`;
