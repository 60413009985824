import React from "react";
import styled from "styled-components";

import { Filter } from "components/dashboard/filter/index";
import { Table } from "components/common/table/index";
import useUncollected from "./hooks/useUncollected";
import { livePhoneOrderList } from "assets/static/table";
import { Pagination } from "components/common/pagination";
import useExcelBtn from "components/common/button/hooks/useExcelBtn";
import { ExcelBtn } from "components/common/button";
import { DropdownBtn } from "components/common/dropdown";
import { dashboardStatus } from "assets/static/status";
import { numberComma } from "utils/helper/formatter";
import { useModal } from "hooks";
import { useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import { QrModal, RatingModal } from "components/common/modal";

const Uncollected = () => {
  const { handleClickOpenModal } = useModal();
  const { modalComponent } = useSelector(
    (state: RootState) => state.common.modal,
  );

  const { uncollectedState, isLoading, isDone, chageStatus } = useUncollected();
  const { isUncollectedExcelLoading, handleExcelDown } = useExcelBtn();

  return (
    <Root>
      {/* <Filter mode="phone" /> */}
      <CustomTable
        heading={`목록 (${numberComma(
          uncollectedState?.pageInfo.totalData ?? 0,
        )})`}
        list={livePhoneOrderList}
        isLoading={isLoading}
      >
        {isLoading ? (
          <CustomLoadingTable list={livePhoneOrderList} />
        ) : isDone && uncollectedState?.list.length ? (
          uncollectedState.list.map((item, rowI) => {
            return (
              <Table.BodyRow key={rowI} status={item.status}>
                {Object.keys(livePhoneOrderList).map((key, i) => {
                  switch (key) {
                    case "containers":
                      return (
                        <Table.Td key={key + i}>
                          {item[key].length !== 0 ? (
                            <CountBtn
                              type="button"
                              onClick={handleClickOpenModal({
                                modalComponent: <QrModal data={item[key]} />,
                              })}
                            >
                              {item.containers}
                            </CountBtn>
                          ) : (
                            0
                          )}
                        </Table.Td>
                      );

                    case "addr":
                    case "doorlockPassword":
                    case "phone":
                    case "orderId":
                      return (
                        <Table.LeftTd key={key + i}>{item[key]}</Table.LeftTd>
                      );
                    case "status":
                      return (
                        <StatusWrapper key={key + i}>
                          <DropdownBtn
                            list={dashboardStatus}
                            status={typeof item[key] === "string" && item[key]}
                            chageStatus={chageStatus(item['key'] as string)}
                          />
                        </StatusWrapper>
                      );
                    default:
                      return <Table.Td key={key + i}>{item[key]}</Table.Td>;
                  }
                })}
              </Table.BodyRow>
            );
          })
        ) : (
          <Table.NoData />
        )}
      </CustomTable>
      <Wrapper>
        {uncollectedState?.list.length !== 0 && (
          <ExcelBtn
            isLoading={isUncollectedExcelLoading}
            handleExcelDown={handleExcelDown}
          />
        )}
        {uncollectedState && (
          <Pagination pageInfo={uncollectedState.pageInfo} />
        )}
      </Wrapper>
      {modalComponent}
    </Root>
  );
};

export default Uncollected;

const Root = styled.div`
  display: flex;
  flex-flow: column;
`;

const CustomTable = styled(Table)`
  & > thead > tr,
  & > tbody > tr {
    grid-template-columns: 
      60px 106px 60px 130px
      500px
      90px 108px 110px 110px 126px 110px;
  }
`;

const CustomLoadingTable = styled(Table.LoadingTable)`
  grid-template-columns: 
    60px 106px 60px 130px
    500px
    90px 108px 110px 110px 126px 110px;
`;

const CountBtn = styled.button`
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.blue};
  text-decoration: underline;

  :hover {
    opacity: 0.5;
  }
`;

const StatusWrapper = styled(Table.Td)`
  margin: auto 0;
  padding: 0 12px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
