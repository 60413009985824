import { ax } from "api/axios";

interface MultipleContainer {
  type: string;
  year: string;
}

interface ServiceAssessment {
  type: string;
  year: string;
}

export const fetchMultipleContainer = (params: MultipleContainer) => {
  return ax.get("/container", { params });
};

export const fetchServiceAssessment = (params: ServiceAssessment) => {
  return ax.get("/review", { params });
};

export const fetchCumulativeData = () => {
  return ax.get("/total");
};
