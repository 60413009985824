import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { CumulativeDataRow } from "types/statistics";

interface MonthData {
  [key: string]: number;
}

interface MultipleContainer {
  isLoading: boolean;
  isDone: boolean;
  error: null | Error;
  data: null | { [key: string]: MonthData };
}

interface ServiceAssessment {
  isLoading: boolean;
  isDone: boolean;
  error: null | Error;
  data: null | { [key: string]: MonthData };
}

interface CumulativeData {
  isLoading: boolean;
  isDone: boolean;
  error: null | Error;
  data: null | CumulativeDataRow;
}

const initialState: {
  multipleContainer: MultipleContainer;
  serviceAssessment: ServiceAssessment;
  cumulativeData: CumulativeData;
} = {
  multipleContainer: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
  serviceAssessment: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
  cumulativeData: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    multipleContainer(state, action) {
      state.multipleContainer.isLoading = true;
      state.multipleContainer.isDone = false;
      state.multipleContainer.error = null;
    },
    multipleContainerSuccess(
      state,
      action: PayloadAction<{ [key: string]: MonthData }>,
    ) {
      state.multipleContainer.isLoading = false;
      state.multipleContainer.isDone = true;
      state.multipleContainer.error = null;
      state.multipleContainer.data = action.payload;
    },
    multipleContainerFailure(state, action: PayloadAction<Error>) {
      state.multipleContainer.isLoading = false;
      state.multipleContainer.isDone = true;
      state.multipleContainer.error = action.payload;
    },
    multipleContainerReset(state) {
      state.multipleContainer.isLoading = false;
      state.multipleContainer.isDone = false;
      state.multipleContainer.error = null;
      state.multipleContainer.data = null;
    },
    serviceAssessment(state, action) {
      state.serviceAssessment.isLoading = true;
      state.serviceAssessment.isDone = false;
      state.serviceAssessment.error = null;
    },
    serviceAssessmentSuccess(
      state,
      action: PayloadAction<{ [key: string]: MonthData }>,
    ) {
      state.serviceAssessment.isLoading = false;
      state.serviceAssessment.isDone = true;
      state.serviceAssessment.error = null;
      state.serviceAssessment.data = action.payload;
    },
    serviceAssessmentFailure(state, action: PayloadAction<Error>) {
      state.serviceAssessment.isLoading = false;
      state.serviceAssessment.isDone = true;
      state.serviceAssessment.error = action.payload;
    },
    cumulativeData(state) {
      state.cumulativeData.isLoading = true;
      state.cumulativeData.isDone = false;
      state.cumulativeData.error = null;
    },
    cumulativeDataSuccess(state, action: PayloadAction<CumulativeDataRow>) {
      state.cumulativeData.isLoading = false;
      state.cumulativeData.isDone = true;
      state.cumulativeData.error = null;
      state.cumulativeData.data = action.payload;
    },
    cumulativeDataFailure(state, action: PayloadAction<Error>) {
      state.cumulativeData.isLoading = false;
      state.cumulativeData.isDone = true;
      state.cumulativeData.error = action.payload;
    },
  },
});

export const {
  multipleContainer,
  multipleContainerSuccess,
  multipleContainerFailure,
  multipleContainerReset,
  serviceAssessment,
  serviceAssessmentSuccess,
  serviceAssessmentFailure,
  cumulativeData,
  cumulativeDataSuccess,
  cumulativeDataFailure,
} = statisticsSlice.actions;
export default statisticsSlice.reducer;
