import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configureStore";

import { cumulativeData } from "store/reducer/statisticsSlice";

const useCumulativeData = () => {
  const dispatch = useDispatch();
  const cumulativeDataState = useSelector(
    (state: RootState) => state.statistics.cumulativeData.data,
  );

  useEffect(() => {
    dispatch({ type: cumulativeData.type });
  }, []);

  return { cumulativeDataState };
};

export default useCumulativeData;
