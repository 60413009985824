import React from "react";
import styled from "styled-components";

import ModalRoot from "./ModalRoot";
import useModal from "hooks/useModal";
import { CloseIcon } from "assets/icon/index";

interface RatingModalProps {
  rating: string;
  data: string;
}

const RatingModal = ({ rating, data }: RatingModalProps) => {
  const { closeBtnRef, handleClickCloseModal } = useModal();

  return (
    <ModalRoot>
      <Root open>
        <Heading>이용 후기</Heading>
        <CloseBtn
          id="modal-close-btn"
          type="button"
          ref={closeBtnRef}
          onClick={handleClickCloseModal}
        >
          <CloseIcon />
        </CloseBtn>
        <ListWrapper>
          <ContentWrapper>
            <RatingHeading>Rating</RatingHeading>
            <RatingContent>{rating}</RatingContent>
          </ContentWrapper>
          <ContentWrapper>
            <ContentHeading>내용</ContentHeading>
            <Content>{data}</Content>
          </ContentWrapper>
        </ListWrapper>
      </Root>
    </ModalRoot>
  );
};

export default RatingModal;

const Root = styled.dialog`
  position: relative;
  width: 666px;
  margin: 0 auto;
  border: 0;
  padding: 32px;
  z-index: ${({ theme }) => theme.zIndex.MODAL};
`;

const Heading = styled.h2`
  margin-bottom: 21px;
  font: ${({ theme }) => theme.text.semibold_18};
  font-weight: 600;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const ListWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray_20};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  min-height: 44px;
`;

const RatingHeading = styled.h3`
  display: flex;
  align-items: flex-start;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.color.gray_10};
  font: ${({ theme }) => theme.text.medium_15};
  color: ${({ theme }) => theme.color.gray_30};
`;

const RatingContent = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_40};
`;

const ContentHeading = styled.h3`
  display: flex;
  align-items: flex-start;
  padding: 12px 20px;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  background-color: ${({ theme }) => theme.color.gray_10};
  font: ${({ theme }) => theme.text.medium_15};
  color: ${({ theme }) => theme.color.gray_30};
`;

const Content = styled.div`
  padding: 12px 20px;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_40};
  height: 300px;
  overflow-y: auto;
`;
