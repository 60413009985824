const dashboardTabList = [
  { type: "live", content: "전체" },
  { type: "uncollected", content: "전화주문" },
];

const statisticsTabList = [
  { type: "multipleContainer", content: "다회용기 사용실적" },
  { type: "serviceAssessment", content: "이용 고객 서비스 평가" },
  { type: "cumulativeData", content: "누적 DATA" },
];

export { dashboardTabList, statisticsTabList };
