import React from "react";
import styled from "styled-components";

import ModalRoot from "./ModalRoot";
import Table from "../table/Table";
import useModal from "hooks/useModal";
import { CloseIcon } from "assets/icon/index";
import { qrModalList } from "assets/static/table";

interface Props {
  data: string[];
}

const QrModal = ({ data }: Props) => {
  const { closeBtnRef, handleClickCloseModal } = useModal();

  return (
    <ModalRoot>
      <Root open>
        <Heading>{`다회용기 수량`}</Heading>
        <CloseBtn
          id="modal-close-btn"
          type="button"
          ref={closeBtnRef}
          onClick={handleClickCloseModal}
        >
          <CloseIcon />
        </CloseBtn>
        <TabelWrapper>
          <CustomTable list={qrModalList}>
            {data ? (
              data.map((item, i) => {
                return (
                  <CustomBodyRow key={i}>
                    <Table.Td>{item}</Table.Td>
                  </CustomBodyRow>
                );
              })
            ) : (
              <div>데이터가 없습니다</div>
            )}
          </CustomTable>
        </TabelWrapper>
      </Root>
    </ModalRoot>
  );
};

export default QrModal;

const Root = styled.dialog`
  position: relative;
  width: 400px;
  margin: 0 auto;
  border: 0;
  padding: 32px;
  z-index: ${({ theme }) => theme.zIndex.MODAL};
`;

const Heading = styled.h2`
  margin-bottom: 21px;
  font: ${({ theme }) => theme.text.semibold_18};
  font-weight: 600;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const TabelWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const CustomTable = styled(Table)`
  display: block;
  width: 338px;
  max-height: 440px;
  overflow-y: auto;

  & + div > div {
    margin: 0 auto;
  }
`;

const CustomBodyRow = styled(Table.BodyRow)`
  width: 336px;

  & > td {
    width: 100%;
  }
`;
