import React from "react";
import styled from "styled-components";

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout = ({ children }: PageLayoutProps) => {
  return <Root>{children}</Root>;
};

export default PageLayout;

const Root = styled.section`
  min-width: 1440px;
  padding: 90px 50px 52px 50px;
`;
