import * as xlsx from "xlsx";

export const downloadExcel = (data: any, name: string) => {
  if (data.length === 0) {
    return alert("데이터가 없습니다.");
  }

  //TODO: changeColumnData type 정의
  let changeColumnData: any = [];

  switch (name) {
    case "live":
      changeColumnData = data.map((item: any) => {
        return {
          번호: item.no,
          일자: item.date,
          시간: item.time,
          "Order id": item.orderId,
          폰번호: item.phone,
          주소: item.addr,
          "다회용기 수량": item.containers,
          수거요청일: item.pickedupAt,
          완료일: item.returnDate,
          "비밀번호 불러오기": item.doorlockPassword,
          재주문수: item.reorderCnt,
          Rating: item.rating,
          처리상태: item.status,
        };
      });
      break;

    case "uncollected":
      changeColumnData = data.map((item: any) => {
        return {
          번호: item.no,
          일자: item.date,
          시간: item.time,
          "Order id": item.orderId,
          폰번호: item.phone,
          주소: item.addr,
          "수거 지연 시간": item.delayTime,
          처리상태: item.status,
        };
      });
      break;
  }

  const now = new Date();
  const worksheet = xlsx.utils.json_to_sheet(changeColumnData);
  const workbook = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  xlsx.writeFile(workbook, `${now}.xlsx`);
};
