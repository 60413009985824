import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

interface AccountInputProps<T extends string> {
  isFocus: boolean;
  isLoginErr?: boolean;
  name: string;
  register: UseFormRegisterReturn<T>;
  handleFocusInput: () => void;
  value: string;
}

const AccountInput = <T extends string>({
  isFocus,
  isLoginErr,
  name,
  register,
  handleFocusInput,
}: AccountInputProps<T>) => {
  return (
    <Root isFocus={isFocus} isLoginErr={isLoginErr}>
      <Label isFocus={isFocus}>
        {name === "email" ? "아이디" : "비밀번호"}
      </Label>
      <Input
        type={name === "email" ? "text" : "password"}
        isFocus={isFocus}
        onFocus={handleFocusInput}
        {...register}
      />
    </Root>
  );
};

export default AccountInput;

const Root = styled.div<{ isFocus: boolean; isLoginErr?: boolean }>`
  position: relative;
  height: 58px;
  margin-bottom: ${({ isLoginErr }) => (isLoginErr ? "0px" : "24px")};
`;

const Label = styled.label<{ isFocus: boolean }>`
  position: absolute;
  font-size: ${({ isFocus }) => (isFocus ? "1.3rem" : "1.5rem")};
  color: ${({ theme, isFocus }) =>
    isFocus ? theme.color.gray_30 : theme.color.gray_60};
  transform: ${({ isFocus }) =>
    isFocus ? "translateY(-14px)" : "translateY(0px)"};
  transition-duration: 0.3s;
  opacity: ${({ isFocus }) => (isFocus ? "1" : "0.3")};
`;

const Input = styled.input<{ isFocus: boolean }>`
  width: 100%;
  height: 36px;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  font: ${({ theme }) => theme.text.regular_14};
  outline: none;

  :-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset;
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.color.active};
  }
`;
