import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";

import Toast from "components/common/toast/Toast";
import { GlobalStyle } from "style/globalStyle";
import CustomRouter from "./router";
import { store, persistor } from "store/configureStore";
import { theme } from "style/theme";
import "style/fonts.css";
import { browserInfo } from "utils/helper/browserName";
import IESupport from "components/common/IE/IESupport";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {browserInfo() === "MS IE" ? <IESupport /> : <CustomRouter />}
          <Toast />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
