import React, { useState, useCallback, useRef, useEffect } from "react";

import { useOnClickOutside } from "hooks/index";

const useDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogOpener, setDialogOpener] = useState<null | Element>(null);
  const dialogRef = useRef<null | HTMLDialogElement>(null);

  const handleDialogOpen = useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();

    setDialogOpener(document.activeElement);
    setIsDialogOpen(true);
    setTimeout(() => (dialogRef.current as HTMLDialogElement).focus());
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
    dialogOpener && (dialogOpener as HTMLButtonElement).focus();
  }, [dialogOpener]);

  const handleToggleDialogOpen = useCallback<React.MouseEventHandler>(
    (e) => {
      isDialogOpen ? handleDialogClose() : handleDialogOpen(e);
    },

    [handleDialogOpen, handleDialogClose, isDialogOpen],
  );

  useOnClickOutside(dialogRef, handleDialogClose, dialogOpener);

  useEffect(() => {
    const keyListenerMap = new Map([[27, handleDialogClose]]);

    const handleKeyListener = (e: KeyboardEvent) => {
      const listener = keyListenerMap.get(e.keyCode);

      typeof listener === "function" && listener();
    };

    window.addEventListener("keydown", handleKeyListener);

    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  }, []);

  return {
    isDialogOpen,
    dialogRef,
    setIsDialogOpen,
    handleDialogOpen,
    handleDialogClose,
    handleToggleDialogOpen,
  };
};

export default useDialog;
