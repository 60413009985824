import React from "react";
import styled from "styled-components";

import { TriangleIcon } from "assets/icon";
import useYearDropdown from "./hooks/useYearDropdown";

const YearDropdown = () => {
  const {
    isOpen,
    ref,
    yearList,
    selectYear,
    handleSelcetYear,
    handleOpenDropdown,
  } = useYearDropdown();

  return (
    <Root ref={ref}>
      {selectYear}년
      <SelectBtn type="button" onClick={handleOpenDropdown}>
        <CustomTriangleIcon />
      </SelectBtn>
      {isOpen && (
        <ListWrapper>
          {yearList.map((year) => (
            <ListItem key={year}>
              <ListBtn
                isSelect={year === Number(selectYear)}
                onClick={handleSelcetYear(year)}
              >
                {year}
              </ListBtn>
            </ListItem>
          ))}
        </ListWrapper>
      )}
    </Root>
  );
};

export default YearDropdown;

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100px;
  margin-bottom: 8px;
  font: ${({ theme }) => theme.text.semibold_18};
`;

const SelectBtn = styled.button`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  transform: rotate(-90deg);

  :hover {
    background-color: ${({ theme }) => theme.color.gray_20};
  }
`;

const CustomTriangleIcon = styled(TriangleIcon)`
  fill: ${({ theme }) => theme.color.gray_30};
`;

const ListWrapper = styled.ul`
  position: absolute;
  top: 24px;
  left: 68px;
  width: 100px;
  max-height: 198px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 8px;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;

  box-sizing: border-box;
`;

const ListItem = styled.li`
  height: 36px;

  :hover {
    background-color: ${({ theme }) => theme.color.gray_10};
  }
`;

const ListBtn = styled.button<{ isSelect: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 0 8px 8px;
  color: ${({ theme, isSelect }) =>
    isSelect ? theme.color.active : theme.color.gray_30};
  font: ${({ theme }) => theme.text.regular_14};
`;
