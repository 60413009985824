import React from "react";
import styled from "styled-components";

import useModal from "hooks/useModal";
import Portal from "./Portal";

const ModalRoot = ({ children }: { children: React.ReactNode }) => {
  const { handleClickDim } = useModal();

  return (
    <Portal>
      <Dim onClick={handleClickDim}>{children}</Dim>
    </Portal>
  );
};

export default ModalRoot;

const Dim = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: ${({ theme }) => theme.zIndex.MODAL};
  background-color: rgba(0, 0, 0, 0.1);
`;
