import { put, takeLatest, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import * as authAction from "store/reducer/authSlice";
import * as API from "../api/service/authApi";
import { AxiosResponse } from "axios";

interface LoginSagaPayload {
  email: string;
  password: string;
  navigate: (path: string) => void;
}

function* loginSaga(action: PayloadAction<LoginSagaPayload>) {
  try {
    const { email, password, navigate } = action.payload;

    const { data }: AxiosResponse = yield call(API.fetchLogin, email, password);

    yield put({
      type: authAction.loginSuccess,
    });

    Cookies.set("REPLHADA_ADMIN_ACCESS_TOKEN", data.accessToken);
    Cookies.set("REPLHADA_ADMIN_REFRESH_TOKEN", data.refreshToken);
    navigate("/dashboard");
  } catch (err) {
    yield put({
      type: authAction.loginFailure,
      payload: err,
    });
  }
}

export function* watchLogin() {
  yield takeLatest(authAction.login.type, loginSaga);
}

function* logoutSaga(action: PayloadAction<LoginSagaPayload>) {
  try {
    const { navigate } = action.payload;

    yield call(API.fetchLogout);

    yield put({
      type: authAction.loginSuccess,
    });

    Cookies.remove("REPLHADA_ADMIN_ACCESS_TOKEN");
    Cookies.remove("REPLHADA_ADMIN_REFRESH_TOKEN");
    navigate("/");
  } catch (err) {
    yield put({
      type: authAction.loginFailure,
      payload: err,
    });
  }
}

export function* watchLogout() {
  yield takeLatest(authAction.logout.type, logoutSaga);
}
