import { ax } from "api/axios";
import type { LiveNUncollectedParams } from "types/dashboard";

export const fetchLive = (params: LiveNUncollectedParams) => {
  delete params.type;
  delete params.first;
  delete params.second;
  delete params.third;

  return ax.get("/realtime", { params });
};

export const fetchLivePhone = (params: LiveNUncollectedParams) => {
  delete params.type;
  delete params.first;
  delete params.second;
  delete params.third;

  return ax.get("/realtime/phone", { params });
};

export const fetchUncollected = (params: LiveNUncollectedParams) => {
  delete params.type;
  delete params.first;
  delete params.second;
  delete params.third;

  return ax.get("/uncollected", { params });
};

export const fetchChangeStatus = (params: {
  orderid: string;
  status: string;
}) => {
  return ax.put("/status", null, { params });
};
