import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "store/reducer/authSlice";

const useHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isActive = useCallback(
    (path: string) => (path.includes(pathname) ? true : false),
    [pathname],
  );

  const handleClickMenu = useCallback(
    (path: string) => () => {
      const year = new Date().getFullYear();

      switch (path) {
        case "/dashboard":
          return navigate(`${path}?type=live&loc=0`);
        case "/statistics":
          return navigate(`${path}?type=multipleContainer&loc=0&year=${year}`);
      }
    },
    [],
  );

  const handleLogout = useCallback(() => {
    dispatch({ type: logout.type, payload: { navigate } });
  }, []);

  return { isActive, handleClickMenu, handleLogout };
};

export default useHeader;
