import React, { createContext, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";

import { SortBtn } from "../button";
import { SearchIcon } from "assets/icon";
import SectionLayout from "layout/SectionLayout";
import useSortBtn from "../button/hooks/useSortBtn";

interface Props<T> {
  list: T;
  heading?: string;
  isLoading?: boolean;
  className?: string;
  children: React.ReactNode;
}

interface TableProps {
  className?: string;
  children: React.ReactNode | string;
  status?: string;
}

interface LoadingTableProps {
  className?: string;
  list: { [key: string]: string };
}

const Context = createContext({ isLoading: false });

const Table = <T extends Object>({
  list,
  heading,
  isLoading = false,
  className,
  children,
}: Props<T>) => {
  const {
    createdSortState,
    completedSortState,
    handleCreatedSort,
    handleCompletedSort,
  } = useSortBtn();

  return (
    <SectionLayout heading={heading}>
      <Context.Provider value={{ isLoading }}>
        <TableWrapper>
          <Root className={className}>
            <thead>
              <HeadRow>
                {Object.entries(list).map(([key, value], i) => {
                  switch (key) {
                    case "date":
                      return (
                        <th key={i}>
                          {value}
                          <SortBtn
                            sortState={createdSortState as string}
                            handleSort={handleCreatedSort}
                          />
                        </th>
                      );

                    case "pickedupAt":
                      return (
                        <th key={i}>
                          {value}
                          <SortBtn
                            sortState={completedSortState as string}
                            handleSort={handleCompletedSort}
                          />
                        </th>
                      );

                    default:
                      return <th key={i}>{value}</th>;
                  }
                })}
              </HeadRow>
            </thead>
            <tbody>{children}</tbody>
          </Root>
        </TableWrapper>
      </Context.Provider>
    </SectionLayout>
  );
};

Table.BodyRow = function BodyRow({ className, children, status }: TableProps) {
  return (
    <TableBodyRow className={className} status={status}>
      {children}
    </TableBodyRow>
  );
};

Table.Td = function Td({ className, children }: TableProps) {
  const { isLoading } = useContext(Context);

  return (
    <TableTd className={className} isLoading={isLoading}>
      {isLoading ? <Skeleton /> : children}
    </TableTd>
  );
};

Table.LeftTd = function LeftTd({ className, children }: TableProps) {
  const { isLoading } = useContext(Context);

  return (
    <TableLeftTd className={className}>
      {isLoading ? <Skeleton /> : children}
    </TableLeftTd>
  );
};

Table.LoadingTable = function LoadingTable({
  className,
  list,
}: LoadingTableProps) {
  return (
    <>
      {new Array(10).fill(null).map((_, i) => (
        <TableBodyRow key={i} className={className}>
          {Object.keys(list).map((item) => {
            return (
              <TableTd key={item} isLoading>
                <Skeleton />
              </TableTd>
            );
          })}
        </TableBodyRow>
      ))}
    </>
  );
};

Table.NoData = function NoData() {
  return (
    <NoDataRoot>
      <td>
        <SearchIcon />
        찾고 있는 검색 결과가 없어요.
      </td>
    </NoDataRoot>
  );
};

export default Table;

const TableWrapper = styled.div`
  margin-bottom: 15px;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  border-left: 1px solid ${({ theme }) => theme.color.gray_20};
  overflow-x: auto;
`;

const Root = styled.table`
  min-width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gray_20};
  cursor: default;
`;

const Tr = styled.tr`
  min-height: 36px;
  display: grid;

  th {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }
`;

const HeadRow = styled(Tr)`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  font: ${({ theme }) => theme.text.medium_14};
  color: ${({ theme }) => theme.color.gray_40};
  background-color: ${({ theme }) => theme.color.gray_10};

  & > :last-child {
    border-right: 0;
  }
`;

interface TableBodyRowProps {
  status?: string;
}

const TableBodyRow = styled(Tr)<TableBodyRowProps>`
  font: ${({ theme }) => theme.text.regular_14};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  background-color: ${({ theme, status }) =>
    status === "대기"
      ? "rgba(244, 191, 0, 0.1)"
      : status === "수거요청"
      ? "rgba(39, 74, 255, 0.04)"
      : status === "완료"
      ? "white"
      : status === "오류"
      ? "rgba(145, 142, 255, 0.05)"
      : status === "분실"
      ? "rgba(255, 113, 113, 0.05)"
      : status === "CS"
      ? "rgba(78, 244, 0, 0.05)"
      : "white"};

  & > :last-child {
    border-right: 0;
  }

  td {
    height: 100%;
  }
`;

const TableTd = styled.td<{ isLoading: boolean }>`
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 12px;
  color: ${({ theme }) => theme.color.gray_50};
  text-align: center;
`;

const TableLeftTd = styled.td`
  display: block;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 12px;
  color: ${({ theme }) => theme.color.gray_50};
  word-break: break-all;
  height: auto;
`;

const NoDataRoot = styled.tr`
  & > td {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    row-gap: 22px;
    height: 300px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
    font: ${({ theme }) => theme.text.regular_14};
    color: ${({ theme }) => theme.color.gray_30};
  }
`;
