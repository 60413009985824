import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";

import XYTable from "components/common/table/XYTable";
import { YearDropdown } from "components/common/dropdown";
import { formatCellWidth } from "utils/helper/formatter";
import useServiceAssessment from "./hooks/useServiceAssessment";
import {
  serviceAssessmentXList,
  serviceAssessmentYList,
} from "assets/static/table";

const ServiceAssessment = () => {
  const { serviceAssessmentData } = useServiceAssessment();

  ChartJS.register(ArcElement, Tooltip, Legend);

  const chartData =
    serviceAssessmentData &&
    Object.values(serviceAssessmentData).map((item) => {
      if (Object.values(item).at(-1) === 0) {
        return 0;
      } else {
        return Object.values(item).at(-1);
      }
    });

  const data = {
    labels: Object.values(serviceAssessmentYList),
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          "rgba(145,142,255,1)",
          "rgba(101,160,232,1)",
          "rgba(123,191,191,1)",
          "rgba(242,204,95,1)",
          "rgba(232,158,78,1)",
          "rgba(224,99,140,1)",
        ],
      },
    ],
  };

  return (
    <>
      <YearDropdown />
      <ChartWrapper>
        {serviceAssessmentData ? (
          <Pie
            options={{
              maintainAspectRatio: false,
            }}
            data={data}
            width={500}
            height={500}
          />
        ) : (
          <SkeletonWrapper>
            <LegendWrapper>
              <Skeleton width={50} height={10} />
              <Skeleton width={50} height={10} />
              <Skeleton width={50} height={10} />
              <Skeleton width={50} height={10} />
              <Skeleton width={50} height={10} />
              <Skeleton width={50} height={10} />
            </LegendWrapper>
            <Skeleton circle width={500} height={500} />
          </SkeletonWrapper>
        )}
      </ChartWrapper>
      {serviceAssessmentData ? (
        <CustomXYTable xList={serviceAssessmentXList}>
          {Object.entries(serviceAssessmentData).map(([key, value], i) => {
            return (
              <XYTable.BodyRow key={key + i}>
                <XYTable.Th>
                  {
                    serviceAssessmentYList[
                      key as keyof typeof serviceAssessmentYList
                    ]
                  }
                </XYTable.Th>
                {Object.entries(value).map(([key, value], i) => (
                  <td key={key + i}>{value}</td>
                ))}
              </XYTable.BodyRow>
            );
          })}
        </CustomXYTable>
      ) : (
        <CustomXYTable xList={serviceAssessmentXList}>
          <CustomLoadingTable
            xlist={serviceAssessmentXList}
            ylist={serviceAssessmentYList}
          />
        </CustomXYTable>
      )}
    </>
  );
};

export default ServiceAssessment;

const ChartWrapper = styled.div`
  height: 604px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  padding: 28px;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  row-gap: 10px;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 10px;
`;

const CustomXYTable = styled(XYTable)`
  height: auto;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.gray_20};

  & > thead > tr,
  & > tbody > tr {
    grid-template-columns: ${formatCellWidth(285)} repeat(13, 1fr);
    border-left: 1px solid ${({ theme }) => theme.color.gray_20};
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }

  & > tbody > tr:nth-of-type(1) :last-child,
  & > tbody > tr:nth-of-type(2) :last-child,
  & > tbody > tr:nth-of-type(3) :last-child,
  & > tbody > tr:nth-of-type(4) :last-child,
  & > tbody > tr:nth-of-type(5) :last-child,
  & > tbody > tr:nth-of-type(6) :last-child,
  & > tbody > tr:nth-of-type(7) :last-child {
    background-color: ${({ theme }) => theme.color.xyTable_total};
  }
`;

const CustomLoadingTable = styled(XYTable.LoadingTable)`
  grid-template-columns: ${formatCellWidth(285)} repeat(13, 1fr);
`;
