import dayjs, { Dayjs } from "dayjs";

export interface MonthYear {
  startDate: dayjs.Dayjs; // first day of the month
  prevMonthStartDate: dayjs.Dayjs; // first day of the previous month
  nextMonthStartDate: dayjs.Dayjs; // first day of the next month
  firstWeekPrevMonthDate: dayjs.Dayjs; // first week of this month with previous month
  firstDOW: number; // day of week; 0 === Sunday
  lastDate: number; // last date of the month
  monthName: string; // name of the month
  date: string; // name of the date
  month: string; // two digit month number
  year: string; // four digit year
}

export const getUpdatedMonthYear = (
  monthYear: MonthYear,
  monthIncrement: number,
) => {
  return monthYear.startDate.clone().add(monthIncrement, "month");
};

export const getMonthYearDetails = (initialDate: Dayjs) => {
  const month = initialDate.format("MM");
  const year = initialDate.format("YYYY");
  const date = initialDate.format("DD");
  const startDate = dayjs(`${year}${month}01`);
  const prevMonthStartDate = startDate.clone().subtract(1, "month");
  const nextMonthStartDate = startDate.clone().add(1, "month");
  const firstDOW = Number(startDate.format("d"));
  const lastDate = Number(startDate.clone().endOf("month").format("DD"));
  const monthName = startDate.format("MMMM");
  const prevMonthLastDate = Number(
    prevMonthStartDate.endOf("month").format("DD"),
  );
  const firstWeekPrevMonthDate = prevMonthStartDate.set(
    "date",
    prevMonthLastDate - firstDOW + 1,
  );

  return {
    startDate,
    prevMonthStartDate,
    nextMonthStartDate,
    firstWeekPrevMonthDate,
    firstDOW,
    lastDate,
    monthName,
    date,
    month,
    year,
  };
};

export const getNewMonthYear = (
  prevData: MonthYear,
  monthIncrement: number,
): MonthYear => {
  const newMonthYear = getUpdatedMonthYear(prevData, monthIncrement);

  return getMonthYearDetails(newMonthYear);
};
