import React from "react";
import styled from "styled-components";

import XYTable from "components/common/table/XYTable";
import { formatCellWidth } from "utils/helper/formatter";
import { cumulativeDataXList, cumulativeDataYList } from "assets/static/table";
import useCumulativeData from "./hooks/useCumulativeData";

const CumulativeData = () => {
  const { cumulativeDataState } = useCumulativeData();

  return (
    <>
      {cumulativeDataState ? (
        <CustomXYTable xList={cumulativeDataXList}>
          {Object.entries(cumulativeDataState).map(([key, value], i) => (
            <XYTable.BodyRow key={i}>
              <XYTable.Th>
                {cumulativeDataYList[key as keyof typeof cumulativeDataYList]}
              </XYTable.Th>
              {value.map((item: { string: string | number }) =>
                Object.entries(item).map(([key, value], i) => {
                  return <td key={key + i}>{value}</td>;
                }),
              )}
            </XYTable.BodyRow>
          ))}
        </CustomXYTable>
      ) : (
        <CustomXYTable xList={cumulativeDataXList}>
          <CustomLoadingTable
            xlist={cumulativeDataXList}
            ylist={cumulativeDataYList}
          />
        </CustomXYTable>
      )}
    </>
  );
};

export default CumulativeData;

const CustomXYTable = styled(XYTable)`
  width: 610px;
  height: auto;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.gray_20};

  & > thead > tr,
  & > tbody > tr {
    grid-template-columns: ${formatCellWidth(285, 610)} ${formatCellWidth(
        80,
        610,
      )} ${formatCellWidth(80, 610)} ${formatCellWidth(162, 610)};
    border-left: 1px solid ${({ theme }) => theme.color.gray_20};
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }
`;

const CustomLoadingTable = styled(XYTable.LoadingTable)`
  grid-template-columns: ${formatCellWidth(285, 610)} ${formatCellWidth(
      80,
      610,
    )} ${formatCellWidth(80, 610)} ${formatCellWidth(162, 610)};
`;
