import type { District } from "types/common";

export const sido: District[] = [
  {
    first: {
      1100000000: "서울특별시",
      second: [
        {
          1111000000: "종로구",
          third: [
            { 1111010100: "청운동" },
            { 1111010200: "신교동" },
            { 1111010300: "궁정동" },
            { 1111010400: "효자동" },
            { 1111010500: "창성동" },
            { 1111010600: "통의동" },
            { 1111010700: "적선동" },
            { 1111010800: "통인동" },
            { 1111010900: "누상동" },
            { 1111011000: "누하동" },
            { 1111011100: "옥인동" },
            { 1111011200: "체부동" },
            { 1111011300: "필운동" },
            { 1111011400: "내자동" },
            { 1111011500: "사직동" },
            { 1111011600: "도렴동" },
            { 1111011700: "당주동" },
            { 1111011800: "내수동" },
            { 1111011900: "세종로" },
            { 1111012000: "신문로1가" },
            { 1111012100: "신문로2가" },
            { 1111012200: "청진동" },
            { 1111012300: "서린동" },
            { 1111012400: "수송동" },
            { 1111012500: "중학동" },
            { 1111012600: "종로1가" },
            { 1111012700: "공평동" },
            { 1111012800: "관훈동" },
            { 1111012900: "견지동" },
            { 1111013000: "와룡동" },
            { 1111013100: "권농동" },
            { 1111013200: "운니동" },
            { 1111013300: "익선동" },
            { 1111013400: "경운동" },
            { 1111013500: "관철동" },
            { 1111013600: "인사동" },
            { 1111013700: "낙원동" },
            { 1111013800: "종로2가" },
            { 1111013900: "팔판동" },
            { 1111014000: "삼청동" },
            { 1111014100: "안국동" },
            { 1111014200: "소격동" },
            { 1111014300: "화동" },
            { 1111014400: "사간동" },
            { 1111014500: "송현동" },
            { 1111014600: "가회동" },
            { 1111014700: "재동" },
            { 1111014800: "계동" },
            { 1111014900: "원서동" },
            { 1111015000: "훈정동" },
            { 1111015100: "묘동" },
            { 1111015200: "봉익동" },
            { 1111015300: "돈의동" },
            { 1111015400: "장사동" },
            { 1111015500: "관수동" },
            { 1111015600: "종로3가" },
            { 1111015700: "인의동" },
            { 1111015800: "예지동" },
            { 1111015900: "원남동" },
            { 1111016000: "연지동" },
            { 1111016100: "종로4가" },
            { 1111016200: "효제동" },
            { 1111016300: "종로5가" },
            { 1111016400: "종로6가" },
            { 1111016500: "이화동" },
            { 1111016600: "연건동" },
            { 1111016700: "충신동" },
            { 1111016800: "동숭동" },
            { 1111016900: "혜화동" },
            { 1111017000: "명륜1가" },
            { 1111017100: "명륜2가" },
            { 1111017200: "명륜4가" },
            { 1111017300: "명륜3가" },
            { 1111017400: "창신동" },
            { 1111017500: "숭인동" },
            { 1111017600: "교남동" },
            { 1111017700: "평동" },
            { 1111017800: "송월동" },
            { 1111017900: "홍파동" },
            { 1111018000: "교북동" },
            { 1111018100: "행촌동" },
            { 1111018200: "구기동" },
            { 1111018300: "평창동" },
            { 1111018400: "부암동" },
            { 1111018500: "홍지동" },
            { 1111018600: "신영동" },
            { 1111018700: "무악동" },
          ],
        },
        {
          1114000000: "중구",
          third: [
            { 1114010100: "무교동" },
            { 1114010200: "다동" },
            { 1114010300: "태평로1가" },
            { 1114010400: "을지로1가" },
            { 1114010500: "을지로2가" },
            { 1114010600: "남대문로1가" },
            { 1114010700: "삼각동" },
            { 1114010800: "수하동" },
            { 1114010900: "장교동" },
            { 1114011000: "수표동" },
            { 1114011100: "소공동" },
            { 1114011200: "남창동" },
            { 1114011300: "북창동" },
            { 1114011400: "태평로2가" },
            { 1114011500: "남대문로2가" },
            { 1114011600: "남대문로3가" },
            { 1114011700: "남대문로4가" },
            { 1114011800: "남대문로5가" },
            { 1114011900: "봉래동1가" },
            { 1114012000: "봉래동2가" },
            { 1114012100: "회현동1가" },
            { 1114012200: "회현동2가" },
            { 1114012300: "회현동3가" },
            { 1114012400: "충무로1가" },
            { 1114012500: "충무로2가" },
            { 1114012600: "명동1가" },
            { 1114012700: "명동2가" },
            { 1114012800: "남산동1가" },
            { 1114012900: "남산동2가" },
            { 1114013000: "남산동3가" },
            { 1114013100: "저동1가" },
            { 1114013200: "충무로4가" },
            { 1114013300: "충무로5가" },
            { 1114013400: "인현동2가" },
            { 1114013500: "예관동" },
            { 1114013600: "묵정동" },
            { 1114013700: "필동1가" },
            { 1114013800: "필동2가" },
            { 1114013900: "필동3가" },
            { 1114014000: "남학동" },
            { 1114014100: "주자동" },
            { 1114014200: "예장동" },
            { 1114014300: "장충동1가" },
            { 1114014400: "장충동2가" },
            { 1114014500: "광희동1가" },
            { 1114014600: "광희동2가" },
            { 1114014700: "쌍림동" },
            { 1114014800: "을지로6가" },
            { 1114014900: "을지로7가" },
            { 1114015000: "을지로4가" },
            { 1114015100: "을지로5가" },
            { 1114015200: "주교동" },
            { 1114015300: "방산동" },
            { 1114015400: "오장동" },
            { 1114015500: "을지로3가" },
            { 1114015600: "입정동" },
            { 1114015700: "산림동" },
            { 1114015800: "충무로3가" },
            { 1114015900: "초동" },
            { 1114016000: "인현동1가" },
            { 1114016100: "저동2가" },
            { 1114016200: "신당동" },
            { 1114016300: "흥인동" },
            { 1114016400: "무학동" },
            { 1114016500: "황학동" },
            { 1114016600: "서소문동" },
            { 1114016700: "정동" },
            { 1114016800: "순화동" },
            { 1114016900: "의주로1가" },
            { 1114017000: "충정로1가" },
            { 1114017100: "중림동" },
            { 1114017200: "의주로2가" },
            { 1114017300: "만리동1가" },
            { 1114017400: "만리동2가" },
          ],
        },
        {
          1117000000: "용산구",
          third: [
            { 1117010100: "후암동" },
            { 1117010200: "용산동2가" },
            { 1117010300: "용산동4가" },
            { 1117010400: "갈월동" },
            { 1117010500: "남영동" },
            { 1117010600: "용산동1가" },
            { 1117010700: "동자동" },
            { 1117010800: "서계동" },
            { 1117010900: "청파동1가" },
            { 1117011000: "청파동2가" },
            { 1117011100: "청파동3가" },
            { 1117011200: "원효로1가" },
            { 1117011300: "원효로2가" },
            { 1117011400: "신창동" },
            { 1117011500: "산천동" },
            { 1117011600: "청암동" },
            { 1117011700: "원효로3가" },
            { 1117011800: "원효로4가" },
            { 1117011900: "효창동" },
            { 1117012000: "도원동" },
            { 1117012100: "용문동" },
            { 1117012200: "문배동" },
            { 1117012300: "신계동" },
            { 1117012400: "한강로1가" },
            { 1117012500: "한강로2가" },
            { 1117012600: "용산동3가" },
            { 1117012700: "용산동5가" },
            { 1117012800: "한강로3가" },
            { 1117012900: "이촌동" },
            { 1117013000: "이태원동" },
            { 1117013100: "한남동" },
            { 1117013200: "동빙고동" },
            { 1117013300: "서빙고동" },
            { 1117013400: "주성동" },
            { 1117013500: "용산동6가" },
            { 1117013600: "보광동" },
          ],
        },
        {
          1120000000: "성동구",
          third: [
            { 1120010100: "상왕십리동" },
            { 1120010200: "하왕십리동" },
            { 1120010300: "홍익동" },
            { 1120010400: "도선동" },
            { 1120010500: "마장동" },
            { 1120010600: "사근동" },
            { 1120010700: "행당동" },
            { 1120010800: "응봉동" },
            { 1120010900: "금호동1가" },
            { 1120011000: "금호동2가" },
            { 1120011100: "금호동3가" },
            { 1120011200: "금호동4가" },
            { 1120011300: "옥수동" },
            { 1120011400: "성수동1가" },
            { 1120011500: "성수동2가" },
            { 1120011800: "송정동" },
            { 1120012200: "용답동" },
          ],
        },
        {
          1121500000: "광진구",
          third: [
            { 1120010100: "상왕십리동" },
            { 1120010200: "하왕십리동" },
            { 1120010300: "홍익동" },
            { 1120010400: "도선동" },
            { 1120010500: "마장동" },
            { 1120010600: "사근동" },
            { 1120010700: "행당동" },
            { 1120010800: "응봉동" },
            { 1120010900: "금호동1가" },
            { 1120011000: "금호동2가" },
            { 1120011100: "금호동3가" },
            { 1120011200: "금호동4가" },
            { 1120011300: "옥수동" },
            { 1120011400: "성수동1가" },
            { 1120011500: "성수동2가" },
            { 1120011800: "송정동" },
            { 1120012200: "용답동" },
          ],
        },
        {
          1123000000: "동대문구",
          third: [
            { 1123010100: "신설동" },
            { 1123010200: "용두동" },
            { 1123010300: "제기동" },
            { 1123010400: "전농동" },
            { 1123010500: "답십리동" },
            { 1123010600: "장안동" },
            { 1123010700: "청량리동" },
            { 1123010800: "회기동" },
            { 1123010900: "휘경동" },
            { 1123011000: "이문동" },
          ],
        },
        {
          1126000000: "중랑구",
          third: [
            { 1126010100: "면목동" },
            { 1126010200: "상봉동" },
            { 1126010300: "중화동" },
            { 1126010400: "묵동" },
            { 1126010500: "망우동" },
            { 1126010600: "신내동" },
          ],
        },
        {
          1129000000: "성북구",
          third: [
            { 1129010100: "성북동" },
            { 1129010200: "성북동1가" },
            { 1129010300: "돈암동" },
            { 1129010400: "동소문동1가" },
            { 1129010500: "동소문동2가" },
            { 1129010600: "동소문동3가" },
            { 1129010700: "동소문동4가" },
            { 1129010800: "동소문동5가" },
            { 1129010900: "동소문동6가" },
            { 1129011000: "동소문동7가" },
            { 1129011100: "삼선동1가" },
            { 1129011200: "삼선동2가" },
            { 1129011300: "삼선동3가" },
            { 1129011400: "삼선동4가" },
            { 1129011500: "삼선동5가" },
            { 1129011600: "동선동1가" },
            { 1129011700: "동선동2가" },
            { 1129011800: "동선동3가" },
            { 1129011900: "동선동4가" },
            { 1129012000: "동선동5가" },
            { 1129012100: "안암동1가" },
            { 1129012200: "안암동2가" },
            { 1129012300: "안암동3가" },
            { 1129012400: "안암동4가" },
            { 1129012500: "안암동5가" },
            { 1129012600: "보문동4가" },
            { 1129012700: "보문동5가" },
            { 1129012800: "보문동6가" },
            { 1129012900: "보문동7가" },
            { 1129013000: "보문동1가" },
            { 1129013100: "보문동2가" },
            { 1129013200: "보문동3가" },
            { 1129013300: "정릉동" },
            { 1129013400: "길음동" },
            { 1129013500: "종암동" },
            { 1129013600: "하월곡동" },
            { 1129013700: "상월곡동" },
            { 1129013800: "장위동" },
            { 1129013900: "석관동" },
          ],
        },
        {
          1130500000: "강북구",
          third: [
            { 1130510100: "미아동" },
            { 1130510200: "번동" },
            { 1130510300: "수유동" },
            { 1130510400: "우이동" },
          ],
        },
        {
          1132000000: "도봉구",
          third: [
            { 1132010500: "쌍문동" },
            { 1132010600: "방학동" },
            { 1132010700: "창동" },
            { 1132010800: "도봉동" },
          ],
        },
        {
          1135000000: "노원구",
          third: [
            { 1135010200: "월계동" },
            { 1135010300: "공릉동" },
            { 1135010400: "하계동" },
            { 1135010500: "상계동" },
            { 1135010600: "중계동" },
          ],
        },
        {
          1138000000: "은평구",
          third: [
            { 1138010100: "수색동" },
            { 1138010200: "녹번동" },
            { 1138010300: "불광동" },
            { 1138010400: "갈현동" },
            { 1138010500: "구산동" },
            { 1138010600: "대조동" },
            { 1138010700: "응암동" },
            { 1138010800: "역촌동" },
            { 1138010900: "신사동" },
            { 1138011000: "증산동" },
            { 1138011400: "진관동" },
          ],
        },
        {
          1141000000: "서대문구",
          third: [
            { 1141010100: "충정로2가" },
            { 1141010200: "충정로3가" },
            { 1141010300: "합동" },
            { 1141010400: "미근동" },
            { 1141010500: "냉천동" },
            { 1141010600: "천연동" },
            { 1141010700: "옥천동" },
            { 1141010800: "영천동" },
            { 1141010900: "현저동" },
            { 1141011000: "북아현동" },
            { 1141011100: "홍제동" },
            { 1141011200: "대현동" },
            { 1141011300: "대신동" },
            { 1141011400: "신촌동" },
            { 1141011500: "봉원동" },
            { 1141011600: "창천동" },
            { 1141011700: "연희동" },
            { 1141011800: "홍은동" },
            { 1141011900: "북가좌동" },
            { 1141012000: "남가좌동" },
          ],
        },
        {
          1144000000: "마포구",
          third: [
            { 1144010100: "아현동" },
            { 1144010200: "공덕동" },
            { 1144010300: "신공덕동" },
            { 1144010400: "도화동" },
            { 1144010500: "용강동" },
            { 1144010600: "토정동" },
            { 1144010700: "마포동" },
            { 1144010800: "대흥동" },
            { 1144010900: "염리동" },
            { 1144011000: "노고산동" },
            { 1144011100: "신수동" },
            { 1144011200: "현석동" },
            { 1144011300: "구수동" },
            { 1144011400: "창전동" },
            { 1144011500: "상수동" },
            { 1144011600: "하중동" },
            { 1144011700: "신정동" },
            { 1144011800: "당인동" },
            { 1144012000: "서교동" },
            { 1144012100: "동교동" },
            { 1144012200: "합정동" },
            { 1144012300: "망원동" },
            { 1144012400: "연남동" },
            { 1144012500: "성산동" },
            { 1144012600: "중동" },
            { 1144012700: "상암동" },
          ],
        },
        {
          1147000000: "양천구",
          third: [
            { 1147010100: "신정동" },
            { 1147010200: "목동" },
            { 1147010300: "신월동" },
          ],
        },
        {
          1150000000: "강서구",
          third: [
            { 1150010100: "염창동" },
            { 1150010200: "등촌동" },
            { 1150010300: "화곡동" },
            { 1150010400: "가양동" },
            { 1150010500: "마곡동" },
            { 1150010600: "내발산동" },
            { 1150010700: "외발산동" },
            { 1150010800: "공항동" },
            { 1150010900: "방화동" },
            { 1150011000: "개화동" },
            { 1150011100: "과해동" },
            { 1150011200: "오곡동" },
            { 1150011300: "오쇠동" },
          ],
        },
        {
          1153000000: "구로구",
          third: [
            { 1153010100: "신도림동" },
            { 1153010200: "구로동" },
            { 1153010300: "가리봉동" },
            { 1153010600: "고척동" },
            { 1153010700: "개봉동" },
            { 1153010800: "오류동" },
            { 1153010900: "궁동" },
            { 1153011000: "온수동" },
            { 1153011100: "천왕동" },
            { 1153011200: "항동" },
          ],
        },
        {
          1154500000: "금천구",
          third: [
            { 1154510100: "가산동" },
            { 1154510200: "독산동" },
            { 1154510300: "시흥동" },
          ],
        },
        {
          1156000000: "영등포구",
          third: [
            { 1156010100: "영등포동" },
            { 1156010200: "영등포동1가" },
            { 1156010300: "영등포동2가" },
            { 1156010400: "영등포동3가" },
            { 1156010500: "영등포동4가" },
            { 1156010600: "영등포동5가" },
            { 1156010700: "영등포동6가" },
            { 1156010800: "영등포동7가" },
            { 1156010900: "영등포동8가" },
            { 1156011000: "여의도동" },
            { 1156011100: "당산동1가" },
            { 1156011200: "당산동2가" },
            { 1156011300: "당산동3가" },
            { 1156011400: "당산동4가" },
            { 1156011500: "당산동5가" },
            { 1156011600: "당산동6가" },
            { 1156011700: "당산동" },
            { 1156011800: "도림동" },
            { 1156011900: "문래동1가" },
            { 1156012000: "문래동2가" },
            { 1156012100: "문래동3가" },
            { 1156012200: "문래동4가" },
            { 1156012300: "문래동5가" },
            { 1156012400: "문래동6가" },
            { 1156012500: "양평동1가" },
            { 1156012600: "양평동2가" },
            { 1156012700: "양평동3가" },
            { 1156012800: "양평동4가" },
            { 1156012900: "양평동5가" },
            { 1156013000: "양평동6가" },
            { 1156013100: "양화동" },
            { 1156013200: "신길동" },
            { 1156013300: "대림동" },
            { 1156013400: "양평동" },
          ],
        },
        {
          1159000000: "동작구",
          third: [
            { 1159010100: "노량진동" },
            { 1159010200: "상도동" },
            { 1159010300: "상도1동" },
            { 1159010400: "본동" },
            { 1159010500: "흑석동" },
            { 1159010600: "동작동" },
            { 1159010700: "사당동" },
            { 1159010800: "대방동" },
            { 1159010900: "신대방동" },
          ],
        },
        {
          1162000000: "관악구",
          third: [
            { 1162010100: "봉천동" },
            { 1162010200: "신림동" },
            { 1162010300: "남현동" },
          ],
        },
        {
          1165000000: "서초구",
          third: [
            { 1165010100: "방배동" },
            { 1165010200: "양재동" },
            { 1165010300: "우면동" },
            { 1165010400: "원지동" },
            { 1165010600: "잠원동" },
            { 1165010700: "반포동" },
            { 1165010800: "서초동" },
            { 1165010900: "내곡동" },
            { 1165011000: "염곡동" },
            { 1165011100: "신원동" },
          ],
        },
        {
          1168000000: "강남구",
          third: [
            { 1168010100: "역삼동" },
            { 1168010300: "개포동" },
            { 1168010400: "청담동" },
            { 1168010500: "삼성동" },
            { 1168010600: "대치동" },
            { 1168010700: "신사동" },
            { 1168010800: "논현동" },
            { 1168011000: "압구정동" },
            { 1168011100: "세곡동" },
            { 1168011200: "자곡동" },
            { 1168011300: "율현동" },
            { 1168011400: "일원동" },
            { 1168011500: "수서동" },
            { 1168011800: "도곡동" },
          ],
        },
        {
          1171000000: "송파구",
          third: [
            { 1171010100: "잠실동" },
            { 1171010200: "신천동" },
            { 1171010300: "풍납동" },
            { 1171010400: "송파동" },
            { 1171010500: "석촌동" },
            { 1171010600: "삼전동" },
            { 1171010700: "가락동" },
            { 1171010800: "문정동" },
            { 1171010900: "장지동" },
            { 1171011100: "방이동" },
            { 1171011200: "오금동" },
            { 1171011300: "거여동" },
            { 1171011400: "마천동" },
          ],
        },
        {
          1174000000: "강동구",
          third: [
            { 1174010100: "명일동" },
            { 1174010200: "고덕동" },
            { 1174010300: "상일동" },
            { 1174010500: "길동" },
            { 1174010600: "둔촌동" },
            { 1174010700: "암사동" },
            { 1174010800: "성내동" },
            { 1174010900: "천호동" },
            { 1174011000: "강일동" },
          ],
        },
      ],
    },
  },
  {
    first: {
      2600000000: "부산광역시",
      second: [
        {
          2611000000: "중구",
          third: [
            { 2611010100: "영주동" },
            { 2611010200: "대창동1가" },
            { 2611010300: "대창동2가" },
            { 2611010400: "중앙동1가" },
            { 2611010500: "중앙동2가" },
            { 2611010600: "중앙동3가" },
            { 2611010700: "중앙동4가" },
            { 2611010800: "중앙동5가" },
            { 2611010900: "중앙동6가" },
            { 2611011000: "중앙동7가" },
            { 2611011100: "동광동1가" },
            { 2611011200: "동광동2가" },
            { 2611011300: "동광동3가" },
            { 2611011400: "동광동4가" },
            { 2611011500: "동광동5가" },
            { 2611011600: "대청동1가" },
            { 2611011700: "대청동2가" },
            { 2611011800: "대청동3가" },
            { 2611011900: "대청동4가" },
            { 2611012000: "보수동1가" },
            { 2611012100: "보수동2가" },
            { 2611012200: "보수동3가" },
            { 2611012300: "부평동1가" },
            { 2611012400: "부평동2가" },
            { 2611012500: "부평동3가" },
            { 2611012600: "부평동4가" },
            { 2611012700: "신창동1가" },
            { 2611012800: "신창동2가" },
            { 2611012900: "신창동3가" },
            { 2611013000: "신창동4가" },
            { 2611013100: "창선동1가" },
            { 2611013200: "창선동2가" },
            { 2611013300: "광복동1가" },
            { 2611013400: "광복동2가" },
            { 2611013500: "광복동3가" },
            { 2611013600: "남포동1가" },
            { 2611013700: "남포동2가" },
            { 2611013800: "남포동3가" },
            { 2611013900: "남포동4가" },
            { 2611014000: "남포동5가" },
            { 2611014100: "남포동6가" },
          ],
        },
        {
          2614000000: "서구",
          third: [
            { 2614010100: "동대신동1가" },
            { 2614010200: "동대신동2가" },
            { 2614010300: "동대신동3가" },
            { 2614010400: "서대신동1가" },
            { 2614010500: "서대신동2가" },
            { 2614010600: "서대신동3가" },
            { 2614010700: "부용동1가" },
            { 2614010800: "부용동2가" },
            { 2614010900: "부민동1가" },
            { 2614011000: "부민동2가" },
            { 2614011100: "부민동3가" },
            { 2614011200: "토성동1가" },
            { 2614011300: "토성동2가" },
            { 2614011400: "토성동3가" },
            { 2614011500: "아미동1가" },
            { 2614011600: "아미동2가" },
            { 2614011700: "토성동4가" },
            { 2614011800: "토성동5가" },
            { 2614011900: "초장동" },
            { 2614012000: "충무동1가" },
            { 2614012100: "충무동2가" },
            { 2614012200: "충무동3가" },
            { 2614012300: "남부민동" },
            { 2614012400: "암남동" },
          ],
        },
        {
          2617000000: "동구",
          third: [
            { 2617010100: "초량동" },
            { 2617010200: "수정동" },
            { 2617010300: "좌천동" },
            { 2617010400: "범일동" },
          ],
        },
        {
          2620000000: "영도구",
          third: [
            { 2620010100: "대교동1가" },
            { 2620010200: "대교동2가" },
            { 2620010300: "대평동1가" },
            { 2620010400: "대평동2가" },
            { 2620010500: "남항동1가" },
            { 2620010600: "남항동2가" },
            { 2620010700: "남항동3가" },
            { 2620010800: "영선동1가" },
            { 2620010900: "영선동2가" },
            { 2620011000: "영선동3가" },
            { 2620011100: "영선동4가" },
            { 2620011200: "신선동1가" },
            { 2620011300: "신선동2가" },
            { 2620011400: "신선동3가" },
            { 2620011500: "봉래동1가" },
            { 2620011600: "봉래동2가" },
            { 2620011700: "봉래동3가" },
            { 2620011800: "봉래동4가" },
            { 2620011900: "봉래동5가" },
            { 2620012000: "청학동" },
            { 2620012100: "동삼동" },
          ],
        },
        {
          2623000000: "부산진구",
          third: [
            { 2623010100: "양정동" },
            { 2623010200: "전포동" },
            { 2623010300: "부전동" },
            { 2623010400: "범천동" },
            { 2623010500: "범전동" },
            { 2623010600: "연지동" },
            { 2623010700: "초읍동" },
            { 2623010800: "부암동" },
            { 2623010900: "당감동" },
            { 2623011000: "가야동" },
            { 2623011100: "개금동" },
          ],
        },
        {
          2626000000: "동래구",
          third: [
            { 2626010100: "명장동" },
            { 2626010200: "안락동" },
            { 2626010300: "칠산동" },
            { 2626010400: "낙민동" },
            { 2626010500: "복천동" },
            { 2626010600: "수안동" },
            { 2626010700: "명륜동" },
            { 2626010800: "온천동" },
            { 2626010900: "사직동" },
          ],
        },
        {
          2629000000: "남구",
          third: [
            { 2629010600: "대연동" },
            { 2629010700: "용호동" },
            { 2629010800: "용당동" },
            { 2629010900: "문현동" },
            { 2629011000: "우암동" },
            { 2629011100: "감만동" },
          ],
        },
        {
          2632000000: "북구",
          third: [
            { 2632010100: "금곡동" },
            { 2632010200: "화명동" },
            { 2632010300: "만덕동" },
            { 2632010400: "덕천동" },
            { 2632010500: "구포동" },
          ],
        },
        {
          2635000000: "해운대구",
          third: [
            { 2635010100: "반송동" },
            { 2635010200: "석대동" },
            { 2635010300: "반여동" },
            { 2635010400: "재송동" },
            { 2635010500: "우동" },
            { 2635010600: "중동" },
            { 2635010700: "좌동" },
            { 2635010800: "송정동" },
          ],
        },
        {
          2638000000: "사하구",
          third: [
            { 2638010100: "괴정동" },
            { 2638010200: "당리동" },
            { 2638010300: "하단동" },
            { 2638010400: "신평동" },
            { 2638010500: "장림동" },
            { 2638010600: "다대동" },
            { 2638010700: "구평동" },
            { 2638010800: "감천동" },
          ],
        },
        {
          2641000000: "금정구",
          third: [
            { 2641010100: "두구동" },
            { 2641010200: "노포동" },
            { 2641010300: "청룡동" },
            { 2641010400: "남산동" },
            { 2641010500: "선동" },
            { 2641010600: "오륜동" },
            { 2641010700: "구서동" },
            { 2641010800: "장전동" },
            { 2641010900: "부곡동" },
            { 2641011000: "서동" },
            { 2641011100: "금사동" },
            { 2641011200: "회동동" },
            { 2641011300: "금성동" },
          ],
        },
        {
          2644000000: "강서구",
          third: [
            { 2644010100: "대저1동" },
            { 2644010200: "대저2동" },
            { 2644010300: "강동동" },
            { 2644010400: "명지동" },
            { 2644010500: "죽림동" },
            { 2644010600: "식만동" },
            { 2644010700: "죽동동" },
            { 2644010800: "봉림동" },
            { 2644010900: "송정동" },
            { 2644011000: "화전동" },
            { 2644011100: "녹산동" },
            { 2644011200: "생곡동" },
            { 2644011300: "구랑동" },
            { 2644011400: "지사동" },
            { 2644011500: "미음동" },
            { 2644011600: "범방동" },
            { 2644011700: "신호동" },
            { 2644011800: "동선동" },
            { 2644011900: "성북동" },
            { 2644012000: "눌차동" },
            { 2644012100: "천성동" },
            { 2644012200: "대항동" },
          ],
        },
        {
          2647000000: "연제구",
          third: [{ 2647010100: "거제동" }, { 2647010200: "연산동" }],
        },
        {
          2650000000: "수영구",
          third: [
            { 2650010100: "망미동" },
            { 2650010200: "수영동" },
            { 2650010300: "민락동" },
            { 2650010400: "광안동" },
            { 2650010500: "남천동" },
          ],
        },
        {
          2653000000: "사상구",
          third: [
            { 2653010100: "삼락동" },
            { 2653010200: "모라동" },
            { 2653010300: "덕포동" },
            { 2653010400: "괘법동" },
            { 2653010500: "감전동" },
            { 2653010600: "주례동" },
            { 2653010700: "학장동" },
            { 2653010800: "엄궁동" },
          ],
        },
        {
          2671000000: "기장군",
          third: [
            { 2671025000: "기장읍" },
            { 2671025300: "장안읍" },
            { 2671025600: "정관읍" },
            { 2671025900: "일광읍" },
            { 2671033000: "철마면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      2700000000: "대구광역시",
      second: [
        {
          2711000000: "중구",
          third: [
            { 2711010100: "동인동1가" },
            { 2711010200: "동인동2가" },
            { 2711010300: "동인동3가" },
            { 2711010400: "동인동4가" },
            { 2711010500: "삼덕동1가" },
            { 2711010600: "삼덕동2가" },
            { 2711010700: "삼덕동3가" },
            { 2711010800: "봉산동" },
            { 2711010900: "장관동" },
            { 2711011000: "상서동" },
            { 2711011100: "수동" },
            { 2711011200: "덕산동" },
            { 2711011300: "종로1가" },
            { 2711011400: "종로2가" },
            { 2711011500: "사일동" },
            { 2711011600: "동일동" },
            { 2711011700: "남일동" },
            { 2711011800: "전동" },
            { 2711011900: "동성로3가" },
            { 2711012000: "동문동" },
            { 2711012100: "문화동" },
            { 2711012200: "공평동" },
            { 2711012300: "동성로2가" },
            { 2711012400: "태평로1가" },
            { 2711012500: "교동" },
            { 2711012600: "용덕동" },
            { 2711012700: "상덕동" },
            { 2711012800: "완전동" },
            { 2711012900: "도원동" },
            { 2711013000: "수창동" },
            { 2711013100: "태평로3가" },
            { 2711013200: "인교동" },
            { 2711013300: "서야동" },
            { 2711013400: "서성로1가" },
            { 2711013500: "시장북로" },
            { 2711013600: "하서동" },
            { 2711013700: "남성로" },
            { 2711013800: "계산동1가" },
            { 2711013900: "계산동2가" },
            { 2711014000: "동산동" },
            { 2711014100: "서문로2가" },
            { 2711014200: "서성로2가" },
            { 2711014300: "포정동" },
            { 2711014400: "서문로1가" },
            { 2711014500: "서내동" },
            { 2711014600: "북성로2가" },
            { 2711014700: "대안동" },
            { 2711014800: "동성로1가" },
            { 2711014900: "태평로2가" },
            { 2711015000: "북성로1가" },
            { 2711015100: "화전동" },
            { 2711015200: "향촌동" },
            { 2711015300: "북내동" },
            { 2711015400: "대신동" },
            { 2711015500: "달성동" },
            { 2711015600: "남산동" },
            { 2711015700: "대봉동" },
          ],
        },
        {
          2714000000: "동구",
          third: [
            { 2714010100: "신암동" },
            { 2714010200: "신천동" },
            { 2714010300: "효목동" },
            { 2714010400: "평광동" },
            { 2714010500: "봉무동" },
            { 2714010600: "불로동" },
            { 2714010700: "도동" },
            { 2714010800: "지저동" },
            { 2714010900: "입석동" },
            { 2714011000: "검사동" },
            { 2714011100: "방촌동" },
            { 2714011200: "둔산동" },
            { 2714011300: "부동" },
            { 2714011400: "신평동" },
            { 2714011500: "서호동" },
            { 2714011600: "동호동" },
            { 2714011700: "신기동" },
            { 2714011800: "율하동" },
            { 2714011900: "용계동" },
            { 2714012000: "율암동" },
            { 2714012100: "상매동" },
            { 2714012200: "매여동" },
            { 2714012300: "각산동" },
            { 2714012400: "신서동" },
            { 2714012500: "동내동" },
            { 2714012600: "괴전동" },
            { 2714012700: "금강동" },
            { 2714012800: "대림동" },
            { 2714012900: "사복동" },
            { 2714013000: "숙천동" },
            { 2714013100: "내곡동" },
            { 2714013200: "능성동" },
            { 2714013300: "진인동" },
            { 2714013400: "도학동" },
            { 2714013500: "백안동" },
            { 2714013600: "미곡동" },
            { 2714013700: "용수동" },
            { 2714013800: "신무동" },
            { 2714013900: "미대동" },
            { 2714014000: "내동" },
            { 2714014100: "신용동" },
            { 2714014200: "중대동" },
            { 2714014300: "송정동" },
            { 2714014400: "덕곡동" },
            { 2714014500: "지묘동" },
          ],
        },
        {
          2717000000: "서구",
          third: [
            { 2717010100: "내당동" },
            { 2717010200: "비산동" },
            { 2717010300: "평리동" },
            { 2717010400: "상리동" },
            { 2717010500: "중리동" },
            { 2717010600: "이현동" },
            { 2717010700: "원대동1가" },
            { 2717010800: "원대동2가" },
            { 2717010900: "원대동3가" },
          ],
        },
        {
          2720000000: "남구",
          third: [
            { 2720010100: "이천동" },
            { 2720010200: "봉덕동" },
            { 2720010300: "대명동" },
          ],
        },
        {
          2723000000: "북구",
          third: [
            { 2723010100: "칠성동1가" },
            { 2723010200: "칠성동2가" },
            { 2723010300: "고성동1가" },
            { 2723010400: "고성동2가" },
            { 2723010500: "고성동3가" },
            { 2723010600: "침산동" },
            { 2723010700: "노원동1가" },
            { 2723010800: "노원동2가" },
            { 2723010900: "노원동3가" },
            { 2723011000: "대현동" },
            { 2723011100: "산격동" },
            { 2723011200: "복현동" },
            { 2723011300: "검단동" },
            { 2723011400: "동변동" },
            { 2723011500: "서변동" },
            { 2723011600: "조야동" },
            { 2723011700: "노곡동" },
            { 2723011800: "읍내동" },
            { 2723011900: "동호동" },
            { 2723012000: "학정동" },
            { 2723012100: "도남동" },
            { 2723012200: "국우동" },
            { 2723012300: "구암동" },
            { 2723012400: "동천동" },
            { 2723012500: "관음동" },
            { 2723012600: "태전동" },
            { 2723012700: "매천동" },
            { 2723012800: "팔달동" },
            { 2723012900: "금호동" },
            { 2723013000: "사수동" },
            { 2723013100: "연경동" },
          ],
        },
        {
          2726000000: "수성구",
          third: [
            { 2726010100: "범어동" },
            { 2726010200: "만촌동" },
            { 2726010300: "수성동1가" },
            { 2726010400: "수성동2가" },
            { 2726010500: "수성동3가" },
            { 2726010600: "수성동4가" },
            { 2726010700: "황금동" },
            { 2726010800: "중동" },
            { 2726010900: "상동" },
            { 2726011000: "파동" },
            { 2726011100: "두산동" },
            { 2726011200: "지산동" },
            { 2726011300: "범물동" },
            { 2726011400: "시지동" },
            { 2726011500: "매호동" },
            { 2726011600: "성동" },
            { 2726011700: "사월동" },
            { 2726011800: "신매동" },
            { 2726011900: "욱수동" },
            { 2726012000: "노변동" },
            { 2726012200: "삼덕동" },
            { 2726012300: "연호동" },
            { 2726012400: "이천동" },
            { 2726012500: "고모동" },
            { 2726012600: "가천동" },
            { 2726012700: "대흥동" },
          ],
        },
        {
          2729000000: "달서구",
          third: [
            { 2729010100: "성당동" },
            { 2729010200: "두류동" },
            { 2729010400: "파호동" },
            { 2729010500: "호림동" },
            { 2729010600: "갈산동" },
            { 2729010700: "신당동" },
            { 2729010800: "이곡동" },
            { 2729010900: "장동" },
            { 2729011000: "장기동" },
            { 2729011100: "용산동" },
            { 2729011200: "죽전동" },
            { 2729011300: "감삼동" },
            { 2729011400: "본리동" },
            { 2729011500: "상인동" },
            { 2729011600: "도원동" },
            { 2729011700: "진천동" },
            { 2729011800: "유천동" },
            { 2729011900: "대천동" },
            { 2729012000: "월성동" },
            { 2729012100: "월암동" },
            { 2729012200: "송현동" },
            { 2729012300: "대곡동" },
            { 2729012400: "본동" },
            { 2729012500: "호산동" },
          ],
        },
        {
          2771000000: "달성군",
          third: [
            { 2771025000: "화원읍" },
            { 2771025300: "논공읍" },
            { 2771025600: "다사읍" },
            { 2771025900: "유가읍" },
            { 2771026200: "옥포읍" },
            { 2771026500: "현풍읍" },
            { 2771031000: "가창면" },
            { 2771033000: "하빈면" },
            { 2771038000: "구지면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      2800000000: "인천광역시",
      second: [
        {
          2811000000: "중구",
          third: [
            { 2811010100: "중앙동1가" },
            { 2811010200: "중앙동2가" },
            { 2811010300: "중앙동3가" },
            { 2811010400: "중앙동4가" },
            { 2811010500: "해안동1가" },
            { 2811010600: "해안동2가" },
            { 2811010700: "해안동3가" },
            { 2811010800: "해안동4가" },
            { 2811010900: "관동1가" },
            { 2811011000: "관동2가" },
            { 2811011100: "관동3가" },
            { 2811011200: "항동1가" },
            { 2811011300: "항동2가" },
            { 2811011400: "항동3가" },
            { 2811011500: "항동4가" },
            { 2811011600: "항동5가" },
            { 2811011700: "항동6가" },
            { 2811011800: "항동7가" },
            { 2811011900: "송학동1가" },
            { 2811012000: "송학동2가" },
            { 2811012100: "송학동3가" },
            { 2811012200: "사동" },
            { 2811012300: "신생동" },
            { 2811012400: "신포동" },
            { 2811012500: "답동" },
            { 2811012600: "신흥동1가" },
            { 2811012700: "신흥동2가" },
            { 2811012800: "신흥동3가" },
            { 2811012900: "선화동" },
            { 2811013000: "유동" },
            { 2811013100: "율목동" },
            { 2811013200: "도원동" },
            { 2811013300: "내동" },
            { 2811013400: "경동" },
            { 2811013500: "용동" },
            { 2811013600: "인현동" },
            { 2811013700: "전동" },
            { 2811013800: "북성동1가" },
            { 2811013900: "북성동2가" },
            { 2811014000: "북성동3가" },
            { 2811014100: "선린동" },
            { 2811014200: "송월동1가" },
            { 2811014300: "송월동2가" },
            { 2811014400: "송월동3가" },
            { 2811014500: "중산동" },
            { 2811014600: "운남동" },
            { 2811014700: "운서동" },
            { 2811014800: "운북동" },
            { 2811014900: "을왕동" },
            { 2811015000: "남북동" },
            { 2811015100: "덕교동" },
            { 2811015200: "무의동" },
          ],
        },
        {
          2814000000: "동구",
          third: [
            { 2814010100: "만석동" },
            { 2814010200: "화수동" },
            { 2814010300: "송현동" },
            { 2814010400: "화평동" },
            { 2814010500: "창영동" },
            { 2814010600: "금곡동" },
            { 2814010700: "송림동" },
          ],
        },
        {
          2817700000: "미추홀구",
          third: [
            { 2817710100: "숭의동" },
            { 2817710200: "용현동" },
            { 2817710300: "학익동" },
            { 2817710400: "도화동" },
            { 2817710500: "주안동" },
            { 2817710600: "관교동" },
            { 2817710700: "문학동" },
          ],
        },
        {
          2818500000: "연수구",
          third: [
            { 2818510100: "옥련동" },
            { 2818510200: "선학동" },
            { 2818510300: "연수동" },
            { 2818510400: "청학동" },
            { 2818510500: "동춘동" },
            { 2818510600: "송도동" },
          ],
        },
        {
          2820000000: "남동구",
          third: [
            { 2820010100: "구월동" },
            { 2820010200: "간석동" },
            { 2820010300: "만수동" },
            { 2820010400: "장수동" },
            { 2820010500: "서창동" },
            { 2820010600: "운연동" },
            { 2820010700: "남촌동" },
            { 2820010800: "수산동" },
            { 2820010900: "도림동" },
            { 2820011000: "논현동" },
            { 2820011100: "고잔동" },
          ],
        },
        {
          2823700000: "부평구",
          third: [
            { 2823710100: "부평동" },
            { 2823710200: "십정동" },
            { 2823710300: "산곡동" },
            { 2823710400: "청천동" },
            { 2823710500: "삼산동" },
            { 2823710600: "갈산동" },
            { 2823710700: "부개동" },
            { 2823710800: "일신동" },
            { 2823710900: "구산동" },
          ],
        },
        {
          2824500000: "계양구",
          third: [
            { 2824510100: "효성동" },
            { 2824510200: "계산동" },
            { 2824510300: "작전동" },
            { 2824510400: "서운동" },
            { 2824510500: "임학동" },
            { 2824510600: "용종동" },
            { 2824510700: "병방동" },
            { 2824510800: "방축동" },
            { 2824510900: "박촌동" },
            { 2824511000: "동양동" },
            { 2824511100: "귤현동" },
            { 2824511200: "상야동" },
            { 2824511300: "하야동" },
            { 2824511400: "평동" },
            { 2824511500: "노오지동" },
            { 2824511600: "선주지동" },
            { 2824511700: "이화동" },
            { 2824511800: "오류동" },
            { 2824511900: "갈현동" },
            { 2824512000: "둑실동" },
            { 2824512100: "목상동" },
            { 2824512200: "다남동" },
            { 2824512300: "장기동" },
          ],
        },
        {
          2826000000: "서구",
          third: [
            { 2826010100: "백석동" },
            { 2826010200: "시천동" },
            { 2826010300: "검암동" },
            { 2826010400: "경서동" },
            { 2826010500: "공촌동" },
            { 2826010600: "연희동" },
            { 2826010700: "심곡동" },
            { 2826010800: "가정동" },
            { 2826010900: "신현동" },
            { 2826011000: "석남동" },
            { 2826011100: "원창동" },
            { 2826011200: "가좌동" },
            { 2826011300: "마전동" },
            { 2826011400: "당하동" },
            { 2826011500: "원당동" },
            { 2826011700: "대곡동" },
            { 2826011800: "금곡동" },
            { 2826011900: "오류동" },
            { 2826012000: "왕길동" },
            { 2826012100: "불로동" },
            { 2826012200: "청라동" },
          ],
        },
        {
          2871000000: "강화군",
          third: [
            { 2871025000: "강화읍" },
            { 2871031000: "선원면" },
            { 2871032000: "불은면" },
            { 2871033000: "길상면" },
            { 2871034000: "화도면" },
            { 2871035000: "양도면" },
            { 2871036000: "내가면" },
            { 2871037000: "하점면" },
            { 2871038000: "양사면" },
            { 2871039000: "송해면" },
            { 2871040000: "교동면" },
            { 2871041000: "삼산면" },
            { 2871042000: "서도면" },
          ],
        },
        {
          2872000000: "옹진군",
          third: [
            { 2872031000: "북도면" },
            { 2872033000: "백령면" },
            { 2872034000: "대청면" },
            { 2872035000: "덕적면" },
            { 2872036000: "영흥면" },
            { 2872037000: "자월면" },
            { 2872038000: "연평면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      2900000000: "광주광역시",
      second: [
        {
          2911000000: "동구",
          third: [
            { 2911010100: "대인동" },
            { 2911010200: "금남로5가" },
            { 2911010300: "충장로5가" },
            { 2911010400: "수기동" },
            { 2911010500: "대의동" },
            { 2911010600: "궁동" },
            { 2911010700: "장동" },
            { 2911010800: "동명동" },
            { 2911010900: "계림동" },
            { 2911011000: "산수동" },
            { 2911011100: "지산동" },
            { 2911011200: "남동" },
            { 2911011300: "광산동" },
            { 2911011400: "금동" },
            { 2911011500: "호남동" },
            { 2911011600: "불로동" },
            { 2911011700: "황금동" },
            { 2911011800: "서석동" },
            { 2911011900: "소태동" },
            { 2911012000: "용연동" },
            { 2911012100: "운림동" },
            { 2911012200: "학동" },
            { 2911012300: "월남동" },
            { 2911012400: "선교동" },
            { 2911012500: "내남동" },
            { 2911012600: "용산동" },
            { 2911012700: "충장로1가" },
            { 2911012800: "충장로2가" },
            { 2911012900: "충장로3가" },
            { 2911013000: "충장로4가" },
            { 2911013100: "금남로1가" },
            { 2911013200: "금남로2가" },
            { 2911013300: "금남로3가" },
            { 2911013400: "금남로4가" },
          ],
        },
        {
          2914000000: "서구",
          third: [
            { 2914010400: "양동" },
            { 2914010600: "농성동" },
            { 2914011500: "광천동" },
            { 2914011600: "유촌동" },
            { 2914011700: "덕흥동" },
            { 2914011800: "쌍촌동" },
            { 2914011900: "화정동" },
            { 2914012000: "치평동" },
            { 2914012100: "내방동" },
            { 2914012500: "서창동" },
            { 2914012600: "세하동" },
            { 2914012700: "용두동" },
            { 2914012800: "풍암동" },
            { 2914012900: "벽진동" },
            { 2914013000: "금호동" },
            { 2914013100: "마륵동" },
            { 2914013200: "매월동" },
            { 2914013300: "동천동" },
          ],
        },
        {
          2915500000: "남구",
          third: [
            { 2915510100: "사동" },
            { 2915510200: "구동" },
            { 2915510300: "서동" },
            { 2915510400: "월산동" },
            { 2915510500: "백운동" },
            { 2915510600: "주월동" },
            { 2915510700: "노대동" },
            { 2915510800: "진월동" },
            { 2915510900: "덕남동" },
            { 2915511000: "행암동" },
            { 2915511100: "임암동" },
            { 2915511200: "송하동" },
            { 2915511300: "양림동" },
            { 2915511400: "방림동" },
            { 2915511500: "봉선동" },
            { 2915511600: "구소동" },
            { 2915511700: "양촌동" },
            { 2915511800: "도금동" },
            { 2915511900: "승촌동" },
            { 2915512000: "지석동" },
            { 2915512100: "압촌동" },
            { 2915512200: "화장동" },
            { 2915512300: "칠석동" },
            { 2915512400: "석정동" },
            { 2915512500: "신장동" },
            { 2915512600: "양과동" },
            { 2915512700: "이장동" },
            { 2915512800: "대지동" },
            { 2915512900: "원산동" },
            { 2915513000: "월성동" },
          ],
        },
        {
          2917000000: "북구",
          third: [
            { 2917010100: "중흥동" },
            { 2917010200: "유동" },
            { 2917010300: "누문동" },
            { 2917010400: "북동" },
            { 2917010500: "임동" },
            { 2917010600: "신안동" },
            { 2917010700: "용봉동" },
            { 2917010800: "동림동" },
            { 2917010900: "운암동" },
            { 2917011000: "우산동" },
            { 2917011100: "풍향동" },
            { 2917011200: "문흥동" },
            { 2917011300: "각화동" },
            { 2917011400: "두암동" },
            { 2917011500: "오치동" },
            { 2917011600: "삼각동" },
            { 2917011700: "매곡동" },
            { 2917011800: "충효동" },
            { 2917011900: "덕의동" },
            { 2917012000: "금곡동" },
            { 2917012100: "망월동" },
            { 2917012200: "청풍동" },
            { 2917012300: "화암동" },
            { 2917012400: "장등동" },
            { 2917012500: "운정동" },
            { 2917012600: "본촌동" },
            { 2917012700: "일곡동" },
            { 2917012800: "양산동" },
            { 2917012900: "연제동" },
            { 2917013000: "신용동" },
            { 2917013100: "용두동" },
            { 2917013200: "지야동" },
            { 2917013300: "태령동" },
            { 2917013400: "수곡동" },
            { 2917013500: "효령동" },
            { 2917013600: "용전동" },
            { 2917013700: "용강동" },
            { 2917013800: "생용동" },
            { 2917013900: "월출동" },
            { 2917014000: "대촌동" },
            { 2917014100: "오룡동" },
          ],
        },
        {
          2920000000: "광산구",
          third: [
            { 2920010100: "송정동" },
            { 2920010200: "도산동" },
            { 2920010300: "도호동" },
            { 2920010400: "신촌동" },
            { 2920010500: "서봉동" },
            { 2920010600: "운수동" },
            { 2920010700: "선암동" },
            { 2920010800: "소촌동" },
            { 2920010900: "우산동" },
            { 2920011000: "황룡동" },
            { 2920011100: "박호동" },
            { 2920011200: "비아동" },
            { 2920011300: "도천동" },
            { 2920011400: "수완동" },
            { 2920011500: "월계동" },
            { 2920011600: "쌍암동" },
            { 2920011700: "산월동" },
            { 2920011800: "신창동" },
            { 2920011900: "신가동" },
            { 2920012000: "운남동" },
            { 2920012100: "안청동" },
            { 2920012200: "진곡동" },
            { 2920012300: "장덕동" },
            { 2920012400: "흑석동" },
            { 2920012500: "하남동" },
            { 2920012600: "장수동" },
            { 2920012700: "산정동" },
            { 2920012800: "월곡동" },
            { 2920012900: "등임동" },
            { 2920013000: "산막동" },
            { 2920013100: "고룡동" },
            { 2920013200: "신룡동" },
            { 2920013300: "두정동" },
            { 2920013400: "임곡동" },
            { 2920013500: "광산동" },
            { 2920013600: "오산동" },
            { 2920013700: "사호동" },
            { 2920013800: "하산동" },
            { 2920013900: "유계동" },
            { 2920014000: "본덕동" },
            { 2920014100: "용봉동" },
            { 2920014200: "요기동" },
            { 2920014300: "복룡동" },
            { 2920014400: "송대동" },
            { 2920014500: "옥동" },
            { 2920014600: "월전동" },
            { 2920014700: "장록동" },
            { 2920014800: "송촌동" },
            { 2920014900: "지죽동" },
            { 2920015000: "용동" },
            { 2920015100: "용곡동" },
            { 2920015200: "지정동" },
            { 2920015300: "명화동" },
            { 2920015400: "동산동" },
            { 2920015500: "연산동" },
            { 2920015600: "도덕동" },
            { 2920015700: "송산동" },
            { 2920015800: "지평동" },
            { 2920015900: "오운동" },
            { 2920016000: "삼거동" },
            { 2920016100: "양동" },
            { 2920016200: "내산동" },
            { 2920016300: "대산동" },
            { 2920016400: "송학동" },
            { 2920016500: "신동" },
            { 2920016600: "삼도동" },
            { 2920016700: "남산동" },
            { 2920016800: "송치동" },
            { 2920016900: "산수동" },
            { 2920017000: "선동" },
            { 2920017100: "지산동" },
            { 2920017200: "왕동" },
            { 2920017300: "북산동" },
            { 2920017400: "명도동" },
            { 2920017500: "동호동" },
            { 2920017600: "덕림동" },
            { 2920017700: "양산동" },
            { 2920017800: "동림동" },
            { 2920020200: "오선동" },
          ],
        },
      ],
    },
  },
  {
    first: {
      3000000000: "대전광역시",
      second: [
        {
          3011000000: "동구",
          third: [
            { 3011010100: "원동" },
            { 3011010200: "인동" },
            { 3011010300: "효동" },
            { 3011010400: "천동" },
            { 3011010500: "가오동" },
            { 3011010600: "신흥동" },
            { 3011010700: "판암동" },
            { 3011010800: "삼정동" },
            { 3011010900: "용운동" },
            { 3011011000: "대동" },
            { 3011011100: "자양동" },
            { 3011011200: "신안동" },
            { 3011011300: "소제동" },
            { 3011011400: "가양동" },
            { 3011011500: "용전동" },
            { 3011011600: "성남동" },
            { 3011011700: "홍도동" },
            { 3011011800: "삼성동" },
            { 3011011900: "정동" },
            { 3011012000: "중동" },
            { 3011012100: "추동" },
            { 3011012200: "비룡동" },
            { 3011012300: "주산동" },
            { 3011012400: "용계동" },
            { 3011012500: "마산동" },
            { 3011012600: "효평동" },
            { 3011012700: "직동" },
            { 3011012800: "세천동" },
            { 3011012900: "신상동" },
            { 3011013000: "신하동" },
            { 3011013100: "신촌동" },
            { 3011013200: "사성동" },
            { 3011013300: "내탑동" },
            { 3011013400: "오동" },
            { 3011013500: "주촌동" },
            { 3011013600: "낭월동" },
            { 3011013700: "대별동" },
            { 3011013800: "이사동" },
            { 3011013900: "대성동" },
            { 3011014000: "장척동" },
            { 3011014100: "소호동" },
            { 3011014200: "구도동" },
            { 3011014300: "삼괴동" },
            { 3011014400: "상소동" },
            { 3011014500: "하소동" },
          ],
        },
        {
          3014000000: "중구",
          third: [
            { 3014010100: "은행동" },
            { 3014010200: "선화동" },
            { 3014010300: "목동" },
            { 3014010400: "중촌동" },
            { 3014010500: "대흥동" },
            { 3014010600: "문창동" },
            { 3014010700: "석교동" },
            { 3014010800: "호동" },
            { 3014010900: "옥계동" },
            { 3014011000: "대사동" },
            { 3014011100: "부사동" },
            { 3014011200: "용두동" },
            { 3014011300: "오류동" },
            { 3014011400: "태평동" },
            { 3014011500: "유천동" },
            { 3014011600: "문화동" },
            { 3014011700: "산성동" },
            { 3014011800: "사정동" },
            { 3014011900: "안영동" },
            { 3014012000: "무수동" },
            { 3014012100: "구완동" },
            { 3014012200: "침산동" },
            { 3014012300: "목달동" },
            { 3014012400: "정생동" },
            { 3014012500: "어남동" },
            { 3014012600: "금동" },
          ],
        },
        {
          3017000000: "서구",
          third: [
            { 3017010100: "복수동" },
            { 3017010200: "변동" },
            { 3017010300: "도마동" },
            { 3017010400: "정림동" },
            { 3017010500: "용문동" },
            { 3017010600: "탄방동" },
            { 3017010800: "괴정동" },
            { 3017010900: "가장동" },
            { 3017011000: "내동" },
            { 3017011100: "갈마동" },
            { 3017011200: "둔산동" },
            { 3017011300: "월평동" },
            { 3017011400: "가수원동" },
            { 3017011500: "도안동" },
            { 3017011600: "관저동" },
            { 3017011700: "흑석동" },
            { 3017011800: "매노동" },
            { 3017011900: "산직동" },
            { 3017012000: "장안동" },
            { 3017012100: "평촌동" },
            { 3017012200: "오동" },
            { 3017012300: "우명동" },
            { 3017012400: "원정동" },
            { 3017012500: "용촌동" },
            { 3017012600: "봉곡동" },
            { 3017012700: "괴곡동" },
            { 3017012800: "만년동" },
          ],
        },
        {
          3020000000: "유성구",
          third: [
            { 3020010100: "원내동" },
            { 3020010200: "교촌동" },
            { 3020010300: "대정동" },
            { 3020010400: "용계동" },
            { 3020010500: "학하동" },
            { 3020010600: "계산동" },
            { 3020010700: "성북동" },
            { 3020010800: "세동" },
            { 3020010900: "송정동" },
            { 3020011000: "방동" },
            { 3020011100: "봉명동" },
            { 3020011200: "구암동" },
            { 3020011300: "덕명동" },
            { 3020011400: "원신흥동" },
            { 3020011500: "상대동" },
            { 3020011600: "복용동" },
            { 3020011700: "장대동" },
            { 3020011800: "갑동" },
            { 3020011900: "노은동" },
            { 3020012000: "지족동" },
            { 3020012100: "죽동" },
            { 3020012200: "궁동" },
            { 3020012300: "어은동" },
            { 3020012400: "구성동" },
            { 3020012500: "신성동" },
            { 3020012600: "가정동" },
            { 3020012700: "도룡동" },
            { 3020012800: "장동" },
            { 3020012900: "방현동" },
            { 3020013000: "화암동" },
            { 3020013100: "덕진동" },
            { 3020013200: "하기동" },
            { 3020013300: "추목동" },
            { 3020013400: "자운동" },
            { 3020013500: "신봉동" },
            { 3020013600: "수남동" },
            { 3020013700: "안산동" },
            { 3020013800: "외삼동" },
            { 3020013900: "반석동" },
            { 3020014000: "문지동" },
            { 3020014100: "전민동" },
            { 3020014200: "원촌동" },
            { 3020014300: "탑립동" },
            { 3020014400: "용산동" },
            { 3020014500: "봉산동" },
            { 3020014600: "관평동" },
            { 3020014700: "송강동" },
            { 3020014800: "금고동" },
            { 3020014900: "대동" },
            { 3020015000: "금탄동" },
            { 3020015100: "신동" },
            { 3020015200: "둔곡동" },
            { 3020015300: "구룡동" },
          ],
        },
        {
          3023000000: "대덕구",
          third: [
            { 3023010100: "오정동" },
            { 3023010200: "대화동" },
            { 3023010300: "읍내동" },
            { 3023010400: "연축동" },
            { 3023010500: "신대동" },
            { 3023010600: "와동" },
            { 3023010700: "송촌동" },
            { 3023010800: "법동" },
            { 3023010900: "중리동" },
            { 3023011000: "비래동" },
            { 3023011100: "석봉동" },
            { 3023011200: "목상동" },
            { 3023011300: "문평동" },
            { 3023011400: "신일동" },
            { 3023011500: "덕암동" },
            { 3023011600: "상서동" },
            { 3023011700: "평촌동" },
            { 3023011800: "장동" },
            { 3023011900: "용호동" },
            { 3023012000: "이현동" },
            { 3023012100: "갈전동" },
            { 3023012200: "부수동" },
            { 3023012300: "황호동" },
            { 3023012400: "삼정동" },
            { 3023012500: "미호동" },
            { 3023012600: "신탄진동" },
          ],
        },
      ],
    },
  },
  {
    first: {
      3100000000: "울산광역시",
      second: [
        {
          3111000000: "중구",
          third: [
            { 3111010100: "학성동" },
            { 3111010200: "학산동" },
            { 3111010300: "복산동" },
            { 3111010400: "북정동" },
            { 3111010500: "옥교동" },
            { 3111010600: "성남동" },
            { 3111010700: "교동" },
            { 3111010800: "우정동" },
            { 3111010900: "성안동" },
            { 3111011000: "유곡동" },
            { 3111011100: "태화동" },
            { 3111011200: "다운동" },
            { 3111011300: "동동" },
            { 3111011400: "서동" },
            { 3111011500: "남외동" },
            { 3111011600: "장현동" },
            { 3111011700: "약사동" },
            { 3111011800: "반구동" },
          ],
        },
        {
          3114000000: "남구",
          third: [
            { 3114010100: "무거동" },
            { 3114010200: "옥동" },
            { 3114010300: "두왕동" },
            { 3114010400: "신정동" },
            { 3114010500: "달동" },
            { 3114010600: "삼산동" },
            { 3114010700: "여천동" },
            { 3114010800: "야음동" },
            { 3114010900: "선암동" },
            { 3114011000: "상개동" },
            { 3114011100: "부곡동" },
            { 3114011200: "고사동" },
            { 3114011300: "성암동" },
            { 3114011400: "황성동" },
            { 3114011500: "용연동" },
            { 3114011600: "남화동" },
            { 3114011700: "용잠동" },
            { 3114011800: "장생포동" },
            { 3114011900: "매암동" },
          ],
        },
        {
          3117000000: "동구",
          third: [
            { 3117010100: "방어동" },
            { 3117010200: "화정동" },
            { 3117010300: "일산동" },
            { 3117010400: "전하동" },
            { 3117010500: "미포동" },
            { 3117010600: "주전동" },
            { 3117010700: "동부동" },
            { 3117010800: "서부동" },
          ],
        },
        {
          3120000000: "북구",
          third: [
            { 3120010100: "창평동" },
            { 3120010200: "호계동" },
            { 3120010300: "매곡동" },
            { 3120010400: "가대동" },
            { 3120010500: "신천동" },
            { 3120010600: "중산동" },
            { 3120010700: "상안동" },
            { 3120010800: "천곡동" },
            { 3120010900: "달천동" },
            { 3120011000: "시례동" },
            { 3120011100: "무룡동" },
            { 3120011200: "구유동" },
            { 3120011300: "정자동" },
            { 3120011400: "신명동" },
            { 3120011500: "대안동" },
            { 3120011600: "당사동" },
            { 3120011700: "신현동" },
            { 3120011800: "산하동" },
            { 3120011900: "어물동" },
            { 3120012000: "명촌동" },
            { 3120012100: "진장동" },
            { 3120012200: "연암동" },
            { 3120012300: "효문동" },
            { 3120012400: "양정동" },
            { 3120012500: "화봉동" },
            { 3120012600: "송정동" },
            { 3120012700: "염포동" },
          ],
        },
        {
          3171000000: "울주군",
          third: [
            { 3171025000: "온산읍" },
            { 3171025300: "언양읍" },
            { 3171025600: "온양읍" },
            { 3171025900: "범서읍" },
            { 3171026200: "청량읍" },
            { 3171026500: "삼남읍" },
            { 3171031000: "서생면" },
            { 3171034000: "웅촌면" },
            { 3171036000: "두동면" },
            { 3171037000: "두서면" },
            { 3171038000: "상북면" },
            { 3171040000: "삼동면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      3611000000: "세종특별자치시",
      second: [
        { 3611010100: "반곡동" },
        { 3611010200: "소담동" },
        { 3611010300: "보람동" },
        { 3611010400: "대평동" },
        { 3611010500: "가람동" },
        { 3611010600: "한솔동" },
        { 3611010700: "나성동" },
        { 3611010800: "새롬동" },
        { 3611010900: "다정동" },
        { 3611011000: "어진동" },
        { 3611011100: "종촌동" },
        { 3611011200: "고운동" },
        { 3611011300: "아름동" },
        { 3611011400: "도담동" },
        { 3611011500: "산울동" },
        { 3611011600: "해밀동" },
        { 3611011700: "합강동" },
        { 3611011800: "집현동" },
        { 3611011900: "세종동" },
        { 3611012000: "누리동" },
        { 3611012100: "한별동" },
        { 3611012200: "다솜동" },
        { 3611012300: "용호동" },
        { 3611025000: "조치원읍" },
        { 3611031000: "연기면" },
        { 3611032000: "연동면" },
        { 3611033000: "부강면" },
        { 3611034000: "금남면" },
        { 3611035000: "장군면" },
        { 3611036000: "연서면" },
        { 3611037000: "전의면" },
        { 3611038000: "전동면" },
        { 3611039000: "소정면" },
      ],
    },
  },
  {
    first: {
      4100000000: "경기도",
      second: [
        {
          4111000000: "수원시",
          third: [
            { 4111100000: "장안구" },
            { 4111112900: "장안구 파장동" },
            { 4111113000: "장안구 정자동" },
            { 4111113100: "장안구 이목동" },
            { 4111113200: "장안구 율전동" },
            { 4111113300: "장안구 천천동" },
            { 4111113400: "장안구 영화동" },
            { 4111113500: "장안구 송죽동" },
            { 4111113600: "장안구 조원동" },
            { 4111113700: "장안구 연무동" },
            { 4111113800: "장안구 상광교동" },
            { 4111113900: "장안구 하광교동" },
            { 4111300000: "권선구" },
            { 4111312600: "권선구 세류동" },
            { 4111312700: "권선구 평동" },
            { 4111312800: "권선구 고색동" },
            { 4111312900: "권선구 오목천동" },
            { 4111313000: "권선구 평리동" },
            { 4111313100: "권선구 서둔동" },
            { 4111313200: "권선구 구운동" },
            { 4111313300: "권선구 탑동" },
            { 4111313400: "권선구 금곡동" },
            { 4111313500: "권선구 호매실동" },
            { 4111313600: "권선구 곡반정동" },
            { 4111313700: "권선구 권선동" },
            { 4111313800: "권선구 장지동" },
            { 4111313900: "권선구 대황교동" },
            { 4111314000: "권선구 입북동" },
            { 4111314100: "권선구 당수동" },
            { 4111500000: "팔달구" },
            { 4111512000: "팔달구 팔달로1가" },
            { 4111512100: "팔달구 팔달로2가" },
            { 4111512200: "팔달구 팔달로3가" },
            { 4111512300: "팔달구 남창동" },
            { 4111512400: "팔달구 영동" },
            { 4111512500: "팔달구 중동" },
            { 4111512600: "팔달구 구천동" },
            { 4111512700: "팔달구 남수동" },
            { 4111512800: "팔달구 매향동" },
            { 4111512900: "팔달구 북수동" },
            { 4111513000: "팔달구 신풍동" },
            { 4111513100: "팔달구 장안동" },
            { 4111513200: "팔달구 교동" },
            { 4111513300: "팔달구 매교동" },
            { 4111513400: "팔달구 매산로1가" },
            { 4111513500: "팔달구 매산로2가" },
            { 4111513600: "팔달구 매산로3가" },
            { 4111513700: "팔달구 고등동" },
            { 4111513800: "팔달구 화서동" },
            { 4111513900: "팔달구 지동" },
            { 4111514000: "팔달구 우만동" },
            { 4111514100: "팔달구 인계동" },
            { 4111700000: "영통구" },
            { 4111710100: "영통구 매탄동" },
            { 4111710200: "영통구 원천동" },
            { 4111710300: "영통구 이의동" },
            { 4111710400: "영통구 하동" },
            { 4111710500: "영통구 영통동" },
            { 4111710600: "영통구 신동" },
            { 4111710700: "영통구 망포동" },
          ],
        },
        {
          4113000000: "성남시",
          third: [
            { 4113100000: "수정구" },
            { 4113110100: "수정구 신흥동" },
            { 4113110200: "수정구 태평동" },
            { 4113110300: "수정구 수진동" },
            { 4113110400: "수정구 단대동" },
            { 4113110500: "수정구 산성동" },
            { 4113110600: "수정구 양지동" },
            { 4113110700: "수정구 복정동" },
            { 4113110800: "수정구 창곡동" },
            { 4113110900: "수정구 신촌동" },
            { 4113111000: "수정구 오야동" },
            { 4113111100: "수정구 심곡동" },
            { 4113111200: "수정구 고등동" },
            { 4113111300: "수정구 상적동" },
            { 4113111400: "수정구 둔전동" },
            { 4113111500: "수정구 시흥동" },
            { 4113111600: "수정구 금토동" },
            { 4113111700: "수정구 사송동" },
            { 4113300000: "중원구" },
            { 4113310100: "중원구 성남동" },
            { 4113310300: "중원구 금광동" },
            { 4113310400: "중원구 은행동" },
            { 4113310500: "중원구 상대원동" },
            { 4113310600: "중원구 여수동" },
            { 4113310700: "중원구 도촌동" },
            { 4113310800: "중원구 갈현동" },
            { 4113310900: "중원구 하대원동" },
            { 4113313200: "중원구 중앙동" },
            { 4113500000: "분당구" },
            { 4113510100: "분당구 분당동" },
            { 4113510200: "분당구 수내동" },
            { 4113510300: "분당구 정자동" },
            { 4113510400: "분당구 율동" },
            { 4113510500: "분당구 서현동" },
            { 4113510600: "분당구 이매동" },
            { 4113510700: "분당구 야탑동" },
            { 4113510800: "분당구 판교동" },
            { 4113510900: "분당구 삼평동" },
            { 4113511000: "분당구 백현동" },
            { 4113511100: "분당구 금곡동" },
            { 4113511200: "분당구 궁내동" },
            { 4113511300: "분당구 동원동" },
            { 4113511400: "분당구 구미동" },
            { 4113511500: "분당구 운중동" },
            { 4113511600: "분당구 대장동" },
            { 4113511700: "분당구 석운동" },
            { 4113511800: "분당구 하산운동" },
          ],
        },
        {
          4115000000: "의정부시",
          third: [
            { 4115010100: "의정부동" },
            { 4115010200: "호원동" },
            { 4115010300: "장암동" },
            { 4115010400: "신곡동" },
            { 4115010500: "용현동" },
            { 4115010600: "민락동" },
            { 4115010700: "낙양동" },
            { 4115010800: "자일동" },
            { 4115010900: "금오동" },
            { 4115011000: "가능동" },
            { 4115011100: "녹양동" },
            { 4115011200: "고산동" },
            { 4115011300: "산곡동" },
          ],
        },
        {
          4117000000: "안양시",
          third: [
            { 4117100000: "만안구" },
            { 4117110100: "만안구 안양동" },
            { 4117110200: "만안구 석수동" },
            { 4117110300: "만안구 박달동" },
            { 4117300000: "동안구" },
            { 4117310100: "동안구 비산동" },
            { 4117310200: "동안구 관양동" },
            { 4117310300: "동안구 평촌동" },
            { 4117310400: "동안구 호계동" },
          ],
        },
        {
          4119000000: "부천시",
          third: [
            { 4119010100: "원미동" },
            { 4119010200: "심곡동" },
            { 4119010300: "춘의동" },
            { 4119010400: "도당동" },
            { 4119010500: "약대동" },
            { 4119010600: "소사동" },
            { 4119010700: "역곡동" },
            { 4119010800: "중동" },
            { 4119010900: "상동" },
            { 4119011000: "소사본동" },
            { 4119011100: "심곡본동" },
            { 4119011200: "범박동" },
            { 4119011300: "괴안동" },
            { 4119011400: "송내동" },
            { 4119011500: "옥길동" },
            { 4119011600: "계수동" },
            { 4119011700: "오정동" },
            { 4119011800: "여월동" },
            { 4119011900: "작동" },
            { 4119012000: "원종동" },
            { 4119012100: "고강동" },
            { 4119012200: "대장동" },
            { 4119012300: "삼정동" },
            { 4119012400: "내동" },
          ],
        },
        {
          4121000000: "광명시",
          third: [
            { 4121010100: "광명동" },
            { 4121010200: "철산동" },
            { 4121010300: "하안동" },
            { 4121010400: "소하동" },
            { 4121010500: "노온사동" },
            { 4121010600: "일직동" },
            { 4121010700: "가학동" },
            { 4121010800: "옥길동" },
          ],
        },
        {
          4122000000: "평택시",
          third: [
            { 4122010100: "서정동" },
            { 4122010200: "장당동" },
            { 4122010300: "모곡동" },
            { 4122010400: "칠괴동" },
            { 4122010500: "칠원동" },
            { 4122010600: "도일동" },
            { 4122010700: "가재동" },
            { 4122010800: "장안동" },
            { 4122010900: "이충동" },
            { 4122011000: "지산동" },
            { 4122011100: "독곡동" },
            { 4122011200: "신장동" },
            { 4122011300: "평택동" },
            { 4122011400: "통복동" },
            { 4122011500: "군문동" },
            { 4122011600: "유천동" },
            { 4122011700: "합정동" },
            { 4122011800: "비전동" },
            { 4122011900: "동삭동" },
            { 4122012000: "세교동" },
            { 4122012100: "지제동" },
            { 4122012200: "신대동" },
            { 4122012300: "소사동" },
            { 4122012400: "용이동" },
            { 4122012500: "월곡동" },
            { 4122012600: "청룡동" },
            { 4122012700: "죽백동" },
            { 4122012800: "고덕동" },
            { 4122025000: "팽성읍" },
            { 4122025300: "안중읍" },
            { 4122025600: "포승읍" },
            { 4122025900: "청북읍" },
            { 4122031000: "진위면" },
            { 4122032000: "서탄면" },
            { 4122033000: "고덕면" },
            { 4122034000: "오성면" },
            { 4122037000: "현덕면" },
          ],
        },
        {
          4125000000: "동두천시",
          third: [
            { 4125010100: "송내동" },
            { 4125010200: "지행동" },
            { 4125010300: "생연동" },
            { 4125010400: "광암동" },
            { 4125010500: "걸산동" },
            { 4125010600: "보산동" },
            { 4125010700: "동두천동" },
            { 4125010800: "안흥동" },
            { 4125010900: "상봉암동" },
            { 4125011000: "하봉암동" },
            { 4125011100: "탑동동" },
            { 4125011200: "상패동" },
          ],
        },
        {
          4127000000: "안산시",
          third: [
            { 4127100000: "상록구" },
            { 4127110100: "상록구 일동" },
            { 4127110200: "상록구 이동" },
            { 4127110300: "상록구 사동" },
            { 4127110400: "상록구 본오동" },
            { 4127110500: "상록구 팔곡이동" },
            { 4127110600: "상록구 양상동" },
            { 4127110700: "상록구 부곡동" },
            { 4127110800: "상록구 성포동" },
            { 4127110900: "상록구 월피동" },
            { 4127111000: "상록구 팔곡일동" },
            { 4127111100: "상록구 건건동" },
            { 4127111200: "상록구 사사동" },
            { 4127111300: "상록구 수암동" },
            { 4127111400: "상록구 장상동" },
            { 4127111500: "상록구 장하동" },
            { 4127300000: "단원구" },
            { 4127310100: "단원구 고잔동" },
            { 4127310200: "단원구 와동" },
            { 4127310300: "단원구 신길동" },
            { 4127310400: "단원구 성곡동" },
            { 4127310500: "단원구 원시동" },
            { 4127310600: "단원구 목내동" },
            { 4127310700: "단원구 초지동" },
            { 4127310800: "단원구 원곡동" },
            { 4127310900: "단원구 선부동" },
            { 4127311000: "단원구 대부동동" },
            { 4127311100: "단원구 대부북동" },
            { 4127311200: "단원구 대부남동" },
            { 4127311300: "단원구 선감동" },
            { 4127311400: "단원구 풍도동" },
            { 4127311500: "단원구 화정동" },
          ],
        },
        {
          4128000000: "고양시",
          third: [
            { 4128100000: "덕양구" },
            { 4128110100: "덕양구 주교동" },
            { 4128110200: "덕양구 원당동" },
            { 4128110300: "덕양구 신원동" },
            { 4128110400: "덕양구 원흥동" },
            { 4128110500: "덕양구 도내동" },
            { 4128110600: "덕양구 성사동" },
            { 4128110700: "덕양구 북한동" },
            { 4128110800: "덕양구 효자동" },
            { 4128110900: "덕양구 지축동" },
            { 4128111000: "덕양구 오금동" },
            { 4128111100: "덕양구 삼송동" },
            { 4128111200: "덕양구 동산동" },
            { 4128111300: "덕양구 용두동" },
            { 4128111400: "덕양구 벽제동" },
            { 4128111500: "덕양구 선유동" },
            { 4128111600: "덕양구 고양동" },
            { 4128111700: "덕양구 대자동" },
            { 4128111800: "덕양구 관산동" },
            { 4128111900: "덕양구 내유동" },
            { 4128112000: "덕양구 토당동" },
            { 4128112100: "덕양구 내곡동" },
            { 4128112200: "덕양구 대장동" },
            { 4128112300: "덕양구 화정동" },
            { 4128112400: "덕양구 강매동" },
            { 4128112500: "덕양구 행주내동" },
            { 4128112600: "덕양구 행주외동" },
            { 4128112700: "덕양구 신평동" },
            { 4128112800: "덕양구 행신동" },
            { 4128112900: "덕양구 화전동" },
            { 4128113000: "덕양구 현천동" },
            { 4128113100: "덕양구 덕은동" },
            { 4128113200: "덕양구 향동동" },
            { 4128500000: "일산동구" },
            { 4128510100: "일산동구 식사동" },
            { 4128510200: "일산동구 중산동" },
            { 4128510300: "일산동구 정발산동" },
            { 4128510400: "일산동구 장항동" },
            { 4128510500: "일산동구 마두동" },
            { 4128510600: "일산동구 백석동" },
            { 4128510700: "일산동구 풍동" },
            { 4128510800: "일산동구 산황동" },
            { 4128510900: "일산동구 사리현동" },
            { 4128511000: "일산동구 지영동" },
            { 4128511100: "일산동구 설문동" },
            { 4128511200: "일산동구 문봉동" },
            { 4128511300: "일산동구 성석동" },
            { 4128700000: "일산서구" },
            { 4128710100: "일산서구 일산동" },
            { 4128710200: "일산서구 주엽동" },
            { 4128710300: "일산서구 탄현동" },
            { 4128710400: "일산서구 대화동" },
            { 4128710500: "일산서구 덕이동" },
            { 4128710600: "일산서구 가좌동" },
            { 4128710700: "일산서구 구산동" },
            { 4128710800: "일산서구 법곳동" },
          ],
        },
        {
          4129000000: "과천시",
          third: [
            { 4129010100: "관문동" },
            { 4129010200: "문원동" },
            { 4129010300: "갈현동" },
            { 4129010400: "막계동" },
            { 4129010500: "과천동" },
            { 4129010600: "주암동" },
            { 4129010700: "중앙동" },
            { 4129010800: "원문동" },
            { 4129010900: "별양동" },
            { 4129011000: "부림동" },
          ],
        },
        {
          4131000000: "구리시",
          third: [
            { 4131010100: "갈매동" },
            { 4131010200: "사노동" },
            { 4131010300: "인창동" },
            { 4131010400: "교문동" },
            { 4131010500: "수택동" },
            { 4131010600: "아천동" },
            { 4131010700: "토평동" },
          ],
        },
        {
          4136000000: "남양주시",
          third: [
            { 4136010100: "호평동" },
            { 4136010200: "평내동" },
            { 4136010300: "금곡동" },
            { 4136010400: "일패동" },
            { 4136010500: "이패동" },
            { 4136010600: "삼패동" },
            { 4136010800: "수석동" },
            { 4136010900: "지금동" },
            { 4136011000: "도농동" },
            { 4136011100: "별내동" },
            { 4136011200: "다산동" },
            { 4136025000: "와부읍" },
            { 4136025300: "진접읍" },
            { 4136025600: "화도읍" },
            { 4136025900: "진건읍" },
            { 4136026200: "오남읍" },
            { 4136026500: "퇴계원읍" },
            { 4136031000: "별내면" },
            { 4136034000: "수동면" },
            { 4136036000: "조안면" },
          ],
        },
        {
          4137000000: "오산시",
          third: [
            { 4137010100: "오산동" },
            { 4137010200: "부산동" },
            { 4137010300: "원동" },
            { 4137010400: "궐동" },
            { 4137010500: "청학동" },
            { 4137010600: "가장동" },
            { 4137010700: "금암동" },
            { 4137010800: "수청동" },
            { 4137010900: "은계동" },
            { 4137011000: "내삼미동" },
            { 4137011100: "외삼미동" },
            { 4137011200: "양산동" },
            { 4137011300: "세교동" },
            { 4137011400: "지곶동" },
            { 4137011500: "서랑동" },
            { 4137011600: "서동" },
            { 4137011700: "벌음동" },
            { 4137011800: "두곡동" },
            { 4137011900: "탑동" },
            { 4137012000: "누읍동" },
            { 4137012100: "가수동" },
            { 4137012200: "고현동" },
            { 4137012300: "청호동" },
            { 4137012400: "갈곶동" },
          ],
        },
        {
          4139000000: "시흥시",
          third: [
            { 4139010100: "대야동" },
            { 4139010200: "신천동" },
            { 4139010300: "방산동" },
            { 4139010400: "포동" },
            { 4139010500: "미산동" },
            { 4139010600: "은행동" },
            { 4139010700: "안현동" },
            { 4139010800: "매화동" },
            { 4139010900: "도창동" },
            { 4139011000: "금이동" },
            { 4139011200: "과림동" },
            { 4139011300: "계수동" },
            { 4139011700: "화정동" },
            { 4139011800: "능곡동" },
            { 4139011900: "하중동" },
            { 4139012000: "하상동" },
            { 4139012100: "광석동" },
            { 4139012200: "물왕동" },
            { 4139012300: "산현동" },
            { 4139012400: "조남동" },
            { 4139012500: "논곡동" },
            { 4139012600: "목감동" },
            { 4139012700: "거모동" },
            { 4139012800: "군자동" },
            { 4139012900: "장현동" },
            { 4139013000: "장곡동" },
            { 4139013100: "월곶동" },
            { 4139013200: "정왕동" },
            { 4139013300: "죽율동" },
            { 4139013400: "무지내동" },
            { 4139013500: "배곧동" },
          ],
        },
        {
          4141000000: "군포시",
          third: [
            { 4141010100: "당동" },
            { 4141010200: "당정동" },
            { 4141010300: "부곡동" },
            { 4141010400: "산본동" },
            { 4141010500: "금정동" },
            { 4141010600: "둔대동" },
            { 4141010700: "속달동" },
            { 4141010800: "대야미동" },
            { 4141010900: "도마교동" },
          ],
        },
        {
          4143000000: "의왕시",
          third: [
            { 4143010100: "고천동" },
            { 4143010200: "이동" },
            { 4143010300: "삼동" },
            { 4143010400: "왕곡동" },
            { 4143010500: "오전동" },
            { 4143010600: "학의동" },
            { 4143010700: "내손동" },
            { 4143010800: "청계동" },
            { 4143010900: "포일동" },
            { 4143011000: "월암동" },
            { 4143011100: "초평동" },
          ],
        },
        {
          4145000000: "하남시",
          third: [
            { 4145010100: "천현동" },
            { 4145010200: "하산곡동" },
            { 4145010300: "창우동" },
            { 4145010400: "배알미동" },
            { 4145010500: "상산곡동" },
            { 4145010600: "신장동" },
            { 4145010700: "당정동" },
            { 4145010800: "덕풍동" },
            { 4145010900: "망월동" },
            { 4145011000: "풍산동" },
            { 4145011100: "미사동" },
            { 4145011200: "선동" },
            { 4145011300: "감북동" },
            { 4145011400: "감일동" },
            { 4145011500: "감이동" },
            { 4145011600: "학암동" },
            { 4145011700: "교산동" },
            { 4145011800: "춘궁동" },
            { 4145011900: "하사창동" },
            { 4145012000: "상사창동" },
            { 4145012100: "항동" },
            { 4145012200: "초일동" },
            { 4145012300: "초이동" },
            { 4145012400: "광암동" },
          ],
        },
        {
          4146000000: "용인시",
          third: [
            { 4146100000: "처인구" },
            { 4146110100: "처인구 김량장동" },
            { 4146110200: "처인구 역북동" },
            { 4146110300: "처인구 삼가동" },
            { 4146110400: "처인구 남동" },
            { 4146110500: "처인구 유방동" },
            { 4146110600: "처인구 고림동" },
            { 4146110700: "처인구 마평동" },
            { 4146110800: "처인구 운학동" },
            { 4146110900: "처인구 호동" },
            { 4146111000: "처인구 해곡동" },
            { 4146125000: "처인구 포곡읍" },
            { 4146125300: "처인구 모현읍" },
            { 4146125600: "처인구 이동읍" },
            { 4146125900: "처인구 남사읍" },
            { 4146134000: "처인구 원삼면" },
            { 4146135000: "처인구 백암면" },
            { 4146136000: "처인구 양지면" },
            { 4146300000: "기흥구" },
            { 4146310100: "기흥구 신갈동" },
            { 4146310200: "기흥구 구갈동" },
            { 4146310300: "기흥구 상갈동" },
            { 4146310400: "기흥구 하갈동" },
            { 4146310500: "기흥구 보라동" },
            { 4146310600: "기흥구 지곡동" },
            { 4146310700: "기흥구 공세동" },
            { 4146310800: "기흥구 고매동" },
            { 4146310900: "기흥구 농서동" },
            { 4146311000: "기흥구 서천동" },
            { 4146311100: "기흥구 영덕동" },
            { 4146311200: "기흥구 언남동" },
            { 4146311300: "기흥구 마북동" },
            { 4146311400: "기흥구 청덕동" },
            { 4146311500: "기흥구 동백동" },
            { 4146311600: "기흥구 중동" },
            { 4146311700: "기흥구 상하동" },
            { 4146311800: "기흥구 보정동" },
            { 4146500000: "수지구" },
            { 4146510100: "수지구 풍덕천동" },
            { 4146510200: "수지구 죽전동" },
            { 4146510300: "수지구 동천동" },
            { 4146510400: "수지구 고기동" },
            { 4146510500: "수지구 신봉동" },
            { 4146510600: "수지구 성복동" },
            { 4146510700: "수지구 상현동" },
          ],
        },
        {
          4148000000: "파주시",
          third: [
            { 4148010100: "금촌동" },
            { 4148010200: "아동동" },
            { 4148010400: "야동동" },
            { 4148010500: "검산동" },
            { 4148010600: "맥금동" },
            { 4148010700: "교하동" },
            { 4148010800: "야당동" },
            { 4148010900: "다율동" },
            { 4148011000: "오도동" },
            { 4148011100: "상지석동" },
            { 4148011200: "산남동" },
            { 4148011300: "동패동" },
            { 4148011400: "당하동" },
            { 4148011500: "문발동" },
            { 4148011600: "송촌동" },
            { 4148011700: "목동동" },
            { 4148011800: "하지석동" },
            { 4148011900: "서패동" },
            { 4148012000: "신촌동" },
            { 4148012100: "연다산동" },
            { 4148012200: "와동동" },
            { 4148012300: "금릉동" },
            { 4148025000: "문산읍" },
            { 4148025300: "파주읍" },
            { 4148025600: "법원읍" },
            { 4148026200: "조리읍" },
            { 4148031000: "월롱면" },
            { 4148032000: "탄현면" },
            { 4148035000: "광탄면" },
            { 4148036000: "파평면" },
            { 4148037000: "적성면" },
            { 4148038000: "군내면" },
            { 4148039000: "장단면" },
            { 4148040000: "진동면" },
            { 4148041000: "진서면" },
          ],
        },
        {
          4150000000: "이천시",
          third: [
            { 4150010100: "창전동" },
            { 4150010200: "관고동" },
            { 4150010300: "중리동" },
            { 4150010400: "증일동" },
            { 4150010500: "율현동" },
            { 4150010600: "진리동" },
            { 4150010700: "안흥동" },
            { 4150010800: "갈산동" },
            { 4150010900: "증포동" },
            { 4150011000: "송정동" },
            { 4150011100: "사음동" },
            { 4150011200: "단월동" },
            { 4150011300: "대포동" },
            { 4150011400: "고담동" },
            { 4150011500: "장록동" },
            { 4150025000: "장호원읍" },
            { 4150025300: "부발읍" },
            { 4150031000: "신둔면" },
            { 4150032000: "백사면" },
            { 4150033000: "호법면" },
            { 4150034000: "마장면" },
            { 4150035000: "대월면" },
            { 4150036000: "모가면" },
            { 4150037000: "설성면" },
            { 4150038000: "율면" },
          ],
        },
        {
          4155000000: "안성시",
          third: [
            { 4155010100: "봉산동" },
            { 4155010200: "숭인동" },
            { 4155010300: "영동" },
            { 4155010400: "봉남동" },
            { 4155010500: "구포동" },
            { 4155010600: "동본동" },
            { 4155010700: "명륜동" },
            { 4155010800: "옥천동" },
            { 4155010900: "낙원동" },
            { 4155011000: "창전동" },
            { 4155011100: "성남동" },
            { 4155011200: "신흥동" },
            { 4155011300: "인지동" },
            { 4155011400: "금산동" },
            { 4155011500: "연지동" },
            { 4155011600: "대천동" },
            { 4155011700: "서인동" },
            { 4155011800: "석정동" },
            { 4155011900: "아양동" },
            { 4155012000: "금석동" },
            { 4155012100: "계동" },
            { 4155012200: "옥산동" },
            { 4155012300: "사곡동" },
            { 4155012400: "도기동" },
            { 4155012500: "당왕동" },
            { 4155012600: "가사동" },
            { 4155012700: "가현동" },
            { 4155012800: "신건지동" },
            { 4155012900: "신소현동" },
            { 4155013000: "신모산동" },
            { 4155013100: "현수동" },
            { 4155013200: "발화동" },
            { 4155013300: "중리동" },
            { 4155025000: "공도읍" },
            { 4155031000: "보개면" },
            { 4155032000: "금광면" },
            { 4155033000: "서운면" },
            { 4155034000: "미양면" },
            { 4155035000: "대덕면" },
            { 4155036000: "양성면" },
            { 4155038000: "원곡면" },
            { 4155039000: "일죽면" },
            { 4155040000: "죽산면" },
            { 4155041000: "삼죽면" },
            { 4155042000: "고삼면" },
          ],
        },
        {
          4157000000: "김포시",
          third: [
            { 4157010100: "북변동" },
            { 4157010200: "걸포동" },
            { 4157010300: "운양동" },
            { 4157010400: "장기동" },
            { 4157010500: "감정동" },
            { 4157010600: "사우동" },
            { 4157010700: "풍무동" },
            { 4157010800: "마산동" },
            { 4157010900: "구래동" },
            { 4157025000: "통진읍" },
            { 4157025300: "고촌읍" },
            { 4157025600: "양촌읍" },
            { 4157034000: "대곶면" },
            { 4157035000: "월곶면" },
            { 4157036000: "하성면" },
          ],
        },
        {
          4159000000: "화성시",
          third: [
            { 4159011600: "진안동" },
            { 4159011700: "병점동" },
            { 4159011800: "능동" },
            { 4159011900: "기산동" },
            { 4159012000: "반월동" },
            { 4159012100: "반정동" },
            { 4159012200: "황계동" },
            { 4159012300: "배양동" },
            { 4159012400: "기안동" },
            { 4159012500: "송산동" },
            { 4159012600: "안녕동" },
            { 4159012700: "반송동" },
            { 4159012800: "석우동" },
            { 4159012900: "오산동" },
            { 4159013000: "청계동" },
            { 4159013100: "영천동" },
            { 4159013200: "중동" },
            { 4159013300: "신동" },
            { 4159013400: "목동" },
            { 4159013500: "산척동" },
            { 4159013600: "장지동" },
            { 4159013700: "송동" },
            { 4159013800: "방교동" },
            { 4159013900: "금곡동" },
            { 4159014000: "새솔동" },
            { 4159025300: "봉담읍" },
            { 4159025600: "우정읍" },
            { 4159025900: "향남읍" },
            { 4159026200: "남양읍" },
            { 4159031000: "매송면" },
            { 4159032000: "비봉면" },
            { 4159033000: "마도면" },
            { 4159034000: "송산면" },
            { 4159035000: "서신면" },
            { 4159036000: "팔탄면" },
            { 4159037000: "장안면" },
            { 4159040000: "양감면" },
            { 4159041000: "정남면" },
          ],
        },
        {
          4161000000: "광주시",
          third: [
            { 4161010100: "경안동" },
            { 4161010200: "쌍령동" },
            { 4161010300: "송정동" },
            { 4161010400: "회덕동" },
            { 4161010500: "탄벌동" },
            { 4161010600: "목현동" },
            { 4161010700: "삼동" },
            { 4161010800: "중대동" },
            { 4161010900: "직동" },
            { 4161011000: "태전동" },
            { 4161011100: "장지동" },
            { 4161011200: "역동" },
            { 4161011300: "목동" },
            { 4161025000: "오포읍" },
            { 4161025300: "초월읍" },
            { 4161025900: "곤지암읍" },
            { 4161033000: "도척면" },
            { 4161034000: "퇴촌면" },
            { 4161035000: "남종면" },
            { 4161037000: "남한산성면" },
          ],
        },
        {
          4163000000: "양주시",
          third: [
            { 4163010100: "유양동" },
            { 4163010200: "어둔동" },
            { 4163010300: "남방동" },
            { 4163010400: "마전동" },
            { 4163010500: "산북동" },
            { 4163010600: "광사동" },
            { 4163010700: "만송동" },
            { 4163010800: "삼숭동" },
            { 4163010900: "고읍동" },
            { 4163011000: "덕정동" },
            { 4163011100: "봉양동" },
            { 4163011200: "회암동" },
            { 4163011300: "율정동" },
            { 4163011400: "옥정동" },
            { 4163011500: "고암동" },
            { 4163011600: "덕계동" },
            { 4163011700: "회정동" },
            { 4163025000: "백석읍" },
            { 4163031000: "은현면" },
            { 4163032000: "남면" },
            { 4163033000: "광적면" },
            { 4163034000: "장흥면" },
          ],
        },
        {
          4165000000: "포천시",
          third: [
            { 4165010100: "신읍동" },
            { 4165010200: "어룡동" },
            { 4165010300: "자작동" },
            { 4165010400: "선단동" },
            { 4165010500: "설운동" },
            { 4165010600: "동교동" },
            { 4165025000: "소흘읍" },
            { 4165031000: "군내면" },
            { 4165032000: "내촌면" },
            { 4165033000: "가산면" },
            { 4165034000: "신북면" },
            { 4165035000: "창수면" },
            { 4165036000: "영중면" },
            { 4165037000: "일동면" },
            { 4165038000: "이동면" },
            { 4165039000: "영북면" },
            { 4165040000: "관인면" },
            { 4165041000: "화현면" },
          ],
        },
        {
          4167000000: "여주시",
          third: [
            { 4167010100: "상동" },
            { 4167010200: "홍문동" },
            { 4167010300: "창동" },
            { 4167010400: "우만동" },
            { 4167010500: "단현동" },
            { 4167010600: "신진동" },
            { 4167010700: "하동" },
            { 4167010800: "교동" },
            { 4167010900: "월송동" },
            { 4167011000: "가업동" },
            { 4167011100: "연라동" },
            { 4167011200: "상거동" },
            { 4167011300: "하거동" },
            { 4167011400: "삼교동" },
            { 4167011500: "점봉동" },
            { 4167011600: "능현동" },
            { 4167011700: "멱곡동" },
            { 4167011800: "연양동" },
            { 4167011900: "매룡동" },
            { 4167012000: "천송동" },
            { 4167012100: "오학동" },
            { 4167012200: "현암동" },
            { 4167012300: "오금동" },
            { 4167025000: "가남읍" },
            { 4167031000: "점동면" },
            { 4167032000: "흥천면" },
            { 4167033000: "금사면" },
            { 4167034500: "세종대왕면" },
            { 4167035000: "대신면" },
            { 4167036000: "북내면" },
            { 4167037000: "강천면" },
            { 4167038000: "산북면" },
          ],
        },
        {
          4180000000: "연천군",
          third: [
            { 4180025000: "연천읍" },
            { 4180025300: "전곡읍" },
            { 4180031000: "군남면" },
            { 4180032000: "청산면" },
            { 4180033000: "백학면" },
            { 4180034000: "미산면" },
            { 4180035000: "왕징면" },
            { 4180036000: "신서면" },
            { 4180037000: "중면" },
            { 4180038000: "장남면" },
          ],
        },
        {
          4182000000: "가평군",
          third: [
            { 4182025000: "가평읍" },
            { 4182031000: "설악면" },
            { 4182032500: "청평면" },
            { 4182033000: "상면" },
            { 4182034500: "조종면" },
            { 4182035000: "북면" },
          ],
        },
        {
          4183000000: "양평군",
          third: [
            { 4183025000: "양평읍" },
            { 4183031000: "강상면" },
            { 4183032000: "강하면" },
            { 4183033000: "양서면" },
            { 4183034000: "옥천면" },
            { 4183035000: "서종면" },
            { 4183036000: "단월면" },
            { 4183037000: "청운면" },
            { 4183038000: "양동면" },
            { 4183039500: "지평면" },
            { 4183040000: "용문면" },
            { 4183041000: "개군면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4200000000: "강원도",
      second: [
        {
          4211000000: "춘천시",
          third: [
            { 4211010100: "봉의동" },
            { 4211010200: "요선동" },
            { 4211010300: "낙원동" },
            { 4211010400: "중앙로1가" },
            { 4211010500: "중앙로2가" },
            { 4211010600: "중앙로3가" },
            { 4211010700: "옥천동" },
            { 4211010800: "조양동" },
            { 4211010900: "죽림동" },
            { 4211011000: "운교동" },
            { 4211011100: "약사동" },
            { 4211011200: "효자동" },
            { 4211011300: "소양로1가" },
            { 4211011400: "소양로2가" },
            { 4211011500: "소양로3가" },
            { 4211011600: "소양로4가" },
            { 4211011700: "근화동" },
            { 4211011800: "우두동" },
            { 4211011900: "사농동" },
            { 4211012000: "후평동" },
            { 4211012100: "온의동" },
            { 4211012200: "교동" },
            { 4211012300: "퇴계동" },
            { 4211012400: "석사동" },
            { 4211012500: "삼천동" },
            { 4211012600: "칠전동" },
            { 4211012700: "송암동" },
            { 4211012800: "신동" },
            { 4211012900: "중도동" },
            { 4211025000: "신북읍" },
            { 4211031000: "동면" },
            { 4211032000: "동산면" },
            { 4211033000: "신동면" },
            { 4211034000: "남면" },
            { 4211035000: "서면" },
            { 4211036000: "사북면" },
            { 4211038000: "북산면" },
            { 4211039000: "동내면" },
            { 4211040000: "남산면" },
          ],
        },
        {
          4213000000: "원주시",
          third: [
            { 4213010100: "중앙동" },
            { 4213010200: "평원동" },
            { 4213010300: "원동" },
            { 4213010400: "인동" },
            { 4213010500: "개운동" },
            { 4213010600: "명륜동" },
            { 4213010700: "단구동" },
            { 4213010800: "일산동" },
            { 4213010900: "학성동" },
            { 4213011000: "단계동" },
            { 4213011100: "우산동" },
            { 4213011200: "태장동" },
            { 4213011300: "봉산동" },
            { 4213011400: "행구동" },
            { 4213011500: "무실동" },
            { 4213011600: "관설동" },
            { 4213011700: "반곡동" },
            { 4213011800: "가현동" },
            { 4213025000: "문막읍" },
            { 4213031000: "소초면" },
            { 4213032000: "호저면" },
            { 4213033000: "지정면" },
            { 4213035000: "부론면" },
            { 4213036000: "귀래면" },
            { 4213037000: "흥업면" },
            { 4213038000: "판부면" },
            { 4213039000: "신림면" },
          ],
        },
        {
          4215000000: "강릉시",
          third: [
            { 4215010100: "홍제동" },
            { 4215010200: "남문동" },
            { 4215010300: "명주동" },
            { 4215010400: "성내동" },
            { 4215010500: "임당동" },
            { 4215010600: "금학동" },
            { 4215010700: "용강동" },
            { 4215010800: "성남동" },
            { 4215010900: "옥천동" },
            { 4215011000: "교동" },
            { 4215011100: "포남동" },
            { 4215011200: "초당동" },
            { 4215011300: "강문동" },
            { 4215011400: "송정동" },
            { 4215011500: "견소동" },
            { 4215011600: "내곡동" },
            { 4215011700: "회산동" },
            { 4215011800: "장현동" },
            { 4215011900: "박월동" },
            { 4215012000: "담산동" },
            { 4215012100: "노암동" },
            { 4215012200: "유산동" },
            { 4215012300: "월호평동" },
            { 4215012400: "신석동" },
            { 4215012500: "입암동" },
            { 4215012600: "청량동" },
            { 4215012700: "두산동" },
            { 4215012800: "학동" },
            { 4215012900: "병산동" },
            { 4215013000: "남항진동" },
            { 4215013100: "유천동" },
            { 4215013200: "지변동" },
            { 4215013300: "죽헌동" },
            { 4215013400: "대전동" },
            { 4215013500: "운정동" },
            { 4215013600: "난곡동" },
            { 4215013700: "저동" },
            { 4215013800: "안현동" },
            { 4215013900: "운산동" },
            { 4215025000: "주문진읍" },
            { 4215031000: "성산면" },
            { 4215032000: "왕산면" },
            { 4215033000: "구정면" },
            { 4215034000: "강동면" },
            { 4215035000: "옥계면" },
            { 4215036000: "사천면" },
            { 4215037000: "연곡면" },
          ],
        },
        {
          4217000000: "동해시",
          third: [
            { 4217010100: "천곡동" },
            { 4217010200: "평릉동" },
            { 4217010300: "송정동" },
            { 4217010400: "용정동" },
            { 4217010500: "지흥동" },
            { 4217010600: "효가동" },
            { 4217010700: "동회동" },
            { 4217010800: "나안동" },
            { 4217010900: "쇄운동" },
            { 4217011000: "부곡동" },
            { 4217011100: "발한동" },
            { 4217011200: "북평동" },
            { 4217011300: "구미동" },
            { 4217011400: "추암동" },
            { 4217011500: "구호동" },
            { 4217011600: "단봉동" },
            { 4217011700: "지가동" },
            { 4217011800: "이도동" },
            { 4217011900: "귀운동" },
            { 4217012000: "대구동" },
            { 4217012100: "호현동" },
            { 4217012200: "내동" },
            { 4217012300: "묵호진동" },
            { 4217012400: "삼화동" },
            { 4217012500: "이기동" },
            { 4217012600: "이로동" },
            { 4217012700: "어달동" },
            { 4217012800: "대진동" },
            { 4217012900: "망상동" },
            { 4217013000: "심곡동" },
            { 4217013100: "초구동" },
            { 4217013200: "괴란동" },
            { 4217013300: "만우동" },
            { 4217013400: "신흥동" },
            { 4217013500: "비천동" },
            { 4217013600: "달방동" },
          ],
        },
        {
          4219000000: "태백시",
          third: [
            { 4219010100: "황지동" },
            { 4219010200: "장성동" },
            { 4219010300: "금천동" },
            { 4219010400: "철암동" },
            { 4219010500: "문곡동" },
            { 4219010600: "동점동" },
            { 4219010700: "소도동" },
            { 4219010800: "혈동" },
            { 4219010900: "화전동" },
            { 4219011000: "적각동" },
            { 4219011100: "창죽동" },
            { 4219011200: "통동" },
            { 4219011300: "백산동" },
            { 4219011400: "원동" },
            { 4219011500: "상사미동" },
            { 4219011600: "하사미동" },
            { 4219011700: "조탄동" },
          ],
        },
        {
          4221000000: "속초시",
          third: [
            { 4221010100: "영랑동" },
            { 4221010200: "동명동" },
            { 4221010300: "중앙동" },
            { 4221010400: "금호동" },
            { 4221010500: "청학동" },
            { 4221010600: "교동" },
            { 4221010700: "노학동" },
            { 4221010800: "조양동" },
            { 4221010900: "청호동" },
            { 4221011000: "대포동" },
            { 4221011100: "도문동" },
            { 4221011200: "설악동" },
            { 4221011300: "장사동" },
          ],
        },
        {
          4223000000: "삼척시",
          third: [
            { 4223010100: "성내동" },
            { 4223010200: "성북동" },
            { 4223010300: "읍상동" },
            { 4223010400: "읍중동" },
            { 4223010500: "당저동" },
            { 4223010600: "교동" },
            { 4223010700: "갈천동" },
            { 4223010800: "증산동" },
            { 4223010900: "우지동" },
            { 4223011000: "마달동" },
            { 4223011100: "자원동" },
            { 4223011200: "평전동" },
            { 4223011300: "등봉동" },
            { 4223011400: "도경동" },
            { 4223011500: "마평동" },
            { 4223011600: "오사동" },
            { 4223011700: "건지동" },
            { 4223011800: "원당동" },
            { 4223011900: "성남동" },
            { 4223012000: "남양동" },
            { 4223012100: "사직동" },
            { 4223012200: "오분동" },
            { 4223012300: "적노동" },
            { 4223012400: "조비동" },
            { 4223012500: "정상동" },
            { 4223012600: "정하동" },
            { 4223012700: "근산동" },
            { 4223025000: "도계읍" },
            { 4223025300: "원덕읍" },
            { 4223031000: "근덕면" },
            { 4223032000: "하장면" },
            { 4223033000: "노곡면" },
            { 4223034000: "미로면" },
            { 4223035000: "가곡면" },
            { 4223036000: "신기면" },
          ],
        },
        {
          4272000000: "홍천군",
          third: [
            { 4272025000: "홍천읍" },
            { 4272031000: "화촌면" },
            { 4272032000: "두촌면" },
            { 4272033000: "내촌면" },
            { 4272034000: "서석면" },
            { 4272035200: "영귀미면" },
            { 4272036000: "남면" },
            { 4272037000: "서면" },
            { 4272038000: "북방면" },
            { 4272039000: "내면" },
          ],
        },
        {
          4273000000: "횡성군",
          third: [
            { 4273025000: "횡성읍" },
            { 4273031000: "우천면" },
            { 4273032000: "안흥면" },
            { 4273033000: "둔내면" },
            { 4273034000: "갑천면" },
            { 4273035000: "청일면" },
            { 4273036000: "공근면" },
            { 4273037000: "서원면" },
            { 4273038000: "강림면" },
          ],
        },
        {
          4275000000: "영월군",
          third: [
            { 4275025000: "영월읍" },
            { 4275025300: "상동읍" },
            { 4275031200: "산솔면" },
            { 4275032500: "김삿갓면" },
            { 4275033000: "북면" },
            { 4275034000: "남면" },
            { 4275035500: "한반도면" },
            { 4275036000: "주천면" },
            { 4275038000: "무릉도원면" },
          ],
        },
        {
          4276000000: "평창군",
          third: [
            { 4276025000: "평창읍" },
            { 4276031000: "미탄면" },
            { 4276032000: "방림면" },
            { 4276033000: "대화면" },
            { 4276034000: "봉평면" },
            { 4276035000: "용평면" },
            { 4276036000: "진부면" },
            { 4276038000: "대관령면" },
          ],
        },
        {
          4277000000: "정선군",
          third: [
            { 4277025000: "정선읍" },
            { 4277025300: "고한읍" },
            { 4277025600: "사북읍" },
            { 4277025900: "신동읍" },
            { 4277032000: "남면" },
            { 4277034000: "북평면" },
            { 4277035000: "임계면" },
            { 4277036000: "화암면" },
            { 4277037000: "여량면" },
          ],
        },
        {
          4278000000: "철원군",
          third: [
            { 4278025000: "철원읍" },
            { 4278025300: "김화읍" },
            { 4278025600: "갈말읍" },
            { 4278025900: "동송읍" },
            { 4278031000: "서면" },
            { 4278032000: "근남면" },
            { 4278033000: "근북면" },
            { 4278034000: "근동면" },
            { 4278035000: "원동면" },
            { 4278036000: "원남면" },
            { 4278037000: "임남면" },
          ],
        },
        {
          4279000000: "화천군",
          third: [
            { 4279025000: "화천읍" },
            { 4279031000: "간동면" },
            { 4279032000: "하남면" },
            { 4279033000: "상서면" },
            { 4279034000: "사내면" },
          ],
        },
        {
          4280000000: "양구군",
          third: [
            { 4280025000: "양구읍" },
            { 4280031500: "국토정중앙면" },
            { 4280032000: "동면" },
            { 4280033000: "방산면" },
            { 4280034000: "해안면" },
          ],
        },
        {
          4281000000: "인제군",
          third: [
            { 4281025000: "인제읍" },
            { 4281031000: "남면" },
            { 4281032000: "북면" },
            { 4281033000: "기린면" },
            { 4281034000: "서화면" },
            { 4281035000: "상남면" },
          ],
        },
        {
          4282000000: "고성군",
          third: [
            { 4282025000: "간성읍" },
            { 4282025300: "거진읍" },
            { 4282031000: "현내면" },
            { 4282032000: "죽왕면" },
            { 4282033000: "토성면" },
            { 4282034000: "수동면" },
          ],
        },
        {
          4283000000: "양양군",
          third: [
            { 4283025000: "양양읍" },
            { 4283031000: "서면" },
            { 4283032000: "손양면" },
            { 4283033000: "현북면" },
            { 4283034000: "현남면" },
            { 4283035000: "강현면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4300000000: "충청북도",
      second: [
        {
          4311000000: "청주시",
          third: [
            { 4311100000: "상당구" },
            { 4311110100: "상당구 영동" },
            { 4311110200: "상당구 북문로1가" },
            { 4311110300: "상당구 북문로2가" },
            { 4311110400: "상당구 북문로3가" },
            { 4311110500: "상당구 남문로1가" },
            { 4311110600: "상당구 남문로2가" },
            { 4311110700: "상당구 문화동" },
            { 4311110800: "상당구 서운동" },
            { 4311110900: "상당구 서문동" },
            { 4311111000: "상당구 남주동" },
            { 4311111100: "상당구 석교동" },
            { 4311111200: "상당구 수동" },
            { 4311111700: "상당구 탑동" },
            { 4311111800: "상당구 대성동" },
            { 4311111900: "상당구 영운동" },
            { 4311112000: "상당구 금천동" },
            { 4311112100: "상당구 용담동" },
            { 4311112200: "상당구 명암동" },
            { 4311112300: "상당구 산성동" },
            { 4311112400: "상당구 용암동" },
            { 4311112500: "상당구 용정동" },
            { 4311112600: "상당구 방서동" },
            { 4311112700: "상당구 평촌동" },
            { 4311112800: "상당구 지북동" },
            { 4311112900: "상당구 운동동" },
            { 4311113000: "상당구 월오동" },
            { 4311131000: "상당구 낭성면" },
            { 4311132000: "상당구 미원면" },
            { 4311133000: "상당구 가덕면" },
            { 4311134000: "상당구 남일면" },
            { 4311135000: "상당구 문의면" },
            { 4311200000: "서원구" },
            { 4311210100: "서원구 사직동" },
            { 4311210200: "서원구 사창동" },
            { 4311210300: "서원구 모충동" },
            { 4311210400: "서원구 산남동" },
            { 4311210500: "서원구 미평동" },
            { 4311210600: "서원구 분평동" },
            { 4311210700: "서원구 수곡동" },
            { 4311210800: "서원구 성화동" },
            { 4311210900: "서원구 개신동" },
            { 4311211000: "서원구 죽림동" },
            { 4311211100: "서원구 장성동" },
            { 4311211200: "서원구 장암동" },
            { 4311231000: "서원구 남이면" },
            { 4311232000: "서원구 현도면" },
            { 4311300000: "흥덕구" },
            { 4311310400: "흥덕구 운천동" },
            { 4311310500: "흥덕구 신봉동" },
            { 4311311300: "흥덕구 가경동" },
            { 4311311400: "흥덕구 복대동" },
            { 4311311500: "흥덕구 봉명동" },
            { 4311311600: "흥덕구 송정동" },
            { 4311311700: "흥덕구 강서동" },
            { 4311311800: "흥덕구 석곡동" },
            { 4311311900: "흥덕구 휴암동" },
            { 4311312000: "흥덕구 신전동" },
            { 4311312100: "흥덕구 현암동" },
            { 4311312200: "흥덕구 동막동" },
            { 4311312300: "흥덕구 수의동" },
            { 4311312400: "흥덕구 지동동" },
            { 4311312500: "흥덕구 서촌동" },
            { 4311312600: "흥덕구 신성동" },
            { 4311312700: "흥덕구 평동" },
            { 4311312800: "흥덕구 신대동" },
            { 4311312900: "흥덕구 남촌동" },
            { 4311313000: "흥덕구 내곡동" },
            { 4311313100: "흥덕구 상신동" },
            { 4311313200: "흥덕구 원평동" },
            { 4311313300: "흥덕구 문암동" },
            { 4311313400: "흥덕구 송절동" },
            { 4311313500: "흥덕구 화계동" },
            { 4311313600: "흥덕구 외북동" },
            { 4311313700: "흥덕구 향정동" },
            { 4311313800: "흥덕구 비하동" },
            { 4311313900: "흥덕구 석소동" },
            { 4311314000: "흥덕구 정봉동" },
            { 4311314100: "흥덕구 신촌동" },
            { 4311325000: "흥덕구 오송읍" },
            { 4311331000: "흥덕구 강내면" },
            { 4311332000: "흥덕구 옥산면" },
            { 4311400000: "청원구" },
            { 4311410100: "청원구 우암동" },
            { 4311410200: "청원구 내덕동" },
            { 4311410300: "청원구 율량동" },
            { 4311410400: "청원구 사천동" },
            { 4311410500: "청원구 주성동" },
            { 4311410600: "청원구 주중동" },
            { 4311410700: "청원구 정상동" },
            { 4311410800: "청원구 정하동" },
            { 4311410900: "청원구 정북동" },
            { 4311411000: "청원구 오동동" },
            { 4311411100: "청원구 외남동" },
            { 4311411200: "청원구 외평동" },
            { 4311411300: "청원구 외하동" },
            { 4311425000: "청원구 내수읍" },
            { 4311425300: "청원구 오창읍" },
            { 4311431000: "청원구 북이면" },
          ],
        },
        {
          4313000000: "충주시",
          third: [
            { 4313010100: "성내동" },
            { 4313010200: "성남동" },
            { 4313010300: "성서동" },
            { 4313010400: "충인동" },
            { 4313010500: "교현동" },
            { 4313010600: "용산동" },
            { 4313010700: "호암동" },
            { 4313010800: "직동" },
            { 4313010900: "단월동" },
            { 4313011000: "풍동" },
            { 4313011100: "가주동" },
            { 4313011200: "용관동" },
            { 4313011300: "용두동" },
            { 4313011400: "달천동" },
            { 4313011500: "봉방동" },
            { 4313011600: "칠금동" },
            { 4313011800: "연수동" },
            { 4313011900: "목행동" },
            { 4313012000: "용탄동" },
            { 4313012100: "종민동" },
            { 4313012200: "안림동" },
            { 4313012300: "목벌동" },
            { 4313012400: "충의동" },
            { 4313012500: "지현동" },
            { 4313012700: "문화동" },
            { 4313012800: "금릉동" },
            { 4313025000: "주덕읍" },
            { 4313031000: "살미면" },
            { 4313032500: "수안보면" },
            { 4313033500: "대소원면" },
            { 4313035000: "신니면" },
            { 4313036000: "노은면" },
            { 4313037000: "앙성면" },
            { 4313038500: "중앙탑면" },
            { 4313039000: "금가면" },
            { 4313040000: "동량면" },
            { 4313041000: "산척면" },
            { 4313042000: "엄정면" },
            { 4313043000: "소태면" },
          ],
        },
        {
          4315000000: "제천시",
          third: [
            { 4315010100: "의림동" },
            { 4315010200: "서부동" },
            { 4315010300: "동현동" },
            { 4315010400: "남천동" },
            { 4315010500: "교동" },
            { 4315010600: "중앙로1가" },
            { 4315010700: "중앙로2가" },
            { 4315010800: "명동" },
            { 4315010900: "화산동" },
            { 4315011000: "영천동" },
            { 4315011100: "하소동" },
            { 4315011200: "신월동" },
            { 4315011300: "청전동" },
            { 4315011400: "모산동" },
            { 4315011500: "고암동" },
            { 4315011600: "장락동" },
            { 4315011700: "흑석동" },
            { 4315011800: "두학동" },
            { 4315011900: "고명동" },
            { 4315012000: "신백동" },
            { 4315012100: "강제동" },
            { 4315012200: "명지동" },
            { 4315012300: "산곡동" },
            { 4315012400: "왕암동" },
            { 4315012500: "천남동" },
            { 4315012600: "신동" },
            { 4315012700: "자작동" },
            { 4315012800: "대랑동" },
            { 4315025000: "봉양읍" },
            { 4315031000: "금성면" },
            { 4315032000: "청풍면" },
            { 4315033000: "수산면" },
            { 4315034000: "덕산면" },
            { 4315035000: "한수면" },
            { 4315036000: "백운면" },
            { 4315038000: "송학면" },
          ],
        },
        {
          4372000000: "보은군",
          third: [
            { 4372025000: "보은읍" },
            { 4372031500: "속리산면" },
            { 4372032500: "장안면" },
            { 4372033000: "마로면" },
            { 4372034000: "탄부면" },
            { 4372035000: "삼승면" },
            { 4372036000: "수한면" },
            { 4372037000: "회남면" },
            { 4372038500: "회인면" },
            { 4372039000: "내북면" },
            { 4372040000: "산외면" },
          ],
        },
        {
          4373000000: "옥천군",
          third: [
            { 4373025000: "옥천읍" },
            { 4373031000: "동이면" },
            { 4373032000: "안남면" },
            { 4373033000: "안내면" },
            { 4373034000: "청성면" },
            { 4373035000: "청산면" },
            { 4373036000: "이원면" },
            { 4373037000: "군서면" },
            { 4373038000: "군북면" },
          ],
        },
        {
          4374000000: "영동군",
          third: [
            { 4374025000: "영동읍" },
            { 4374031000: "용산면" },
            { 4374032000: "황간면" },
            { 4374033500: "추풍령면" },
            { 4374034000: "매곡면" },
            { 4374035000: "상촌면" },
            { 4374036000: "양강면" },
            { 4374037000: "용화면" },
            { 4374038000: "학산면" },
            { 4374039000: "양산면" },
            { 4374040000: "심천면" },
          ],
        },
        {
          4374500000: "증평군",
          third: [{ 4374525000: "증평읍" }, { 4374531000: "도안면" }],
        },
        {
          4375000000: "진천군",
          third: [
            { 4375025000: "진천읍" },
            { 4375025300: "덕산읍" },
            { 4375032000: "초평면" },
            { 4375033000: "문백면" },
            { 4375034000: "백곡면" },
            { 4375035000: "이월면" },
            { 4375037000: "광혜원면" },
          ],
        },
        {
          4376000000: "괴산군",
          third: [
            { 4376025000: "괴산읍" },
            { 4376031000: "감물면" },
            { 4376032000: "장연면" },
            { 4376033000: "연풍면" },
            { 4376034000: "칠성면" },
            { 4376035000: "문광면" },
            { 4376036000: "청천면" },
            { 4376037000: "청안면" },
            { 4376039000: "사리면" },
            { 4376040000: "소수면" },
            { 4376041000: "불정면" },
          ],
        },
        {
          4377000000: "음성군",
          third: [
            { 4377025000: "음성읍" },
            { 4377025300: "금왕읍" },
            { 4377031000: "소이면" },
            { 4377032000: "원남면" },
            { 4377033000: "맹동면" },
            { 4377034000: "대소면" },
            { 4377035000: "삼성면" },
            { 4377036000: "생극면" },
            { 4377037000: "감곡면" },
          ],
        },
        {
          4380000000: "단양군",
          third: [
            { 4380025000: "단양읍" },
            { 4380025300: "매포읍" },
            { 4380031000: "대강면" },
            { 4380032000: "가곡면" },
            { 4380033000: "영춘면" },
            { 4380034000: "어상천면" },
            { 4380035000: "적성면" },
            { 4380036000: "단성면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4400000000: "충청남도",
      second: [
        {
          4413000000: "천안시",
          third: [
            { 4413100000: "동남구" },
            { 4413110100: "동남구 대흥동" },
            { 4413110200: "동남구 성황동" },
            { 4413110300: "동남구 문화동" },
            { 4413110400: "동남구 사직동" },
            { 4413110500: "동남구 영성동" },
            { 4413110600: "동남구 오룡동" },
            { 4413110700: "동남구 원성동" },
            { 4413110800: "동남구 구성동" },
            { 4413110900: "동남구 청수동" },
            { 4413111000: "동남구 삼룡동" },
            { 4413111100: "동남구 청당동" },
            { 4413111200: "동남구 유량동" },
            { 4413111300: "동남구 봉명동" },
            { 4413111400: "동남구 다가동" },
            { 4413111500: "동남구 용곡동" },
            { 4413111600: "동남구 신방동" },
            { 4413111700: "동남구 쌍용동" },
            { 4413111800: "동남구 신부동" },
            { 4413111900: "동남구 안서동" },
            { 4413112000: "동남구 구룡동" },
            { 4413125000: "동남구 목천읍" },
            { 4413131000: "동남구 풍세면" },
            { 4413132000: "동남구 광덕면" },
            { 4413133000: "동남구 북면" },
            { 4413134000: "동남구 성남면" },
            { 4413135000: "동남구 수신면" },
            { 4413136000: "동남구 병천면" },
            { 4413137000: "동남구 동면" },
            { 4413300000: "서북구" },
            { 4413310100: "서북구 와촌동" },
            { 4413310200: "서북구 성정동" },
            { 4413310300: "서북구 백석동" },
            { 4413310400: "서북구 두정동" },
            { 4413310500: "서북구 성성동" },
            { 4413310600: "서북구 차암동" },
            { 4413310700: "서북구 쌍용동" },
            { 4413310800: "서북구 불당동" },
            { 4413310900: "서북구 업성동" },
            { 4413311000: "서북구 신당동" },
            { 4413311100: "서북구 부대동" },
            { 4413325000: "서북구 성환읍" },
            { 4413325300: "서북구 성거읍" },
            { 4413325600: "서북구 직산읍" },
            { 4413331000: "서북구 입장면" },
          ],
        },
        {
          4415000000: "공주시",
          third: [
            { 4415010100: "반죽동" },
            { 4415010200: "봉황동" },
            { 4415010300: "중학동" },
            { 4415010400: "중동" },
            { 4415010500: "산성동" },
            { 4415010600: "교동" },
            { 4415010700: "웅진동" },
            { 4415010800: "금성동" },
            { 4415010900: "옥룡동" },
            { 4415011000: "금학동" },
            { 4415011100: "봉정동" },
            { 4415011200: "주미동" },
            { 4415011300: "태봉동" },
            { 4415011400: "오곡동" },
            { 4415011500: "신기동" },
            { 4415011600: "소학동" },
            { 4415011700: "상왕동" },
            { 4415011800: "무릉동" },
            { 4415011900: "월송동" },
            { 4415012000: "신관동" },
            { 4415012100: "금흥동" },
            { 4415012200: "쌍신동" },
            { 4415012300: "월미동" },
            { 4415012400: "검상동" },
            { 4415012500: "석장리동" },
            { 4415012600: "송선동" },
            { 4415012700: "동현동" },
            { 4415025000: "유구읍" },
            { 4415031000: "이인면" },
            { 4415032000: "탄천면" },
            { 4415033000: "계룡면" },
            { 4415034000: "반포면" },
            { 4415036000: "의당면" },
            { 4415037000: "정안면" },
            { 4415038000: "우성면" },
            { 4415039000: "사곡면" },
            { 4415040000: "신풍면" },
          ],
        },
        {
          4418000000: "보령시",
          third: [
            { 4418010100: "대천동" },
            { 4418010200: "죽정동" },
            { 4418010300: "화산동" },
            { 4418010400: "동대동" },
            { 4418010500: "명천동" },
            { 4418010600: "궁촌동" },
            { 4418010700: "내항동" },
            { 4418010800: "남곡동" },
            { 4418010900: "요암동" },
            { 4418011000: "신흑동" },
            { 4418025000: "웅천읍" },
            { 4418031000: "주포면" },
            { 4418032000: "오천면" },
            { 4418033000: "천북면" },
            { 4418034000: "청소면" },
            { 4418035000: "청라면" },
            { 4418036000: "남포면" },
            { 4418038000: "주산면" },
            { 4418039000: "미산면" },
            { 4418040000: "성주면" },
            { 4418041000: "주교면" },
          ],
        },
        {
          4420000000: "아산시",
          third: [
            { 4420010100: "온천동" },
            { 4420010200: "실옥동" },
            { 4420010300: "방축동" },
            { 4420010400: "기산동" },
            { 4420010500: "초사동" },
            { 4420010600: "신인동" },
            { 4420010700: "법곡동" },
            { 4420010800: "장존동" },
            { 4420010900: "좌부동" },
            { 4420011000: "읍내동" },
            { 4420011100: "풍기동" },
            { 4420011200: "용화동" },
            { 4420011300: "모종동" },
            { 4420011400: "권곡동" },
            { 4420011500: "배미동" },
            { 4420011600: "득산동" },
            { 4420011700: "점양동" },
            { 4420011800: "신동" },
            { 4420011900: "남동" },
            { 4420025000: "염치읍" },
            { 4420025300: "배방읍" },
            { 4420031000: "송악면" },
            { 4420033000: "탕정면" },
            { 4420035000: "음봉면" },
            { 4420036000: "둔포면" },
            { 4420037000: "영인면" },
            { 4420038000: "인주면" },
            { 4420039000: "선장면" },
            { 4420040000: "도고면" },
            { 4420041000: "신창면" },
          ],
        },
        {
          4421000000: "서산시",
          third: [
            { 4421010100: "읍내동" },
            { 4421010200: "동문동" },
            { 4421010300: "갈산동" },
            { 4421010400: "온석동" },
            { 4421010500: "잠홍동" },
            { 4421010600: "수석동" },
            { 4421010700: "석림동" },
            { 4421010800: "석남동" },
            { 4421010900: "예천동" },
            { 4421011000: "죽성동" },
            { 4421011100: "양대동" },
            { 4421011200: "오남동" },
            { 4421011300: "장동" },
            { 4421011400: "덕지천동" },
            { 4421025000: "대산읍" },
            { 4421031000: "인지면" },
            { 4421032000: "부석면" },
            { 4421033000: "팔봉면" },
            { 4421034000: "지곡면" },
            { 4421036000: "성연면" },
            { 4421037000: "음암면" },
            { 4421038000: "운산면" },
            { 4421039000: "해미면" },
            { 4421040000: "고북면" },
          ],
        },
        {
          4423000000: "논산시",
          third: [
            { 4423010100: "화지동" },
            { 4423010200: "반월동" },
            { 4423010300: "대교동" },
            { 4423010400: "부창동" },
            { 4423010500: "취암동" },
            { 4423010600: "등화동" },
            { 4423010700: "지산동" },
            { 4423010800: "덕지동" },
            { 4423010900: "내동" },
            { 4423011000: "강산동" },
            { 4423011100: "관촉동" },
            { 4423025000: "강경읍" },
            { 4423025300: "연무읍" },
            { 4423031000: "성동면" },
            { 4423032000: "광석면" },
            { 4423033000: "노성면" },
            { 4423034000: "상월면" },
            { 4423035000: "부적면" },
            { 4423036000: "연산면" },
            { 4423038000: "벌곡면" },
            { 4423039000: "양촌면" },
            { 4423040000: "가야곡면" },
            { 4423041000: "은진면" },
            { 4423042000: "채운면" },
          ],
        },
        {
          4425000000: "계룡시",
          third: [
            { 4425010100: "금암동" },
            { 4425031000: "두마면" },
            { 4425031500: "엄사면" },
            { 4425033000: "신도안면" },
          ],
        },
        {
          4427000000: "당진시",
          third: [
            { 4427010100: "읍내동" },
            { 4427010200: "채운동" },
            { 4427010300: "우두동" },
            { 4427010400: "원당동" },
            { 4427010500: "시곡동" },
            { 4427010600: "수청동" },
            { 4427010700: "대덕동" },
            { 4427010800: "행정동" },
            { 4427010900: "용연동" },
            { 4427011000: "사기소동" },
            { 4427011100: "구룡동" },
            { 4427025000: "합덕읍" },
            { 4427025300: "송악읍" },
            { 4427031000: "고대면" },
            { 4427032000: "석문면" },
            { 4427033000: "대호지면" },
            { 4427034000: "정미면" },
            { 4427035000: "면천면" },
            { 4427036000: "순성면" },
            { 4427037000: "우강면" },
            { 4427038000: "신평면" },
            { 4427039000: "송산면" },
          ],
        },
        {
          4471000000: "금산군",
          third: [
            { 4471025000: "금산읍" },
            { 4471031000: "금성면" },
            { 4471032000: "제원면" },
            { 4471033000: "부리면" },
            { 4471034000: "군북면" },
            { 4471035000: "남일면" },
            { 4471036000: "남이면" },
            { 4471037000: "진산면" },
            { 4471038000: "복수면" },
            { 4471039000: "추부면" },
          ],
        },
        {
          4476000000: "부여군",
          third: [
            { 4476025000: "부여읍" },
            { 4476031000: "규암면" },
            { 4476032000: "은산면" },
            { 4476033000: "외산면" },
            { 4476034000: "내산면" },
            { 4476035000: "구룡면" },
            { 4476036000: "홍산면" },
            { 4476037000: "옥산면" },
            { 4476038000: "남면" },
            { 4476039000: "충화면" },
            { 4476040000: "양화면" },
            { 4476041000: "임천면" },
            { 4476042000: "장암면" },
            { 4476043000: "세도면" },
            { 4476044000: "석성면" },
            { 4476045000: "초촌면" },
          ],
        },
        {
          4477000000: "서천군",
          third: [
            { 4477025000: "장항읍" },
            { 4477025300: "서천읍" },
            { 4477031000: "마서면" },
            { 4477032000: "화양면" },
            { 4477033000: "기산면" },
            { 4477034000: "한산면" },
            { 4477035000: "마산면" },
            { 4477036000: "시초면" },
            { 4477037000: "문산면" },
            { 4477038000: "판교면" },
            { 4477039000: "종천면" },
            { 4477040000: "비인면" },
            { 4477041000: "서면" },
          ],
        },
        {
          4479000000: "청양군",
          third: [
            { 4479025000: "청양읍" },
            { 4479031000: "운곡면" },
            { 4479032000: "대치면" },
            { 4479033000: "정산면" },
            { 4479034000: "목면" },
            { 4479035000: "청남면" },
            { 4479036000: "장평면" },
            { 4479037000: "남양면" },
            { 4479038000: "화성면" },
            { 4479039000: "비봉면" },
          ],
        },
        {
          4480000000: "홍성군",
          third: [
            { 4480025000: "홍성읍" },
            { 4480025300: "광천읍" },
            { 4480025600: "홍북읍" },
            { 4480032000: "금마면" },
            { 4480033000: "홍동면" },
            { 4480034000: "장곡면" },
            { 4480035000: "은하면" },
            { 4480036000: "결성면" },
            { 4480037000: "서부면" },
            { 4480038000: "갈산면" },
            { 4480039000: "구항면" },
          ],
        },
        {
          4481000000: "예산군",
          third: [
            { 4481025000: "예산읍" },
            { 4481025300: "삽교읍" },
            { 4481031000: "대술면" },
            { 4481032000: "신양면" },
            { 4481033000: "광시면" },
            { 4481034000: "대흥면" },
            { 4481035000: "응봉면" },
            { 4481036000: "덕산면" },
            { 4481037000: "봉산면" },
            { 4481038000: "고덕면" },
            { 4481039000: "신암면" },
            { 4481040000: "오가면" },
          ],
        },
        {
          4482500000: "태안군",
          third: [
            { 4482525000: "태안읍" },
            { 4482525300: "안면읍" },
            { 4482531000: "고남면" },
            { 4482532000: "남면" },
            { 4482533000: "근흥면" },
            { 4482534000: "소원면" },
            { 4482535000: "원북면" },
            { 4482536000: "이원면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4500000000: "전라북도",
      second: [
        {
          4511000000: "전주시",
          third: [
            { 4511100000: "완산구" },
            { 4511110100: "완산구 중앙동1가" },
            { 4511110200: "완산구 중앙동2가" },
            { 4511110300: "완산구 중앙동3가" },
            { 4511110400: "완산구 중앙동4가" },
            { 4511110500: "완산구 경원동1가" },
            { 4511110600: "완산구 경원동2가" },
            { 4511110700: "완산구 경원동3가" },
            { 4511110800: "완산구 풍남동1가" },
            { 4511110900: "완산구 풍남동2가" },
            { 4511111000: "완산구 풍남동3가" },
            { 4511111100: "완산구 전동" },
            { 4511111200: "완산구 전동3가" },
            { 4511111300: "완산구 다가동1가" },
            { 4511111400: "완산구 다가동2가" },
            { 4511111500: "완산구 다가동3가" },
            { 4511111600: "완산구 다가동4가" },
            { 4511111700: "완산구 고사동" },
            { 4511111800: "완산구 교동" },
            { 4511111900: "완산구 태평동" },
            { 4511112000: "완산구 중노송동" },
            { 4511112100: "완산구 남노송동" },
            { 4511112200: "완산구 동완산동" },
            { 4511112300: "완산구 서완산동1가" },
            { 4511112400: "완산구 서완산동2가" },
            { 4511112500: "완산구 동서학동" },
            { 4511112600: "완산구 서서학동" },
            { 4511112700: "완산구 중화산동1가" },
            { 4511112800: "완산구 중화산동2가" },
            { 4511112900: "완산구 서신동" },
            { 4511113000: "완산구 석구동" },
            { 4511113100: "완산구 원당동" },
            { 4511113200: "완산구 평화동1가" },
            { 4511113300: "완산구 평화동2가" },
            { 4511113400: "완산구 평화동3가" },
            { 4511113500: "완산구 중인동" },
            { 4511113600: "완산구 용복동" },
            { 4511113700: "완산구 삼천동1가" },
            { 4511113800: "완산구 삼천동2가" },
            { 4511113900: "완산구 삼천동3가" },
            { 4511114000: "완산구 효자동1가" },
            { 4511114100: "완산구 효자동2가" },
            { 4511114200: "완산구 효자동3가" },
            { 4511114300: "완산구 대성동" },
            { 4511114400: "완산구 색장동" },
            { 4511114500: "완산구 상림동" },
            { 4511114700: "완산구 서노송동" },
            { 4511300000: "덕진구" },
            { 4511310200: "덕진구 진북동" },
            { 4511310300: "덕진구 인후동1가" },
            { 4511310400: "덕진구 인후동2가" },
            { 4511310500: "덕진구 덕진동1가" },
            { 4511310600: "덕진구 덕진동2가" },
            { 4511310700: "덕진구 금암동" },
            { 4511310800: "덕진구 팔복동1가" },
            { 4511310900: "덕진구 팔복동2가" },
            { 4511311000: "덕진구 팔복동3가" },
            { 4511311100: "덕진구 산정동" },
            { 4511311200: "덕진구 금상동" },
            { 4511311300: "덕진구 우아동1가" },
            { 4511311400: "덕진구 우아동2가" },
            { 4511311500: "덕진구 우아동3가" },
            { 4511311600: "덕진구 호성동1가" },
            { 4511311700: "덕진구 호성동2가" },
            { 4511311800: "덕진구 호성동3가" },
            { 4511311900: "덕진구 전미동1가" },
            { 4511312000: "덕진구 전미동2가" },
            { 4511312100: "덕진구 송천동1가" },
            { 4511312200: "덕진구 송천동2가" },
            { 4511312300: "덕진구 반월동" },
            { 4511312400: "덕진구 화전동" },
            { 4511312500: "덕진구 용정동" },
            { 4511312600: "덕진구 성덕동" },
            { 4511312700: "덕진구 원동" },
            { 4511312900: "덕진구 고랑동" },
            { 4511313000: "덕진구 여의동" },
            { 4511313100: "덕진구 만성동" },
            { 4511313200: "덕진구 장동" },
            { 4511313300: "덕진구 팔복동4가" },
            { 4511313400: "덕진구 도도동" },
            { 4511313500: "덕진구 강흥동" },
            { 4511313600: "덕진구 도덕동" },
            { 4511313700: "덕진구 남정동" },
            { 4511313800: "덕진구 중동" },
            { 4511313900: "덕진구 여의동2가" },
          ],
        },
        {
          4513000000: "군산시",
          third: [
            { 4513010100: "해망동" },
            { 4513010200: "신흥동" },
            { 4513010300: "금동" },
            { 4513010400: "월명동" },
            { 4513010500: "신창동" },
            { 4513010600: "오룡동" },
            { 4513010700: "금광동" },
            { 4513010800: "신풍동" },
            { 4513010900: "송풍동" },
            { 4513011000: "문화동" },
            { 4513011100: "삼학동" },
            { 4513011200: "선양동" },
            { 4513011300: "둔율동" },
            { 4513011400: "창성동" },
            { 4513011500: "명산동" },
            { 4513011600: "송창동" },
            { 4513011700: "개복동" },
            { 4513011800: "중앙로1가" },
            { 4513011900: "영화동" },
            { 4513012000: "장미동" },
            { 4513012100: "중앙로2가" },
            { 4513012200: "영동" },
            { 4513012300: "신영동" },
            { 4513012400: "죽성동" },
            { 4513012500: "평화동" },
            { 4513012600: "중앙로3가" },
            { 4513012700: "대명동" },
            { 4513012800: "장재동" },
            { 4513012900: "미원동" },
            { 4513013000: "중동" },
            { 4513013100: "금암동" },
            { 4513013200: "동흥남동" },
            { 4513013300: "서흥남동" },
            { 4513013400: "조촌동" },
            { 4513013500: "경장동" },
            { 4513013600: "경암동" },
            { 4513013700: "구암동" },
            { 4513013800: "내흥동" },
            { 4513013900: "개정동" },
            { 4513014000: "사정동" },
            { 4513014100: "수송동" },
            { 4513014200: "미장동" },
            { 4513014300: "지곡동" },
            { 4513014400: "나운동" },
            { 4513014500: "미룡동" },
            { 4513014600: "소룡동" },
            { 4513014700: "오식도동" },
            { 4513014800: "비응도동" },
            { 4513014900: "신관동" },
            { 4513015000: "개사동" },
            { 4513015100: "산북동" },
            { 4513015200: "내초동" },
            { 4513025000: "옥구읍" },
            { 4513031000: "옥산면" },
            { 4513032000: "회현면" },
            { 4513033000: "임피면" },
            { 4513034000: "서수면" },
            { 4513035000: "대야면" },
            { 4513036000: "개정면" },
            { 4513037000: "성산면" },
            { 4513038000: "나포면" },
            { 4513039000: "옥도면" },
            { 4513040000: "옥서면" },
          ],
        },
        {
          4514000000: "익산시",
          third: [
            { 4514010100: "창인동1가" },
            { 4514010200: "창인동2가" },
            { 4514010300: "중앙동1가" },
            { 4514010400: "중앙동2가" },
            { 4514010500: "중앙동3가" },
            { 4514010600: "평화동" },
            { 4514010700: "갈산동" },
            { 4514010800: "주현동" },
            { 4514010900: "인화동1가" },
            { 4514011000: "인화동2가" },
            { 4514011100: "동산동" },
            { 4514011200: "마동" },
            { 4514011300: "남중동" },
            { 4514011400: "모현동1가" },
            { 4514011500: "모현동2가" },
            { 4514011600: "송학동" },
            { 4514011700: "목천동" },
            { 4514011800: "만석동" },
            { 4514011900: "현영동" },
            { 4514012000: "신용동" },
            { 4514012100: "신동" },
            { 4514012200: "영등동" },
            { 4514012300: "어양동" },
            { 4514012400: "신흥동" },
            { 4514012500: "금강동" },
            { 4514012600: "석탄동" },
            { 4514012700: "팔봉동" },
            { 4514012800: "덕기동" },
            { 4514012900: "석왕동" },
            { 4514013000: "은기동" },
            { 4514013100: "정족동" },
            { 4514013200: "임상동" },
            { 4514013300: "월성동" },
            { 4514013400: "부송동" },
            { 4514013500: "용제동" },
            { 4514013600: "석암동" },
            { 4514025000: "함열읍" },
            { 4514031000: "오산면" },
            { 4514032000: "황등면" },
            { 4514033000: "함라면" },
            { 4514034000: "웅포면" },
            { 4514035000: "성당면" },
            { 4514036000: "용안면" },
            { 4514037000: "낭산면" },
            { 4514038000: "망성면" },
            { 4514039000: "여산면" },
            { 4514040000: "금마면" },
            { 4514041000: "왕궁면" },
            { 4514042000: "춘포면" },
            { 4514043000: "삼기면" },
            { 4514044000: "용동면" },
          ],
        },
        {
          4518000000: "정읍시",
          third: [
            { 4518010100: "수성동" },
            { 4518010200: "장명동" },
            { 4518010300: "상동" },
            { 4518010400: "시기동" },
            { 4518010500: "연지동" },
            { 4518010600: "농소동" },
            { 4518010700: "하모동" },
            { 4518010800: "상평동" },
            { 4518010900: "과교동" },
            { 4518011000: "삼산동" },
            { 4518011100: "진산동" },
            { 4518011200: "금붕동" },
            { 4518011300: "송산동" },
            { 4518011400: "신월동" },
            { 4518011500: "용산동" },
            { 4518011600: "교암동" },
            { 4518011700: "부전동" },
            { 4518011800: "쌍암동" },
            { 4518011900: "내장동" },
            { 4518012000: "영파동" },
            { 4518012100: "하북동" },
            { 4518012200: "구룡동" },
            { 4518012300: "흑암동" },
            { 4518012400: "용계동" },
            { 4518012500: "공평동" },
            { 4518012600: "망제동" },
            { 4518012700: "신정동" },
            { 4518025000: "신태인읍" },
            { 4518031000: "북면" },
            { 4518032000: "입암면" },
            { 4518033000: "소성면" },
            { 4518034000: "고부면" },
            { 4518035000: "영원면" },
            { 4518036000: "덕천면" },
            { 4518037000: "이평면" },
            { 4518038000: "정우면" },
            { 4518039000: "태인면" },
            { 4518040000: "감곡면" },
            { 4518041000: "옹동면" },
            { 4518042000: "칠보면" },
            { 4518043000: "산내면" },
            { 4518044000: "산외면" },
          ],
        },
        {
          4519000000: "남원시",
          third: [
            { 4519010100: "동충동" },
            { 4519010200: "하정동" },
            { 4519010300: "죽항동" },
            { 4519010400: "쌍교동" },
            { 4519010500: "천거동" },
            { 4519010600: "금동" },
            { 4519010700: "조산동" },
            { 4519010800: "왕정동" },
            { 4519010900: "신정동" },
            { 4519011000: "화정동" },
            { 4519011100: "향교동" },
            { 4519011200: "용정동" },
            { 4519011300: "광치동" },
            { 4519011400: "내척동" },
            { 4519011500: "산곡동" },
            { 4519011600: "도통동" },
            { 4519011700: "월락동" },
            { 4519011800: "고죽동" },
            { 4519011900: "식정동" },
            { 4519012000: "갈치동" },
            { 4519012100: "노암동" },
            { 4519012200: "어현동" },
            { 4519012300: "신촌동" },
            { 4519025000: "운봉읍" },
            { 4519031000: "주천면" },
            { 4519032000: "수지면" },
            { 4519033000: "송동면" },
            { 4519034000: "주생면" },
            { 4519035000: "금지면" },
            { 4519036000: "대강면" },
            { 4519037000: "대산면" },
            { 4519038000: "사매면" },
            { 4519039000: "덕과면" },
            { 4519040000: "보절면" },
            { 4519041000: "산동면" },
            { 4519042000: "이백면" },
            { 4519045000: "아영면" },
            { 4519046000: "산내면" },
            { 4519047000: "인월면" },
          ],
        },
        {
          4521000000: "김제시",
          third: [
            { 4521010100: "요촌동" },
            { 4521010200: "신풍동" },
            { 4521010300: "용동" },
            { 4521010400: "검산동" },
            { 4521010500: "순동" },
            { 4521010600: "백학동" },
            { 4521010700: "서암동" },
            { 4521010800: "신곡동" },
            { 4521010900: "교동" },
            { 4521011000: "옥산동" },
            { 4521011100: "갈공동" },
            { 4521011200: "하동" },
            { 4521011300: "흥사동" },
            { 4521011400: "상동동" },
            { 4521011500: "월성동" },
            { 4521011600: "황산동" },
            { 4521011700: "난봉동" },
            { 4521011800: "오정동" },
            { 4521011900: "복죽동" },
            { 4521012000: "입석동" },
            { 4521012100: "장화동" },
            { 4521012200: "신덕동" },
            { 4521012300: "월봉동" },
            { 4521012400: "신월동" },
            { 4521012500: "연정동" },
            { 4521012600: "명덕동" },
            { 4521012700: "제월동" },
            { 4521012800: "도장동" },
            { 4521012900: "서정동" },
            { 4521013000: "양전동" },
            { 4521025000: "만경읍" },
            { 4521032000: "죽산면" },
            { 4521033000: "백산면" },
            { 4521034000: "용지면" },
            { 4521035000: "백구면" },
            { 4521036000: "부량면" },
            { 4521038000: "공덕면" },
            { 4521039000: "청하면" },
            { 4521040000: "성덕면" },
            { 4521041000: "진봉면" },
            { 4521042000: "금구면" },
            { 4521043000: "봉남면" },
            { 4521044000: "황산면" },
            { 4521045000: "금산면" },
            { 4521046000: "광활면" },
          ],
        },
        {
          4571000000: "완주군",
          third: [
            { 4571025000: "삼례읍" },
            { 4571025300: "봉동읍" },
            { 4571025600: "용진읍" },
            { 4571032000: "상관면" },
            { 4571033000: "이서면" },
            { 4571034000: "소양면" },
            { 4571035000: "구이면" },
            { 4571036000: "고산면" },
            { 4571037000: "비봉면" },
            { 4571038000: "운주면" },
            { 4571039000: "화산면" },
            { 4571040000: "동상면" },
            { 4571041000: "경천면" },
          ],
        },
        {
          4572000000: "진안군",
          third: [
            { 4572025000: "진안읍" },
            { 4572031000: "용담면" },
            { 4572032000: "안천면" },
            { 4572033000: "동향면" },
            { 4572034000: "상전면" },
            { 4572035000: "백운면" },
            { 4572036000: "성수면" },
            { 4572037000: "마령면" },
            { 4572038000: "부귀면" },
            { 4572039000: "정천면" },
            { 4572040000: "주천면" },
          ],
        },
        {
          4573000000: "무주군",
          third: [
            { 4573025000: "무주읍" },
            { 4573031000: "무풍면" },
            { 4573032000: "설천면" },
            { 4573033000: "적상면" },
            { 4573034000: "안성면" },
            { 4573035000: "부남면" },
          ],
        },
        {
          4574000000: "장수군",
          third: [
            { 4574025000: "장수읍" },
            { 4574031000: "산서면" },
            { 4574032000: "번암면" },
            { 4574033500: "장계면" },
            { 4574034000: "천천면" },
            { 4574035000: "계남면" },
            { 4574036000: "계북면" },
          ],
        },
        {
          4575000000: "임실군",
          third: [
            { 4575025000: "임실읍" },
            { 4575031000: "청웅면" },
            { 4575032000: "운암면" },
            { 4575033000: "신평면" },
            { 4575034000: "성수면" },
            { 4575035500: "오수면" },
            { 4575036000: "신덕면" },
            { 4575037000: "삼계면" },
            { 4575038000: "관촌면" },
            { 4575039000: "강진면" },
            { 4575040000: "덕치면" },
            { 4575041000: "지사면" },
          ],
        },
        {
          4577000000: "순창군",
          third: [
            { 4577025000: "순창읍" },
            { 4577031000: "인계면" },
            { 4577032000: "동계면" },
            { 4577033000: "풍산면" },
            { 4577034000: "금과면" },
            { 4577035000: "팔덕면" },
            { 4577036000: "쌍치면" },
            { 4577037000: "복흥면" },
            { 4577038000: "적성면" },
            { 4577039000: "유등면" },
            { 4577040000: "구림면" },
          ],
        },
        {
          4579000000: "고창군",
          third: [
            { 4579025000: "고창읍" },
            { 4579031000: "고수면" },
            { 4579032000: "아산면" },
            { 4579033000: "무장면" },
            { 4579034000: "공음면" },
            { 4579035000: "상하면" },
            { 4579036000: "해리면" },
            { 4579037000: "성송면" },
            { 4579038000: "대산면" },
            { 4579039000: "심원면" },
            { 4579040000: "흥덕면" },
            { 4579041000: "성내면" },
            { 4579042000: "신림면" },
            { 4579043000: "부안면" },
          ],
        },
        {
          4580000000: "부안군",
          third: [
            { 4580025000: "부안읍" },
            { 4580031000: "주산면" },
            { 4580032000: "동진면" },
            { 4580033000: "행안면" },
            { 4580034000: "계화면" },
            { 4580035000: "보안면" },
            { 4580036000: "변산면" },
            { 4580037000: "진서면" },
            { 4580038000: "백산면" },
            { 4580039000: "상서면" },
            { 4580040000: "하서면" },
            { 4580041000: "줄포면" },
            { 4580042000: "위도면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4600000000: "전라남도",
      second: [
        {
          4611000000: "목포시",
          third: [
            { 4611010100: "용당동" },
            { 4611010200: "산정동" },
            { 4611010300: "연산동" },
            { 4611010400: "대성동" },
            { 4611010500: "양동" },
            { 4611010600: "북교동" },
            { 4611010700: "남교동" },
            { 4611010800: "호남동" },
            { 4611010900: "대안동" },
            { 4611011000: "창평동" },
            { 4611011100: "명륜동" },
            { 4611011200: "죽동" },
            { 4611011300: "무안동" },
            { 4611011400: "측후동" },
            { 4611011500: "상락동1가" },
            { 4611011600: "상락동2가" },
            { 4611011700: "복만동" },
            { 4611011800: "동명동" },
            { 4611011900: "광동1가" },
            { 4611012000: "광동2가" },
            { 4611012100: "광동3가" },
            { 4611012200: "영해동1가" },
            { 4611012300: "영해동2가" },
            { 4611012400: "행복동1가" },
            { 4611012500: "행복동2가" },
            { 4611012600: "축복동1가" },
            { 4611012700: "축복동2가" },
            { 4611012800: "축복동3가" },
            { 4611012900: "보광동1가" },
            { 4611013000: "보광동2가" },
            { 4611013100: "보광동3가" },
            { 4611013200: "유달동" },
            { 4611013300: "대의동1가" },
            { 4611013400: "대의동2가" },
            { 4611013500: "대의동3가" },
            { 4611013600: "중앙동1가" },
            { 4611013700: "중앙동2가" },
            { 4611013800: "중앙동3가" },
            { 4611013900: "만호동" },
            { 4611014000: "수강동1가" },
            { 4611014100: "수강동2가" },
            { 4611014200: "해안동1가" },
            { 4611014300: "해안동2가" },
            { 4611014400: "해안동3가" },
            { 4611014500: "해안동4가" },
            { 4611014600: "항동" },
            { 4611014700: "중동1가" },
            { 4611014800: "중동2가" },
            { 4611014900: "유동" },
            { 4611015000: "금동1가" },
            { 4611015100: "금동2가" },
            { 4611015200: "경동1가" },
            { 4611015300: "경동2가" },
            { 4611015400: "서산동" },
            { 4611015500: "금화동" },
            { 4611015600: "온금동" },
            { 4611015700: "죽교동" },
            { 4611015800: "상동" },
            { 4611015900: "용해동" },
            { 4611016000: "석현동" },
            { 4611016100: "달동" },
            { 4611016200: "율도동" },
            { 4611016300: "대양동" },
            { 4611016400: "옥암동" },
          ],
        },
        {
          4613000000: "여수시",
          third: [
            { 4613010100: "종화동" },
            { 4613010200: "수정동" },
            { 4613010300: "공화동" },
            { 4613010400: "관문동" },
            { 4613010500: "고소동" },
            { 4613010600: "동산동" },
            { 4613010700: "중앙동" },
            { 4613010800: "교동" },
            { 4613010900: "군자동" },
            { 4613011000: "충무동" },
            { 4613011100: "연등동" },
            { 4613011200: "광무동" },
            { 4613011300: "서교동" },
            { 4613011400: "봉강동" },
            { 4613011500: "봉산동" },
            { 4613011600: "남산동" },
            { 4613011700: "국동" },
            { 4613011800: "신월동" },
            { 4613011900: "여서동" },
            { 4613012000: "문수동" },
            { 4613012100: "오림동" },
            { 4613012200: "미평동" },
            { 4613012300: "둔덕동" },
            { 4613012400: "오천동" },
            { 4613012500: "만흥동" },
            { 4613012600: "덕충동" },
            { 4613012700: "경호동" },
            { 4613012800: "학동" },
            { 4613012900: "학용동" },
            { 4613013000: "안산동" },
            { 4613013100: "소호동" },
            { 4613013200: "시전동" },
            { 4613013300: "신기동" },
            { 4613013400: "웅천동" },
            { 4613013500: "선원동" },
            { 4613013600: "여천동" },
            { 4613013700: "화장동" },
            { 4613013800: "주삼동" },
            { 4613013900: "봉계동" },
            { 4613014000: "해산동" },
            { 4613014100: "화치동" },
            { 4613014200: "월하동" },
            { 4613014300: "평여동" },
            { 4613014400: "중흥동" },
            { 4613014500: "적량동" },
            { 4613014600: "월내동" },
            { 4613014700: "묘도동" },
            { 4613014800: "낙포동" },
            { 4613014900: "신덕동" },
            { 4613015000: "상암동" },
            { 4613015100: "호명동" },
            { 4613025000: "돌산읍" },
            { 4613031000: "소라면" },
            { 4613032000: "율촌면" },
            { 4613033000: "화양면" },
            { 4613034000: "남면" },
            { 4613035000: "화정면" },
            { 4613036000: "삼산면" },
          ],
        },
        {
          4615000000: "순천시",
          third: [
            { 4615010100: "삼거동" },
            { 4615010200: "와룡동" },
            { 4615010300: "영동" },
            { 4615010400: "옥천동" },
            { 4615010500: "행동" },
            { 4615010600: "금곡동" },
            { 4615010700: "매곡동" },
            { 4615010800: "석현동" },
            { 4615010900: "가곡동" },
            { 4615011000: "용당동" },
            { 4615011100: "조곡동" },
            { 4615011200: "생목동" },
            { 4615011300: "덕암동" },
            { 4615011400: "연향동" },
            { 4615011500: "풍덕동" },
            { 4615011600: "남정동" },
            { 4615011700: "인제동" },
            { 4615011800: "저전동" },
            { 4615011900: "장천동" },
            { 4615012000: "남내동" },
            { 4615012100: "중앙동" },
            { 4615012200: "동외동" },
            { 4615012300: "교량동" },
            { 4615012400: "대룡동" },
            { 4615012500: "홍내동" },
            { 4615012600: "오천동" },
            { 4615012700: "덕월동" },
            { 4615012800: "야흥동" },
            { 4615012900: "인월동" },
            { 4615013000: "안풍동" },
            { 4615013100: "대대동" },
            { 4615013200: "왕지동" },
            { 4615013300: "조례동" },
            { 4615025000: "승주읍" },
            { 4615031000: "해룡면" },
            { 4615032000: "서면" },
            { 4615033000: "황전면" },
            { 4615034000: "월등면" },
            { 4615035000: "주암면" },
            { 4615036000: "송광면" },
            { 4615037000: "외서면" },
            { 4615038000: "낙안면" },
            { 4615039000: "별량면" },
            { 4615040000: "상사면" },
          ],
        },
        {
          4617000000: "나주시",
          third: [
            { 4617010100: "토계동" },
            { 4617010200: "송월동" },
            { 4617010300: "안창동" },
            { 4617010400: "삼영동" },
            { 4617010500: "교동" },
            { 4617010600: "서내동" },
            { 4617010700: "산정동" },
            { 4617010800: "경현동" },
            { 4617010900: "보산동" },
            { 4617011000: "금계동" },
            { 4617011100: "금성동" },
            { 4617011200: "남내동" },
            { 4617011300: "과원동" },
            { 4617011400: "성북동" },
            { 4617011500: "중앙동" },
            { 4617011600: "대호동" },
            { 4617011700: "송촌동" },
            { 4617011800: "석현동" },
            { 4617011900: "청동" },
            { 4617012000: "남외동" },
            { 4617012100: "죽림동" },
            { 4617012200: "삼도동" },
            { 4617012300: "영산동" },
            { 4617012400: "용산동" },
            { 4617012500: "관정동" },
            { 4617012600: "평산동" },
            { 4617012700: "부덕동" },
            { 4617012800: "이창동" },
            { 4617012900: "대기동" },
            { 4617013000: "운곡동" },
            { 4617013100: "동수동" },
            { 4617013200: "오량동" },
            { 4617013300: "진포동" },
            { 4617013400: "빛가람동" },
            { 4617025000: "남평읍" },
            { 4617031000: "세지면" },
            { 4617032000: "왕곡면" },
            { 4617033000: "반남면" },
            { 4617034000: "공산면" },
            { 4617035000: "동강면" },
            { 4617036000: "다시면" },
            { 4617037000: "문평면" },
            { 4617038000: "노안면" },
            { 4617039000: "금천면" },
            { 4617040000: "산포면" },
            { 4617042000: "다도면" },
            { 4617043000: "봉황면" },
          ],
        },
        {
          4623000000: "광양시",
          third: [
            { 4623010100: "황금동" },
            { 4623010200: "황길동" },
            { 4623010300: "도이동" },
            { 4623010400: "성황동" },
            { 4623010500: "중군동" },
            { 4623010600: "중동" },
            { 4623010700: "마동" },
            { 4623010800: "광영동" },
            { 4623010900: "태인동" },
            { 4623011000: "금호동" },
            { 4623025000: "광양읍" },
            { 4623031000: "봉강면" },
            { 4623032000: "옥룡면" },
            { 4623033000: "옥곡면" },
            { 4623034000: "진상면" },
            { 4623035000: "진월면" },
            { 4623036000: "다압면" },
          ],
        },
        {
          4671000000: "담양군",
          third: [
            { 4671025000: "담양읍" },
            { 4671031000: "봉산면" },
            { 4671032000: "고서면" },
            { 4671033500: "가사문학면" },
            { 4671034000: "창평면" },
            { 4671035000: "대덕면" },
            { 4671036000: "무정면" },
            { 4671037000: "금성면" },
            { 4671038000: "용면" },
            { 4671039000: "월산면" },
            { 4671040000: "수북면" },
            { 4671041000: "대전면" },
          ],
        },
        {
          4672000000: "곡성군",
          third: [
            { 4672025000: "곡성읍" },
            { 4672031000: "오곡면" },
            { 4672032000: "삼기면" },
            { 4672033000: "석곡면" },
            { 4672034000: "목사동면" },
            { 4672035000: "죽곡면" },
            { 4672036000: "고달면" },
            { 4672037000: "옥과면" },
            { 4672038000: "입면" },
            { 4672039000: "겸면" },
            { 4672040000: "오산면" },
          ],
        },
        {
          4673000000: "구례군",
          third: [
            { 4673025000: "구례읍" },
            { 4673031000: "문척면" },
            { 4673032000: "간전면" },
            { 4673033000: "토지면" },
            { 4673034000: "마산면" },
            { 4673035000: "광의면" },
            { 4673036000: "용방면" },
            { 4673037000: "산동면" },
          ],
        },
        {
          4677000000: "고흥군",
          third: [
            { 4677025000: "고흥읍" },
            { 4677025300: "도양읍" },
            { 4677031000: "풍양면" },
            { 4677032000: "도덕면" },
            { 4677033000: "금산면" },
            { 4677034000: "도화면" },
            { 4677035000: "포두면" },
            { 4677036000: "봉래면" },
            { 4677037000: "점암면" },
            { 4677038000: "과역면" },
            { 4677039000: "남양면" },
            { 4677040000: "동강면" },
            { 4677041000: "대서면" },
            { 4677042000: "두원면" },
            { 4677044000: "영남면" },
            { 4677045000: "동일면" },
          ],
        },
        {
          4678000000: "보성군",
          third: [
            { 4678025000: "보성읍" },
            { 4678025300: "벌교읍" },
            { 4678031000: "노동면" },
            { 4678032000: "미력면" },
            { 4678033000: "겸백면" },
            { 4678034000: "율어면" },
            { 4678035000: "복내면" },
            { 4678036000: "문덕면" },
            { 4678037000: "조성면" },
            { 4678038000: "득량면" },
            { 4678039000: "회천면" },
            { 4678040000: "웅치면" },
          ],
        },
        {
          4679000000: "화순군",
          third: [
            { 4679025000: "화순읍" },
            { 4679031000: "한천면" },
            { 4679032000: "춘양면" },
            { 4679033000: "청풍면" },
            { 4679034000: "이양면" },
            { 4679035000: "능주면" },
            { 4679036000: "도곡면" },
            { 4679037000: "도암면" },
            { 4679038000: "이서면" },
            { 4679039500: "백아면" },
            { 4679040000: "동복면" },
            { 4679041500: "사평면" },
            { 4679042000: "동면" },
          ],
        },
        {
          4680000000: "장흥군",
          third: [
            { 4680025000: "장흥읍" },
            { 4680025300: "관산읍" },
            { 4680025600: "대덕읍" },
            { 4680031000: "용산면" },
            { 4680032000: "안양면" },
            { 4680033000: "장동면" },
            { 4680034000: "장평면" },
            { 4680035000: "유치면" },
            { 4680036000: "부산면" },
            { 4680037000: "회진면" },
          ],
        },
        {
          4681000000: "강진군",
          third: [
            { 4681025000: "강진읍" },
            { 4681031000: "군동면" },
            { 4681032000: "칠량면" },
            { 4681033000: "대구면" },
            { 4681034000: "도암면" },
            { 4681035000: "신전면" },
            { 4681036000: "성전면" },
            { 4681037000: "작천면" },
            { 4681038000: "병영면" },
            { 4681039000: "옴천면" },
            { 4681040000: "마량면" },
          ],
        },
        {
          4682000000: "해남군",
          third: [
            { 4682025000: "해남읍" },
            { 4682031000: "삼산면" },
            { 4682032000: "화산면" },
            { 4682033000: "현산면" },
            { 4682034000: "송지면" },
            { 4682035000: "북평면" },
            { 4682036000: "북일면" },
            { 4682037000: "옥천면" },
            { 4682038000: "계곡면" },
            { 4682039000: "마산면" },
            { 4682040000: "황산면" },
            { 4682041000: "산이면" },
            { 4682042000: "문내면" },
            { 4682043000: "화원면" },
          ],
        },
        {
          4683000000: "영암군",
          third: [
            { 4683025000: "영암읍" },
            { 4683025300: "삼호읍" },
            { 4683031000: "덕진면" },
            { 4683032000: "금정면" },
            { 4683033000: "신북면" },
            { 4683034000: "시종면" },
            { 4683035000: "도포면" },
            { 4683036000: "군서면" },
            { 4683037000: "서호면" },
            { 4683038000: "학산면" },
            { 4683039000: "미암면" },
          ],
        },
        {
          4684000000: "무안군",
          third: [
            { 4684025000: "무안읍" },
            { 4684025300: "일로읍" },
            { 4684025600: "삼향읍" },
            { 4684032000: "몽탄면" },
            { 4684033000: "청계면" },
            { 4684034000: "현경면" },
            { 4684035000: "망운면" },
            { 4684036000: "해제면" },
            { 4684037000: "운남면" },
          ],
        },
        {
          4686000000: "함평군",
          third: [
            { 4686025000: "함평읍" },
            { 4686031000: "손불면" },
            { 4686032000: "신광면" },
            { 4686033000: "학교면" },
            { 4686034000: "엄다면" },
            { 4686035000: "대동면" },
            { 4686036000: "나산면" },
            { 4686037000: "해보면" },
            { 4686038000: "월야면" },
          ],
        },
        {
          4687000000: "영광군",
          third: [
            { 4687025000: "영광읍" },
            { 4687025300: "백수읍" },
            { 4687025600: "홍농읍" },
            { 4687031000: "대마면" },
            { 4687032000: "묘량면" },
            { 4687033000: "불갑면" },
            { 4687034000: "군서면" },
            { 4687035000: "군남면" },
            { 4687036000: "염산면" },
            { 4687037000: "법성면" },
            { 4687038000: "낙월면" },
          ],
        },
        {
          4688000000: "장성군",
          third: [
            { 4688025000: "장성읍" },
            { 4688031000: "진원면" },
            { 4688032000: "남면" },
            { 4688033000: "동화면" },
            { 4688034000: "삼서면" },
            { 4688035000: "삼계면" },
            { 4688036000: "황룡면" },
            { 4688037000: "서삼면" },
            { 4688038000: "북일면" },
            { 4688039000: "북이면" },
            { 4688040000: "북하면" },
          ],
        },
        {
          4689000000: "완도군",
          third: [
            { 4689025000: "완도읍" },
            { 4689025300: "금일읍" },
            { 4689025600: "노화읍" },
            { 4689031000: "군외면" },
            { 4689032000: "신지면" },
            { 4689033000: "고금면" },
            { 4689034000: "약산면" },
            { 4689035000: "청산면" },
            { 4689036000: "소안면" },
            { 4689037000: "금당면" },
            { 4689038000: "보길면" },
            { 4689039000: "생일면" },
          ],
        },
        {
          4690000000: "진도군",
          third: [
            { 4690025000: "진도읍" },
            { 4690031000: "군내면" },
            { 4690032000: "고군면" },
            { 4690033000: "의신면" },
            { 4690034000: "임회면" },
            { 4690035000: "지산면" },
            { 4690036000: "조도면" },
          ],
        },
        {
          4691000000: "신안군",
          third: [
            { 4691025000: "지도읍" },
            { 4691025300: "압해읍" },
            { 4691031000: "증도면" },
            { 4691032000: "임자면" },
            { 4691033000: "자은면" },
            { 4691034000: "비금면" },
            { 4691035000: "도초면" },
            { 4691036000: "흑산면" },
            { 4691037000: "하의면" },
            { 4691038000: "신의면" },
            { 4691039000: "장산면" },
            { 4691040000: "안좌면" },
            { 4691041000: "팔금면" },
            { 4691042000: "암태면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4700000000: "경상북도",
      second: [
        {
          4711000000: "포항시",
          third: [
            { 4711100000: "남구" },
            { 4711110100: "남구 상도동" },
            { 4711110200: "남구 대도동" },
            { 4711110300: "남구 해도동" },
            { 4711110400: "남구 송도동" },
            { 4711110500: "남구 청림동" },
            { 4711110600: "남구 일월동" },
            { 4711110700: "남구 송정동" },
            { 4711110800: "남구 송내동" },
            { 4711110900: "남구 괴동동" },
            { 4711111000: "남구 동촌동" },
            { 4711111100: "남구 장흥동" },
            { 4711111200: "남구 인덕동" },
            { 4711111300: "남구 호동" },
            { 4711111400: "남구 효자동" },
            { 4711111500: "남구 지곡동" },
            { 4711111600: "남구 대잠동" },
            { 4711111700: "남구 이동" },
            { 4711125000: "남구 구룡포읍" },
            { 4711125300: "남구 연일읍" },
            { 4711125600: "남구 오천읍" },
            { 4711131000: "남구 대송면" },
            { 4711132000: "남구 동해면" },
            { 4711133000: "남구 장기면" },
            { 4711135000: "남구 호미곶면" },
            { 4711300000: "북구" },
            { 4711310100: "북구 대흥동" },
            { 4711310200: "북구 신흥동" },
            { 4711310300: "북구 남빈동" },
            { 4711310400: "북구 상원동" },
            { 4711310500: "북구 여천동" },
            { 4711310600: "북구 중앙동" },
            { 4711310700: "북구 덕산동" },
            { 4711310800: "북구 덕수동" },
            { 4711310900: "북구 대신동" },
            { 4711311000: "북구 동빈1가" },
            { 4711311100: "북구 동빈2가" },
            { 4711311200: "북구 학산동" },
            { 4711311300: "북구 항구동" },
            { 4711311400: "북구 득량동" },
            { 4711311500: "북구 학잠동" },
            { 4711311600: "북구 죽도동" },
            { 4711311700: "북구 용흥동" },
            { 4711311800: "북구 우현동" },
            { 4711311900: "북구 창포동" },
            { 4711312000: "북구 두호동" },
            { 4711312100: "북구 장성동" },
            { 4711312200: "북구 양덕동" },
            { 4711312300: "북구 환호동" },
            { 4711312400: "북구 여남동" },
            { 4711325000: "북구 흥해읍" },
            { 4711331000: "북구 신광면" },
            { 4711332000: "북구 청하면" },
            { 4711333000: "북구 송라면" },
            { 4711334000: "북구 기계면" },
            { 4711335000: "북구 죽장면" },
            { 4711336000: "북구 기북면" },
          ],
        },
        {
          4713000000: "경주시",
          third: [
            { 4713010100: "동부동" },
            { 4713010200: "서부동" },
            { 4713010300: "북부동" },
            { 4713010400: "성동동" },
            { 4713010500: "황오동" },
            { 4713010600: "노동동" },
            { 4713010700: "노서동" },
            { 4713010800: "성건동" },
            { 4713010900: "사정동" },
            { 4713011000: "황남동" },
            { 4713011100: "교동" },
            { 4713011200: "인왕동" },
            { 4713011300: "탑동" },
            { 4713011400: "충효동" },
            { 4713011500: "서악동" },
            { 4713011600: "효현동" },
            { 4713011700: "광명동" },
            { 4713011800: "동방동" },
            { 4713011900: "도지동" },
            { 4713012000: "남산동" },
            { 4713012100: "배반동" },
            { 4713012200: "구황동" },
            { 4713012300: "보문동" },
            { 4713012400: "황성동" },
            { 4713012500: "용강동" },
            { 4713012600: "동천동" },
            { 4713012700: "평동" },
            { 4713012800: "조양동" },
            { 4713012900: "시동" },
            { 4713013000: "시래동" },
            { 4713013100: "구정동" },
            { 4713013200: "마동" },
            { 4713013300: "하동" },
            { 4713013400: "진현동" },
            { 4713013500: "천군동" },
            { 4713013600: "신평동" },
            { 4713013700: "덕동" },
            { 4713013800: "암곡동" },
            { 4713013900: "황용동" },
            { 4713014000: "북군동" },
            { 4713014100: "손곡동" },
            { 4713014200: "율동" },
            { 4713014300: "배동" },
            { 4713014400: "석장동" },
            { 4713025000: "감포읍" },
            { 4713025300: "안강읍" },
            { 4713025600: "건천읍" },
            { 4713025900: "외동읍" },
            { 4713031500: "문무대왕면" },
            { 4713032000: "양남면" },
            { 4713033000: "내남면" },
            { 4713034000: "산내면" },
            { 4713035000: "서면" },
            { 4713036000: "현곡면" },
            { 4713037000: "강동면" },
            { 4713038000: "천북면" },
          ],
        },
        {
          4715000000: "김천시",
          third: [
            { 4715010100: "감호동" },
            { 4715010200: "용두동" },
            { 4715010300: "모암동" },
            { 4715010400: "성내동" },
            { 4715010500: "평화동" },
            { 4715010600: "남산동" },
            { 4715010700: "황금동" },
            { 4715010800: "신음동" },
            { 4715010900: "교동" },
            { 4715011000: "삼락동" },
            { 4715011100: "문당동" },
            { 4715011200: "다수동" },
            { 4715011300: "백옥동" },
            { 4715011400: "부곡동" },
            { 4715011500: "지좌동" },
            { 4715011600: "덕곡동" },
            { 4715011700: "대광동" },
            { 4715011800: "응명동" },
            { 4715011900: "양천동" },
            { 4715012000: "율곡동" },
            { 4715025000: "아포읍" },
            { 4715031000: "농소면" },
            { 4715032000: "남면" },
            { 4715034000: "개령면" },
            { 4715035000: "감문면" },
            { 4715036000: "어모면" },
            { 4715037000: "봉산면" },
            { 4715038000: "대항면" },
            { 4715039000: "감천면" },
            { 4715040000: "조마면" },
            { 4715041000: "구성면" },
            { 4715042000: "지례면" },
            { 4715043000: "부항면" },
            { 4715044000: "대덕면" },
            { 4715045000: "증산면" },
          ],
        },
        {
          4717000000: "안동시",
          third: [
            { 4717010100: "삼산동" },
            { 4717010200: "서부동" },
            { 4717010300: "북문동" },
            { 4717010400: "명륜동" },
            { 4717010500: "신안동" },
            { 4717010600: "율세동" },
            { 4717010700: "옥정동" },
            { 4717010800: "신세동" },
            { 4717010900: "법흥동" },
            { 4717011000: "용상동" },
            { 4717011100: "동문동" },
            { 4717011200: "동부동" },
            { 4717011300: "운흥동" },
            { 4717011400: "천리동" },
            { 4717011500: "남부동" },
            { 4717011600: "남문동" },
            { 4717011700: "안흥동" },
            { 4717011800: "대석동" },
            { 4717011900: "옥야동" },
            { 4717012000: "광석동" },
            { 4717012100: "당북동" },
            { 4717012200: "태화동" },
            { 4717012300: "화성동" },
            { 4717012400: "목성동" },
            { 4717012500: "법상동" },
            { 4717012600: "금곡동" },
            { 4717012700: "평화동" },
            { 4717012800: "안기동" },
            { 4717012900: "운안동" },
            { 4717013000: "성곡동" },
            { 4717013100: "상아동" },
            { 4717013200: "안막동" },
            { 4717013300: "옥동" },
            { 4717013400: "이천동" },
            { 4717013500: "노하동" },
            { 4717013600: "송현동" },
            { 4717013700: "송천동" },
            { 4717013800: "석동동" },
            { 4717013900: "정상동" },
            { 4717014000: "정하동" },
            { 4717014100: "수상동" },
            { 4717014200: "수하동" },
            { 4717025000: "풍산읍" },
            { 4717031000: "와룡면" },
            { 4717032000: "북후면" },
            { 4717033000: "서후면" },
            { 4717034000: "풍천면" },
            { 4717035000: "일직면" },
            { 4717036000: "남후면" },
            { 4717037000: "남선면" },
            { 4717038000: "임하면" },
            { 4717039000: "길안면" },
            { 4717040000: "임동면" },
            { 4717041000: "예안면" },
            { 4717042000: "도산면" },
            { 4717043000: "녹전면" },
          ],
        },
        {
          4719000000: "구미시",
          third: [
            { 4719010100: "원평동" },
            { 4719010200: "지산동" },
            { 4719010300: "도량동" },
            { 4719010400: "봉곡동" },
            { 4719010500: "부곡동" },
            { 4719010600: "선기동" },
            { 4719010700: "수점동" },
            { 4719010800: "남통동" },
            { 4719010900: "형곡동" },
            { 4719011000: "송정동" },
            { 4719011100: "신평동" },
            { 4719011200: "비산동" },
            { 4719011300: "공단동" },
            { 4719011400: "광평동" },
            { 4719011500: "사곡동" },
            { 4719011600: "상모동" },
            { 4719011700: "임은동" },
            { 4719011800: "오태동" },
            { 4719011900: "신동" },
            { 4719012000: "구평동" },
            { 4719012100: "황상동" },
            { 4719012200: "인의동" },
            { 4719012300: "진평동" },
            { 4719012400: "시미동" },
            { 4719012500: "임수동" },
            { 4719012600: "양호동" },
            { 4719012700: "거의동" },
            { 4719012800: "옥계동" },
            { 4719012900: "구포동" },
            { 4719013000: "금전동" },
            { 4719025000: "선산읍" },
            { 4719025300: "고아읍" },
            { 4719025600: "산동읍" },
            { 4719031000: "무을면" },
            { 4719032000: "옥성면" },
            { 4719033000: "도개면" },
            { 4719034000: "해평면" },
            { 4719036000: "장천면" },
          ],
        },
        {
          4721000000: "영주시",
          third: [
            { 4721010100: "영주동" },
            { 4721010200: "상망동" },
            { 4721010300: "하망동" },
            { 4721010400: "휴천동" },
            { 4721010500: "가흥동" },
            { 4721010600: "문정동" },
            { 4721010700: "고현동" },
            { 4721010800: "창진동" },
            { 4721010900: "상줄동" },
            { 4721011000: "조와동" },
            { 4721011100: "조암동" },
            { 4721011200: "적서동" },
            { 4721011300: "아지동" },
            { 4721025000: "풍기읍" },
            { 4721031000: "이산면" },
            { 4721032000: "평은면" },
            { 4721033000: "문수면" },
            { 4721034000: "장수면" },
            { 4721035000: "안정면" },
            { 4721036000: "봉현면" },
            { 4721037000: "순흥면" },
            { 4721038000: "단산면" },
            { 4721039000: "부석면" },
          ],
        },
        {
          4723000000: "영천시",
          third: [
            { 4723010100: "조교동" },
            { 4723010200: "망정동" },
            { 4723010300: "야사동" },
            { 4723010400: "문내동" },
            { 4723010500: "문외동" },
            { 4723010600: "창구동" },
            { 4723010700: "교촌동" },
            { 4723010800: "과전동" },
            { 4723010900: "성내동" },
            { 4723011000: "화룡동" },
            { 4723011100: "도동" },
            { 4723011200: "금노동" },
            { 4723011300: "완산동" },
            { 4723011400: "범어동" },
            { 4723011500: "작산동" },
            { 4723011600: "봉동" },
            { 4723011700: "본촌동" },
            { 4723011800: "채신동" },
            { 4723011900: "괴연동" },
            { 4723012000: "대전동" },
            { 4723012100: "녹전동" },
            { 4723012200: "도림동" },
            { 4723012300: "오미동" },
            { 4723012400: "오수동" },
            { 4723012500: "쌍계동" },
            { 4723012600: "도남동" },
            { 4723012700: "매산동" },
            { 4723012800: "언하동" },
            { 4723012900: "신기동" },
            { 4723013000: "서산동" },
            { 4723025000: "금호읍" },
            { 4723031000: "청통면" },
            { 4723032000: "신녕면" },
            { 4723033000: "화산면" },
            { 4723034000: "화북면" },
            { 4723035000: "화남면" },
            { 4723036000: "자양면" },
            { 4723037000: "임고면" },
            { 4723038000: "고경면" },
            { 4723039000: "북안면" },
            { 4723040000: "대창면" },
          ],
        },
        {
          4725000000: "상주시",
          third: [
            { 4725010100: "성하동" },
            { 4725010200: "성동동" },
            { 4725010300: "인봉동" },
            { 4725010400: "복룡동" },
            { 4725010500: "냉림동" },
            { 4725010600: "서성동" },
            { 4725010700: "남성동" },
            { 4725010800: "서문동" },
            { 4725010900: "무양동" },
            { 4725011000: "낙양동" },
            { 4725011100: "개운동" },
            { 4725011200: "신봉동" },
            { 4725011300: "가장동" },
            { 4725011400: "양촌동" },
            { 4725011500: "지천동" },
            { 4725011600: "오대동" },
            { 4725011700: "흥각동" },
            { 4725011800: "거동동" },
            { 4725011900: "인평동" },
            { 4725012000: "서곡동" },
            { 4725012100: "화개동" },
            { 4725012200: "외답동" },
            { 4725012300: "헌신동" },
            { 4725012400: "병성동" },
            { 4725012500: "도남동" },
            { 4725012600: "낙상동" },
            { 4725012700: "중덕동" },
            { 4725012800: "초산동" },
            { 4725012900: "화산동" },
            { 4725013000: "계산동" },
            { 4725013100: "부원동" },
            { 4725013200: "죽전동" },
            { 4725013300: "만산동" },
            { 4725013400: "연원동" },
            { 4725013500: "남장동" },
            { 4725013600: "남적동" },
            { 4725025000: "함창읍" },
            { 4725031000: "중동면" },
            { 4725032500: "사벌국면" },
            { 4725033000: "낙동면" },
            { 4725034000: "청리면" },
            { 4725035000: "공성면" },
            { 4725036000: "외남면" },
            { 4725037000: "내서면" },
            { 4725038000: "모동면" },
            { 4725039000: "모서면" },
            { 4725040000: "화동면" },
            { 4725041000: "화서면" },
            { 4725042000: "화북면" },
            { 4725043000: "외서면" },
            { 4725044000: "은척면" },
            { 4725045000: "공검면" },
            { 4725046000: "이안면" },
            { 4725047000: "화남면" },
          ],
        },
        {
          4728000000: "문경시",
          third: [
            { 4728010100: "점촌동" },
            { 4728010200: "영신동" },
            { 4728010300: "흥덕동" },
            { 4728010400: "우지동" },
            { 4728010500: "창동" },
            { 4728010600: "신기동" },
            { 4728010700: "불정동" },
            { 4728010800: "유곡동" },
            { 4728010900: "공평동" },
            { 4728011000: "모전동" },
            { 4728011100: "윤직동" },
            { 4728025000: "문경읍" },
            { 4728025300: "가은읍" },
            { 4728031000: "영순면" },
            { 4728032000: "산양면" },
            { 4728033000: "호계면" },
            { 4728034000: "산북면" },
            { 4728035000: "동로면" },
            { 4728036000: "마성면" },
            { 4728037000: "농암면" },
          ],
        },
        {
          4729000000: "경산시",
          third: [
            { 4729010100: "삼남동" },
            { 4729010200: "삼북동" },
            { 4729010300: "서상동" },
            { 4729010400: "신교동" },
            { 4729010500: "상방동" },
            { 4729010600: "백천동" },
            { 4729010700: "옥곡동" },
            { 4729010800: "사정동" },
            { 4729010900: "옥산동" },
            { 4729011000: "중산동" },
            { 4729011100: "정평동" },
            { 4729011200: "대평동" },
            { 4729011300: "대정동" },
            { 4729011400: "임당동" },
            { 4729011500: "대동" },
            { 4729011600: "계양동" },
            { 4729011700: "중방동" },
            { 4729011800: "조영동" },
            { 4729011900: "남방동" },
            { 4729012000: "내동" },
            { 4729012100: "여천동" },
            { 4729012200: "유곡동" },
            { 4729012300: "신천동" },
            { 4729012400: "점촌동" },
            { 4729012500: "평산동" },
            { 4729012600: "사동" },
            { 4729012700: "삼풍동" },
            { 4729012800: "갑제동" },
            { 4729025000: "하양읍" },
            { 4729025300: "진량읍" },
            { 4729025600: "압량읍" },
            { 4729031000: "와촌면" },
            { 4729033000: "자인면" },
            { 4729034000: "용성면" },
            { 4729035000: "남산면" },
            { 4729037000: "남천면" },
          ],
        },
        {
          4772000000: "군위군",
          third: [
            { 4772025000: "군위읍" },
            { 4772031000: "소보면" },
            { 4772032000: "효령면" },
            { 4772033000: "부계면" },
            { 4772034000: "우보면" },
            { 4772035000: "의흥면" },
            { 4772036000: "산성면" },
            { 4772038000: "삼국유사면" },
          ],
        },
        {
          4773000000: "의성군",
          third: [
            { 4773025000: "의성읍" },
            { 4773031000: "단촌면" },
            { 4773032000: "점곡면" },
            { 4773033000: "옥산면" },
            { 4773034000: "사곡면" },
            { 4773035000: "춘산면" },
            { 4773036000: "가음면" },
            { 4773037000: "금성면" },
            { 4773038000: "봉양면" },
            { 4773039000: "비안면" },
            { 4773040000: "구천면" },
            { 4773041000: "단밀면" },
            { 4773042000: "단북면" },
            { 4773043000: "안계면" },
            { 4773044000: "다인면" },
            { 4773045000: "신평면" },
            { 4773046000: "안평면" },
            { 4773047000: "안사면" },
          ],
        },
        {
          4775000000: "청송군",
          third: [
            { 4775025000: "청송읍" },
            { 4775031500: "주왕산면" },
            { 4775032000: "부남면" },
            { 4775033000: "현동면" },
            { 4775034000: "현서면" },
            { 4775035000: "안덕면" },
            { 4775036000: "파천면" },
            { 4775037000: "진보면" },
          ],
        },
        {
          4776000000: "영양군",
          third: [
            { 4776025000: "영양읍" },
            { 4776031000: "입암면" },
            { 4776032000: "청기면" },
            { 4776033000: "일월면" },
            { 4776034000: "수비면" },
            { 4776035000: "석보면" },
          ],
        },
        {
          4777000000: "영덕군",
          third: [
            { 4777025000: "영덕읍" },
            { 4777031000: "강구면" },
            { 4777032000: "남정면" },
            { 4777033000: "달산면" },
            { 4777034000: "지품면" },
            { 4777035000: "축산면" },
            { 4777036000: "영해면" },
            { 4777037000: "병곡면" },
            { 4777038000: "창수면" },
          ],
        },
        {
          4782000000: "청도군",
          third: [
            { 4782025000: "화양읍" },
            { 4782025300: "청도읍" },
            { 4782031000: "각남면" },
            { 4782032000: "풍각면" },
            { 4782033000: "각북면" },
            { 4782034000: "이서면" },
            { 4782035000: "운문면" },
            { 4782036000: "금천면" },
            { 4782037000: "매전면" },
          ],
        },
        {
          4783000000: "고령군",
          third: [
            { 4783025300: "대가야읍" },
            { 4783031000: "덕곡면" },
            { 4783032000: "운수면" },
            { 4783033000: "성산면" },
            { 4783034000: "다산면" },
            { 4783035000: "개진면" },
            { 4783036000: "우곡면" },
            { 4783037000: "쌍림면" },
          ],
        },
        {
          4784000000: "성주군",
          third: [
            { 4784025000: "성주읍" },
            { 4784031000: "선남면" },
            { 4784032000: "용암면" },
            { 4784033000: "수륜면" },
            { 4784034000: "가천면" },
            { 4784035000: "금수면" },
            { 4784036000: "대가면" },
            { 4784037000: "벽진면" },
            { 4784038000: "초전면" },
            { 4784039000: "월항면" },
          ],
        },
        {
          4785000000: "칠곡군",
          third: [
            { 4785025000: "왜관읍" },
            { 4785025300: "북삼읍" },
            { 4785025600: "석적읍" },
            { 4785031000: "지천면" },
            { 4785032000: "동명면" },
            { 4785033000: "가산면" },
            { 4785036000: "약목면" },
            { 4785037000: "기산면" },
          ],
        },
        {
          4790000000: "예천군",
          third: [
            { 4790025000: "예천읍" },
            { 4790031000: "용문면" },
            { 4790034000: "감천면" },
            { 4790035000: "보문면" },
            { 4790036000: "호명면" },
            { 4790037000: "유천면" },
            { 4790038000: "용궁면" },
            { 4790039000: "개포면" },
            { 4790040000: "지보면" },
            { 4790041000: "풍양면" },
            { 4790042000: "효자면" },
            { 4790043000: "은풍면" },
          ],
        },
        {
          4792000000: "봉화군",
          third: [
            { 4792025000: "봉화읍" },
            { 4792031000: "물야면" },
            { 4792032000: "봉성면" },
            { 4792033000: "법전면" },
            { 4792034000: "춘양면" },
            { 4792035000: "소천면" },
            { 4792036000: "재산면" },
            { 4792037000: "명호면" },
            { 4792038000: "상운면" },
            { 4792039000: "석포면" },
          ],
        },
        {
          4793000000: "울진군",
          third: [
            { 4793025000: "울진읍" },
            { 4793025300: "평해읍" },
            { 4793031000: "북면" },
            { 4793033000: "근남면" },
            { 4793035000: "기성면" },
            { 4793036000: "온정면" },
            { 4793037000: "죽변면" },
            { 4793038000: "후포면" },
            { 4793039000: "금강송면" },
            { 4793040000: "매화면" },
          ],
        },
        {
          4794000000: "울릉군",
          third: [
            { 4794025000: "울릉읍" },
            { 4794031000: "서면" },
            { 4794032000: "북면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      4800000000: "경상남도",
      second: [
        {
          4812000000: "창원시",
          third: [
            { 4812100000: "의창구" },
            { 4812110100: "의창구 북동" },
            { 4812110200: "의창구 중동" },
            { 4812110300: "의창구 서상동" },
            { 4812110400: "의창구 소답동" },
            { 4812110500: "의창구 도계동" },
            { 4812110600: "의창구 동정동" },
            { 4812110700: "의창구 소계동" },
            { 4812110800: "의창구 용동" },
            { 4812111200: "의창구 덕정동" },
            { 4812111300: "의창구 지귀동" },
            { 4812111400: "의창구 서곡동" },
            { 4812111600: "의창구 봉림동" },
            { 4812111700: "의창구 퇴촌동" },
            { 4812111800: "의창구 명곡동" },
            { 4812111900: "의창구 반계동" },
            { 4812112000: "의창구 사화동" },
            { 4812112100: "의창구 차용동" },
            { 4812112200: "의창구 내리동" },
            { 4812112400: "의창구 명서동" },
            { 4812112500: "의창구 사림동" },
            { 4812112700: "의창구 봉곡동" },
            { 4812112900: "의창구 팔용동" },
            { 4812125000: "의창구 동읍" },
            { 4812131000: "의창구 북면" },
            { 4812132000: "의창구 대산면" },
            { 4812300000: "성산구" },
            { 4812310100: "성산구 토월동" },
            { 4812310200: "성산구 사파정동" },
            { 4812310300: "성산구 가음정동" },
            { 4812310400: "성산구 외동" },
            { 4812310500: "성산구 대방동" },
            { 4812310600: "성산구 남산동" },
            { 4812310700: "성산구 삼정자동" },
            { 4812310800: "성산구 천선동" },
            { 4812310900: "성산구 불모산동" },
            { 4812311000: "성산구 안민동" },
            { 4812311100: "성산구 내동" },
            { 4812311200: "성산구 남지동" },
            { 4812311300: "성산구 상복동" },
            { 4812311400: "성산구 완암동" },
            { 4812311500: "성산구 창곡동" },
            { 4812311600: "성산구 월림동" },
            { 4812311700: "성산구 적현동" },
            { 4812311800: "성산구 양곡동" },
            { 4812311900: "성산구 반송동" },
            { 4812312000: "성산구 귀산동" },
            { 4812312100: "성산구 귀곡동" },
            { 4812312200: "성산구 귀현동" },
            { 4812312300: "성산구 신촌동" },
            { 4812312400: "성산구 반지동" },
            { 4812312500: "성산구 중앙동" },
            { 4812312600: "성산구 반림동" },
            { 4812312700: "성산구 상남동" },
            { 4812312800: "성산구 성주동" },
            { 4812312900: "성산구 웅남동" },
            { 4812313000: "성산구 사파동" },
            { 4812313100: "성산구 가음동" },
            { 4812313200: "성산구 성산동" },
            { 4812313300: "성산구 남양동" },
            { 4812313400: "성산구 용지동" },
            { 4812313500: "성산구 용호동" },
            { 4812313600: "성산구 신월동" },
            { 4812313700: "성산구 대원동" },
            { 4812313800: "성산구 두대동" },
            { 4812313900: "성산구 삼동동" },
            { 4812314000: "성산구 덕정동" },
            { 4812314100: "성산구 퇴촌동" },
            { 4812500000: "마산합포구" },
            { 4812510100: "마산합포구 가포동" },
            { 4812510200: "마산합포구 교방동" },
            { 4812510300: "마산합포구 교원동" },
            { 4812510400: "마산합포구 남성동" },
            { 4812510500: "마산합포구 대내동" },
            { 4812510600: "마산합포구 대성동1가" },
            { 4812510700: "마산합포구 대성동2가" },
            { 4812510800: "마산합포구 대외동" },
            { 4812510900: "마산합포구 대창동" },
            { 4812511000: "마산합포구 덕동동" },
            { 4812511100: "마산합포구 동성동" },
            { 4812511200: "마산합포구 두월동1가" },
            { 4812511300: "마산합포구 두월동2가" },
            { 4812511400: "마산합포구 두월동3가" },
            { 4812511500: "마산합포구 문화동" },
            { 4812511600: "마산합포구 반월동" },
            { 4812511700: "마산합포구 부림동" },
            { 4812511800: "마산합포구 산호동" },
            { 4812511900: "마산합포구 상남동" },
            { 4812512000: "마산합포구 서성동" },
            { 4812512100: "마산합포구 성호동" },
            { 4812512200: "마산합포구 수성동" },
            { 4812512300: "마산합포구 신월동" },
            { 4812512400: "마산합포구 신창동" },
            { 4812512500: "마산합포구 신포동1가" },
            { 4812512600: "마산합포구 신포동2가" },
            { 4812512700: "마산합포구 신흥동" },
            { 4812512800: "마산합포구 완월동" },
            { 4812512900: "마산합포구 월남동1가" },
            { 4812513000: "마산합포구 월남동2가" },
            { 4812513100: "마산합포구 월남동3가" },
            { 4812513200: "마산합포구 월남동4가" },
            { 4812513300: "마산합포구 월남동5가" },
            { 4812513400: "마산합포구 월영동" },
            { 4812513500: "마산합포구 월포동" },
            { 4812513600: "마산합포구 예곡동" },
            { 4812513700: "마산합포구 오동동" },
            { 4812513800: "마산합포구 우산동" },
            { 4812513900: "마산합포구 유록동" },
            { 4812514000: "마산합포구 자산동" },
            { 4812514100: "마산합포구 장군동1가" },
            { 4812514200: "마산합포구 장군동2가" },
            { 4812514300: "마산합포구 장군동3가" },
            { 4812514400: "마산합포구 장군동4가" },
            { 4812514500: "마산합포구 장군동5가" },
            { 4812514600: "마산합포구 중성동" },
            { 4812514700: "마산합포구 중앙동1가" },
            { 4812514800: "마산합포구 중앙동2가" },
            { 4812514900: "마산합포구 중앙동3가" },
            { 4812515000: "마산합포구 창동" },
            { 4812515100: "마산합포구 창포동1가" },
            { 4812515200: "마산합포구 창포동2가" },
            { 4812515300: "마산합포구 창포동3가" },
            { 4812515400: "마산합포구 청계동" },
            { 4812515500: "마산합포구 추산동" },
            { 4812515600: "마산합포구 평화동" },
            { 4812515700: "마산합포구 화영동" },
            { 4812515800: "마산합포구 해운동" },
            { 4812515900: "마산합포구 현동" },
            { 4812516000: "마산합포구 홍문동" },
            { 4812531000: "마산합포구 구산면" },
            { 4812532000: "마산합포구 진동면" },
            { 4812533000: "마산합포구 진북면" },
            { 4812534000: "마산합포구 진전면" },
            { 4812700000: "마산회원구" },
            { 4812710100: "마산회원구 구암동" },
            { 4812710200: "마산회원구 두척동" },
            { 4812710300: "마산회원구 봉암동" },
            { 4812710400: "마산회원구 석전동" },
            { 4812710500: "마산회원구 양덕동" },
            { 4812710600: "마산회원구 합성동" },
            { 4812710700: "마산회원구 회성동" },
            { 4812710800: "마산회원구 회원동" },
            { 4812725000: "마산회원구 내서읍" },
            { 4812900000: "진해구" },
            { 4812910100: "진해구 동상동" },
            { 4812910200: "진해구 도천동" },
            { 4812910300: "진해구 도만동" },
            { 4812910400: "진해구 신흥동" },
            { 4812910500: "진해구 현동" },
            { 4812910600: "진해구 비봉동" },
            { 4812910700: "진해구 태평동" },
            { 4812910800: "진해구 충의동" },
            { 4812910900: "진해구 무송동" },
            { 4812911000: "진해구 인의동" },
            { 4812911100: "진해구 숭인동" },
            { 4812911200: "진해구 대영동" },
            { 4812911300: "진해구 남빈동" },
            { 4812911400: "진해구 앵곡동" },
            { 4812911500: "진해구 제황산동" },
            { 4812911600: "진해구 속천동" },
            { 4812911700: "진해구 대죽동" },
            { 4812911800: "진해구 안곡동" },
            { 4812911900: "진해구 수송동" },
            { 4812912000: "진해구 회현동" },
            { 4812912100: "진해구 익선동" },
            { 4812912200: "진해구 창선동" },
            { 4812912300: "진해구 대천동" },
            { 4812912400: "진해구 광화동" },
            { 4812912500: "진해구 통신동" },
            { 4812912600: "진해구 중앙동" },
            { 4812912700: "진해구 부흥동" },
            { 4812912800: "진해구 중평동" },
            { 4812912900: "진해구 근화동" },
            { 4812913000: "진해구 송죽동" },
            { 4812913100: "진해구 화천동" },
            { 4812913200: "진해구 송학동" },
            { 4812913300: "진해구 대흥동" },
            { 4812913400: "진해구 평안동" },
            { 4812913500: "진해구 충무동" },
            { 4812913600: "진해구 인사동" },
            { 4812913700: "진해구 여좌동" },
            { 4812913800: "진해구 태백동" },
            { 4812913900: "진해구 경화동" },
            { 4812914000: "진해구 석동" },
            { 4812914100: "진해구 이동" },
            { 4812914200: "진해구 자은동" },
            { 4812914300: "진해구 덕산동" },
            { 4812914400: "진해구 풍호동" },
            { 4812914500: "진해구 장천동" },
            { 4812914600: "진해구 행암동" },
            { 4812914700: "진해구 북부동" },
            { 4812914800: "진해구 성내동" },
            { 4812914900: "진해구 서중동" },
            { 4812915000: "진해구 남문동" },
            { 4812915100: "진해구 제덕동" },
            { 4812915200: "진해구 수도동" },
            { 4812915300: "진해구 연도동" },
            { 4812915400: "진해구 명동" },
            { 4812915500: "진해구 죽곡동" },
            { 4812915600: "진해구 원포동" },
            { 4812915700: "진해구 남양동" },
            { 4812915800: "진해구 마천동" },
            { 4812915900: "진해구 소사동" },
            { 4812916000: "진해구 대장동" },
            { 4812916100: "진해구 두동" },
            { 4812916200: "진해구 청안동" },
            { 4812916300: "진해구 안골동" },
            { 4812916400: "진해구 용원동" },
            { 4812916500: "진해구 가주동" },
          ],
        },
        {
          4817000000: "진주시",
          third: [
            { 4817010100: "망경동" },
            { 4817010200: "주약동" },
            { 4817010300: "강남동" },
            { 4817010400: "칠암동" },
            { 4817010500: "본성동" },
            { 4817010600: "동성동" },
            { 4817010700: "남성동" },
            { 4817010800: "인사동" },
            { 4817010900: "대안동" },
            { 4817011000: "평안동" },
            { 4817011100: "중안동" },
            { 4817011200: "계동" },
            { 4817011300: "봉곡동" },
            { 4817011400: "상봉동" },
            { 4817011500: "봉래동" },
            { 4817011600: "수정동" },
            { 4817011700: "장대동" },
            { 4817011800: "옥봉동" },
            { 4817011900: "상대동" },
            { 4817012000: "하대동" },
            { 4817012100: "상평동" },
            { 4817012200: "초전동" },
            { 4817012300: "장재동" },
            { 4817012400: "하촌동" },
            { 4817012500: "신안동" },
            { 4817012600: "평거동" },
            { 4817012700: "이현동" },
            { 4817012800: "유곡동" },
            { 4817012900: "판문동" },
            { 4817013000: "귀곡동" },
            { 4817013100: "가좌동" },
            { 4817013200: "호탄동" },
            { 4817013700: "충무공동" },
            { 4817025000: "문산읍" },
            { 4817031000: "내동면" },
            { 4817032000: "정촌면" },
            { 4817033000: "금곡면" },
            { 4817035000: "진성면" },
            { 4817036000: "일반성면" },
            { 4817037000: "이반성면" },
            { 4817038000: "사봉면" },
            { 4817039000: "지수면" },
            { 4817040000: "대곡면" },
            { 4817041000: "금산면" },
            { 4817042000: "집현면" },
            { 4817043000: "미천면" },
            { 4817044000: "명석면" },
            { 4817045000: "대평면" },
            { 4817046000: "수곡면" },
          ],
        },
        {
          4822000000: "통영시",
          third: [
            { 4822010100: "도천동" },
            { 4822010200: "서호동" },
            { 4822010300: "명정동" },
            { 4822010400: "항남동" },
            { 4822010500: "중앙동" },
            { 4822010600: "문화동" },
            { 4822010700: "태평동" },
            { 4822010800: "동호동" },
            { 4822010900: "정량동" },
            { 4822011000: "북신동" },
            { 4822011100: "무전동" },
            { 4822011200: "평림동" },
            { 4822011300: "인평동" },
            { 4822011400: "당동" },
            { 4822011500: "미수동" },
            { 4822011600: "봉평동" },
            { 4822011700: "도남동" },
            { 4822025000: "산양읍" },
            { 4822031000: "용남면" },
            { 4822033000: "도산면" },
            { 4822034000: "광도면" },
            { 4822035000: "욕지면" },
            { 4822036000: "한산면" },
            { 4822037000: "사량면" },
          ],
        },
        {
          4824000000: "사천시",
          third: [
            { 4824010100: "동동" },
            { 4824010200: "서동" },
            { 4824010300: "선구동" },
            { 4824010400: "동금동" },
            { 4824010500: "서금동" },
            { 4824010600: "동림동" },
            { 4824010700: "좌룡동" },
            { 4824010800: "벌리동" },
            { 4824010900: "용강동" },
            { 4824011000: "와룡동" },
            { 4824011100: "봉남동" },
            { 4824011200: "이금동" },
            { 4824011300: "이홀동" },
            { 4824011400: "궁지동" },
            { 4824011500: "사등동" },
            { 4824011600: "향촌동" },
            { 4824011700: "대방동" },
            { 4824011800: "실안동" },
            { 4824011900: "마도동" },
            { 4824012000: "늑도동" },
            { 4824012100: "신수동" },
            { 4824012200: "백천동" },
            { 4824012300: "신벽동" },
            { 4824012400: "노룡동" },
            { 4824012500: "대포동" },
            { 4824012600: "송포동" },
            { 4824012700: "죽림동" },
            { 4824025000: "사천읍" },
            { 4824031000: "정동면" },
            { 4824032000: "사남면" },
            { 4824033000: "용현면" },
            { 4824034000: "축동면" },
            { 4824035000: "곤양면" },
            { 4824036000: "곤명면" },
            { 4824037000: "서포면" },
          ],
        },
        {
          4825000000: "김해시",
          third: [
            { 4825010100: "동상동" },
            { 4825010200: "서상동" },
            { 4825010300: "부원동" },
            { 4825010400: "봉황동" },
            { 4825010500: "대성동" },
            { 4825010600: "구산동" },
            { 4825010700: "삼계동" },
            { 4825010800: "내동" },
            { 4825010900: "외동" },
            { 4825011000: "흥동" },
            { 4825011100: "풍유동" },
            { 4825011200: "명법동" },
            { 4825011300: "이동" },
            { 4825011400: "화목동" },
            { 4825011500: "전하동" },
            { 4825011600: "강동" },
            { 4825011700: "삼정동" },
            { 4825011800: "어방동" },
            { 4825011900: "삼방동" },
            { 4825012000: "안동" },
            { 4825012100: "지내동" },
            { 4825012200: "불암동" },
            { 4825012300: "유하동" },
            { 4825012400: "내덕동" },
            { 4825012500: "부곡동" },
            { 4825012600: "무계동" },
            { 4825012700: "신문동" },
            { 4825012800: "삼문동" },
            { 4825012900: "대청동" },
            { 4825013000: "관동동" },
            { 4825013100: "율하동" },
            { 4825013200: "장유동" },
            { 4825013300: "응달동" },
            { 4825013400: "수가동" },
            { 4825025000: "진영읍" },
            { 4825032000: "주촌면" },
            { 4825033000: "진례면" },
            { 4825034000: "한림면" },
            { 4825035000: "생림면" },
            { 4825036000: "상동면" },
            { 4825037000: "대동면" },
          ],
        },
        {
          4827000000: "밀양시",
          third: [
            { 4827010100: "내일동" },
            { 4827010200: "내이동" },
            { 4827010300: "교동" },
            { 4827010400: "삼문동" },
            { 4827010500: "남포동" },
            { 4827010600: "용평동" },
            { 4827010700: "활성동" },
            { 4827010800: "가곡동" },
            { 4827025000: "삼랑진읍" },
            { 4827025300: "하남읍" },
            { 4827031000: "부북면" },
            { 4827032000: "상동면" },
            { 4827033000: "산외면" },
            { 4827034000: "산내면" },
            { 4827035000: "단장면" },
            { 4827036000: "상남면" },
            { 4827037000: "초동면" },
            { 4827038000: "무안면" },
            { 4827039000: "청도면" },
          ],
        },
        {
          4831000000: "거제시",
          third: [
            { 4831010100: "능포동" },
            { 4831010200: "장승포동" },
            { 4831010300: "두모동" },
            { 4831010400: "아양동" },
            { 4831010500: "아주동" },
            { 4831010600: "옥포동" },
            { 4831010700: "덕포동" },
            { 4831010800: "장평동" },
            { 4831010900: "고현동" },
            { 4831011000: "상동동" },
            { 4831011100: "문동동" },
            { 4831011200: "삼거동" },
            { 4831011300: "양정동" },
            { 4831011400: "수월동" },
            { 4831031000: "일운면" },
            { 4831032000: "동부면" },
            { 4831033000: "남부면" },
            { 4831034000: "거제면" },
            { 4831035000: "둔덕면" },
            { 4831036000: "사등면" },
            { 4831037000: "연초면" },
            { 4831038000: "하청면" },
            { 4831039000: "장목면" },
          ],
        },
        {
          4833000000: "양산시",
          third: [
            { 4833010100: "다방동" },
            { 4833010200: "남부동" },
            { 4833010300: "중부동" },
            { 4833010400: "북부동" },
            { 4833010500: "명곡동" },
            { 4833010600: "신기동" },
            { 4833010700: "북정동" },
            { 4833010800: "산막동" },
            { 4833010900: "호계동" },
            { 4833011000: "교동" },
            { 4833011100: "유산동" },
            { 4833011200: "어곡동" },
            { 4833011300: "용당동" },
            { 4833011400: "삼호동" },
            { 4833011500: "명동" },
            { 4833011600: "주남동" },
            { 4833011700: "소주동" },
            { 4833011800: "주진동" },
            { 4833011900: "평산동" },
            { 4833012000: "덕계동" },
            { 4833012100: "매곡동" },
            { 4833025300: "물금읍" },
            { 4833031000: "동면" },
            { 4833032000: "원동면" },
            { 4833033000: "상북면" },
            { 4833034000: "하북면" },
          ],
        },
        {
          4872000000: "의령군",
          third: [
            { 4872025000: "의령읍" },
            { 4872031000: "가례면" },
            { 4872032000: "칠곡면" },
            { 4872033000: "대의면" },
            { 4872034000: "화정면" },
            { 4872035000: "용덕면" },
            { 4872036000: "정곡면" },
            { 4872037000: "지정면" },
            { 4872038000: "낙서면" },
            { 4872039000: "부림면" },
            { 4872040000: "봉수면" },
            { 4872041500: "궁류면" },
            { 4872042000: "유곡면" },
          ],
        },
        {
          4873000000: "함안군",
          third: [
            { 4873025000: "가야읍" },
            { 4873025300: "칠원읍" },
            { 4873031000: "함안면" },
            { 4873032000: "군북면" },
            { 4873033000: "법수면" },
            { 4873034000: "대산면" },
            { 4873035000: "칠서면" },
            { 4873036000: "칠북면" },
            { 4873038000: "산인면" },
            { 4873039000: "여항면" },
          ],
        },
        {
          4874000000: "창녕군",
          third: [
            { 4874025000: "창녕읍" },
            { 4874025300: "남지읍" },
            { 4874031000: "고암면" },
            { 4874032000: "성산면" },
            { 4874033000: "대합면" },
            { 4874034000: "이방면" },
            { 4874035000: "유어면" },
            { 4874036000: "대지면" },
            { 4874037000: "계성면" },
            { 4874038000: "영산면" },
            { 4874039000: "장마면" },
            { 4874040000: "도천면" },
            { 4874041000: "길곡면" },
            { 4874042000: "부곡면" },
          ],
        },
        {
          4882000000: "고성군",
          third: [
            { 4882025000: "고성읍" },
            { 4882031000: "삼산면" },
            { 4882032000: "하일면" },
            { 4882033000: "하이면" },
            { 4882034000: "상리면" },
            { 4882035000: "대가면" },
            { 4882036000: "영현면" },
            { 4882037000: "영오면" },
            { 4882038000: "개천면" },
            { 4882039000: "구만면" },
            { 4882040000: "회화면" },
            { 4882041000: "마암면" },
            { 4882042000: "동해면" },
            { 4882043000: "거류면" },
          ],
        },
        {
          4884000000: "남해군",
          third: [
            { 4884025000: "남해읍" },
            { 4884031000: "이동면" },
            { 4884032000: "상주면" },
            { 4884033000: "삼동면" },
            { 4884034000: "미조면" },
            { 4884035000: "남면" },
            { 4884036000: "서면" },
            { 4884037000: "고현면" },
            { 4884038000: "설천면" },
            { 4884039000: "창선면" },
          ],
        },
        {
          4885000000: "하동군",
          third: [
            { 4885025000: "하동읍" },
            { 4885031000: "화개면" },
            { 4885032000: "악양면" },
            { 4885033000: "적량면" },
            { 4885034000: "횡천면" },
            { 4885035000: "고전면" },
            { 4885036000: "금남면" },
            { 4885037000: "진교면" },
            { 4885038000: "양보면" },
            { 4885039000: "북천면" },
            { 4885040000: "청암면" },
            { 4885041000: "옥종면" },
            { 4885042000: "금성면" },
          ],
        },
        {
          4886000000: "산청군",
          third: [
            { 4886025000: "산청읍" },
            { 4886031000: "차황면" },
            { 4886032000: "오부면" },
            { 4886033000: "생초면" },
            { 4886034000: "금서면" },
            { 4886035000: "삼장면" },
            { 4886036000: "시천면" },
            { 4886037000: "단성면" },
            { 4886038000: "신안면" },
            { 4886039000: "생비량면" },
            { 4886040000: "신등면" },
          ],
        },
        {
          4887000000: "함양군",
          third: [
            { 4887025000: "함양읍" },
            { 4887031000: "마천면" },
            { 4887032000: "휴천면" },
            { 4887033000: "유림면" },
            { 4887034000: "수동면" },
            { 4887035000: "지곡면" },
            { 4887036000: "안의면" },
            { 4887037000: "서하면" },
            { 4887038000: "서상면" },
            { 4887039000: "백전면" },
            { 4887040000: "병곡면" },
          ],
        },
        {
          4888000000: "거창군",
          third: [
            { 4888025000: "거창읍" },
            { 4888031000: "주상면" },
            { 4888032000: "웅양면" },
            { 4888033000: "고제면" },
            { 4888034000: "북상면" },
            { 4888035000: "위천면" },
            { 4888036000: "마리면" },
            { 4888037000: "남상면" },
            { 4888038000: "남하면" },
            { 4888039000: "신원면" },
            { 4888040000: "가조면" },
            { 4888041000: "가북면" },
          ],
        },
        {
          4889000000: "합천군",
          third: [
            { 4889025000: "합천읍" },
            { 4889031000: "봉산면" },
            { 4889032000: "묘산면" },
            { 4889033000: "가야면" },
            { 4889034000: "야로면" },
            { 4889035000: "율곡면" },
            { 4889036000: "초계면" },
            { 4889037000: "쌍책면" },
            { 4889038000: "덕곡면" },
            { 4889039000: "청덕면" },
            { 4889040000: "적중면" },
            { 4889041000: "대양면" },
            { 4889042000: "쌍백면" },
            { 4889043000: "삼가면" },
            { 4889044000: "가회면" },
            { 4889045000: "대병면" },
            { 4889046000: "용주면" },
          ],
        },
      ],
    },
  },
  {
    first: {
      5000000000: "제주특별자치도",
      second: [
        {
          5011000000: "제주시",
          third: [
            { 5011010100: "일도일동" },
            { 5011010200: "일도이동" },
            { 5011010300: "이도일동" },
            { 5011010400: "이도이동" },
            { 5011010500: "삼도일동" },
            { 5011010600: "삼도이동" },
            { 5011010700: "건입동" },
            { 5011010800: "용담일동" },
            { 5011010900: "용담이동" },
            { 5011011000: "용담삼동" },
            { 5011011100: "화북일동" },
            { 5011011200: "화북이동" },
            { 5011011300: "삼양일동" },
            { 5011011400: "삼양이동" },
            { 5011011500: "삼양삼동" },
            { 5011011600: "봉개동" },
            { 5011011700: "아라일동" },
            { 5011011800: "아라이동" },
            { 5011011900: "오라일동" },
            { 5011012000: "오라이동" },
            { 5011012100: "오라삼동" },
            { 5011012200: "노형동" },
            { 5011012300: "외도일동" },
            { 5011012400: "외도이동" },
            { 5011012500: "이호일동" },
            { 5011012600: "이호이동" },
            { 5011012700: "도두일동" },
            { 5011012800: "도두이동" },
            { 5011012900: "도남동" },
            { 5011013000: "도련일동" },
            { 5011013100: "도련이동" },
            { 5011013200: "용강동" },
            { 5011013300: "회천동" },
            { 5011013400: "오등동" },
            { 5011013500: "월평동" },
            { 5011013600: "영평동" },
            { 5011013700: "연동" },
            { 5011013800: "도평동" },
            { 5011013900: "해안동" },
            { 5011014000: "내도동" },
            { 5011025000: "한림읍" },
            { 5011025300: "애월읍" },
            { 5011025600: "구좌읍" },
            { 5011025900: "조천읍" },
            { 5011031000: "한경면" },
            { 5011032000: "추자면" },
            { 5011033000: "우도면" },
          ],
        },
        {
          5013000000: "서귀포시",
          third: [
            { 5013010100: "서귀동" },
            { 5013010200: "법환동" },
            { 5013010300: "서호동" },
            { 5013010400: "호근동" },
            { 5013010500: "동홍동" },
            { 5013010600: "서홍동" },
            { 5013010700: "상효동" },
            { 5013010800: "하효동" },
            { 5013010900: "신효동" },
            { 5013011000: "보목동" },
            { 5013011100: "토평동" },
            { 5013011200: "중문동" },
            { 5013011300: "회수동" },
            { 5013011400: "대포동" },
            { 5013011500: "월평동" },
            { 5013011600: "강정동" },
            { 5013011700: "도순동" },
            { 5013011800: "하원동" },
            { 5013011900: "색달동" },
            { 5013012000: "상예동" },
            { 5013012100: "하예동" },
            { 5013012200: "영남동" },
            { 5013025000: "대정읍" },
            { 5013025300: "남원읍" },
            { 5013025900: "성산읍" },
            { 5013031000: "안덕면" },
            { 5013032000: "표선면" },
          ],
        },
      ],
    },
  },
];

export const totalSido: { [key: number]: string } = {
  1000000000: "전체",
  1100000000: "서울특별시",
  1111000000: "서울특별시 종로구",
  1111010100: "서울특별시 종로구 청운동",
  1111010200: "서울특별시 종로구 신교동",
  1111010300: "서울특별시 종로구 궁정동",
  1111010400: "서울특별시 종로구 효자동",
  1111010500: "서울특별시 종로구 창성동",
  1111010600: "서울특별시 종로구 통의동",
  1111010700: "서울특별시 종로구 적선동",
  1111010800: "서울특별시 종로구 통인동",
  1111010900: "서울특별시 종로구 누상동",
  1111011000: "서울특별시 종로구 누하동",
  1111011100: "서울특별시 종로구 옥인동",
  1111011200: "서울특별시 종로구 체부동",
  1111011300: "서울특별시 종로구 필운동",
  1111011400: "서울특별시 종로구 내자동",
  1111011500: "서울특별시 종로구 사직동",
  1111011600: "서울특별시 종로구 도렴동",
  1111011700: "서울특별시 종로구 당주동",
  1111011800: "서울특별시 종로구 내수동",
  1111011900: "서울특별시 종로구 세종로",
  1111012000: "서울특별시 종로구 신문로1가",
  1111012100: "서울특별시 종로구 신문로2가",
  1111012200: "서울특별시 종로구 청진동",
  1111012300: "서울특별시 종로구 서린동",
  1111012400: "서울특별시 종로구 수송동",
  1111012500: "서울특별시 종로구 중학동",
  1111012600: "서울특별시 종로구 종로1가",
  1111012700: "서울특별시 종로구 공평동",
  1111012800: "서울특별시 종로구 관훈동",
  1111012900: "서울특별시 종로구 견지동",
  1111013000: "서울특별시 종로구 와룡동",
  1111013100: "서울특별시 종로구 권농동",
  1111013200: "서울특별시 종로구 운니동",
  1111013300: "서울특별시 종로구 익선동",
  1111013400: "서울특별시 종로구 경운동",
  1111013500: "서울특별시 종로구 관철동",
  1111013600: "서울특별시 종로구 인사동",
  1111013700: "서울특별시 종로구 낙원동",
  1111013800: "서울특별시 종로구 종로2가",
  1111013900: "서울특별시 종로구 팔판동",
  1111014000: "서울특별시 종로구 삼청동",
  1111014100: "서울특별시 종로구 안국동",
  1111014200: "서울특별시 종로구 소격동",
  1111014300: "서울특별시 종로구 화동",
  1111014400: "서울특별시 종로구 사간동",
  1111014500: "서울특별시 종로구 송현동",
  1111014600: "서울특별시 종로구 가회동",
  1111014700: "서울특별시 종로구 재동",
  1111014800: "서울특별시 종로구 계동",
  1111014900: "서울특별시 종로구 원서동",
  1111015000: "서울특별시 종로구 훈정동",
  1111015100: "서울특별시 종로구 묘동",
  1111015200: "서울특별시 종로구 봉익동",
  1111015300: "서울특별시 종로구 돈의동",
  1111015400: "서울특별시 종로구 장사동",
  1111015500: "서울특별시 종로구 관수동",
  1111015600: "서울특별시 종로구 종로3가",
  1111015700: "서울특별시 종로구 인의동",
  1111015800: "서울특별시 종로구 예지동",
  1111015900: "서울특별시 종로구 원남동",
  1111016000: "서울특별시 종로구 연지동",
  1111016100: "서울특별시 종로구 종로4가",
  1111016200: "서울특별시 종로구 효제동",
  1111016300: "서울특별시 종로구 종로5가",
  1111016400: "서울특별시 종로구 종로6가",
  1111016500: "서울특별시 종로구 이화동",
  1111016600: "서울특별시 종로구 연건동",
  1111016700: "서울특별시 종로구 충신동",
  1111016800: "서울특별시 종로구 동숭동",
  1111016900: "서울특별시 종로구 혜화동",
  1111017000: "서울특별시 종로구 명륜1가",
  1111017100: "서울특별시 종로구 명륜2가",
  1111017200: "서울특별시 종로구 명륜4가",
  1111017300: "서울특별시 종로구 명륜3가",
  1111017400: "서울특별시 종로구 창신동",
  1111017500: "서울특별시 종로구 숭인동",
  1111017600: "서울특별시 종로구 교남동",
  1111017700: "서울특별시 종로구 평동",
  1111017800: "서울특별시 종로구 송월동",
  1111017900: "서울특별시 종로구 홍파동",
  1111018000: "서울특별시 종로구 교북동",
  1111018100: "서울특별시 종로구 행촌동",
  1111018200: "서울특별시 종로구 구기동",
  1111018300: "서울특별시 종로구 평창동",
  1111018400: "서울특별시 종로구 부암동",
  1111018500: "서울특별시 종로구 홍지동",
  1111018600: "서울특별시 종로구 신영동",
  1111018700: "서울특별시 종로구 무악동",
  1114000000: "서울특별시 중구",
  1114010100: "서울특별시 중구 무교동",
  1114010200: "서울특별시 중구 다동",
  1114010300: "서울특별시 중구 태평로1가",
  1114010400: "서울특별시 중구 을지로1가",
  1114010500: "서울특별시 중구 을지로2가",
  1114010600: "서울특별시 중구 남대문로1가",
  1114010700: "서울특별시 중구 삼각동",
  1114010800: "서울특별시 중구 수하동",
  1114010900: "서울특별시 중구 장교동",
  1114011000: "서울특별시 중구 수표동",
  1114011100: "서울특별시 중구 소공동",
  1114011200: "서울특별시 중구 남창동",
  1114011300: "서울특별시 중구 북창동",
  1114011400: "서울특별시 중구 태평로2가",
  1114011500: "서울특별시 중구 남대문로2가",
  1114011600: "서울특별시 중구 남대문로3가",
  1114011700: "서울특별시 중구 남대문로4가",
  1114011800: "서울특별시 중구 남대문로5가",
  1114011900: "서울특별시 중구 봉래동1가",
  1114012000: "서울특별시 중구 봉래동2가",
  1114012100: "서울특별시 중구 회현동1가",
  1114012200: "서울특별시 중구 회현동2가",
  1114012300: "서울특별시 중구 회현동3가",
  1114012400: "서울특별시 중구 충무로1가",
  1114012500: "서울특별시 중구 충무로2가",
  1114012600: "서울특별시 중구 명동1가",
  1114012700: "서울특별시 중구 명동2가",
  1114012800: "서울특별시 중구 남산동1가",
  1114012900: "서울특별시 중구 남산동2가",
  1114013000: "서울특별시 중구 남산동3가",
  1114013100: "서울특별시 중구 저동1가",
  1114013200: "서울특별시 중구 충무로4가",
  1114013300: "서울특별시 중구 충무로5가",
  1114013400: "서울특별시 중구 인현동2가",
  1114013500: "서울특별시 중구 예관동",
  1114013600: "서울특별시 중구 묵정동",
  1114013700: "서울특별시 중구 필동1가",
  1114013800: "서울특별시 중구 필동2가",
  1114013900: "서울특별시 중구 필동3가",
  1114014000: "서울특별시 중구 남학동",
  1114014100: "서울특별시 중구 주자동",
  1114014200: "서울특별시 중구 예장동",
  1114014300: "서울특별시 중구 장충동1가",
  1114014400: "서울특별시 중구 장충동2가",
  1114014500: "서울특별시 중구 광희동1가",
  1114014600: "서울특별시 중구 광희동2가",
  1114014700: "서울특별시 중구 쌍림동",
  1114014800: "서울특별시 중구 을지로6가",
  1114014900: "서울특별시 중구 을지로7가",
  1114015000: "서울특별시 중구 을지로4가",
  1114015100: "서울특별시 중구 을지로5가",
  1114015200: "서울특별시 중구 주교동",
  1114015300: "서울특별시 중구 방산동",
  1114015400: "서울특별시 중구 오장동",
  1114015500: "서울특별시 중구 을지로3가",
  1114015600: "서울특별시 중구 입정동",
  1114015700: "서울특별시 중구 산림동",
  1114015800: "서울특별시 중구 충무로3가",
  1114015900: "서울특별시 중구 초동",
  1114016000: "서울특별시 중구 인현동1가",
  1114016100: "서울특별시 중구 저동2가",
  1114016200: "서울특별시 중구 신당동",
  1114016300: "서울특별시 중구 흥인동",
  1114016400: "서울특별시 중구 무학동",
  1114016500: "서울특별시 중구 황학동",
  1114016600: "서울특별시 중구 서소문동",
  1114016700: "서울특별시 중구 정동",
  1114016800: "서울특별시 중구 순화동",
  1114016900: "서울특별시 중구 의주로1가",
  1114017000: "서울특별시 중구 충정로1가",
  1114017100: "서울특별시 중구 중림동",
  1114017200: "서울특별시 중구 의주로2가",
  1114017300: "서울특별시 중구 만리동1가",
  1114017400: "서울특별시 중구 만리동2가",
  1117000000: "서울특별시 용산구",
  1117010100: "서울특별시 용산구 후암동",
  1117010200: "서울특별시 용산구 용산동2가",
  1117010300: "서울특별시 용산구 용산동4가",
  1117010400: "서울특별시 용산구 갈월동",
  1117010500: "서울특별시 용산구 남영동",
  1117010600: "서울특별시 용산구 용산동1가",
  1117010700: "서울특별시 용산구 동자동",
  1117010800: "서울특별시 용산구 서계동",
  1117010900: "서울특별시 용산구 청파동1가",
  1117011000: "서울특별시 용산구 청파동2가",
  1117011100: "서울특별시 용산구 청파동3가",
  1117011200: "서울특별시 용산구 원효로1가",
  1117011300: "서울특별시 용산구 원효로2가",
  1117011400: "서울특별시 용산구 신창동",
  1117011500: "서울특별시 용산구 산천동",
  1117011600: "서울특별시 용산구 청암동",
  1117011700: "서울특별시 용산구 원효로3가",
  1117011800: "서울특별시 용산구 원효로4가",
  1117011900: "서울특별시 용산구 효창동",
  1117012000: "서울특별시 용산구 도원동",
  1117012100: "서울특별시 용산구 용문동",
  1117012200: "서울특별시 용산구 문배동",
  1117012300: "서울특별시 용산구 신계동",
  1117012400: "서울특별시 용산구 한강로1가",
  1117012500: "서울특별시 용산구 한강로2가",
  1117012600: "서울특별시 용산구 용산동3가",
  1117012700: "서울특별시 용산구 용산동5가",
  1117012800: "서울특별시 용산구 한강로3가",
  1117012900: "서울특별시 용산구 이촌동",
  1117013000: "서울특별시 용산구 이태원동",
  1117013100: "서울특별시 용산구 한남동",
  1117013200: "서울특별시 용산구 동빙고동",
  1117013300: "서울특별시 용산구 서빙고동",
  1117013400: "서울특별시 용산구 주성동",
  1117013500: "서울특별시 용산구 용산동6가",
  1117013600: "서울특별시 용산구 보광동",
  1120000000: "서울특별시 성동구",
  1120010100: "서울특별시 성동구 상왕십리동",
  1120010200: "서울특별시 성동구 하왕십리동",
  1120010300: "서울특별시 성동구 홍익동",
  1120010400: "서울특별시 성동구 도선동",
  1120010500: "서울특별시 성동구 마장동",
  1120010600: "서울특별시 성동구 사근동",
  1120010700: "서울특별시 성동구 행당동",
  1120010800: "서울특별시 성동구 응봉동",
  1120010900: "서울특별시 성동구 금호동1가",
  1120011000: "서울특별시 성동구 금호동2가",
  1120011100: "서울특별시 성동구 금호동3가",
  1120011200: "서울특별시 성동구 금호동4가",
  1120011300: "서울특별시 성동구 옥수동",
  1120011400: "서울특별시 성동구 성수동1가",
  1120011500: "서울특별시 성동구 성수동2가",
  1120011800: "서울특별시 성동구 송정동",
  1120012200: "서울특별시 성동구 용답동",
  1121500000: "서울특별시 광진구",
  1121510100: "서울특별시 광진구 중곡동",
  1121510200: "서울특별시 광진구 능동",
  1121510300: "서울특별시 광진구 구의동",
  1121510400: "서울특별시 광진구 광장동",
  1121510500: "서울특별시 광진구 자양동",
  1121510700: "서울특별시 광진구 화양동",
  1121510900: "서울특별시 광진구 군자동",
  1123000000: "서울특별시 동대문구",
  1123010100: "서울특별시 동대문구 신설동",
  1123010200: "서울특별시 동대문구 용두동",
  1123010300: "서울특별시 동대문구 제기동",
  1123010400: "서울특별시 동대문구 전농동",
  1123010500: "서울특별시 동대문구 답십리동",
  1123010600: "서울특별시 동대문구 장안동",
  1123010700: "서울특별시 동대문구 청량리동",
  1123010800: "서울특별시 동대문구 회기동",
  1123010900: "서울특별시 동대문구 휘경동",
  1123011000: "서울특별시 동대문구 이문동",
  1126000000: "서울특별시 중랑구",
  1126010100: "서울특별시 중랑구 면목동",
  1126010200: "서울특별시 중랑구 상봉동",
  1126010300: "서울특별시 중랑구 중화동",
  1126010400: "서울특별시 중랑구 묵동",
  1126010500: "서울특별시 중랑구 망우동",
  1126010600: "서울특별시 중랑구 신내동",
  1129000000: "서울특별시 성북구",
  1129010100: "서울특별시 성북구 성북동",
  1129010200: "서울특별시 성북구 성북동1가",
  1129010300: "서울특별시 성북구 돈암동",
  1129010400: "서울특별시 성북구 동소문동1가",
  1129010500: "서울특별시 성북구 동소문동2가",
  1129010600: "서울특별시 성북구 동소문동3가",
  1129010700: "서울특별시 성북구 동소문동4가",
  1129010800: "서울특별시 성북구 동소문동5가",
  1129010900: "서울특별시 성북구 동소문동6가",
  1129011000: "서울특별시 성북구 동소문동7가",
  1129011100: "서울특별시 성북구 삼선동1가",
  1129011200: "서울특별시 성북구 삼선동2가",
  1129011300: "서울특별시 성북구 삼선동3가",
  1129011400: "서울특별시 성북구 삼선동4가",
  1129011500: "서울특별시 성북구 삼선동5가",
  1129011600: "서울특별시 성북구 동선동1가",
  1129011700: "서울특별시 성북구 동선동2가",
  1129011800: "서울특별시 성북구 동선동3가",
  1129011900: "서울특별시 성북구 동선동4가",
  1129012000: "서울특별시 성북구 동선동5가",
  1129012100: "서울특별시 성북구 안암동1가",
  1129012200: "서울특별시 성북구 안암동2가",
  1129012300: "서울특별시 성북구 안암동3가",
  1129012400: "서울특별시 성북구 안암동4가",
  1129012500: "서울특별시 성북구 안암동5가",
  1129012600: "서울특별시 성북구 보문동4가",
  1129012700: "서울특별시 성북구 보문동5가",
  1129012800: "서울특별시 성북구 보문동6가",
  1129012900: "서울특별시 성북구 보문동7가",
  1129013000: "서울특별시 성북구 보문동1가",
  1129013100: "서울특별시 성북구 보문동2가",
  1129013200: "서울특별시 성북구 보문동3가",
  1129013300: "서울특별시 성북구 정릉동",
  1129013400: "서울특별시 성북구 길음동",
  1129013500: "서울특별시 성북구 종암동",
  1129013600: "서울특별시 성북구 하월곡동",
  1129013700: "서울특별시 성북구 상월곡동",
  1129013800: "서울특별시 성북구 장위동",
  1129013900: "서울특별시 성북구 석관동",
  1130500000: "서울특별시 강북구",
  1130510100: "서울특별시 강북구 미아동",
  1130510200: "서울특별시 강북구 번동",
  1130510300: "서울특별시 강북구 수유동",
  1130510400: "서울특별시 강북구 우이동",
  1132000000: "서울특별시 도봉구",
  1132010500: "서울특별시 도봉구 쌍문동",
  1132010600: "서울특별시 도봉구 방학동",
  1132010700: "서울특별시 도봉구 창동",
  1132010800: "서울특별시 도봉구 도봉동",
  1135000000: "서울특별시 노원구",
  1135010200: "서울특별시 노원구 월계동",
  1135010300: "서울특별시 노원구 공릉동",
  1135010400: "서울특별시 노원구 하계동",
  1135010500: "서울특별시 노원구 상계동",
  1135010600: "서울특별시 노원구 중계동",
  1138000000: "서울특별시 은평구",
  1138010100: "서울특별시 은평구 수색동",
  1138010200: "서울특별시 은평구 녹번동",
  1138010300: "서울특별시 은평구 불광동",
  1138010400: "서울특별시 은평구 갈현동",
  1138010500: "서울특별시 은평구 구산동",
  1138010600: "서울특별시 은평구 대조동",
  1138010700: "서울특별시 은평구 응암동",
  1138010800: "서울특별시 은평구 역촌동",
  1138010900: "서울특별시 은평구 신사동",
  1138011000: "서울특별시 은평구 증산동",
  1138011400: "서울특별시 은평구 진관동",
  1141000000: "서울특별시 서대문구",
  1141010100: "서울특별시 서대문구 충정로2가",
  1141010200: "서울특별시 서대문구 충정로3가",
  1141010300: "서울특별시 서대문구 합동",
  1141010400: "서울특별시 서대문구 미근동",
  1141010500: "서울특별시 서대문구 냉천동",
  1141010600: "서울특별시 서대문구 천연동",
  1141010700: "서울특별시 서대문구 옥천동",
  1141010800: "서울특별시 서대문구 영천동",
  1141010900: "서울특별시 서대문구 현저동",
  1141011000: "서울특별시 서대문구 북아현동",
  1141011100: "서울특별시 서대문구 홍제동",
  1141011200: "서울특별시 서대문구 대현동",
  1141011300: "서울특별시 서대문구 대신동",
  1141011400: "서울특별시 서대문구 신촌동",
  1141011500: "서울특별시 서대문구 봉원동",
  1141011600: "서울특별시 서대문구 창천동",
  1141011700: "서울특별시 서대문구 연희동",
  1141011800: "서울특별시 서대문구 홍은동",
  1141011900: "서울특별시 서대문구 북가좌동",
  1141012000: "서울특별시 서대문구 남가좌동",
  1144000000: "서울특별시 마포구",
  1144010100: "서울특별시 마포구 아현동",
  1144010200: "서울특별시 마포구 공덕동",
  1144010300: "서울특별시 마포구 신공덕동",
  1144010400: "서울특별시 마포구 도화동",
  1144010500: "서울특별시 마포구 용강동",
  1144010600: "서울특별시 마포구 토정동",
  1144010700: "서울특별시 마포구 마포동",
  1144010800: "서울특별시 마포구 대흥동",
  1144010900: "서울특별시 마포구 염리동",
  1144011000: "서울특별시 마포구 노고산동",
  1144011100: "서울특별시 마포구 신수동",
  1144011200: "서울특별시 마포구 현석동",
  1144011300: "서울특별시 마포구 구수동",
  1144011400: "서울특별시 마포구 창전동",
  1144011500: "서울특별시 마포구 상수동",
  1144011600: "서울특별시 마포구 하중동",
  1144011700: "서울특별시 마포구 신정동",
  1144011800: "서울특별시 마포구 당인동",
  1144012000: "서울특별시 마포구 서교동",
  1144012100: "서울특별시 마포구 동교동",
  1144012200: "서울특별시 마포구 합정동",
  1144012300: "서울특별시 마포구 망원동",
  1144012400: "서울특별시 마포구 연남동",
  1144012500: "서울특별시 마포구 성산동",
  1144012600: "서울특별시 마포구 중동",
  1144012700: "서울특별시 마포구 상암동",
  1147000000: "서울특별시 양천구",
  1147010100: "서울특별시 양천구 신정동",
  1147010200: "서울특별시 양천구 목동",
  1147010300: "서울특별시 양천구 신월동",
  1150000000: "서울특별시 강서구",
  1150010100: "서울특별시 강서구 염창동",
  1150010200: "서울특별시 강서구 등촌동",
  1150010300: "서울특별시 강서구 화곡동",
  1150010400: "서울특별시 강서구 가양동",
  1150010500: "서울특별시 강서구 마곡동",
  1150010600: "서울특별시 강서구 내발산동",
  1150010700: "서울특별시 강서구 외발산동",
  1150010800: "서울특별시 강서구 공항동",
  1150010900: "서울특별시 강서구 방화동",
  1150011000: "서울특별시 강서구 개화동",
  1150011100: "서울특별시 강서구 과해동",
  1150011200: "서울특별시 강서구 오곡동",
  1150011300: "서울특별시 강서구 오쇠동",
  1153000000: "서울특별시 구로구",
  1153010100: "서울특별시 구로구 신도림동",
  1153010200: "서울특별시 구로구 구로동",
  1153010300: "서울특별시 구로구 가리봉동",
  1153010600: "서울특별시 구로구 고척동",
  1153010700: "서울특별시 구로구 개봉동",
  1153010800: "서울특별시 구로구 오류동",
  1153010900: "서울특별시 구로구 궁동",
  1153011000: "서울특별시 구로구 온수동",
  1153011100: "서울특별시 구로구 천왕동",
  1153011200: "서울특별시 구로구 항동",
  1154500000: "서울특별시 금천구",
  1154510100: "서울특별시 금천구 가산동",
  1154510200: "서울특별시 금천구 독산동",
  1154510300: "서울특별시 금천구 시흥동",
  1156000000: "서울특별시 영등포구",
  1156010100: "서울특별시 영등포구 영등포동",
  1156010200: "서울특별시 영등포구 영등포동1가",
  1156010300: "서울특별시 영등포구 영등포동2가",
  1156010400: "서울특별시 영등포구 영등포동3가",
  1156010500: "서울특별시 영등포구 영등포동4가",
  1156010600: "서울특별시 영등포구 영등포동5가",
  1156010700: "서울특별시 영등포구 영등포동6가",
  1156010800: "서울특별시 영등포구 영등포동7가",
  1156010900: "서울특별시 영등포구 영등포동8가",
  1156011000: "서울특별시 영등포구 여의도동",
  1156011100: "서울특별시 영등포구 당산동1가",
  1156011200: "서울특별시 영등포구 당산동2가",
  1156011300: "서울특별시 영등포구 당산동3가",
  1156011400: "서울특별시 영등포구 당산동4가",
  1156011500: "서울특별시 영등포구 당산동5가",
  1156011600: "서울특별시 영등포구 당산동6가",
  1156011700: "서울특별시 영등포구 당산동",
  1156011800: "서울특별시 영등포구 도림동",
  1156011900: "서울특별시 영등포구 문래동1가",
  1156012000: "서울특별시 영등포구 문래동2가",
  1156012100: "서울특별시 영등포구 문래동3가",
  1156012200: "서울특별시 영등포구 문래동4가",
  1156012300: "서울특별시 영등포구 문래동5가",
  1156012400: "서울특별시 영등포구 문래동6가",
  1156012500: "서울특별시 영등포구 양평동1가",
  1156012600: "서울특별시 영등포구 양평동2가",
  1156012700: "서울특별시 영등포구 양평동3가",
  1156012800: "서울특별시 영등포구 양평동4가",
  1156012900: "서울특별시 영등포구 양평동5가",
  1156013000: "서울특별시 영등포구 양평동6가",
  1156013100: "서울특별시 영등포구 양화동",
  1156013200: "서울특별시 영등포구 신길동",
  1156013300: "서울특별시 영등포구 대림동",
  1156013400: "서울특별시 영등포구 양평동",
  1159000000: "서울특별시 동작구",
  1159010100: "서울특별시 동작구 노량진동",
  1159010200: "서울특별시 동작구 상도동",
  1159010300: "서울특별시 동작구 상도1동",
  1159010400: "서울특별시 동작구 본동",
  1159010500: "서울특별시 동작구 흑석동",
  1159010600: "서울특별시 동작구 동작동",
  1159010700: "서울특별시 동작구 사당동",
  1159010800: "서울특별시 동작구 대방동",
  1159010900: "서울특별시 동작구 신대방동",
  1162000000: "서울특별시 관악구",
  1162010100: "서울특별시 관악구 봉천동",
  1162010200: "서울특별시 관악구 신림동",
  1162010300: "서울특별시 관악구 남현동",
  1165000000: "서울특별시 서초구",
  1165010100: "서울특별시 서초구 방배동",
  1165010200: "서울특별시 서초구 양재동",
  1165010300: "서울특별시 서초구 우면동",
  1165010400: "서울특별시 서초구 원지동",
  1165010600: "서울특별시 서초구 잠원동",
  1165010700: "서울특별시 서초구 반포동",
  1165010800: "서울특별시 서초구 서초동",
  1165010900: "서울특별시 서초구 내곡동",
  1165011000: "서울특별시 서초구 염곡동",
  1165011100: "서울특별시 서초구 신원동",
  1168000000: "서울특별시 강남구",
  1168010100: "서울특별시 강남구 역삼동",
  1168010300: "서울특별시 강남구 개포동",
  1168010400: "서울특별시 강남구 청담동",
  1168010500: "서울특별시 강남구 삼성동",
  1168010600: "서울특별시 강남구 대치동",
  1168010700: "서울특별시 강남구 신사동",
  1168010800: "서울특별시 강남구 논현동",
  1168011000: "서울특별시 강남구 압구정동",
  1168011100: "서울특별시 강남구 세곡동",
  1168011200: "서울특별시 강남구 자곡동",
  1168011300: "서울특별시 강남구 율현동",
  1168011400: "서울특별시 강남구 일원동",
  1168011500: "서울특별시 강남구 수서동",
  1168011800: "서울특별시 강남구 도곡동",
  1171000000: "서울특별시 송파구",
  1171010100: "서울특별시 송파구 잠실동",
  1171010200: "서울특별시 송파구 신천동",
  1171010300: "서울특별시 송파구 풍납동",
  1171010400: "서울특별시 송파구 송파동",
  1171010500: "서울특별시 송파구 석촌동",
  1171010600: "서울특별시 송파구 삼전동",
  1171010700: "서울특별시 송파구 가락동",
  1171010800: "서울특별시 송파구 문정동",
  1171010900: "서울특별시 송파구 장지동",
  1171011100: "서울특별시 송파구 방이동",
  1171011200: "서울특별시 송파구 오금동",
  1171011300: "서울특별시 송파구 거여동",
  1171011400: "서울특별시 송파구 마천동",
  1174000000: "서울특별시 강동구",
  1174010100: "서울특별시 강동구 명일동",
  1174010200: "서울특별시 강동구 고덕동",
  1174010300: "서울특별시 강동구 상일동",
  1174010500: "서울특별시 강동구 길동",
  1174010600: "서울특별시 강동구 둔촌동",
  1174010700: "서울특별시 강동구 암사동",
  1174010800: "서울특별시 강동구 성내동",
  1174010900: "서울특별시 강동구 천호동",
  1174011000: "서울특별시 강동구 강일동",
  2600000000: "부산광역시",
  2611000000: "부산광역시 중구",
  2611010100: "부산광역시 중구 영주동",
  2611010200: "부산광역시 중구 대창동1가",
  2611010300: "부산광역시 중구 대창동2가",
  2611010400: "부산광역시 중구 중앙동1가",
  2611010500: "부산광역시 중구 중앙동2가",
  2611010600: "부산광역시 중구 중앙동3가",
  2611010700: "부산광역시 중구 중앙동4가",
  2611010800: "부산광역시 중구 중앙동5가",
  2611010900: "부산광역시 중구 중앙동6가",
  2611011000: "부산광역시 중구 중앙동7가",
  2611011100: "부산광역시 중구 동광동1가",
  2611011200: "부산광역시 중구 동광동2가",
  2611011300: "부산광역시 중구 동광동3가",
  2611011400: "부산광역시 중구 동광동4가",
  2611011500: "부산광역시 중구 동광동5가",
  2611011600: "부산광역시 중구 대청동1가",
  2611011700: "부산광역시 중구 대청동2가",
  2611011800: "부산광역시 중구 대청동3가",
  2611011900: "부산광역시 중구 대청동4가",
  2611012000: "부산광역시 중구 보수동1가",
  2611012100: "부산광역시 중구 보수동2가",
  2611012200: "부산광역시 중구 보수동3가",
  2611012300: "부산광역시 중구 부평동1가",
  2611012400: "부산광역시 중구 부평동2가",
  2611012500: "부산광역시 중구 부평동3가",
  2611012600: "부산광역시 중구 부평동4가",
  2611012700: "부산광역시 중구 신창동1가",
  2611012800: "부산광역시 중구 신창동2가",
  2611012900: "부산광역시 중구 신창동3가",
  2611013000: "부산광역시 중구 신창동4가",
  2611013100: "부산광역시 중구 창선동1가",
  2611013200: "부산광역시 중구 창선동2가",
  2611013300: "부산광역시 중구 광복동1가",
  2611013400: "부산광역시 중구 광복동2가",
  2611013500: "부산광역시 중구 광복동3가",
  2611013600: "부산광역시 중구 남포동1가",
  2611013700: "부산광역시 중구 남포동2가",
  2611013800: "부산광역시 중구 남포동3가",
  2611013900: "부산광역시 중구 남포동4가",
  2611014000: "부산광역시 중구 남포동5가",
  2611014100: "부산광역시 중구 남포동6가",
  2614000000: "부산광역시 서구",
  2614010100: "부산광역시 서구 동대신동1가",
  2614010200: "부산광역시 서구 동대신동2가",
  2614010300: "부산광역시 서구 동대신동3가",
  2614010400: "부산광역시 서구 서대신동1가",
  2614010500: "부산광역시 서구 서대신동2가",
  2614010600: "부산광역시 서구 서대신동3가",
  2614010700: "부산광역시 서구 부용동1가",
  2614010800: "부산광역시 서구 부용동2가",
  2614010900: "부산광역시 서구 부민동1가",
  2614011000: "부산광역시 서구 부민동2가",
  2614011100: "부산광역시 서구 부민동3가",
  2614011200: "부산광역시 서구 토성동1가",
  2614011300: "부산광역시 서구 토성동2가",
  2614011400: "부산광역시 서구 토성동3가",
  2614011500: "부산광역시 서구 아미동1가",
  2614011600: "부산광역시 서구 아미동2가",
  2614011700: "부산광역시 서구 토성동4가",
  2614011800: "부산광역시 서구 토성동5가",
  2614011900: "부산광역시 서구 초장동",
  2614012000: "부산광역시 서구 충무동1가",
  2614012100: "부산광역시 서구 충무동2가",
  2614012200: "부산광역시 서구 충무동3가",
  2614012300: "부산광역시 서구 남부민동",
  2614012400: "부산광역시 서구 암남동",
  2617000000: "부산광역시 동구",
  2617010100: "부산광역시 동구 초량동",
  2617010200: "부산광역시 동구 수정동",
  2617010300: "부산광역시 동구 좌천동",
  2617010400: "부산광역시 동구 범일동",
  2620000000: "부산광역시 영도구",
  2620010100: "부산광역시 영도구 대교동1가",
  2620010200: "부산광역시 영도구 대교동2가",
  2620010300: "부산광역시 영도구 대평동1가",
  2620010400: "부산광역시 영도구 대평동2가",
  2620010500: "부산광역시 영도구 남항동1가",
  2620010600: "부산광역시 영도구 남항동2가",
  2620010700: "부산광역시 영도구 남항동3가",
  2620010800: "부산광역시 영도구 영선동1가",
  2620010900: "부산광역시 영도구 영선동2가",
  2620011000: "부산광역시 영도구 영선동3가",
  2620011100: "부산광역시 영도구 영선동4가",
  2620011200: "부산광역시 영도구 신선동1가",
  2620011300: "부산광역시 영도구 신선동2가",
  2620011400: "부산광역시 영도구 신선동3가",
  2620011500: "부산광역시 영도구 봉래동1가",
  2620011600: "부산광역시 영도구 봉래동2가",
  2620011700: "부산광역시 영도구 봉래동3가",
  2620011800: "부산광역시 영도구 봉래동4가",
  2620011900: "부산광역시 영도구 봉래동5가",
  2620012000: "부산광역시 영도구 청학동",
  2620012100: "부산광역시 영도구 동삼동",
  2623000000: "부산광역시 부산진구",
  2623010100: "부산광역시 부산진구 양정동",
  2623010200: "부산광역시 부산진구 전포동",
  2623010300: "부산광역시 부산진구 부전동",
  2623010400: "부산광역시 부산진구 범천동",
  2623010500: "부산광역시 부산진구 범전동",
  2623010600: "부산광역시 부산진구 연지동",
  2623010700: "부산광역시 부산진구 초읍동",
  2623010800: "부산광역시 부산진구 부암동",
  2623010900: "부산광역시 부산진구 당감동",
  2623011000: "부산광역시 부산진구 가야동",
  2623011100: "부산광역시 부산진구 개금동",
  2626000000: "부산광역시 동래구",
  2626010100: "부산광역시 동래구 명장동",
  2626010200: "부산광역시 동래구 안락동",
  2626010300: "부산광역시 동래구 칠산동",
  2626010400: "부산광역시 동래구 낙민동",
  2626010500: "부산광역시 동래구 복천동",
  2626010600: "부산광역시 동래구 수안동",
  2626010700: "부산광역시 동래구 명륜동",
  2626010800: "부산광역시 동래구 온천동",
  2626010900: "부산광역시 동래구 사직동",
  2629000000: "부산광역시 남구",
  2629010600: "부산광역시 남구 대연동",
  2629010700: "부산광역시 남구 용호동",
  2629010800: "부산광역시 남구 용당동",
  2629010900: "부산광역시 남구 문현동",
  2629011000: "부산광역시 남구 우암동",
  2629011100: "부산광역시 남구 감만동",
  2632000000: "부산광역시 북구",
  2632010100: "부산광역시 북구 금곡동",
  2632010200: "부산광역시 북구 화명동",
  2632010300: "부산광역시 북구 만덕동",
  2632010400: "부산광역시 북구 덕천동",
  2632010500: "부산광역시 북구 구포동",
  2635000000: "부산광역시 해운대구",
  2635010100: "부산광역시 해운대구 반송동",
  2635010200: "부산광역시 해운대구 석대동",
  2635010300: "부산광역시 해운대구 반여동",
  2635010400: "부산광역시 해운대구 재송동",
  2635010500: "부산광역시 해운대구 우동",
  2635010600: "부산광역시 해운대구 중동",
  2635010700: "부산광역시 해운대구 좌동",
  2635010800: "부산광역시 해운대구 송정동",
  2638000000: "부산광역시 사하구",
  2638010100: "부산광역시 사하구 괴정동",
  2638010200: "부산광역시 사하구 당리동",
  2638010300: "부산광역시 사하구 하단동",
  2638010400: "부산광역시 사하구 신평동",
  2638010500: "부산광역시 사하구 장림동",
  2638010600: "부산광역시 사하구 다대동",
  2638010700: "부산광역시 사하구 구평동",
  2638010800: "부산광역시 사하구 감천동",
  2641000000: "부산광역시 금정구",
  2641010100: "부산광역시 금정구 두구동",
  2641010200: "부산광역시 금정구 노포동",
  2641010300: "부산광역시 금정구 청룡동",
  2641010400: "부산광역시 금정구 남산동",
  2641010500: "부산광역시 금정구 선동",
  2641010600: "부산광역시 금정구 오륜동",
  2641010700: "부산광역시 금정구 구서동",
  2641010800: "부산광역시 금정구 장전동",
  2641010900: "부산광역시 금정구 부곡동",
  2641011000: "부산광역시 금정구 서동",
  2641011100: "부산광역시 금정구 금사동",
  2641011200: "부산광역시 금정구 회동동",
  2641011300: "부산광역시 금정구 금성동",
  2644000000: "부산광역시 강서구",
  2644010100: "부산광역시 강서구 대저1동",
  2644010200: "부산광역시 강서구 대저2동",
  2644010300: "부산광역시 강서구 강동동",
  2644010400: "부산광역시 강서구 명지동",
  2644010500: "부산광역시 강서구 죽림동",
  2644010600: "부산광역시 강서구 식만동",
  2644010700: "부산광역시 강서구 죽동동",
  2644010800: "부산광역시 강서구 봉림동",
  2644010900: "부산광역시 강서구 송정동",
  2644011000: "부산광역시 강서구 화전동",
  2644011100: "부산광역시 강서구 녹산동",
  2644011200: "부산광역시 강서구 생곡동",
  2644011300: "부산광역시 강서구 구랑동",
  2644011400: "부산광역시 강서구 지사동",
  2644011500: "부산광역시 강서구 미음동",
  2644011600: "부산광역시 강서구 범방동",
  2644011700: "부산광역시 강서구 신호동",
  2644011800: "부산광역시 강서구 동선동",
  2644011900: "부산광역시 강서구 성북동",
  2644012000: "부산광역시 강서구 눌차동",
  2644012100: "부산광역시 강서구 천성동",
  2644012200: "부산광역시 강서구 대항동",
  2647000000: "부산광역시 연제구",
  2647010100: "부산광역시 연제구 거제동",
  2647010200: "부산광역시 연제구 연산동",
  2650000000: "부산광역시 수영구",
  2650010100: "부산광역시 수영구 망미동",
  2650010200: "부산광역시 수영구 수영동",
  2650010300: "부산광역시 수영구 민락동",
  2650010400: "부산광역시 수영구 광안동",
  2650010500: "부산광역시 수영구 남천동",
  2653000000: "부산광역시 사상구",
  2653010100: "부산광역시 사상구 삼락동",
  2653010200: "부산광역시 사상구 모라동",
  2653010300: "부산광역시 사상구 덕포동",
  2653010400: "부산광역시 사상구 괘법동",
  2653010500: "부산광역시 사상구 감전동",
  2653010600: "부산광역시 사상구 주례동",
  2653010700: "부산광역시 사상구 학장동",
  2653010800: "부산광역시 사상구 엄궁동",
  2671000000: "부산광역시 기장군",
  2671025000: "부산광역시 기장군 기장읍",
  2671025300: "부산광역시 기장군 장안읍",
  2671025600: "부산광역시 기장군 정관읍",
  2671025900: "부산광역시 기장군 일광읍",
  2671033000: "부산광역시 기장군 철마면",
  2700000000: "대구광역시",
  2711000000: "대구광역시 중구",
  2711010100: "대구광역시 중구 동인동1가",
  2711010200: "대구광역시 중구 동인동2가",
  2711010300: "대구광역시 중구 동인동3가",
  2711010400: "대구광역시 중구 동인동4가",
  2711010500: "대구광역시 중구 삼덕동1가",
  2711010600: "대구광역시 중구 삼덕동2가",
  2711010700: "대구광역시 중구 삼덕동3가",
  2711010800: "대구광역시 중구 봉산동",
  2711010900: "대구광역시 중구 장관동",
  2711011000: "대구광역시 중구 상서동",
  2711011100: "대구광역시 중구 수동",
  2711011200: "대구광역시 중구 덕산동",
  2711011300: "대구광역시 중구 종로1가",
  2711011400: "대구광역시 중구 종로2가",
  2711011500: "대구광역시 중구 사일동",
  2711011600: "대구광역시 중구 동일동",
  2711011700: "대구광역시 중구 남일동",
  2711011800: "대구광역시 중구 전동",
  2711011900: "대구광역시 중구 동성로3가",
  2711012000: "대구광역시 중구 동문동",
  2711012100: "대구광역시 중구 문화동",
  2711012200: "대구광역시 중구 공평동",
  2711012300: "대구광역시 중구 동성로2가",
  2711012400: "대구광역시 중구 태평로1가",
  2711012500: "대구광역시 중구 교동",
  2711012600: "대구광역시 중구 용덕동",
  2711012700: "대구광역시 중구 상덕동",
  2711012800: "대구광역시 중구 완전동",
  2711012900: "대구광역시 중구 도원동",
  2711013000: "대구광역시 중구 수창동",
  2711013100: "대구광역시 중구 태평로3가",
  2711013200: "대구광역시 중구 인교동",
  2711013300: "대구광역시 중구 서야동",
  2711013400: "대구광역시 중구 서성로1가",
  2711013500: "대구광역시 중구 시장북로",
  2711013600: "대구광역시 중구 하서동",
  2711013700: "대구광역시 중구 남성로",
  2711013800: "대구광역시 중구 계산동1가",
  2711013900: "대구광역시 중구 계산동2가",
  2711014000: "대구광역시 중구 동산동",
  2711014100: "대구광역시 중구 서문로2가",
  2711014200: "대구광역시 중구 서성로2가",
  2711014300: "대구광역시 중구 포정동",
  2711014400: "대구광역시 중구 서문로1가",
  2711014500: "대구광역시 중구 서내동",
  2711014600: "대구광역시 중구 북성로2가",
  2711014700: "대구광역시 중구 대안동",
  2711014800: "대구광역시 중구 동성로1가",
  2711014900: "대구광역시 중구 태평로2가",
  2711015000: "대구광역시 중구 북성로1가",
  2711015100: "대구광역시 중구 화전동",
  2711015200: "대구광역시 중구 향촌동",
  2711015300: "대구광역시 중구 북내동",
  2711015400: "대구광역시 중구 대신동",
  2711015500: "대구광역시 중구 달성동",
  2711015600: "대구광역시 중구 남산동",
  2711015700: "대구광역시 중구 대봉동",
  2714000000: "대구광역시 동구",
  2714010100: "대구광역시 동구 신암동",
  2714010200: "대구광역시 동구 신천동",
  2714010300: "대구광역시 동구 효목동",
  2714010400: "대구광역시 동구 평광동",
  2714010500: "대구광역시 동구 봉무동",
  2714010600: "대구광역시 동구 불로동",
  2714010700: "대구광역시 동구 도동",
  2714010800: "대구광역시 동구 지저동",
  2714010900: "대구광역시 동구 입석동",
  2714011000: "대구광역시 동구 검사동",
  2714011100: "대구광역시 동구 방촌동",
  2714011200: "대구광역시 동구 둔산동",
  2714011300: "대구광역시 동구 부동",
  2714011400: "대구광역시 동구 신평동",
  2714011500: "대구광역시 동구 서호동",
  2714011600: "대구광역시 동구 동호동",
  2714011700: "대구광역시 동구 신기동",
  2714011800: "대구광역시 동구 율하동",
  2714011900: "대구광역시 동구 용계동",
  2714012000: "대구광역시 동구 율암동",
  2714012100: "대구광역시 동구 상매동",
  2714012200: "대구광역시 동구 매여동",
  2714012300: "대구광역시 동구 각산동",
  2714012400: "대구광역시 동구 신서동",
  2714012500: "대구광역시 동구 동내동",
  2714012600: "대구광역시 동구 괴전동",
  2714012700: "대구광역시 동구 금강동",
  2714012800: "대구광역시 동구 대림동",
  2714012900: "대구광역시 동구 사복동",
  2714013000: "대구광역시 동구 숙천동",
  2714013100: "대구광역시 동구 내곡동",
  2714013200: "대구광역시 동구 능성동",
  2714013300: "대구광역시 동구 진인동",
  2714013400: "대구광역시 동구 도학동",
  2714013500: "대구광역시 동구 백안동",
  2714013600: "대구광역시 동구 미곡동",
  2714013700: "대구광역시 동구 용수동",
  2714013800: "대구광역시 동구 신무동",
  2714013900: "대구광역시 동구 미대동",
  2714014000: "대구광역시 동구 내동",
  2714014100: "대구광역시 동구 신용동",
  2714014200: "대구광역시 동구 중대동",
  2714014300: "대구광역시 동구 송정동",
  2714014400: "대구광역시 동구 덕곡동",
  2714014500: "대구광역시 동구 지묘동",
  2717000000: "대구광역시 서구",
  2717010100: "대구광역시 서구 내당동",
  2717010200: "대구광역시 서구 비산동",
  2717010300: "대구광역시 서구 평리동",
  2717010400: "대구광역시 서구 상리동",
  2717010500: "대구광역시 서구 중리동",
  2717010600: "대구광역시 서구 이현동",
  2717010700: "대구광역시 서구 원대동1가",
  2717010800: "대구광역시 서구 원대동2가",
  2717010900: "대구광역시 서구 원대동3가",
  2720000000: "대구광역시 남구",
  2720010100: "대구광역시 남구 이천동",
  2720010200: "대구광역시 남구 봉덕동",
  2720010300: "대구광역시 남구 대명동",
  2723000000: "대구광역시 북구",
  2723010100: "대구광역시 북구 칠성동1가",
  2723010200: "대구광역시 북구 칠성동2가",
  2723010300: "대구광역시 북구 고성동1가",
  2723010400: "대구광역시 북구 고성동2가",
  2723010500: "대구광역시 북구 고성동3가",
  2723010600: "대구광역시 북구 침산동",
  2723010700: "대구광역시 북구 노원동1가",
  2723010800: "대구광역시 북구 노원동2가",
  2723010900: "대구광역시 북구 노원동3가",
  2723011000: "대구광역시 북구 대현동",
  2723011100: "대구광역시 북구 산격동",
  2723011200: "대구광역시 북구 복현동",
  2723011300: "대구광역시 북구 검단동",
  2723011400: "대구광역시 북구 동변동",
  2723011500: "대구광역시 북구 서변동",
  2723011600: "대구광역시 북구 조야동",
  2723011700: "대구광역시 북구 노곡동",
  2723011800: "대구광역시 북구 읍내동",
  2723011900: "대구광역시 북구 동호동",
  2723012000: "대구광역시 북구 학정동",
  2723012100: "대구광역시 북구 도남동",
  2723012200: "대구광역시 북구 국우동",
  2723012300: "대구광역시 북구 구암동",
  2723012400: "대구광역시 북구 동천동",
  2723012500: "대구광역시 북구 관음동",
  2723012600: "대구광역시 북구 태전동",
  2723012700: "대구광역시 북구 매천동",
  2723012800: "대구광역시 북구 팔달동",
  2723012900: "대구광역시 북구 금호동",
  2723013000: "대구광역시 북구 사수동",
  2723013100: "대구광역시 북구 연경동",
  2726000000: "대구광역시 수성구",
  2726010100: "대구광역시 수성구 범어동",
  2726010200: "대구광역시 수성구 만촌동",
  2726010300: "대구광역시 수성구 수성동1가",
  2726010400: "대구광역시 수성구 수성동2가",
  2726010500: "대구광역시 수성구 수성동3가",
  2726010600: "대구광역시 수성구 수성동4가",
  2726010700: "대구광역시 수성구 황금동",
  2726010800: "대구광역시 수성구 중동",
  2726010900: "대구광역시 수성구 상동",
  2726011000: "대구광역시 수성구 파동",
  2726011100: "대구광역시 수성구 두산동",
  2726011200: "대구광역시 수성구 지산동",
  2726011300: "대구광역시 수성구 범물동",
  2726011400: "대구광역시 수성구 시지동",
  2726011500: "대구광역시 수성구 매호동",
  2726011600: "대구광역시 수성구 성동",
  2726011700: "대구광역시 수성구 사월동",
  2726011800: "대구광역시 수성구 신매동",
  2726011900: "대구광역시 수성구 욱수동",
  2726012000: "대구광역시 수성구 노변동",
  2726012200: "대구광역시 수성구 삼덕동",
  2726012300: "대구광역시 수성구 연호동",
  2726012400: "대구광역시 수성구 이천동",
  2726012500: "대구광역시 수성구 고모동",
  2726012600: "대구광역시 수성구 가천동",
  2726012700: "대구광역시 수성구 대흥동",
  2729000000: "대구광역시 달서구",
  2729010100: "대구광역시 달서구 성당동",
  2729010200: "대구광역시 달서구 두류동",
  2729010400: "대구광역시 달서구 파호동",
  2729010500: "대구광역시 달서구 호림동",
  2729010600: "대구광역시 달서구 갈산동",
  2729010700: "대구광역시 달서구 신당동",
  2729010800: "대구광역시 달서구 이곡동",
  2729010900: "대구광역시 달서구 장동",
  2729011000: "대구광역시 달서구 장기동",
  2729011100: "대구광역시 달서구 용산동",
  2729011200: "대구광역시 달서구 죽전동",
  2729011300: "대구광역시 달서구 감삼동",
  2729011400: "대구광역시 달서구 본리동",
  2729011500: "대구광역시 달서구 상인동",
  2729011600: "대구광역시 달서구 도원동",
  2729011700: "대구광역시 달서구 진천동",
  2729011800: "대구광역시 달서구 유천동",
  2729011900: "대구광역시 달서구 대천동",
  2729012000: "대구광역시 달서구 월성동",
  2729012100: "대구광역시 달서구 월암동",
  2729012200: "대구광역시 달서구 송현동",
  2729012300: "대구광역시 달서구 대곡동",
  2729012400: "대구광역시 달서구 본동",
  2729012500: "대구광역시 달서구 호산동",
  2771000000: "대구광역시 달성군",
  2771025000: "대구광역시 달성군 화원읍",
  2771025300: "대구광역시 달성군 논공읍",
  2771025600: "대구광역시 달성군 다사읍",
  2771025900: "대구광역시 달성군 유가읍",
  2771026200: "대구광역시 달성군 옥포읍",
  2771026500: "대구광역시 달성군 현풍읍",
  2771031000: "대구광역시 달성군 가창면",
  2771033000: "대구광역시 달성군 하빈면",
  2771038000: "대구광역시 달성군 구지면",
  2800000000: "인천광역시",
  2811000000: "인천광역시 중구",
  2811010100: "인천광역시 중구 중앙동1가",
  2811010200: "인천광역시 중구 중앙동2가",
  2811010300: "인천광역시 중구 중앙동3가",
  2811010400: "인천광역시 중구 중앙동4가",
  2811010500: "인천광역시 중구 해안동1가",
  2811010600: "인천광역시 중구 해안동2가",
  2811010700: "인천광역시 중구 해안동3가",
  2811010800: "인천광역시 중구 해안동4가",
  2811010900: "인천광역시 중구 관동1가",
  2811011000: "인천광역시 중구 관동2가",
  2811011100: "인천광역시 중구 관동3가",
  2811011200: "인천광역시 중구 항동1가",
  2811011300: "인천광역시 중구 항동2가",
  2811011400: "인천광역시 중구 항동3가",
  2811011500: "인천광역시 중구 항동4가",
  2811011600: "인천광역시 중구 항동5가",
  2811011700: "인천광역시 중구 항동6가",
  2811011800: "인천광역시 중구 항동7가",
  2811011900: "인천광역시 중구 송학동1가",
  2811012000: "인천광역시 중구 송학동2가",
  2811012100: "인천광역시 중구 송학동3가",
  2811012200: "인천광역시 중구 사동",
  2811012300: "인천광역시 중구 신생동",
  2811012400: "인천광역시 중구 신포동",
  2811012500: "인천광역시 중구 답동",
  2811012600: "인천광역시 중구 신흥동1가",
  2811012700: "인천광역시 중구 신흥동2가",
  2811012800: "인천광역시 중구 신흥동3가",
  2811012900: "인천광역시 중구 선화동",
  2811013000: "인천광역시 중구 유동",
  2811013100: "인천광역시 중구 율목동",
  2811013200: "인천광역시 중구 도원동",
  2811013300: "인천광역시 중구 내동",
  2811013400: "인천광역시 중구 경동",
  2811013500: "인천광역시 중구 용동",
  2811013600: "인천광역시 중구 인현동",
  2811013700: "인천광역시 중구 전동",
  2811013800: "인천광역시 중구 북성동1가",
  2811013900: "인천광역시 중구 북성동2가",
  2811014000: "인천광역시 중구 북성동3가",
  2811014100: "인천광역시 중구 선린동",
  2811014200: "인천광역시 중구 송월동1가",
  2811014300: "인천광역시 중구 송월동2가",
  2811014400: "인천광역시 중구 송월동3가",
  2811014500: "인천광역시 중구 중산동",
  2811014600: "인천광역시 중구 운남동",
  2811014700: "인천광역시 중구 운서동",
  2811014800: "인천광역시 중구 운북동",
  2811014900: "인천광역시 중구 을왕동",
  2811015000: "인천광역시 중구 남북동",
  2811015100: "인천광역시 중구 덕교동",
  2811015200: "인천광역시 중구 무의동",
  2814000000: "인천광역시 동구",
  2814010100: "인천광역시 동구 만석동",
  2814010200: "인천광역시 동구 화수동",
  2814010300: "인천광역시 동구 송현동",
  2814010400: "인천광역시 동구 화평동",
  2814010500: "인천광역시 동구 창영동",
  2814010600: "인천광역시 동구 금곡동",
  2814010700: "인천광역시 동구 송림동",
  2817700000: "인천광역시 미추홀구",
  2817710100: "인천광역시 미추홀구 숭의동",
  2817710200: "인천광역시 미추홀구 용현동",
  2817710300: "인천광역시 미추홀구 학익동",
  2817710400: "인천광역시 미추홀구 도화동",
  2817710500: "인천광역시 미추홀구 주안동",
  2817710600: "인천광역시 미추홀구 관교동",
  2817710700: "인천광역시 미추홀구 문학동",
  2818500000: "인천광역시 연수구",
  2818510100: "인천광역시 연수구 옥련동",
  2818510200: "인천광역시 연수구 선학동",
  2818510300: "인천광역시 연수구 연수동",
  2818510400: "인천광역시 연수구 청학동",
  2818510500: "인천광역시 연수구 동춘동",
  2818510600: "인천광역시 연수구 송도동",
  2820000000: "인천광역시 남동구",
  2820010100: "인천광역시 남동구 구월동",
  2820010200: "인천광역시 남동구 간석동",
  2820010300: "인천광역시 남동구 만수동",
  2820010400: "인천광역시 남동구 장수동",
  2820010500: "인천광역시 남동구 서창동",
  2820010600: "인천광역시 남동구 운연동",
  2820010700: "인천광역시 남동구 남촌동",
  2820010800: "인천광역시 남동구 수산동",
  2820010900: "인천광역시 남동구 도림동",
  2820011000: "인천광역시 남동구 논현동",
  2820011100: "인천광역시 남동구 고잔동",
  2823700000: "인천광역시 부평구",
  2823710100: "인천광역시 부평구 부평동",
  2823710200: "인천광역시 부평구 십정동",
  2823710300: "인천광역시 부평구 산곡동",
  2823710400: "인천광역시 부평구 청천동",
  2823710500: "인천광역시 부평구 삼산동",
  2823710600: "인천광역시 부평구 갈산동",
  2823710700: "인천광역시 부평구 부개동",
  2823710800: "인천광역시 부평구 일신동",
  2823710900: "인천광역시 부평구 구산동",
  2824500000: "인천광역시 계양구",
  2824510100: "인천광역시 계양구 효성동",
  2824510200: "인천광역시 계양구 계산동",
  2824510300: "인천광역시 계양구 작전동",
  2824510400: "인천광역시 계양구 서운동",
  2824510500: "인천광역시 계양구 임학동",
  2824510600: "인천광역시 계양구 용종동",
  2824510700: "인천광역시 계양구 병방동",
  2824510800: "인천광역시 계양구 방축동",
  2824510900: "인천광역시 계양구 박촌동",
  2824511000: "인천광역시 계양구 동양동",
  2824511100: "인천광역시 계양구 귤현동",
  2824511200: "인천광역시 계양구 상야동",
  2824511300: "인천광역시 계양구 하야동",
  2824511400: "인천광역시 계양구 평동",
  2824511500: "인천광역시 계양구 노오지동",
  2824511600: "인천광역시 계양구 선주지동",
  2824511700: "인천광역시 계양구 이화동",
  2824511800: "인천광역시 계양구 오류동",
  2824511900: "인천광역시 계양구 갈현동",
  2824512000: "인천광역시 계양구 둑실동",
  2824512100: "인천광역시 계양구 목상동",
  2824512200: "인천광역시 계양구 다남동",
  2824512300: "인천광역시 계양구 장기동",
  2826000000: "인천광역시 서구",
  2826010100: "인천광역시 서구 백석동",
  2826010200: "인천광역시 서구 시천동",
  2826010300: "인천광역시 서구 검암동",
  2826010400: "인천광역시 서구 경서동",
  2826010500: "인천광역시 서구 공촌동",
  2826010600: "인천광역시 서구 연희동",
  2826010700: "인천광역시 서구 심곡동",
  2826010800: "인천광역시 서구 가정동",
  2826010900: "인천광역시 서구 신현동",
  2826011000: "인천광역시 서구 석남동",
  2826011100: "인천광역시 서구 원창동",
  2826011200: "인천광역시 서구 가좌동",
  2826011300: "인천광역시 서구 마전동",
  2826011400: "인천광역시 서구 당하동",
  2826011500: "인천광역시 서구 원당동",
  2826011700: "인천광역시 서구 대곡동",
  2826011800: "인천광역시 서구 금곡동",
  2826011900: "인천광역시 서구 오류동",
  2826012000: "인천광역시 서구 왕길동",
  2826012100: "인천광역시 서구 불로동",
  2826012200: "인천광역시 서구 청라동",
  2871000000: "인천광역시 강화군",
  2871025000: "인천광역시 강화군 강화읍",
  2871031000: "인천광역시 강화군 선원면",
  2871032000: "인천광역시 강화군 불은면",
  2871033000: "인천광역시 강화군 길상면",
  2871034000: "인천광역시 강화군 화도면",
  2871035000: "인천광역시 강화군 양도면",
  2871036000: "인천광역시 강화군 내가면",
  2871037000: "인천광역시 강화군 하점면",
  2871038000: "인천광역시 강화군 양사면",
  2871039000: "인천광역시 강화군 송해면",
  2871040000: "인천광역시 강화군 교동면",
  2871041000: "인천광역시 강화군 삼산면",
  2871042000: "인천광역시 강화군 서도면",
  2872000000: "인천광역시 옹진군",
  2872031000: "인천광역시 옹진군 북도면",
  2872033000: "인천광역시 옹진군 백령면",
  2872034000: "인천광역시 옹진군 대청면",
  2872035000: "인천광역시 옹진군 덕적면",
  2872036000: "인천광역시 옹진군 영흥면",
  2872037000: "인천광역시 옹진군 자월면",
  2872038000: "인천광역시 옹진군 연평면",
  2900000000: "광주광역시",
  2911000000: "광주광역시 동구",
  2911010100: "광주광역시 동구 대인동",
  2911010200: "광주광역시 동구 금남로5가",
  2911010300: "광주광역시 동구 충장로5가",
  2911010400: "광주광역시 동구 수기동",
  2911010500: "광주광역시 동구 대의동",
  2911010600: "광주광역시 동구 궁동",
  2911010700: "광주광역시 동구 장동",
  2911010800: "광주광역시 동구 동명동",
  2911010900: "광주광역시 동구 계림동",
  2911011000: "광주광역시 동구 산수동",
  2911011100: "광주광역시 동구 지산동",
  2911011200: "광주광역시 동구 남동",
  2911011300: "광주광역시 동구 광산동",
  2911011400: "광주광역시 동구 금동",
  2911011500: "광주광역시 동구 호남동",
  2911011600: "광주광역시 동구 불로동",
  2911011700: "광주광역시 동구 황금동",
  2911011800: "광주광역시 동구 서석동",
  2911011900: "광주광역시 동구 소태동",
  2911012000: "광주광역시 동구 용연동",
  2911012100: "광주광역시 동구 운림동",
  2911012200: "광주광역시 동구 학동",
  2911012300: "광주광역시 동구 월남동",
  2911012400: "광주광역시 동구 선교동",
  2911012500: "광주광역시 동구 내남동",
  2911012600: "광주광역시 동구 용산동",
  2911012700: "광주광역시 동구 충장로1가",
  2911012800: "광주광역시 동구 충장로2가",
  2911012900: "광주광역시 동구 충장로3가",
  2911013000: "광주광역시 동구 충장로4가",
  2911013100: "광주광역시 동구 금남로1가",
  2911013200: "광주광역시 동구 금남로2가",
  2911013300: "광주광역시 동구 금남로3가",
  2911013400: "광주광역시 동구 금남로4가",
  2914000000: "광주광역시 서구",
  2914010400: "광주광역시 서구 양동",
  2914010600: "광주광역시 서구 농성동",
  2914011500: "광주광역시 서구 광천동",
  2914011600: "광주광역시 서구 유촌동",
  2914011700: "광주광역시 서구 덕흥동",
  2914011800: "광주광역시 서구 쌍촌동",
  2914011900: "광주광역시 서구 화정동",
  2914012000: "광주광역시 서구 치평동",
  2914012100: "광주광역시 서구 내방동",
  2914012500: "광주광역시 서구 서창동",
  2914012600: "광주광역시 서구 세하동",
  2914012700: "광주광역시 서구 용두동",
  2914012800: "광주광역시 서구 풍암동",
  2914012900: "광주광역시 서구 벽진동",
  2914013000: "광주광역시 서구 금호동",
  2914013100: "광주광역시 서구 마륵동",
  2914013200: "광주광역시 서구 매월동",
  2914013300: "광주광역시 서구 동천동",
  2915500000: "광주광역시 남구",
  2915510100: "광주광역시 남구 사동",
  2915510200: "광주광역시 남구 구동",
  2915510300: "광주광역시 남구 서동",
  2915510400: "광주광역시 남구 월산동",
  2915510500: "광주광역시 남구 백운동",
  2915510600: "광주광역시 남구 주월동",
  2915510700: "광주광역시 남구 노대동",
  2915510800: "광주광역시 남구 진월동",
  2915510900: "광주광역시 남구 덕남동",
  2915511000: "광주광역시 남구 행암동",
  2915511100: "광주광역시 남구 임암동",
  2915511200: "광주광역시 남구 송하동",
  2915511300: "광주광역시 남구 양림동",
  2915511400: "광주광역시 남구 방림동",
  2915511500: "광주광역시 남구 봉선동",
  2915511600: "광주광역시 남구 구소동",
  2915511700: "광주광역시 남구 양촌동",
  2915511800: "광주광역시 남구 도금동",
  2915511900: "광주광역시 남구 승촌동",
  2915512000: "광주광역시 남구 지석동",
  2915512100: "광주광역시 남구 압촌동",
  2915512200: "광주광역시 남구 화장동",
  2915512300: "광주광역시 남구 칠석동",
  2915512400: "광주광역시 남구 석정동",
  2915512500: "광주광역시 남구 신장동",
  2915512600: "광주광역시 남구 양과동",
  2915512700: "광주광역시 남구 이장동",
  2915512800: "광주광역시 남구 대지동",
  2915512900: "광주광역시 남구 원산동",
  2915513000: "광주광역시 남구 월성동",
  2917000000: "광주광역시 북구",
  2917010100: "광주광역시 북구 중흥동",
  2917010200: "광주광역시 북구 유동",
  2917010300: "광주광역시 북구 누문동",
  2917010400: "광주광역시 북구 북동",
  2917010500: "광주광역시 북구 임동",
  2917010600: "광주광역시 북구 신안동",
  2917010700: "광주광역시 북구 용봉동",
  2917010800: "광주광역시 북구 동림동",
  2917010900: "광주광역시 북구 운암동",
  2917011000: "광주광역시 북구 우산동",
  2917011100: "광주광역시 북구 풍향동",
  2917011200: "광주광역시 북구 문흥동",
  2917011300: "광주광역시 북구 각화동",
  2917011400: "광주광역시 북구 두암동",
  2917011500: "광주광역시 북구 오치동",
  2917011600: "광주광역시 북구 삼각동",
  2917011700: "광주광역시 북구 매곡동",
  2917011800: "광주광역시 북구 충효동",
  2917011900: "광주광역시 북구 덕의동",
  2917012000: "광주광역시 북구 금곡동",
  2917012100: "광주광역시 북구 망월동",
  2917012200: "광주광역시 북구 청풍동",
  2917012300: "광주광역시 북구 화암동",
  2917012400: "광주광역시 북구 장등동",
  2917012500: "광주광역시 북구 운정동",
  2917012600: "광주광역시 북구 본촌동",
  2917012700: "광주광역시 북구 일곡동",
  2917012800: "광주광역시 북구 양산동",
  2917012900: "광주광역시 북구 연제동",
  2917013000: "광주광역시 북구 신용동",
  2917013100: "광주광역시 북구 용두동",
  2917013200: "광주광역시 북구 지야동",
  2917013300: "광주광역시 북구 태령동",
  2917013400: "광주광역시 북구 수곡동",
  2917013500: "광주광역시 북구 효령동",
  2917013600: "광주광역시 북구 용전동",
  2917013700: "광주광역시 북구 용강동",
  2917013800: "광주광역시 북구 생용동",
  2917013900: "광주광역시 북구 월출동",
  2917014000: "광주광역시 북구 대촌동",
  2917014100: "광주광역시 북구 오룡동",
  2920000000: "광주광역시 광산구",
  2920010100: "광주광역시 광산구 송정동",
  2920010200: "광주광역시 광산구 도산동",
  2920010300: "광주광역시 광산구 도호동",
  2920010400: "광주광역시 광산구 신촌동",
  2920010500: "광주광역시 광산구 서봉동",
  2920010600: "광주광역시 광산구 운수동",
  2920010700: "광주광역시 광산구 선암동",
  2920010800: "광주광역시 광산구 소촌동",
  2920010900: "광주광역시 광산구 우산동",
  2920011000: "광주광역시 광산구 황룡동",
  2920011100: "광주광역시 광산구 박호동",
  2920011200: "광주광역시 광산구 비아동",
  2920011300: "광주광역시 광산구 도천동",
  2920011400: "광주광역시 광산구 수완동",
  2920011500: "광주광역시 광산구 월계동",
  2920011600: "광주광역시 광산구 쌍암동",
  2920011700: "광주광역시 광산구 산월동",
  2920011800: "광주광역시 광산구 신창동",
  2920011900: "광주광역시 광산구 신가동",
  2920012000: "광주광역시 광산구 운남동",
  2920012100: "광주광역시 광산구 안청동",
  2920012200: "광주광역시 광산구 진곡동",
  2920012300: "광주광역시 광산구 장덕동",
  2920012400: "광주광역시 광산구 흑석동",
  2920012500: "광주광역시 광산구 하남동",
  2920012600: "광주광역시 광산구 장수동",
  2920012700: "광주광역시 광산구 산정동",
  2920012800: "광주광역시 광산구 월곡동",
  2920012900: "광주광역시 광산구 등임동",
  2920013000: "광주광역시 광산구 산막동",
  2920013100: "광주광역시 광산구 고룡동",
  2920013200: "광주광역시 광산구 신룡동",
  2920013300: "광주광역시 광산구 두정동",
  2920013400: "광주광역시 광산구 임곡동",
  2920013500: "광주광역시 광산구 광산동",
  2920013600: "광주광역시 광산구 오산동",
  2920013700: "광주광역시 광산구 사호동",
  2920013800: "광주광역시 광산구 하산동",
  2920013900: "광주광역시 광산구 유계동",
  2920014000: "광주광역시 광산구 본덕동",
  2920014100: "광주광역시 광산구 용봉동",
  2920014200: "광주광역시 광산구 요기동",
  2920014300: "광주광역시 광산구 복룡동",
  2920014400: "광주광역시 광산구 송대동",
  2920014500: "광주광역시 광산구 옥동",
  2920014600: "광주광역시 광산구 월전동",
  2920014700: "광주광역시 광산구 장록동",
  2920014800: "광주광역시 광산구 송촌동",
  2920014900: "광주광역시 광산구 지죽동",
  2920015000: "광주광역시 광산구 용동",
  2920015100: "광주광역시 광산구 용곡동",
  2920015200: "광주광역시 광산구 지정동",
  2920015300: "광주광역시 광산구 명화동",
  2920015400: "광주광역시 광산구 동산동",
  2920015500: "광주광역시 광산구 연산동",
  2920015600: "광주광역시 광산구 도덕동",
  2920015700: "광주광역시 광산구 송산동",
  2920015800: "광주광역시 광산구 지평동",
  2920015900: "광주광역시 광산구 오운동",
  2920016000: "광주광역시 광산구 삼거동",
  2920016100: "광주광역시 광산구 양동",
  2920016200: "광주광역시 광산구 내산동",
  2920016300: "광주광역시 광산구 대산동",
  2920016400: "광주광역시 광산구 송학동",
  2920016500: "광주광역시 광산구 신동",
  2920016600: "광주광역시 광산구 삼도동",
  2920016700: "광주광역시 광산구 남산동",
  2920016800: "광주광역시 광산구 송치동",
  2920016900: "광주광역시 광산구 산수동",
  2920017000: "광주광역시 광산구 선동",
  2920017100: "광주광역시 광산구 지산동",
  2920017200: "광주광역시 광산구 왕동",
  2920017300: "광주광역시 광산구 북산동",
  2920017400: "광주광역시 광산구 명도동",
  2920017500: "광주광역시 광산구 동호동",
  2920017600: "광주광역시 광산구 덕림동",
  2920017700: "광주광역시 광산구 양산동",
  2920017800: "광주광역시 광산구 동림동",
  2920020200: "광주광역시 광산구 오선동",
  3000000000: "대전광역시",
  3011000000: "대전광역시 동구",
  3011010100: "대전광역시 동구 원동",
  3011010200: "대전광역시 동구 인동",
  3011010300: "대전광역시 동구 효동",
  3011010400: "대전광역시 동구 천동",
  3011010500: "대전광역시 동구 가오동",
  3011010600: "대전광역시 동구 신흥동",
  3011010700: "대전광역시 동구 판암동",
  3011010800: "대전광역시 동구 삼정동",
  3011010900: "대전광역시 동구 용운동",
  3011011000: "대전광역시 동구 대동",
  3011011100: "대전광역시 동구 자양동",
  3011011200: "대전광역시 동구 신안동",
  3011011300: "대전광역시 동구 소제동",
  3011011400: "대전광역시 동구 가양동",
  3011011500: "대전광역시 동구 용전동",
  3011011600: "대전광역시 동구 성남동",
  3011011700: "대전광역시 동구 홍도동",
  3011011800: "대전광역시 동구 삼성동",
  3011011900: "대전광역시 동구 정동",
  3011012000: "대전광역시 동구 중동",
  3011012100: "대전광역시 동구 추동",
  3011012200: "대전광역시 동구 비룡동",
  3011012300: "대전광역시 동구 주산동",
  3011012400: "대전광역시 동구 용계동",
  3011012500: "대전광역시 동구 마산동",
  3011012600: "대전광역시 동구 효평동",
  3011012700: "대전광역시 동구 직동",
  3011012800: "대전광역시 동구 세천동",
  3011012900: "대전광역시 동구 신상동",
  3011013000: "대전광역시 동구 신하동",
  3011013100: "대전광역시 동구 신촌동",
  3011013200: "대전광역시 동구 사성동",
  3011013300: "대전광역시 동구 내탑동",
  3011013400: "대전광역시 동구 오동",
  3011013500: "대전광역시 동구 주촌동",
  3011013600: "대전광역시 동구 낭월동",
  3011013700: "대전광역시 동구 대별동",
  3011013800: "대전광역시 동구 이사동",
  3011013900: "대전광역시 동구 대성동",
  3011014000: "대전광역시 동구 장척동",
  3011014100: "대전광역시 동구 소호동",
  3011014200: "대전광역시 동구 구도동",
  3011014300: "대전광역시 동구 삼괴동",
  3011014400: "대전광역시 동구 상소동",
  3011014500: "대전광역시 동구 하소동",
  3014000000: "대전광역시 중구",
  3014010100: "대전광역시 중구 은행동",
  3014010200: "대전광역시 중구 선화동",
  3014010300: "대전광역시 중구 목동",
  3014010400: "대전광역시 중구 중촌동",
  3014010500: "대전광역시 중구 대흥동",
  3014010600: "대전광역시 중구 문창동",
  3014010700: "대전광역시 중구 석교동",
  3014010800: "대전광역시 중구 호동",
  3014010900: "대전광역시 중구 옥계동",
  3014011000: "대전광역시 중구 대사동",
  3014011100: "대전광역시 중구 부사동",
  3014011200: "대전광역시 중구 용두동",
  3014011300: "대전광역시 중구 오류동",
  3014011400: "대전광역시 중구 태평동",
  3014011500: "대전광역시 중구 유천동",
  3014011600: "대전광역시 중구 문화동",
  3014011700: "대전광역시 중구 산성동",
  3014011800: "대전광역시 중구 사정동",
  3014011900: "대전광역시 중구 안영동",
  3014012000: "대전광역시 중구 무수동",
  3014012100: "대전광역시 중구 구완동",
  3014012200: "대전광역시 중구 침산동",
  3014012300: "대전광역시 중구 목달동",
  3014012400: "대전광역시 중구 정생동",
  3014012500: "대전광역시 중구 어남동",
  3014012600: "대전광역시 중구 금동",
  3017000000: "대전광역시 서구",
  3017010100: "대전광역시 서구 복수동",
  3017010200: "대전광역시 서구 변동",
  3017010300: "대전광역시 서구 도마동",
  3017010400: "대전광역시 서구 정림동",
  3017010500: "대전광역시 서구 용문동",
  3017010600: "대전광역시 서구 탄방동",
  3017010800: "대전광역시 서구 괴정동",
  3017010900: "대전광역시 서구 가장동",
  3017011000: "대전광역시 서구 내동",
  3017011100: "대전광역시 서구 갈마동",
  3017011200: "대전광역시 서구 둔산동",
  3017011300: "대전광역시 서구 월평동",
  3017011400: "대전광역시 서구 가수원동",
  3017011500: "대전광역시 서구 도안동",
  3017011600: "대전광역시 서구 관저동",
  3017011700: "대전광역시 서구 흑석동",
  3017011800: "대전광역시 서구 매노동",
  3017011900: "대전광역시 서구 산직동",
  3017012000: "대전광역시 서구 장안동",
  3017012100: "대전광역시 서구 평촌동",
  3017012200: "대전광역시 서구 오동",
  3017012300: "대전광역시 서구 우명동",
  3017012400: "대전광역시 서구 원정동",
  3017012500: "대전광역시 서구 용촌동",
  3017012600: "대전광역시 서구 봉곡동",
  3017012700: "대전광역시 서구 괴곡동",
  3017012800: "대전광역시 서구 만년동",
  3020000000: "대전광역시 유성구",
  3020010100: "대전광역시 유성구 원내동",
  3020010200: "대전광역시 유성구 교촌동",
  3020010300: "대전광역시 유성구 대정동",
  3020010400: "대전광역시 유성구 용계동",
  3020010500: "대전광역시 유성구 학하동",
  3020010600: "대전광역시 유성구 계산동",
  3020010700: "대전광역시 유성구 성북동",
  3020010800: "대전광역시 유성구 세동",
  3020010900: "대전광역시 유성구 송정동",
  3020011000: "대전광역시 유성구 방동",
  3020011100: "대전광역시 유성구 봉명동",
  3020011200: "대전광역시 유성구 구암동",
  3020011300: "대전광역시 유성구 덕명동",
  3020011400: "대전광역시 유성구 원신흥동",
  3020011500: "대전광역시 유성구 상대동",
  3020011600: "대전광역시 유성구 복용동",
  3020011700: "대전광역시 유성구 장대동",
  3020011800: "대전광역시 유성구 갑동",
  3020011900: "대전광역시 유성구 노은동",
  3020012000: "대전광역시 유성구 지족동",
  3020012100: "대전광역시 유성구 죽동",
  3020012200: "대전광역시 유성구 궁동",
  3020012300: "대전광역시 유성구 어은동",
  3020012400: "대전광역시 유성구 구성동",
  3020012500: "대전광역시 유성구 신성동",
  3020012600: "대전광역시 유성구 가정동",
  3020012700: "대전광역시 유성구 도룡동",
  3020012800: "대전광역시 유성구 장동",
  3020012900: "대전광역시 유성구 방현동",
  3020013000: "대전광역시 유성구 화암동",
  3020013100: "대전광역시 유성구 덕진동",
  3020013200: "대전광역시 유성구 하기동",
  3020013300: "대전광역시 유성구 추목동",
  3020013400: "대전광역시 유성구 자운동",
  3020013500: "대전광역시 유성구 신봉동",
  3020013600: "대전광역시 유성구 수남동",
  3020013700: "대전광역시 유성구 안산동",
  3020013800: "대전광역시 유성구 외삼동",
  3020013900: "대전광역시 유성구 반석동",
  3020014000: "대전광역시 유성구 문지동",
  3020014100: "대전광역시 유성구 전민동",
  3020014200: "대전광역시 유성구 원촌동",
  3020014300: "대전광역시 유성구 탑립동",
  3020014400: "대전광역시 유성구 용산동",
  3020014500: "대전광역시 유성구 봉산동",
  3020014600: "대전광역시 유성구 관평동",
  3020014700: "대전광역시 유성구 송강동",
  3020014800: "대전광역시 유성구 금고동",
  3020014900: "대전광역시 유성구 대동",
  3020015000: "대전광역시 유성구 금탄동",
  3020015100: "대전광역시 유성구 신동",
  3020015200: "대전광역시 유성구 둔곡동",
  3020015300: "대전광역시 유성구 구룡동",
  3023000000: "대전광역시 대덕구",
  3023010100: "대전광역시 대덕구 오정동",
  3023010200: "대전광역시 대덕구 대화동",
  3023010300: "대전광역시 대덕구 읍내동",
  3023010400: "대전광역시 대덕구 연축동",
  3023010500: "대전광역시 대덕구 신대동",
  3023010600: "대전광역시 대덕구 와동",
  3023010700: "대전광역시 대덕구 송촌동",
  3023010800: "대전광역시 대덕구 법동",
  3023010900: "대전광역시 대덕구 중리동",
  3023011000: "대전광역시 대덕구 비래동",
  3023011100: "대전광역시 대덕구 석봉동",
  3023011200: "대전광역시 대덕구 목상동",
  3023011300: "대전광역시 대덕구 문평동",
  3023011400: "대전광역시 대덕구 신일동",
  3023011500: "대전광역시 대덕구 덕암동",
  3023011600: "대전광역시 대덕구 상서동",
  3023011700: "대전광역시 대덕구 평촌동",
  3023011800: "대전광역시 대덕구 장동",
  3023011900: "대전광역시 대덕구 용호동",
  3023012000: "대전광역시 대덕구 이현동",
  3023012100: "대전광역시 대덕구 갈전동",
  3023012200: "대전광역시 대덕구 부수동",
  3023012300: "대전광역시 대덕구 황호동",
  3023012400: "대전광역시 대덕구 삼정동",
  3023012500: "대전광역시 대덕구 미호동",
  3023012600: "대전광역시 대덕구 신탄진동",
  3100000000: "울산광역시",
  3111000000: "울산광역시 중구",
  3111010100: "울산광역시 중구 학성동",
  3111010200: "울산광역시 중구 학산동",
  3111010300: "울산광역시 중구 복산동",
  3111010400: "울산광역시 중구 북정동",
  3111010500: "울산광역시 중구 옥교동",
  3111010600: "울산광역시 중구 성남동",
  3111010700: "울산광역시 중구 교동",
  3111010800: "울산광역시 중구 우정동",
  3111010900: "울산광역시 중구 성안동",
  3111011000: "울산광역시 중구 유곡동",
  3111011100: "울산광역시 중구 태화동",
  3111011200: "울산광역시 중구 다운동",
  3111011300: "울산광역시 중구 동동",
  3111011400: "울산광역시 중구 서동",
  3111011500: "울산광역시 중구 남외동",
  3111011600: "울산광역시 중구 장현동",
  3111011700: "울산광역시 중구 약사동",
  3111011800: "울산광역시 중구 반구동",
  3114000000: "울산광역시 남구",
  3114010100: "울산광역시 남구 무거동",
  3114010200: "울산광역시 남구 옥동",
  3114010300: "울산광역시 남구 두왕동",
  3114010400: "울산광역시 남구 신정동",
  3114010500: "울산광역시 남구 달동",
  3114010600: "울산광역시 남구 삼산동",
  3114010700: "울산광역시 남구 여천동",
  3114010800: "울산광역시 남구 야음동",
  3114010900: "울산광역시 남구 선암동",
  3114011000: "울산광역시 남구 상개동",
  3114011100: "울산광역시 남구 부곡동",
  3114011200: "울산광역시 남구 고사동",
  3114011300: "울산광역시 남구 성암동",
  3114011400: "울산광역시 남구 황성동",
  3114011500: "울산광역시 남구 용연동",
  3114011600: "울산광역시 남구 남화동",
  3114011700: "울산광역시 남구 용잠동",
  3114011800: "울산광역시 남구 장생포동",
  3114011900: "울산광역시 남구 매암동",
  3117000000: "울산광역시 동구",
  3117010100: "울산광역시 동구 방어동",
  3117010200: "울산광역시 동구 화정동",
  3117010300: "울산광역시 동구 일산동",
  3117010400: "울산광역시 동구 전하동",
  3117010500: "울산광역시 동구 미포동",
  3117010600: "울산광역시 동구 주전동",
  3117010700: "울산광역시 동구 동부동",
  3117010800: "울산광역시 동구 서부동",
  3120000000: "울산광역시 북구",
  3120010100: "울산광역시 북구 창평동",
  3120010200: "울산광역시 북구 호계동",
  3120010300: "울산광역시 북구 매곡동",
  3120010400: "울산광역시 북구 가대동",
  3120010500: "울산광역시 북구 신천동",
  3120010600: "울산광역시 북구 중산동",
  3120010700: "울산광역시 북구 상안동",
  3120010800: "울산광역시 북구 천곡동",
  3120010900: "울산광역시 북구 달천동",
  3120011000: "울산광역시 북구 시례동",
  3120011100: "울산광역시 북구 무룡동",
  3120011200: "울산광역시 북구 구유동",
  3120011300: "울산광역시 북구 정자동",
  3120011400: "울산광역시 북구 신명동",
  3120011500: "울산광역시 북구 대안동",
  3120011600: "울산광역시 북구 당사동",
  3120011700: "울산광역시 북구 신현동",
  3120011800: "울산광역시 북구 산하동",
  3120011900: "울산광역시 북구 어물동",
  3120012000: "울산광역시 북구 명촌동",
  3120012100: "울산광역시 북구 진장동",
  3120012200: "울산광역시 북구 연암동",
  3120012300: "울산광역시 북구 효문동",
  3120012400: "울산광역시 북구 양정동",
  3120012500: "울산광역시 북구 화봉동",
  3120012600: "울산광역시 북구 송정동",
  3120012700: "울산광역시 북구 염포동",
  3171000000: "울산광역시 울주군",
  3171025000: "울산광역시 울주군 온산읍",
  3171025300: "울산광역시 울주군 언양읍",
  3171025600: "울산광역시 울주군 온양읍",
  3171025900: "울산광역시 울주군 범서읍",
  3171026200: "울산광역시 울주군 청량읍",
  3171026500: "울산광역시 울주군 삼남읍",
  3171031000: "울산광역시 울주군 서생면",
  3171034000: "울산광역시 울주군 웅촌면",
  3171036000: "울산광역시 울주군 두동면",
  3171037000: "울산광역시 울주군 두서면",
  3171038000: "울산광역시 울주군 상북면",
  3171040000: "울산광역시 울주군 삼동면",
  3611000000: "세종특별자치시",
  3611010100: "세종특별자치시 반곡동",
  3611010200: "세종특별자치시 소담동",
  3611010300: "세종특별자치시 보람동",
  3611010400: "세종특별자치시 대평동",
  3611010500: "세종특별자치시 가람동",
  3611010600: "세종특별자치시 한솔동",
  3611010700: "세종특별자치시 나성동",
  3611010800: "세종특별자치시 새롬동",
  3611010900: "세종특별자치시 다정동",
  3611011000: "세종특별자치시 어진동",
  3611011100: "세종특별자치시 종촌동",
  3611011200: "세종특별자치시 고운동",
  3611011300: "세종특별자치시 아름동",
  3611011400: "세종특별자치시 도담동",
  3611011500: "세종특별자치시 산울동",
  3611011600: "세종특별자치시 해밀동",
  3611011700: "세종특별자치시 합강동",
  3611011800: "세종특별자치시 집현동",
  3611011900: "세종특별자치시 세종동",
  3611012000: "세종특별자치시 누리동",
  3611012100: "세종특별자치시 한별동",
  3611012200: "세종특별자치시 다솜동",
  3611012300: "세종특별자치시 용호동",
  3611025000: "세종특별자치시 조치원읍",
  3611031000: "세종특별자치시 연기면",
  3611032000: "세종특별자치시 연동면",
  3611033000: "세종특별자치시 부강면",
  3611034000: "세종특별자치시 금남면",
  3611035000: "세종특별자치시 장군면",
  3611036000: "세종특별자치시 연서면",
  3611037000: "세종특별자치시 전의면",
  3611038000: "세종특별자치시 전동면",
  3611039000: "세종특별자치시 소정면",
  4100000000: "경기도",
  4111000000: "경기도 수원시",
  4111100000: "경기도 수원시 장안구",
  4111112900: "경기도 수원시 장안구 파장동",
  4111113000: "경기도 수원시 장안구 정자동",
  4111113100: "경기도 수원시 장안구 이목동",
  4111113200: "경기도 수원시 장안구 율전동",
  4111113300: "경기도 수원시 장안구 천천동",
  4111113400: "경기도 수원시 장안구 영화동",
  4111113500: "경기도 수원시 장안구 송죽동",
  4111113600: "경기도 수원시 장안구 조원동",
  4111113700: "경기도 수원시 장안구 연무동",
  4111113800: "경기도 수원시 장안구 상광교동",
  4111113900: "경기도 수원시 장안구 하광교동",
  4111300000: "경기도 수원시 권선구",
  4111312600: "경기도 수원시 권선구 세류동",
  4111312700: "경기도 수원시 권선구 평동",
  4111312800: "경기도 수원시 권선구 고색동",
  4111312900: "경기도 수원시 권선구 오목천동",
  4111313000: "경기도 수원시 권선구 평리동",
  4111313100: "경기도 수원시 권선구 서둔동",
  4111313200: "경기도 수원시 권선구 구운동",
  4111313300: "경기도 수원시 권선구 탑동",
  4111313400: "경기도 수원시 권선구 금곡동",
  4111313500: "경기도 수원시 권선구 호매실동",
  4111313600: "경기도 수원시 권선구 곡반정동",
  4111313700: "경기도 수원시 권선구 권선동",
  4111313800: "경기도 수원시 권선구 장지동",
  4111313900: "경기도 수원시 권선구 대황교동",
  4111314000: "경기도 수원시 권선구 입북동",
  4111314100: "경기도 수원시 권선구 당수동",
  4111500000: "경기도 수원시 팔달구",
  4111512000: "경기도 수원시 팔달구 팔달로1가",
  4111512100: "경기도 수원시 팔달구 팔달로2가",
  4111512200: "경기도 수원시 팔달구 팔달로3가",
  4111512300: "경기도 수원시 팔달구 남창동",
  4111512400: "경기도 수원시 팔달구 영동",
  4111512500: "경기도 수원시 팔달구 중동",
  4111512600: "경기도 수원시 팔달구 구천동",
  4111512700: "경기도 수원시 팔달구 남수동",
  4111512800: "경기도 수원시 팔달구 매향동",
  4111512900: "경기도 수원시 팔달구 북수동",
  4111513000: "경기도 수원시 팔달구 신풍동",
  4111513100: "경기도 수원시 팔달구 장안동",
  4111513200: "경기도 수원시 팔달구 교동",
  4111513300: "경기도 수원시 팔달구 매교동",
  4111513400: "경기도 수원시 팔달구 매산로1가",
  4111513500: "경기도 수원시 팔달구 매산로2가",
  4111513600: "경기도 수원시 팔달구 매산로3가",
  4111513700: "경기도 수원시 팔달구 고등동",
  4111513800: "경기도 수원시 팔달구 화서동",
  4111513900: "경기도 수원시 팔달구 지동",
  4111514000: "경기도 수원시 팔달구 우만동",
  4111514100: "경기도 수원시 팔달구 인계동",
  4111700000: "경기도 수원시 영통구",
  4111710100: "경기도 수원시 영통구 매탄동",
  4111710200: "경기도 수원시 영통구 원천동",
  4111710300: "경기도 수원시 영통구 이의동",
  4111710400: "경기도 수원시 영통구 하동",
  4111710500: "경기도 수원시 영통구 영통동",
  4111710600: "경기도 수원시 영통구 신동",
  4111710700: "경기도 수원시 영통구 망포동",
  4113000000: "경기도 성남시",
  4113100000: "경기도 성남시 수정구",
  4113110100: "경기도 성남시 수정구 신흥동",
  4113110200: "경기도 성남시 수정구 태평동",
  4113110300: "경기도 성남시 수정구 수진동",
  4113110400: "경기도 성남시 수정구 단대동",
  4113110500: "경기도 성남시 수정구 산성동",
  4113110600: "경기도 성남시 수정구 양지동",
  4113110700: "경기도 성남시 수정구 복정동",
  4113110800: "경기도 성남시 수정구 창곡동",
  4113110900: "경기도 성남시 수정구 신촌동",
  4113111000: "경기도 성남시 수정구 오야동",
  4113111100: "경기도 성남시 수정구 심곡동",
  4113111200: "경기도 성남시 수정구 고등동",
  4113111300: "경기도 성남시 수정구 상적동",
  4113111400: "경기도 성남시 수정구 둔전동",
  4113111500: "경기도 성남시 수정구 시흥동",
  4113111600: "경기도 성남시 수정구 금토동",
  4113111700: "경기도 성남시 수정구 사송동",
  4113300000: "경기도 성남시 중원구",
  4113310100: "경기도 성남시 중원구 성남동",
  4113310300: "경기도 성남시 중원구 금광동",
  4113310400: "경기도 성남시 중원구 은행동",
  4113310500: "경기도 성남시 중원구 상대원동",
  4113310600: "경기도 성남시 중원구 여수동",
  4113310700: "경기도 성남시 중원구 도촌동",
  4113310800: "경기도 성남시 중원구 갈현동",
  4113310900: "경기도 성남시 중원구 하대원동",
  4113313200: "경기도 성남시 중원구 중앙동",
  4113500000: "경기도 성남시 분당구",
  4113510100: "경기도 성남시 분당구 분당동",
  4113510200: "경기도 성남시 분당구 수내동",
  4113510300: "경기도 성남시 분당구 정자동",
  4113510400: "경기도 성남시 분당구 율동",
  4113510500: "경기도 성남시 분당구 서현동",
  4113510600: "경기도 성남시 분당구 이매동",
  4113510700: "경기도 성남시 분당구 야탑동",
  4113510800: "경기도 성남시 분당구 판교동",
  4113510900: "경기도 성남시 분당구 삼평동",
  4113511000: "경기도 성남시 분당구 백현동",
  4113511100: "경기도 성남시 분당구 금곡동",
  4113511200: "경기도 성남시 분당구 궁내동",
  4113511300: "경기도 성남시 분당구 동원동",
  4113511400: "경기도 성남시 분당구 구미동",
  4113511500: "경기도 성남시 분당구 운중동",
  4113511600: "경기도 성남시 분당구 대장동",
  4113511700: "경기도 성남시 분당구 석운동",
  4113511800: "경기도 성남시 분당구 하산운동",
  4115000000: "경기도 의정부시",
  4115010100: "경기도 의정부시 의정부동",
  4115010200: "경기도 의정부시 호원동",
  4115010300: "경기도 의정부시 장암동",
  4115010400: "경기도 의정부시 신곡동",
  4115010500: "경기도 의정부시 용현동",
  4115010600: "경기도 의정부시 민락동",
  4115010700: "경기도 의정부시 낙양동",
  4115010800: "경기도 의정부시 자일동",
  4115010900: "경기도 의정부시 금오동",
  4115011000: "경기도 의정부시 가능동",
  4115011100: "경기도 의정부시 녹양동",
  4115011200: "경기도 의정부시 고산동",
  4115011300: "경기도 의정부시 산곡동",
  4117000000: "경기도 안양시",
  4117100000: "경기도 안양시 만안구",
  4117110100: "경기도 안양시 만안구 안양동",
  4117110200: "경기도 안양시 만안구 석수동",
  4117110300: "경기도 안양시 만안구 박달동",
  4117300000: "경기도 안양시 동안구",
  4117310100: "경기도 안양시 동안구 비산동",
  4117310200: "경기도 안양시 동안구 관양동",
  4117310300: "경기도 안양시 동안구 평촌동",
  4117310400: "경기도 안양시 동안구 호계동",
  4119000000: "경기도 부천시",
  4119010100: "경기도 부천시 원미동",
  4119010200: "경기도 부천시 심곡동",
  4119010300: "경기도 부천시 춘의동",
  4119010400: "경기도 부천시 도당동",
  4119010500: "경기도 부천시 약대동",
  4119010600: "경기도 부천시 소사동",
  4119010700: "경기도 부천시 역곡동",
  4119010800: "경기도 부천시 중동",
  4119010900: "경기도 부천시 상동",
  4119011000: "경기도 부천시 소사본동",
  4119011100: "경기도 부천시 심곡본동",
  4119011200: "경기도 부천시 범박동",
  4119011300: "경기도 부천시 괴안동",
  4119011400: "경기도 부천시 송내동",
  4119011500: "경기도 부천시 옥길동",
  4119011600: "경기도 부천시 계수동",
  4119011700: "경기도 부천시 오정동",
  4119011800: "경기도 부천시 여월동",
  4119011900: "경기도 부천시 작동",
  4119012000: "경기도 부천시 원종동",
  4119012100: "경기도 부천시 고강동",
  4119012200: "경기도 부천시 대장동",
  4119012300: "경기도 부천시 삼정동",
  4119012400: "경기도 부천시 내동",
  4121000000: "경기도 광명시",
  4121010100: "경기도 광명시 광명동",
  4121010200: "경기도 광명시 철산동",
  4121010300: "경기도 광명시 하안동",
  4121010400: "경기도 광명시 소하동",
  4121010500: "경기도 광명시 노온사동",
  4121010600: "경기도 광명시 일직동",
  4121010700: "경기도 광명시 가학동",
  4121010800: "경기도 광명시 옥길동",
  4122000000: "경기도 평택시",
  4122010100: "경기도 평택시 서정동",
  4122010200: "경기도 평택시 장당동",
  4122010300: "경기도 평택시 모곡동",
  4122010400: "경기도 평택시 칠괴동",
  4122010500: "경기도 평택시 칠원동",
  4122010600: "경기도 평택시 도일동",
  4122010700: "경기도 평택시 가재동",
  4122010800: "경기도 평택시 장안동",
  4122010900: "경기도 평택시 이충동",
  4122011000: "경기도 평택시 지산동",
  4122011100: "경기도 평택시 독곡동",
  4122011200: "경기도 평택시 신장동",
  4122011300: "경기도 평택시 평택동",
  4122011400: "경기도 평택시 통복동",
  4122011500: "경기도 평택시 군문동",
  4122011600: "경기도 평택시 유천동",
  4122011700: "경기도 평택시 합정동",
  4122011800: "경기도 평택시 비전동",
  4122011900: "경기도 평택시 동삭동",
  4122012000: "경기도 평택시 세교동",
  4122012100: "경기도 평택시 지제동",
  4122012200: "경기도 평택시 신대동",
  4122012300: "경기도 평택시 소사동",
  4122012400: "경기도 평택시 용이동",
  4122012500: "경기도 평택시 월곡동",
  4122012600: "경기도 평택시 청룡동",
  4122012700: "경기도 평택시 죽백동",
  4122012800: "경기도 평택시 고덕동",
  4122025000: "경기도 평택시 팽성읍",
  4122025300: "경기도 평택시 안중읍",
  4122025600: "경기도 평택시 포승읍",
  4122025900: "경기도 평택시 청북읍",
  4122031000: "경기도 평택시 진위면",
  4122032000: "경기도 평택시 서탄면",
  4122033000: "경기도 평택시 고덕면",
  4122034000: "경기도 평택시 오성면",
  4122037000: "경기도 평택시 현덕면",
  4125000000: "경기도 동두천시",
  4125010100: "경기도 동두천시 송내동",
  4125010200: "경기도 동두천시 지행동",
  4125010300: "경기도 동두천시 생연동",
  4125010400: "경기도 동두천시 광암동",
  4125010500: "경기도 동두천시 걸산동",
  4125010600: "경기도 동두천시 보산동",
  4125010700: "경기도 동두천시 동두천동",
  4125010800: "경기도 동두천시 안흥동",
  4125010900: "경기도 동두천시 상봉암동",
  4125011000: "경기도 동두천시 하봉암동",
  4125011100: "경기도 동두천시 탑동동",
  4125011200: "경기도 동두천시 상패동",
  4127000000: "경기도 안산시",
  4127100000: "경기도 안산시 상록구",
  4127110100: "경기도 안산시 상록구 일동",
  4127110200: "경기도 안산시 상록구 이동",
  4127110300: "경기도 안산시 상록구 사동",
  4127110400: "경기도 안산시 상록구 본오동",
  4127110500: "경기도 안산시 상록구 팔곡이동",
  4127110600: "경기도 안산시 상록구 양상동",
  4127110700: "경기도 안산시 상록구 부곡동",
  4127110800: "경기도 안산시 상록구 성포동",
  4127110900: "경기도 안산시 상록구 월피동",
  4127111000: "경기도 안산시 상록구 팔곡일동",
  4127111100: "경기도 안산시 상록구 건건동",
  4127111200: "경기도 안산시 상록구 사사동",
  4127111300: "경기도 안산시 상록구 수암동",
  4127111400: "경기도 안산시 상록구 장상동",
  4127111500: "경기도 안산시 상록구 장하동",
  4127300000: "경기도 안산시 단원구",
  4127310100: "경기도 안산시 단원구 고잔동",
  4127310200: "경기도 안산시 단원구 와동",
  4127310300: "경기도 안산시 단원구 신길동",
  4127310400: "경기도 안산시 단원구 성곡동",
  4127310500: "경기도 안산시 단원구 원시동",
  4127310600: "경기도 안산시 단원구 목내동",
  4127310700: "경기도 안산시 단원구 초지동",
  4127310800: "경기도 안산시 단원구 원곡동",
  4127310900: "경기도 안산시 단원구 선부동",
  4127311000: "경기도 안산시 단원구 대부동동",
  4127311100: "경기도 안산시 단원구 대부북동",
  4127311200: "경기도 안산시 단원구 대부남동",
  4127311300: "경기도 안산시 단원구 선감동",
  4127311400: "경기도 안산시 단원구 풍도동",
  4127311500: "경기도 안산시 단원구 화정동",
  4128000000: "경기도 고양시",
  4128100000: "경기도 고양시 덕양구",
  4128110100: "경기도 고양시 덕양구 주교동",
  4128110200: "경기도 고양시 덕양구 원당동",
  4128110300: "경기도 고양시 덕양구 신원동",
  4128110400: "경기도 고양시 덕양구 원흥동",
  4128110500: "경기도 고양시 덕양구 도내동",
  4128110600: "경기도 고양시 덕양구 성사동",
  4128110700: "경기도 고양시 덕양구 북한동",
  4128110800: "경기도 고양시 덕양구 효자동",
  4128110900: "경기도 고양시 덕양구 지축동",
  4128111000: "경기도 고양시 덕양구 오금동",
  4128111100: "경기도 고양시 덕양구 삼송동",
  4128111200: "경기도 고양시 덕양구 동산동",
  4128111300: "경기도 고양시 덕양구 용두동",
  4128111400: "경기도 고양시 덕양구 벽제동",
  4128111500: "경기도 고양시 덕양구 선유동",
  4128111600: "경기도 고양시 덕양구 고양동",
  4128111700: "경기도 고양시 덕양구 대자동",
  4128111800: "경기도 고양시 덕양구 관산동",
  4128111900: "경기도 고양시 덕양구 내유동",
  4128112000: "경기도 고양시 덕양구 토당동",
  4128112100: "경기도 고양시 덕양구 내곡동",
  4128112200: "경기도 고양시 덕양구 대장동",
  4128112300: "경기도 고양시 덕양구 화정동",
  4128112400: "경기도 고양시 덕양구 강매동",
  4128112500: "경기도 고양시 덕양구 행주내동",
  4128112600: "경기도 고양시 덕양구 행주외동",
  4128112700: "경기도 고양시 덕양구 신평동",
  4128112800: "경기도 고양시 덕양구 행신동",
  4128112900: "경기도 고양시 덕양구 화전동",
  4128113000: "경기도 고양시 덕양구 현천동",
  4128113100: "경기도 고양시 덕양구 덕은동",
  4128113200: "경기도 고양시 덕양구 향동동",
  4128500000: "경기도 고양시 일산동구",
  4128510100: "경기도 고양시 일산동구 식사동",
  4128510200: "경기도 고양시 일산동구 중산동",
  4128510300: "경기도 고양시 일산동구 정발산동",
  4128510400: "경기도 고양시 일산동구 장항동",
  4128510500: "경기도 고양시 일산동구 마두동",
  4128510600: "경기도 고양시 일산동구 백석동",
  4128510700: "경기도 고양시 일산동구 풍동",
  4128510800: "경기도 고양시 일산동구 산황동",
  4128510900: "경기도 고양시 일산동구 사리현동",
  4128511000: "경기도 고양시 일산동구 지영동",
  4128511100: "경기도 고양시 일산동구 설문동",
  4128511200: "경기도 고양시 일산동구 문봉동",
  4128511300: "경기도 고양시 일산동구 성석동",
  4128700000: "경기도 고양시 일산서구",
  4128710100: "경기도 고양시 일산서구 일산동",
  4128710200: "경기도 고양시 일산서구 주엽동",
  4128710300: "경기도 고양시 일산서구 탄현동",
  4128710400: "경기도 고양시 일산서구 대화동",
  4128710500: "경기도 고양시 일산서구 덕이동",
  4128710600: "경기도 고양시 일산서구 가좌동",
  4128710700: "경기도 고양시 일산서구 구산동",
  4128710800: "경기도 고양시 일산서구 법곳동",
  4129000000: "경기도 과천시",
  4129010100: "경기도 과천시 관문동",
  4129010200: "경기도 과천시 문원동",
  4129010300: "경기도 과천시 갈현동",
  4129010400: "경기도 과천시 막계동",
  4129010500: "경기도 과천시 과천동",
  4129010600: "경기도 과천시 주암동",
  4129010700: "경기도 과천시 중앙동",
  4129010800: "경기도 과천시 원문동",
  4129010900: "경기도 과천시 별양동",
  4129011000: "경기도 과천시 부림동",
  4131000000: "경기도 구리시",
  4131010100: "경기도 구리시 갈매동",
  4131010200: "경기도 구리시 사노동",
  4131010300: "경기도 구리시 인창동",
  4131010400: "경기도 구리시 교문동",
  4131010500: "경기도 구리시 수택동",
  4131010600: "경기도 구리시 아천동",
  4131010700: "경기도 구리시 토평동",
  4136000000: "경기도 남양주시",
  4136010100: "경기도 남양주시 호평동",
  4136010200: "경기도 남양주시 평내동",
  4136010300: "경기도 남양주시 금곡동",
  4136010400: "경기도 남양주시 일패동",
  4136010500: "경기도 남양주시 이패동",
  4136010600: "경기도 남양주시 삼패동",
  4136010800: "경기도 남양주시 수석동",
  4136010900: "경기도 남양주시 지금동",
  4136011000: "경기도 남양주시 도농동",
  4136011100: "경기도 남양주시 별내동",
  4136011200: "경기도 남양주시 다산동",
  4136025000: "경기도 남양주시 와부읍",
  4136025300: "경기도 남양주시 진접읍",
  4136025600: "경기도 남양주시 화도읍",
  4136025900: "경기도 남양주시 진건읍",
  4136026200: "경기도 남양주시 오남읍",
  4136026500: "경기도 남양주시 퇴계원읍",
  4136031000: "경기도 남양주시 별내면",
  4136034000: "경기도 남양주시 수동면",
  4136036000: "경기도 남양주시 조안면",
  4137000000: "경기도 오산시",
  4137010100: "경기도 오산시 오산동",
  4137010200: "경기도 오산시 부산동",
  4137010300: "경기도 오산시 원동",
  4137010400: "경기도 오산시 궐동",
  4137010500: "경기도 오산시 청학동",
  4137010600: "경기도 오산시 가장동",
  4137010700: "경기도 오산시 금암동",
  4137010800: "경기도 오산시 수청동",
  4137010900: "경기도 오산시 은계동",
  4137011000: "경기도 오산시 내삼미동",
  4137011100: "경기도 오산시 외삼미동",
  4137011200: "경기도 오산시 양산동",
  4137011300: "경기도 오산시 세교동",
  4137011400: "경기도 오산시 지곶동",
  4137011500: "경기도 오산시 서랑동",
  4137011600: "경기도 오산시 서동",
  4137011700: "경기도 오산시 벌음동",
  4137011800: "경기도 오산시 두곡동",
  4137011900: "경기도 오산시 탑동",
  4137012000: "경기도 오산시 누읍동",
  4137012100: "경기도 오산시 가수동",
  4137012200: "경기도 오산시 고현동",
  4137012300: "경기도 오산시 청호동",
  4137012400: "경기도 오산시 갈곶동",
  4139000000: "경기도 시흥시",
  4139010100: "경기도 시흥시 대야동",
  4139010200: "경기도 시흥시 신천동",
  4139010300: "경기도 시흥시 방산동",
  4139010400: "경기도 시흥시 포동",
  4139010500: "경기도 시흥시 미산동",
  4139010600: "경기도 시흥시 은행동",
  4139010700: "경기도 시흥시 안현동",
  4139010800: "경기도 시흥시 매화동",
  4139010900: "경기도 시흥시 도창동",
  4139011000: "경기도 시흥시 금이동",
  4139011200: "경기도 시흥시 과림동",
  4139011300: "경기도 시흥시 계수동",
  4139011700: "경기도 시흥시 화정동",
  4139011800: "경기도 시흥시 능곡동",
  4139011900: "경기도 시흥시 하중동",
  4139012000: "경기도 시흥시 하상동",
  4139012100: "경기도 시흥시 광석동",
  4139012200: "경기도 시흥시 물왕동",
  4139012300: "경기도 시흥시 산현동",
  4139012400: "경기도 시흥시 조남동",
  4139012500: "경기도 시흥시 논곡동",
  4139012600: "경기도 시흥시 목감동",
  4139012700: "경기도 시흥시 거모동",
  4139012800: "경기도 시흥시 군자동",
  4139012900: "경기도 시흥시 장현동",
  4139013000: "경기도 시흥시 장곡동",
  4139013100: "경기도 시흥시 월곶동",
  4139013200: "경기도 시흥시 정왕동",
  4139013300: "경기도 시흥시 죽율동",
  4139013400: "경기도 시흥시 무지내동",
  4139013500: "경기도 시흥시 배곧동",
  4141000000: "경기도 군포시",
  4141010100: "경기도 군포시 당동",
  4141010200: "경기도 군포시 당정동",
  4141010300: "경기도 군포시 부곡동",
  4141010400: "경기도 군포시 산본동",
  4141010500: "경기도 군포시 금정동",
  4141010600: "경기도 군포시 둔대동",
  4141010700: "경기도 군포시 속달동",
  4141010800: "경기도 군포시 대야미동",
  4141010900: "경기도 군포시 도마교동",
  4143000000: "경기도 의왕시",
  4143010100: "경기도 의왕시 고천동",
  4143010200: "경기도 의왕시 이동",
  4143010300: "경기도 의왕시 삼동",
  4143010400: "경기도 의왕시 왕곡동",
  4143010500: "경기도 의왕시 오전동",
  4143010600: "경기도 의왕시 학의동",
  4143010700: "경기도 의왕시 내손동",
  4143010800: "경기도 의왕시 청계동",
  4143010900: "경기도 의왕시 포일동",
  4143011000: "경기도 의왕시 월암동",
  4143011100: "경기도 의왕시 초평동",
  4145000000: "경기도 하남시",
  4145010100: "경기도 하남시 천현동",
  4145010200: "경기도 하남시 하산곡동",
  4145010300: "경기도 하남시 창우동",
  4145010400: "경기도 하남시 배알미동",
  4145010500: "경기도 하남시 상산곡동",
  4145010600: "경기도 하남시 신장동",
  4145010700: "경기도 하남시 당정동",
  4145010800: "경기도 하남시 덕풍동",
  4145010900: "경기도 하남시 망월동",
  4145011000: "경기도 하남시 풍산동",
  4145011100: "경기도 하남시 미사동",
  4145011200: "경기도 하남시 선동",
  4145011300: "경기도 하남시 감북동",
  4145011400: "경기도 하남시 감일동",
  4145011500: "경기도 하남시 감이동",
  4145011600: "경기도 하남시 학암동",
  4145011700: "경기도 하남시 교산동",
  4145011800: "경기도 하남시 춘궁동",
  4145011900: "경기도 하남시 하사창동",
  4145012000: "경기도 하남시 상사창동",
  4145012100: "경기도 하남시 항동",
  4145012200: "경기도 하남시 초일동",
  4145012300: "경기도 하남시 초이동",
  4145012400: "경기도 하남시 광암동",
  4146000000: "경기도 용인시",
  4146100000: "경기도 용인시 처인구",
  4146110100: "경기도 용인시 처인구 김량장동",
  4146110200: "경기도 용인시 처인구 역북동",
  4146110300: "경기도 용인시 처인구 삼가동",
  4146110400: "경기도 용인시 처인구 남동",
  4146110500: "경기도 용인시 처인구 유방동",
  4146110600: "경기도 용인시 처인구 고림동",
  4146110700: "경기도 용인시 처인구 마평동",
  4146110800: "경기도 용인시 처인구 운학동",
  4146110900: "경기도 용인시 처인구 호동",
  4146111000: "경기도 용인시 처인구 해곡동",
  4146125000: "경기도 용인시 처인구 포곡읍",
  4146125300: "경기도 용인시 처인구 모현읍",
  4146125600: "경기도 용인시 처인구 이동읍",
  4146125900: "경기도 용인시 처인구 남사읍",
  4146134000: "경기도 용인시 처인구 원삼면",
  4146135000: "경기도 용인시 처인구 백암면",
  4146136000: "경기도 용인시 처인구 양지면",
  4146300000: "경기도 용인시 기흥구",
  4146310100: "경기도 용인시 기흥구 신갈동",
  4146310200: "경기도 용인시 기흥구 구갈동",
  4146310300: "경기도 용인시 기흥구 상갈동",
  4146310400: "경기도 용인시 기흥구 하갈동",
  4146310500: "경기도 용인시 기흥구 보라동",
  4146310600: "경기도 용인시 기흥구 지곡동",
  4146310700: "경기도 용인시 기흥구 공세동",
  4146310800: "경기도 용인시 기흥구 고매동",
  4146310900: "경기도 용인시 기흥구 농서동",
  4146311000: "경기도 용인시 기흥구 서천동",
  4146311100: "경기도 용인시 기흥구 영덕동",
  4146311200: "경기도 용인시 기흥구 언남동",
  4146311300: "경기도 용인시 기흥구 마북동",
  4146311400: "경기도 용인시 기흥구 청덕동",
  4146311500: "경기도 용인시 기흥구 동백동",
  4146311600: "경기도 용인시 기흥구 중동",
  4146311700: "경기도 용인시 기흥구 상하동",
  4146311800: "경기도 용인시 기흥구 보정동",
  4146500000: "경기도 용인시 수지구",
  4146510100: "경기도 용인시 수지구 풍덕천동",
  4146510200: "경기도 용인시 수지구 죽전동",
  4146510300: "경기도 용인시 수지구 동천동",
  4146510400: "경기도 용인시 수지구 고기동",
  4146510500: "경기도 용인시 수지구 신봉동",
  4146510600: "경기도 용인시 수지구 성복동",
  4146510700: "경기도 용인시 수지구 상현동",
  4148000000: "경기도 파주시",
  4148010100: "경기도 파주시 금촌동",
  4148010200: "경기도 파주시 아동동",
  4148010400: "경기도 파주시 야동동",
  4148010500: "경기도 파주시 검산동",
  4148010600: "경기도 파주시 맥금동",
  4148010700: "경기도 파주시 교하동",
  4148010800: "경기도 파주시 야당동",
  4148010900: "경기도 파주시 다율동",
  4148011000: "경기도 파주시 오도동",
  4148011100: "경기도 파주시 상지석동",
  4148011200: "경기도 파주시 산남동",
  4148011300: "경기도 파주시 동패동",
  4148011400: "경기도 파주시 당하동",
  4148011500: "경기도 파주시 문발동",
  4148011600: "경기도 파주시 송촌동",
  4148011700: "경기도 파주시 목동동",
  4148011800: "경기도 파주시 하지석동",
  4148011900: "경기도 파주시 서패동",
  4148012000: "경기도 파주시 신촌동",
  4148012100: "경기도 파주시 연다산동",
  4148012200: "경기도 파주시 와동동",
  4148012300: "경기도 파주시 금릉동",
  4148025000: "경기도 파주시 문산읍",
  4148025300: "경기도 파주시 파주읍",
  4148025600: "경기도 파주시 법원읍",
  4148026200: "경기도 파주시 조리읍",
  4148031000: "경기도 파주시 월롱면",
  4148032000: "경기도 파주시 탄현면",
  4148035000: "경기도 파주시 광탄면",
  4148036000: "경기도 파주시 파평면",
  4148037000: "경기도 파주시 적성면",
  4148038000: "경기도 파주시 군내면",
  4148039000: "경기도 파주시 장단면",
  4148040000: "경기도 파주시 진동면",
  4148041000: "경기도 파주시 진서면",
  4150000000: "경기도 이천시",
  4150010100: "경기도 이천시 창전동",
  4150010200: "경기도 이천시 관고동",
  4150010300: "경기도 이천시 중리동",
  4150010400: "경기도 이천시 증일동",
  4150010500: "경기도 이천시 율현동",
  4150010600: "경기도 이천시 진리동",
  4150010700: "경기도 이천시 안흥동",
  4150010800: "경기도 이천시 갈산동",
  4150010900: "경기도 이천시 증포동",
  4150011000: "경기도 이천시 송정동",
  4150011100: "경기도 이천시 사음동",
  4150011200: "경기도 이천시 단월동",
  4150011300: "경기도 이천시 대포동",
  4150011400: "경기도 이천시 고담동",
  4150011500: "경기도 이천시 장록동",
  4150025000: "경기도 이천시 장호원읍",
  4150025300: "경기도 이천시 부발읍",
  4150031000: "경기도 이천시 신둔면",
  4150032000: "경기도 이천시 백사면",
  4150033000: "경기도 이천시 호법면",
  4150034000: "경기도 이천시 마장면",
  4150035000: "경기도 이천시 대월면",
  4150036000: "경기도 이천시 모가면",
  4150037000: "경기도 이천시 설성면",
  4150038000: "경기도 이천시 율면",
  4155000000: "경기도 안성시",
  4155010100: "경기도 안성시 봉산동",
  4155010200: "경기도 안성시 숭인동",
  4155010300: "경기도 안성시 영동",
  4155010400: "경기도 안성시 봉남동",
  4155010500: "경기도 안성시 구포동",
  4155010600: "경기도 안성시 동본동",
  4155010700: "경기도 안성시 명륜동",
  4155010800: "경기도 안성시 옥천동",
  4155010900: "경기도 안성시 낙원동",
  4155011000: "경기도 안성시 창전동",
  4155011100: "경기도 안성시 성남동",
  4155011200: "경기도 안성시 신흥동",
  4155011300: "경기도 안성시 인지동",
  4155011400: "경기도 안성시 금산동",
  4155011500: "경기도 안성시 연지동",
  4155011600: "경기도 안성시 대천동",
  4155011700: "경기도 안성시 서인동",
  4155011800: "경기도 안성시 석정동",
  4155011900: "경기도 안성시 아양동",
  4155012000: "경기도 안성시 금석동",
  4155012100: "경기도 안성시 계동",
  4155012200: "경기도 안성시 옥산동",
  4155012300: "경기도 안성시 사곡동",
  4155012400: "경기도 안성시 도기동",
  4155012500: "경기도 안성시 당왕동",
  4155012600: "경기도 안성시 가사동",
  4155012700: "경기도 안성시 가현동",
  4155012800: "경기도 안성시 신건지동",
  4155012900: "경기도 안성시 신소현동",
  4155013000: "경기도 안성시 신모산동",
  4155013100: "경기도 안성시 현수동",
  4155013200: "경기도 안성시 발화동",
  4155013300: "경기도 안성시 중리동",
  4155025000: "경기도 안성시 공도읍",
  4155031000: "경기도 안성시 보개면",
  4155032000: "경기도 안성시 금광면",
  4155033000: "경기도 안성시 서운면",
  4155034000: "경기도 안성시 미양면",
  4155035000: "경기도 안성시 대덕면",
  4155036000: "경기도 안성시 양성면",
  4155038000: "경기도 안성시 원곡면",
  4155039000: "경기도 안성시 일죽면",
  4155040000: "경기도 안성시 죽산면",
  4155041000: "경기도 안성시 삼죽면",
  4155042000: "경기도 안성시 고삼면",
  4157000000: "경기도 김포시",
  4157010100: "경기도 김포시 북변동",
  4157010200: "경기도 김포시 걸포동",
  4157010300: "경기도 김포시 운양동",
  4157010400: "경기도 김포시 장기동",
  4157010500: "경기도 김포시 감정동",
  4157010600: "경기도 김포시 사우동",
  4157010700: "경기도 김포시 풍무동",
  4157010800: "경기도 김포시 마산동",
  4157010900: "경기도 김포시 구래동",
  4157025000: "경기도 김포시 통진읍",
  4157025300: "경기도 김포시 고촌읍",
  4157025600: "경기도 김포시 양촌읍",
  4157034000: "경기도 김포시 대곶면",
  4157035000: "경기도 김포시 월곶면",
  4157036000: "경기도 김포시 하성면",
  4159000000: "경기도 화성시",
  4159011600: "경기도 화성시 진안동",
  4159011700: "경기도 화성시 병점동",
  4159011800: "경기도 화성시 능동",
  4159011900: "경기도 화성시 기산동",
  4159012000: "경기도 화성시 반월동",
  4159012100: "경기도 화성시 반정동",
  4159012200: "경기도 화성시 황계동",
  4159012300: "경기도 화성시 배양동",
  4159012400: "경기도 화성시 기안동",
  4159012500: "경기도 화성시 송산동",
  4159012600: "경기도 화성시 안녕동",
  4159012700: "경기도 화성시 반송동",
  4159012800: "경기도 화성시 석우동",
  4159012900: "경기도 화성시 오산동",
  4159013000: "경기도 화성시 청계동",
  4159013100: "경기도 화성시 영천동",
  4159013200: "경기도 화성시 중동",
  4159013300: "경기도 화성시 신동",
  4159013400: "경기도 화성시 목동",
  4159013500: "경기도 화성시 산척동",
  4159013600: "경기도 화성시 장지동",
  4159013700: "경기도 화성시 송동",
  4159013800: "경기도 화성시 방교동",
  4159013900: "경기도 화성시 금곡동",
  4159014000: "경기도 화성시 새솔동",
  4159025300: "경기도 화성시 봉담읍",
  4159025600: "경기도 화성시 우정읍",
  4159025900: "경기도 화성시 향남읍",
  4159026200: "경기도 화성시 남양읍",
  4159031000: "경기도 화성시 매송면",
  4159032000: "경기도 화성시 비봉면",
  4159033000: "경기도 화성시 마도면",
  4159034000: "경기도 화성시 송산면",
  4159035000: "경기도 화성시 서신면",
  4159036000: "경기도 화성시 팔탄면",
  4159037000: "경기도 화성시 장안면",
  4159040000: "경기도 화성시 양감면",
  4159041000: "경기도 화성시 정남면",
  4161000000: "경기도 광주시",
  4161010100: "경기도 광주시 경안동",
  4161010200: "경기도 광주시 쌍령동",
  4161010300: "경기도 광주시 송정동",
  4161010400: "경기도 광주시 회덕동",
  4161010500: "경기도 광주시 탄벌동",
  4161010600: "경기도 광주시 목현동",
  4161010700: "경기도 광주시 삼동",
  4161010800: "경기도 광주시 중대동",
  4161010900: "경기도 광주시 직동",
  4161011000: "경기도 광주시 태전동",
  4161011100: "경기도 광주시 장지동",
  4161011200: "경기도 광주시 역동",
  4161011300: "경기도 광주시 목동",
  4161025000: "경기도 광주시 오포읍",
  4161025300: "경기도 광주시 초월읍",
  4161025900: "경기도 광주시 곤지암읍",
  4161033000: "경기도 광주시 도척면",
  4161034000: "경기도 광주시 퇴촌면",
  4161035000: "경기도 광주시 남종면",
  4161037000: "경기도 광주시 남한산성면",
  4163000000: "경기도 양주시",
  4163010100: "경기도 양주시 유양동",
  4163010200: "경기도 양주시 어둔동",
  4163010300: "경기도 양주시 남방동",
  4163010400: "경기도 양주시 마전동",
  4163010500: "경기도 양주시 산북동",
  4163010600: "경기도 양주시 광사동",
  4163010700: "경기도 양주시 만송동",
  4163010800: "경기도 양주시 삼숭동",
  4163010900: "경기도 양주시 고읍동",
  4163011000: "경기도 양주시 덕정동",
  4163011100: "경기도 양주시 봉양동",
  4163011200: "경기도 양주시 회암동",
  4163011300: "경기도 양주시 율정동",
  4163011400: "경기도 양주시 옥정동",
  4163011500: "경기도 양주시 고암동",
  4163011600: "경기도 양주시 덕계동",
  4163011700: "경기도 양주시 회정동",
  4163025000: "경기도 양주시 백석읍",
  4163031000: "경기도 양주시 은현면",
  4163032000: "경기도 양주시 남면",
  4163033000: "경기도 양주시 광적면",
  4163034000: "경기도 양주시 장흥면",
  4165000000: "경기도 포천시",
  4165010100: "경기도 포천시 신읍동",
  4165010200: "경기도 포천시 어룡동",
  4165010300: "경기도 포천시 자작동",
  4165010400: "경기도 포천시 선단동",
  4165010500: "경기도 포천시 설운동",
  4165010600: "경기도 포천시 동교동",
  4165025000: "경기도 포천시 소흘읍",
  4165031000: "경기도 포천시 군내면",
  4165032000: "경기도 포천시 내촌면",
  4165033000: "경기도 포천시 가산면",
  4165034000: "경기도 포천시 신북면",
  4165035000: "경기도 포천시 창수면",
  4165036000: "경기도 포천시 영중면",
  4165037000: "경기도 포천시 일동면",
  4165038000: "경기도 포천시 이동면",
  4165039000: "경기도 포천시 영북면",
  4165040000: "경기도 포천시 관인면",
  4165041000: "경기도 포천시 화현면",
  4167000000: "경기도 여주시",
  4167010100: "경기도 여주시 상동",
  4167010200: "경기도 여주시 홍문동",
  4167010300: "경기도 여주시 창동",
  4167010400: "경기도 여주시 우만동",
  4167010500: "경기도 여주시 단현동",
  4167010600: "경기도 여주시 신진동",
  4167010700: "경기도 여주시 하동",
  4167010800: "경기도 여주시 교동",
  4167010900: "경기도 여주시 월송동",
  4167011000: "경기도 여주시 가업동",
  4167011100: "경기도 여주시 연라동",
  4167011200: "경기도 여주시 상거동",
  4167011300: "경기도 여주시 하거동",
  4167011400: "경기도 여주시 삼교동",
  4167011500: "경기도 여주시 점봉동",
  4167011600: "경기도 여주시 능현동",
  4167011700: "경기도 여주시 멱곡동",
  4167011800: "경기도 여주시 연양동",
  4167011900: "경기도 여주시 매룡동",
  4167012000: "경기도 여주시 천송동",
  4167012100: "경기도 여주시 오학동",
  4167012200: "경기도 여주시 현암동",
  4167012300: "경기도 여주시 오금동",
  4167025000: "경기도 여주시 가남읍",
  4167031000: "경기도 여주시 점동면",
  4167032000: "경기도 여주시 흥천면",
  4167033000: "경기도 여주시 금사면",
  4167034500: "경기도 여주시 세종대왕면",
  4167035000: "경기도 여주시 대신면 ",
  4167036000: "경기도 여주시 북내면",
  4167037000: "경기도 여주시 강천면",
  4167038000: "경기도 여주시 산북면",
  4180000000: "경기도 연천군",
  4180025000: "경기도 연천군 연천읍",
  4180025300: "경기도 연천군 전곡읍",
  4180031000: "경기도 연천군 군남면",
  4180032000: "경기도 연천군 청산면",
  4180033000: "경기도 연천군 백학면",
  4180034000: "경기도 연천군 미산면",
  4180035000: "경기도 연천군 왕징면",
  4180036000: "경기도 연천군 신서면",
  4180037000: "경기도 연천군 중면",
  4180038000: "경기도 연천군 장남면",
  4182000000: "경기도 가평군",
  4182025000: "경기도 가평군 가평읍",
  4182031000: "경기도 가평군 설악면",
  4182032500: "경기도 가평군 청평면",
  4182033000: "경기도 가평군 상면",
  4182034500: "경기도 가평군 조종면",
  4182035000: "경기도 가평군 북면",
  4183000000: "경기도 양평군",
  4183025000: "경기도 양평군 양평읍",
  4183031000: "경기도 양평군 강상면",
  4183032000: "경기도 양평군 강하면",
  4183033000: "경기도 양평군 양서면",
  4183034000: "경기도 양평군 옥천면",
  4183035000: "경기도 양평군 서종면",
  4183036000: "경기도 양평군 단월면",
  4183037000: "경기도 양평군 청운면",
  4183038000: "경기도 양평군 양동면",
  4183039500: "경기도 양평군 지평면",
  4183040000: "경기도 양평군 용문면",
  4183041000: "경기도 양평군 개군면",
  4200000000: "강원도",
  4211000000: "강원도 춘천시",
  4211010100: "강원도 춘천시 봉의동",
  4211010200: "강원도 춘천시 요선동",
  4211010300: "강원도 춘천시 낙원동",
  4211010400: "강원도 춘천시 중앙로1가",
  4211010500: "강원도 춘천시 중앙로2가",
  4211010600: "강원도 춘천시 중앙로3가",
  4211010700: "강원도 춘천시 옥천동",
  4211010800: "강원도 춘천시 조양동",
  4211010900: "강원도 춘천시 죽림동",
  4211011000: "강원도 춘천시 운교동",
  4211011100: "강원도 춘천시 약사동",
  4211011200: "강원도 춘천시 효자동",
  4211011300: "강원도 춘천시 소양로1가",
  4211011400: "강원도 춘천시 소양로2가",
  4211011500: "강원도 춘천시 소양로3가",
  4211011600: "강원도 춘천시 소양로4가",
  4211011700: "강원도 춘천시 근화동",
  4211011800: "강원도 춘천시 우두동",
  4211011900: "강원도 춘천시 사농동",
  4211012000: "강원도 춘천시 후평동",
  4211012100: "강원도 춘천시 온의동",
  4211012200: "강원도 춘천시 교동",
  4211012300: "강원도 춘천시 퇴계동",
  4211012400: "강원도 춘천시 석사동",
  4211012500: "강원도 춘천시 삼천동",
  4211012600: "강원도 춘천시 칠전동",
  4211012700: "강원도 춘천시 송암동",
  4211012800: "강원도 춘천시 신동",
  4211012900: "강원도 춘천시 중도동",
  4211025000: "강원도 춘천시 신북읍",
  4211031000: "강원도 춘천시 동면",
  4211032000: "강원도 춘천시 동산면",
  4211033000: "강원도 춘천시 신동면",
  4211034000: "강원도 춘천시 남면",
  4211035000: "강원도 춘천시 서면",
  4211036000: "강원도 춘천시 사북면",
  4211038000: "강원도 춘천시 북산면",
  4211039000: "강원도 춘천시 동내면",
  4211040000: "강원도 춘천시 남산면",
  4213000000: "강원도 원주시",
  4213010100: "강원도 원주시 중앙동",
  4213010200: "강원도 원주시 평원동",
  4213010300: "강원도 원주시 원동",
  4213010400: "강원도 원주시 인동",
  4213010500: "강원도 원주시 개운동",
  4213010600: "강원도 원주시 명륜동",
  4213010700: "강원도 원주시 단구동",
  4213010800: "강원도 원주시 일산동",
  4213010900: "강원도 원주시 학성동",
  4213011000: "강원도 원주시 단계동",
  4213011100: "강원도 원주시 우산동",
  4213011200: "강원도 원주시 태장동",
  4213011300: "강원도 원주시 봉산동",
  4213011400: "강원도 원주시 행구동",
  4213011500: "강원도 원주시 무실동",
  4213011600: "강원도 원주시 관설동",
  4213011700: "강원도 원주시 반곡동",
  4213011800: "강원도 원주시 가현동",
  4213025000: "강원도 원주시 문막읍",
  4213031000: "강원도 원주시 소초면",
  4213032000: "강원도 원주시 호저면",
  4213033000: "강원도 원주시 지정면",
  4213035000: "강원도 원주시 부론면",
  4213036000: "강원도 원주시 귀래면",
  4213037000: "강원도 원주시 흥업면",
  4213038000: "강원도 원주시 판부면",
  4213039000: "강원도 원주시 신림면",
  4215000000: "강원도 강릉시",
  4215010100: "강원도 강릉시 홍제동",
  4215010200: "강원도 강릉시 남문동",
  4215010300: "강원도 강릉시 명주동",
  4215010400: "강원도 강릉시 성내동",
  4215010500: "강원도 강릉시 임당동",
  4215010600: "강원도 강릉시 금학동",
  4215010700: "강원도 강릉시 용강동",
  4215010800: "강원도 강릉시 성남동",
  4215010900: "강원도 강릉시 옥천동",
  4215011000: "강원도 강릉시 교동",
  4215011100: "강원도 강릉시 포남동",
  4215011200: "강원도 강릉시 초당동",
  4215011300: "강원도 강릉시 강문동",
  4215011400: "강원도 강릉시 송정동",
  4215011500: "강원도 강릉시 견소동",
  4215011600: "강원도 강릉시 내곡동",
  4215011700: "강원도 강릉시 회산동",
  4215011800: "강원도 강릉시 장현동",
  4215011900: "강원도 강릉시 박월동",
  4215012000: "강원도 강릉시 담산동",
  4215012100: "강원도 강릉시 노암동",
  4215012200: "강원도 강릉시 유산동",
  4215012300: "강원도 강릉시 월호평동",
  4215012400: "강원도 강릉시 신석동",
  4215012500: "강원도 강릉시 입암동",
  4215012600: "강원도 강릉시 청량동",
  4215012700: "강원도 강릉시 두산동",
  4215012800: "강원도 강릉시 학동",
  4215012900: "강원도 강릉시 병산동",
  4215013000: "강원도 강릉시 남항진동",
  4215013100: "강원도 강릉시 유천동",
  4215013200: "강원도 강릉시 지변동",
  4215013300: "강원도 강릉시 죽헌동",
  4215013400: "강원도 강릉시 대전동",
  4215013500: "강원도 강릉시 운정동",
  4215013600: "강원도 강릉시 난곡동",
  4215013700: "강원도 강릉시 저동",
  4215013800: "강원도 강릉시 안현동",
  4215013900: "강원도 강릉시 운산동",
  4215025000: "강원도 강릉시 주문진읍",
  4215031000: "강원도 강릉시 성산면",
  4215032000: "강원도 강릉시 왕산면",
  4215033000: "강원도 강릉시 구정면",
  4215034000: "강원도 강릉시 강동면",
  4215035000: "강원도 강릉시 옥계면",
  4215036000: "강원도 강릉시 사천면",
  4215037000: "강원도 강릉시 연곡면",
  4217000000: "강원도 동해시",
  4217010100: "강원도 동해시 천곡동",
  4217010200: "강원도 동해시 평릉동",
  4217010300: "강원도 동해시 송정동",
  4217010400: "강원도 동해시 용정동",
  4217010500: "강원도 동해시 지흥동",
  4217010600: "강원도 동해시 효가동",
  4217010700: "강원도 동해시 동회동",
  4217010800: "강원도 동해시 나안동",
  4217010900: "강원도 동해시 쇄운동",
  4217011000: "강원도 동해시 부곡동",
  4217011100: "강원도 동해시 발한동",
  4217011200: "강원도 동해시 북평동",
  4217011300: "강원도 동해시 구미동",
  4217011400: "강원도 동해시 추암동",
  4217011500: "강원도 동해시 구호동",
  4217011600: "강원도 동해시 단봉동",
  4217011700: "강원도 동해시 지가동",
  4217011800: "강원도 동해시 이도동",
  4217011900: "강원도 동해시 귀운동",
  4217012000: "강원도 동해시 대구동",
  4217012100: "강원도 동해시 호현동",
  4217012200: "강원도 동해시 내동",
  4217012300: "강원도 동해시 묵호진동",
  4217012400: "강원도 동해시 삼화동",
  4217012500: "강원도 동해시 이기동",
  4217012600: "강원도 동해시 이로동",
  4217012700: "강원도 동해시 어달동",
  4217012800: "강원도 동해시 대진동",
  4217012900: "강원도 동해시 망상동",
  4217013000: "강원도 동해시 심곡동",
  4217013100: "강원도 동해시 초구동",
  4217013200: "강원도 동해시 괴란동",
  4217013300: "강원도 동해시 만우동",
  4217013400: "강원도 동해시 신흥동",
  4217013500: "강원도 동해시 비천동",
  4217013600: "강원도 동해시 달방동",
  4219000000: "강원도 태백시",
  4219010100: "강원도 태백시 황지동",
  4219010200: "강원도 태백시 장성동",
  4219010300: "강원도 태백시 금천동",
  4219010400: "강원도 태백시 철암동",
  4219010500: "강원도 태백시 문곡동",
  4219010600: "강원도 태백시 동점동",
  4219010700: "강원도 태백시 소도동",
  4219010800: "강원도 태백시 혈동",
  4219010900: "강원도 태백시 화전동",
  4219011000: "강원도 태백시 적각동",
  4219011100: "강원도 태백시 창죽동",
  4219011200: "강원도 태백시 통동",
  4219011300: "강원도 태백시 백산동",
  4219011400: "강원도 태백시 원동",
  4219011500: "강원도 태백시 상사미동",
  4219011600: "강원도 태백시 하사미동",
  4219011700: "강원도 태백시 조탄동",
  4221000000: "강원도 속초시",
  4221010100: "강원도 속초시 영랑동",
  4221010200: "강원도 속초시 동명동",
  4221010300: "강원도 속초시 중앙동",
  4221010400: "강원도 속초시 금호동",
  4221010500: "강원도 속초시 청학동",
  4221010600: "강원도 속초시 교동",
  4221010700: "강원도 속초시 노학동",
  4221010800: "강원도 속초시 조양동",
  4221010900: "강원도 속초시 청호동",
  4221011000: "강원도 속초시 대포동",
  4221011100: "강원도 속초시 도문동",
  4221011200: "강원도 속초시 설악동",
  4221011300: "강원도 속초시 장사동",
  4223000000: "강원도 삼척시",
  4223010100: "강원도 삼척시 성내동",
  4223010200: "강원도 삼척시 성북동",
  4223010300: "강원도 삼척시 읍상동",
  4223010400: "강원도 삼척시 읍중동",
  4223010500: "강원도 삼척시 당저동",
  4223010600: "강원도 삼척시 교동",
  4223010700: "강원도 삼척시 갈천동",
  4223010800: "강원도 삼척시 증산동",
  4223010900: "강원도 삼척시 우지동",
  4223011000: "강원도 삼척시 마달동",
  4223011100: "강원도 삼척시 자원동",
  4223011200: "강원도 삼척시 평전동",
  4223011300: "강원도 삼척시 등봉동",
  4223011400: "강원도 삼척시 도경동",
  4223011500: "강원도 삼척시 마평동",
  4223011600: "강원도 삼척시 오사동",
  4223011700: "강원도 삼척시 건지동",
  4223011800: "강원도 삼척시 원당동",
  4223011900: "강원도 삼척시 성남동",
  4223012000: "강원도 삼척시 남양동",
  4223012100: "강원도 삼척시 사직동",
  4223012200: "강원도 삼척시 오분동",
  4223012300: "강원도 삼척시 적노동",
  4223012400: "강원도 삼척시 조비동",
  4223012500: "강원도 삼척시 정상동",
  4223012600: "강원도 삼척시 정하동",
  4223012700: "강원도 삼척시 근산동",
  4223025000: "강원도 삼척시 도계읍",
  4223025300: "강원도 삼척시 원덕읍",
  4223031000: "강원도 삼척시 근덕면",
  4223032000: "강원도 삼척시 하장면",
  4223033000: "강원도 삼척시 노곡면",
  4223034000: "강원도 삼척시 미로면",
  4223035000: "강원도 삼척시 가곡면",
  4223036000: "강원도 삼척시 신기면",
  4272000000: "강원도 홍천군",
  4272025000: "강원도 홍천군 홍천읍",
  4272031000: "강원도 홍천군 화촌면",
  4272032000: "강원도 홍천군 두촌면",
  4272033000: "강원도 홍천군 내촌면",
  4272034000: "강원도 홍천군 서석면",
  4272035200: "강원도 홍천군 영귀미면",
  4272036000: "강원도 홍천군 남면",
  4272037000: "강원도 홍천군 서면",
  4272038000: "강원도 홍천군 북방면",
  4272039000: "강원도 홍천군 내면",
  4273000000: "강원도 횡성군",
  4273025000: "강원도 횡성군 횡성읍",
  4273031000: "강원도 횡성군 우천면",
  4273032000: "강원도 횡성군 안흥면",
  4273033000: "강원도 횡성군 둔내면",
  4273034000: "강원도 횡성군 갑천면",
  4273035000: "강원도 횡성군 청일면",
  4273036000: "강원도 횡성군 공근면",
  4273037000: "강원도 횡성군 서원면",
  4273038000: "강원도 횡성군 강림면",
  4275000000: "강원도 영월군",
  4275025000: "강원도 영월군 영월읍",
  4275025300: "강원도 영월군 상동읍",
  4275031200: "강원도 영월군 산솔면",
  4275032500: "강원도 영월군 김삿갓면",
  4275033000: "강원도 영월군 북면",
  4275034000: "강원도 영월군 남면",
  4275035500: "강원도 영월군 한반도면",
  4275036000: "강원도 영월군 주천면",
  4275038000: "강원도 영월군 무릉도원면",
  4276000000: "강원도 평창군",
  4276025000: "강원도 평창군 평창읍",
  4276031000: "강원도 평창군 미탄면",
  4276032000: "강원도 평창군 방림면",
  4276033000: "강원도 평창군 대화면",
  4276034000: "강원도 평창군 봉평면",
  4276035000: "강원도 평창군 용평면",
  4276036000: "강원도 평창군 진부면",
  4276038000: "강원도 평창군 대관령면",
  4277000000: "강원도 정선군",
  4277025000: "강원도 정선군 정선읍",
  4277025300: "강원도 정선군 고한읍",
  4277025600: "강원도 정선군 사북읍",
  4277025900: "강원도 정선군 신동읍",
  4277032000: "강원도 정선군 남면",
  4277034000: "강원도 정선군 북평면",
  4277035000: "강원도 정선군 임계면",
  4277036000: "강원도 정선군 화암면",
  4277037000: "강원도 정선군 여량면",
  4278000000: "강원도 철원군",
  4278025000: "강원도 철원군 철원읍",
  4278025300: "강원도 철원군 김화읍",
  4278025600: "강원도 철원군 갈말읍",
  4278025900: "강원도 철원군 동송읍",
  4278031000: "강원도 철원군 서면",
  4278032000: "강원도 철원군 근남면",
  4278033000: "강원도 철원군 근북면",
  4278034000: "강원도 철원군 근동면",
  4278035000: "강원도 철원군 원동면",
  4278036000: "강원도 철원군 원남면",
  4278037000: "강원도 철원군 임남면",
  4279000000: "강원도 화천군",
  4279025000: "강원도 화천군 화천읍",
  4279031000: "강원도 화천군 간동면",
  4279032000: "강원도 화천군 하남면",
  4279033000: "강원도 화천군 상서면",
  4279034000: "강원도 화천군 사내면",
  4280000000: "강원도 양구군",
  4280025000: "강원도 양구군 양구읍",
  4280031500: "강원도 양구군 국토정중앙면",
  4280032000: "강원도 양구군 동면",
  4280033000: "강원도 양구군 방산면",
  4280034000: "강원도 양구군 해안면",
  4281000000: "강원도 인제군",
  4281025000: "강원도 인제군 인제읍",
  4281031000: "강원도 인제군 남면",
  4281032000: "강원도 인제군 북면",
  4281033000: "강원도 인제군 기린면",
  4281034000: "강원도 인제군 서화면",
  4281035000: "강원도 인제군 상남면",
  4282000000: "강원도 고성군",
  4282025000: "강원도 고성군 간성읍",
  4282025300: "강원도 고성군 거진읍",
  4282031000: "강원도 고성군 현내면",
  4282032000: "강원도 고성군 죽왕면",
  4282033000: "강원도 고성군 토성면",
  4282034000: "강원도 고성군 수동면",
  4283000000: "강원도 양양군",
  4283025000: "강원도 양양군 양양읍",
  4283031000: "강원도 양양군 서면",
  4283032000: "강원도 양양군 손양면",
  4283033000: "강원도 양양군 현북면",
  4283034000: "강원도 양양군 현남면",
  4283035000: "강원도 양양군 강현면",
  4300000000: "충청북도",
  4311000000: "충청북도 청주시",
  4311100000: "충청북도 청주시 상당구",
  4311110100: "충청북도 청주시 상당구 영동",
  4311110200: "충청북도 청주시 상당구 북문로1가",
  4311110300: "충청북도 청주시 상당구 북문로2가",
  4311110400: "충청북도 청주시 상당구 북문로3가",
  4311110500: "충청북도 청주시 상당구 남문로1가",
  4311110600: "충청북도 청주시 상당구 남문로2가",
  4311110700: "충청북도 청주시 상당구 문화동",
  4311110800: "충청북도 청주시 상당구 서운동",
  4311110900: "충청북도 청주시 상당구 서문동",
  4311111000: "충청북도 청주시 상당구 남주동",
  4311111100: "충청북도 청주시 상당구 석교동",
  4311111200: "충청북도 청주시 상당구 수동",
  4311111700: "충청북도 청주시 상당구 탑동",
  4311111800: "충청북도 청주시 상당구 대성동",
  4311111900: "충청북도 청주시 상당구 영운동",
  4311112000: "충청북도 청주시 상당구 금천동",
  4311112100: "충청북도 청주시 상당구 용담동",
  4311112200: "충청북도 청주시 상당구 명암동",
  4311112300: "충청북도 청주시 상당구 산성동",
  4311112400: "충청북도 청주시 상당구 용암동",
  4311112500: "충청북도 청주시 상당구 용정동",
  4311112600: "충청북도 청주시 상당구 방서동",
  4311112700: "충청북도 청주시 상당구 평촌동",
  4311112800: "충청북도 청주시 상당구 지북동",
  4311112900: "충청북도 청주시 상당구 운동동",
  4311113000: "충청북도 청주시 상당구 월오동",
  4311131000: "충청북도 청주시 상당구 낭성면",
  4311132000: "충청북도 청주시 상당구 미원면",
  4311133000: "충청북도 청주시 상당구 가덕면",
  4311134000: "충청북도 청주시 상당구 남일면",
  4311135000: "충청북도 청주시 상당구 문의면",
  4311200000: "충청북도 청주시 서원구",
  4311210100: "충청북도 청주시 서원구 사직동",
  4311210200: "충청북도 청주시 서원구 사창동",
  4311210300: "충청북도 청주시 서원구 모충동",
  4311210400: "충청북도 청주시 서원구 산남동",
  4311210500: "충청북도 청주시 서원구 미평동",
  4311210600: "충청북도 청주시 서원구 분평동",
  4311210700: "충청북도 청주시 서원구 수곡동",
  4311210800: "충청북도 청주시 서원구 성화동",
  4311210900: "충청북도 청주시 서원구 개신동",
  4311211000: "충청북도 청주시 서원구 죽림동",
  4311211100: "충청북도 청주시 서원구 장성동",
  4311211200: "충청북도 청주시 서원구 장암동",
  4311231000: "충청북도 청주시 서원구 남이면",
  4311232000: "충청북도 청주시 서원구 현도면",
  4311300000: "충청북도 청주시 흥덕구",
  4311310400: "충청북도 청주시 흥덕구 운천동",
  4311310500: "충청북도 청주시 흥덕구 신봉동",
  4311311300: "충청북도 청주시 흥덕구 가경동",
  4311311400: "충청북도 청주시 흥덕구 복대동",
  4311311500: "충청북도 청주시 흥덕구 봉명동",
  4311311600: "충청북도 청주시 흥덕구 송정동",
  4311311700: "충청북도 청주시 흥덕구 강서동",
  4311311800: "충청북도 청주시 흥덕구 석곡동",
  4311311900: "충청북도 청주시 흥덕구 휴암동",
  4311312000: "충청북도 청주시 흥덕구 신전동",
  4311312100: "충청북도 청주시 흥덕구 현암동",
  4311312200: "충청북도 청주시 흥덕구 동막동",
  4311312300: "충청북도 청주시 흥덕구 수의동",
  4311312400: "충청북도 청주시 흥덕구 지동동",
  4311312500: "충청북도 청주시 흥덕구 서촌동",
  4311312600: "충청북도 청주시 흥덕구 신성동",
  4311312700: "충청북도 청주시 흥덕구 평동",
  4311312800: "충청북도 청주시 흥덕구 신대동",
  4311312900: "충청북도 청주시 흥덕구 남촌동",
  4311313000: "충청북도 청주시 흥덕구 내곡동",
  4311313100: "충청북도 청주시 흥덕구 상신동",
  4311313200: "충청북도 청주시 흥덕구 원평동",
  4311313300: "충청북도 청주시 흥덕구 문암동",
  4311313400: "충청북도 청주시 흥덕구 송절동",
  4311313500: "충청북도 청주시 흥덕구 화계동",
  4311313600: "충청북도 청주시 흥덕구 외북동",
  4311313700: "충청북도 청주시 흥덕구 향정동",
  4311313800: "충청북도 청주시 흥덕구 비하동",
  4311313900: "충청북도 청주시 흥덕구 석소동",
  4311314000: "충청북도 청주시 흥덕구 정봉동",
  4311314100: "충청북도 청주시 흥덕구 신촌동",
  4311325000: "충청북도 청주시 흥덕구 오송읍",
  4311331000: "충청북도 청주시 흥덕구 강내면",
  4311332000: "충청북도 청주시 흥덕구 옥산면",
  4311400000: "충청북도 청주시 청원구",
  4311410100: "충청북도 청주시 청원구 우암동",
  4311410200: "충청북도 청주시 청원구 내덕동",
  4311410300: "충청북도 청주시 청원구 율량동",
  4311410400: "충청북도 청주시 청원구 사천동",
  4311410500: "충청북도 청주시 청원구 주성동",
  4311410600: "충청북도 청주시 청원구 주중동",
  4311410700: "충청북도 청주시 청원구 정상동",
  4311410800: "충청북도 청주시 청원구 정하동",
  4311410900: "충청북도 청주시 청원구 정북동",
  4311411000: "충청북도 청주시 청원구 오동동",
  4311411100: "충청북도 청주시 청원구 외남동",
  4311411200: "충청북도 청주시 청원구 외평동",
  4311411300: "충청북도 청주시 청원구 외하동",
  4311425000: "충청북도 청주시 청원구 내수읍",
  4311425300: "충청북도 청주시 청원구 오창읍",
  4311431000: "충청북도 청주시 청원구 북이면",
  4313000000: "충청북도 충주시",
  4313010100: "충청북도 충주시 성내동",
  4313010200: "충청북도 충주시 성남동",
  4313010300: "충청북도 충주시 성서동",
  4313010400: "충청북도 충주시 충인동",
  4313010500: "충청북도 충주시 교현동",
  4313010600: "충청북도 충주시 용산동",
  4313010700: "충청북도 충주시 호암동",
  4313010800: "충청북도 충주시 직동",
  4313010900: "충청북도 충주시 단월동",
  4313011000: "충청북도 충주시 풍동",
  4313011100: "충청북도 충주시 가주동",
  4313011200: "충청북도 충주시 용관동",
  4313011300: "충청북도 충주시 용두동",
  4313011400: "충청북도 충주시 달천동",
  4313011500: "충청북도 충주시 봉방동",
  4313011600: "충청북도 충주시 칠금동",
  4313011800: "충청북도 충주시 연수동",
  4313011900: "충청북도 충주시 목행동",
  4313012000: "충청북도 충주시 용탄동",
  4313012100: "충청북도 충주시 종민동",
  4313012200: "충청북도 충주시 안림동",
  4313012300: "충청북도 충주시 목벌동",
  4313012400: "충청북도 충주시 충의동",
  4313012500: "충청북도 충주시 지현동",
  4313012700: "충청북도 충주시 문화동",
  4313012800: "충청북도 충주시 금릉동",
  4313025000: "충청북도 충주시 주덕읍",
  4313031000: "충청북도 충주시 살미면",
  4313032500: "충청북도 충주시 수안보면",
  4313033500: "충청북도 충주시 대소원면",
  4313035000: "충청북도 충주시 신니면",
  4313036000: "충청북도 충주시 노은면",
  4313037000: "충청북도 충주시 앙성면",
  4313038500: "충청북도 충주시 중앙탑면",
  4313039000: "충청북도 충주시 금가면",
  4313040000: "충청북도 충주시 동량면",
  4313041000: "충청북도 충주시 산척면",
  4313042000: "충청북도 충주시 엄정면",
  4313043000: "충청북도 충주시 소태면",
  4315000000: "충청북도 제천시",
  4315010100: "충청북도 제천시 의림동",
  4315010200: "충청북도 제천시 서부동",
  4315010300: "충청북도 제천시 동현동",
  4315010400: "충청북도 제천시 남천동",
  4315010500: "충청북도 제천시 교동",
  4315010600: "충청북도 제천시 중앙로1가",
  4315010700: "충청북도 제천시 중앙로2가",
  4315010800: "충청북도 제천시 명동",
  4315010900: "충청북도 제천시 화산동",
  4315011000: "충청북도 제천시 영천동",
  4315011100: "충청북도 제천시 하소동",
  4315011200: "충청북도 제천시 신월동",
  4315011300: "충청북도 제천시 청전동",
  4315011400: "충청북도 제천시 모산동",
  4315011500: "충청북도 제천시 고암동",
  4315011600: "충청북도 제천시 장락동",
  4315011700: "충청북도 제천시 흑석동",
  4315011800: "충청북도 제천시 두학동",
  4315011900: "충청북도 제천시 고명동",
  4315012000: "충청북도 제천시 신백동",
  4315012100: "충청북도 제천시 강제동",
  4315012200: "충청북도 제천시 명지동",
  4315012300: "충청북도 제천시 산곡동",
  4315012400: "충청북도 제천시 왕암동",
  4315012500: "충청북도 제천시 천남동",
  4315012600: "충청북도 제천시 신동",
  4315012700: "충청북도 제천시 자작동",
  4315012800: "충청북도 제천시 대랑동",
  4315025000: "충청북도 제천시 봉양읍",
  4315031000: "충청북도 제천시 금성면",
  4315032000: "충청북도 제천시 청풍면",
  4315033000: "충청북도 제천시 수산면",
  4315034000: "충청북도 제천시 덕산면",
  4315035000: "충청북도 제천시 한수면",
  4315036000: "충청북도 제천시 백운면",
  4315038000: "충청북도 제천시 송학면",
  4372000000: "충청북도 보은군",
  4372025000: "충청북도 보은군 보은읍",
  4372031500: "충청북도 보은군 속리산면",
  4372032500: "충청북도 보은군 장안면",
  4372033000: "충청북도 보은군 마로면",
  4372034000: "충청북도 보은군 탄부면",
  4372035000: "충청북도 보은군 삼승면",
  4372036000: "충청북도 보은군 수한면",
  4372037000: "충청북도 보은군 회남면",
  4372038500: "충청북도 보은군 회인면",
  4372039000: "충청북도 보은군 내북면",
  4372040000: "충청북도 보은군 산외면",
  4373000000: "충청북도 옥천군",
  4373025000: "충청북도 옥천군 옥천읍",
  4373031000: "충청북도 옥천군 동이면",
  4373032000: "충청북도 옥천군 안남면",
  4373033000: "충청북도 옥천군 안내면",
  4373034000: "충청북도 옥천군 청성면",
  4373035000: "충청북도 옥천군 청산면",
  4373036000: "충청북도 옥천군 이원면",
  4373037000: "충청북도 옥천군 군서면",
  4373038000: "충청북도 옥천군 군북면",
  4374000000: "충청북도 영동군",
  4374025000: "충청북도 영동군 영동읍",
  4374031000: "충청북도 영동군 용산면",
  4374032000: "충청북도 영동군 황간면",
  4374033500: "충청북도 영동군 추풍령면",
  4374034000: "충청북도 영동군 매곡면",
  4374035000: "충청북도 영동군 상촌면",
  4374036000: "충청북도 영동군 양강면",
  4374037000: "충청북도 영동군 용화면",
  4374038000: "충청북도 영동군 학산면",
  4374039000: "충청북도 영동군 양산면",
  4374040000: "충청북도 영동군 심천면",
  4374500000: "충청북도 증평군",
  4374525000: "충청북도 증평군 증평읍",
  4374531000: "충청북도 증평군 도안면",
  4375000000: "충청북도 진천군",
  4375025000: "충청북도 진천군 진천읍",
  4375025300: "충청북도 진천군 덕산읍",
  4375032000: "충청북도 진천군 초평면",
  4375033000: "충청북도 진천군 문백면",
  4375034000: "충청북도 진천군 백곡면",
  4375035000: "충청북도 진천군 이월면",
  4375037000: "충청북도 진천군 광혜원면",
  4376000000: "충청북도 괴산군",
  4376025000: "충청북도 괴산군 괴산읍",
  4376031000: "충청북도 괴산군 감물면",
  4376032000: "충청북도 괴산군 장연면",
  4376033000: "충청북도 괴산군 연풍면",
  4376034000: "충청북도 괴산군 칠성면",
  4376035000: "충청북도 괴산군 문광면",
  4376036000: "충청북도 괴산군 청천면",
  4376037000: "충청북도 괴산군 청안면",
  4376039000: "충청북도 괴산군 사리면",
  4376040000: "충청북도 괴산군 소수면",
  4376041000: "충청북도 괴산군 불정면",
  4377000000: "충청북도 음성군",
  4377025000: "충청북도 음성군 음성읍",
  4377025300: "충청북도 음성군 금왕읍",
  4377031000: "충청북도 음성군 소이면",
  4377032000: "충청북도 음성군 원남면",
  4377033000: "충청북도 음성군 맹동면",
  4377034000: "충청북도 음성군 대소면",
  4377035000: "충청북도 음성군 삼성면",
  4377036000: "충청북도 음성군 생극면",
  4377037000: "충청북도 음성군 감곡면",
  4380000000: "충청북도 단양군",
  4380025000: "충청북도 단양군 단양읍",
  4380025300: "충청북도 단양군 매포읍",
  4380031000: "충청북도 단양군 대강면",
  4380032000: "충청북도 단양군 가곡면",
  4380033000: "충청북도 단양군 영춘면",
  4380034000: "충청북도 단양군 어상천면",
  4380035000: "충청북도 단양군 적성면",
  4380036000: "충청북도 단양군 단성면",
  4400000000: "충청남도",
  4413000000: "충청남도 천안시",
  4413100000: "충청남도 천안시 동남구",
  4413110100: "충청남도 천안시 동남구 대흥동",
  4413110200: "충청남도 천안시 동남구 성황동",
  4413110300: "충청남도 천안시 동남구 문화동",
  4413110400: "충청남도 천안시 동남구 사직동",
  4413110500: "충청남도 천안시 동남구 영성동",
  4413110600: "충청남도 천안시 동남구 오룡동",
  4413110700: "충청남도 천안시 동남구 원성동",
  4413110800: "충청남도 천안시 동남구 구성동",
  4413110900: "충청남도 천안시 동남구 청수동",
  4413111000: "충청남도 천안시 동남구 삼룡동",
  4413111100: "충청남도 천안시 동남구 청당동",
  4413111200: "충청남도 천안시 동남구 유량동",
  4413111300: "충청남도 천안시 동남구 봉명동",
  4413111400: "충청남도 천안시 동남구 다가동",
  4413111500: "충청남도 천안시 동남구 용곡동",
  4413111600: "충청남도 천안시 동남구 신방동",
  4413111700: "충청남도 천안시 동남구 쌍용동",
  4413111800: "충청남도 천안시 동남구 신부동",
  4413111900: "충청남도 천안시 동남구 안서동",
  4413112000: "충청남도 천안시 동남구 구룡동",
  4413125000: "충청남도 천안시 동남구 목천읍",
  4413131000: "충청남도 천안시 동남구 풍세면",
  4413132000: "충청남도 천안시 동남구 광덕면",
  4413133000: "충청남도 천안시 동남구 북면",
  4413134000: "충청남도 천안시 동남구 성남면",
  4413135000: "충청남도 천안시 동남구 수신면",
  4413136000: "충청남도 천안시 동남구 병천면",
  4413137000: "충청남도 천안시 동남구 동면",
  4413300000: "충청남도 천안시 서북구",
  4413310100: "충청남도 천안시 서북구 와촌동",
  4413310200: "충청남도 천안시 서북구 성정동",
  4413310300: "충청남도 천안시 서북구 백석동",
  4413310400: "충청남도 천안시 서북구 두정동",
  4413310500: "충청남도 천안시 서북구 성성동",
  4413310600: "충청남도 천안시 서북구 차암동",
  4413310700: "충청남도 천안시 서북구 쌍용동",
  4413310800: "충청남도 천안시 서북구 불당동",
  4413310900: "충청남도 천안시 서북구 업성동",
  4413311000: "충청남도 천안시 서북구 신당동",
  4413311100: "충청남도 천안시 서북구 부대동",
  4413325000: "충청남도 천안시 서북구 성환읍",
  4413325300: "충청남도 천안시 서북구 성거읍",
  4413325600: "충청남도 천안시 서북구 직산읍",
  4413331000: "충청남도 천안시 서북구 입장면",
  4415000000: "충청남도 공주시",
  4415010100: "충청남도 공주시 반죽동",
  4415010200: "충청남도 공주시 봉황동",
  4415010300: "충청남도 공주시 중학동",
  4415010400: "충청남도 공주시 중동",
  4415010500: "충청남도 공주시 산성동",
  4415010600: "충청남도 공주시 교동",
  4415010700: "충청남도 공주시 웅진동",
  4415010800: "충청남도 공주시 금성동",
  4415010900: "충청남도 공주시 옥룡동",
  4415011000: "충청남도 공주시 금학동",
  4415011100: "충청남도 공주시 봉정동",
  4415011200: "충청남도 공주시 주미동",
  4415011300: "충청남도 공주시 태봉동",
  4415011400: "충청남도 공주시 오곡동",
  4415011500: "충청남도 공주시 신기동",
  4415011600: "충청남도 공주시 소학동",
  4415011700: "충청남도 공주시 상왕동",
  4415011800: "충청남도 공주시 무릉동",
  4415011900: "충청남도 공주시 월송동",
  4415012000: "충청남도 공주시 신관동",
  4415012100: "충청남도 공주시 금흥동",
  4415012200: "충청남도 공주시 쌍신동",
  4415012300: "충청남도 공주시 월미동",
  4415012400: "충청남도 공주시 검상동",
  4415012500: "충청남도 공주시 석장리동",
  4415012600: "충청남도 공주시 송선동",
  4415012700: "충청남도 공주시 동현동",
  4415025000: "충청남도 공주시 유구읍",
  4415031000: "충청남도 공주시 이인면",
  4415032000: "충청남도 공주시 탄천면",
  4415033000: "충청남도 공주시 계룡면",
  4415034000: "충청남도 공주시 반포면",
  4415036000: "충청남도 공주시 의당면",
  4415037000: "충청남도 공주시 정안면",
  4415038000: "충청남도 공주시 우성면",
  4415039000: "충청남도 공주시 사곡면",
  4415040000: "충청남도 공주시 신풍면",
  4418000000: "충청남도 보령시",
  4418010100: "충청남도 보령시 대천동",
  4418010200: "충청남도 보령시 죽정동",
  4418010300: "충청남도 보령시 화산동",
  4418010400: "충청남도 보령시 동대동",
  4418010500: "충청남도 보령시 명천동",
  4418010600: "충청남도 보령시 궁촌동",
  4418010700: "충청남도 보령시 내항동",
  4418010800: "충청남도 보령시 남곡동",
  4418010900: "충청남도 보령시 요암동",
  4418011000: "충청남도 보령시 신흑동",
  4418025000: "충청남도 보령시 웅천읍",
  4418031000: "충청남도 보령시 주포면",
  4418032000: "충청남도 보령시 오천면",
  4418033000: "충청남도 보령시 천북면",
  4418034000: "충청남도 보령시 청소면",
  4418035000: "충청남도 보령시 청라면",
  4418036000: "충청남도 보령시 남포면",
  4418038000: "충청남도 보령시 주산면",
  4418039000: "충청남도 보령시 미산면",
  4418040000: "충청남도 보령시 성주면",
  4418041000: "충청남도 보령시 주교면",
  4420000000: "충청남도 아산시",
  4420010100: "충청남도 아산시 온천동",
  4420010200: "충청남도 아산시 실옥동",
  4420010300: "충청남도 아산시 방축동",
  4420010400: "충청남도 아산시 기산동",
  4420010500: "충청남도 아산시 초사동",
  4420010600: "충청남도 아산시 신인동",
  4420010700: "충청남도 아산시 법곡동",
  4420010800: "충청남도 아산시 장존동",
  4420010900: "충청남도 아산시 좌부동",
  4420011000: "충청남도 아산시 읍내동",
  4420011100: "충청남도 아산시 풍기동",
  4420011200: "충청남도 아산시 용화동",
  4420011300: "충청남도 아산시 모종동",
  4420011400: "충청남도 아산시 권곡동",
  4420011500: "충청남도 아산시 배미동",
  4420011600: "충청남도 아산시 득산동",
  4420011700: "충청남도 아산시 점양동",
  4420011800: "충청남도 아산시 신동",
  4420011900: "충청남도 아산시 남동",
  4420025000: "충청남도 아산시 염치읍",
  4420025300: "충청남도 아산시 배방읍",
  4420031000: "충청남도 아산시 송악면",
  4420033000: "충청남도 아산시 탕정면",
  4420035000: "충청남도 아산시 음봉면",
  4420036000: "충청남도 아산시 둔포면",
  4420037000: "충청남도 아산시 영인면",
  4420038000: "충청남도 아산시 인주면",
  4420039000: "충청남도 아산시 선장면",
  4420040000: "충청남도 아산시 도고면",
  4420041000: "충청남도 아산시 신창면",
  4421000000: "충청남도 서산시",
  4421010100: "충청남도 서산시 읍내동",
  4421010200: "충청남도 서산시 동문동",
  4421010300: "충청남도 서산시 갈산동",
  4421010400: "충청남도 서산시 온석동",
  4421010500: "충청남도 서산시 잠홍동",
  4421010600: "충청남도 서산시 수석동",
  4421010700: "충청남도 서산시 석림동",
  4421010800: "충청남도 서산시 석남동",
  4421010900: "충청남도 서산시 예천동",
  4421011000: "충청남도 서산시 죽성동",
  4421011100: "충청남도 서산시 양대동",
  4421011200: "충청남도 서산시 오남동",
  4421011300: "충청남도 서산시 장동",
  4421011400: "충청남도 서산시 덕지천동",
  4421025000: "충청남도 서산시 대산읍",
  4421031000: "충청남도 서산시 인지면",
  4421032000: "충청남도 서산시 부석면",
  4421033000: "충청남도 서산시 팔봉면",
  4421034000: "충청남도 서산시 지곡면",
  4421036000: "충청남도 서산시 성연면",
  4421037000: "충청남도 서산시 음암면",
  4421038000: "충청남도 서산시 운산면",
  4421039000: "충청남도 서산시 해미면",
  4421040000: "충청남도 서산시 고북면",
  4423000000: "충청남도 논산시",
  4423010100: "충청남도 논산시 화지동",
  4423010200: "충청남도 논산시 반월동",
  4423010300: "충청남도 논산시 대교동",
  4423010400: "충청남도 논산시 부창동",
  4423010500: "충청남도 논산시 취암동",
  4423010600: "충청남도 논산시 등화동",
  4423010700: "충청남도 논산시 지산동",
  4423010800: "충청남도 논산시 덕지동",
  4423010900: "충청남도 논산시 내동",
  4423011000: "충청남도 논산시 강산동",
  4423011100: "충청남도 논산시 관촉동",
  4423025000: "충청남도 논산시 강경읍",
  4423025300: "충청남도 논산시 연무읍",
  4423031000: "충청남도 논산시 성동면",
  4423032000: "충청남도 논산시 광석면",
  4423033000: "충청남도 논산시 노성면",
  4423034000: "충청남도 논산시 상월면",
  4423035000: "충청남도 논산시 부적면",
  4423036000: "충청남도 논산시 연산면",
  4423038000: "충청남도 논산시 벌곡면",
  4423039000: "충청남도 논산시 양촌면",
  4423040000: "충청남도 논산시 가야곡면",
  4423041000: "충청남도 논산시 은진면",
  4423042000: "충청남도 논산시 채운면",
  4425000000: "충청남도 계룡시",
  4425010100: "충청남도 계룡시 금암동",
  4425031000: "충청남도 계룡시 두마면",
  4425031500: "충청남도 계룡시 엄사면",
  4425033000: "충청남도 계룡시 신도안면",
  4427000000: "충청남도 당진시",
  4427010100: "충청남도 당진시 읍내동",
  4427010200: "충청남도 당진시 채운동",
  4427010300: "충청남도 당진시 우두동",
  4427010400: "충청남도 당진시 원당동",
  4427010500: "충청남도 당진시 시곡동",
  4427010600: "충청남도 당진시 수청동",
  4427010700: "충청남도 당진시 대덕동",
  4427010800: "충청남도 당진시 행정동",
  4427010900: "충청남도 당진시 용연동",
  4427011000: "충청남도 당진시 사기소동",
  4427011100: "충청남도 당진시 구룡동",
  4427025000: "충청남도 당진시 합덕읍",
  4427025300: "충청남도 당진시 송악읍",
  4427031000: "충청남도 당진시 고대면",
  4427032000: "충청남도 당진시 석문면",
  4427033000: "충청남도 당진시 대호지면",
  4427034000: "충청남도 당진시 정미면",
  4427035000: "충청남도 당진시 면천면",
  4427036000: "충청남도 당진시 순성면",
  4427037000: "충청남도 당진시 우강면",
  4427038000: "충청남도 당진시 신평면",
  4427039000: "충청남도 당진시 송산면",
  4471000000: "충청남도 금산군",
  4471025000: "충청남도 금산군 금산읍",
  4471031000: "충청남도 금산군 금성면",
  4471032000: "충청남도 금산군 제원면",
  4471033000: "충청남도 금산군 부리면",
  4471034000: "충청남도 금산군 군북면",
  4471035000: "충청남도 금산군 남일면",
  4471036000: "충청남도 금산군 남이면",
  4471037000: "충청남도 금산군 진산면",
  4471038000: "충청남도 금산군 복수면",
  4471039000: "충청남도 금산군 추부면",
  4476000000: "충청남도 부여군",
  4476025000: "충청남도 부여군 부여읍",
  4476031000: "충청남도 부여군 규암면",
  4476032000: "충청남도 부여군 은산면",
  4476033000: "충청남도 부여군 외산면",
  4476034000: "충청남도 부여군 내산면",
  4476035000: "충청남도 부여군 구룡면",
  4476036000: "충청남도 부여군 홍산면",
  4476037000: "충청남도 부여군 옥산면",
  4476038000: "충청남도 부여군 남면",
  4476039000: "충청남도 부여군 충화면",
  4476040000: "충청남도 부여군 양화면",
  4476041000: "충청남도 부여군 임천면",
  4476042000: "충청남도 부여군 장암면",
  4476043000: "충청남도 부여군 세도면",
  4476044000: "충청남도 부여군 석성면",
  4476045000: "충청남도 부여군 초촌면",
  4477000000: "충청남도 서천군",
  4477025000: "충청남도 서천군 장항읍",
  4477025300: "충청남도 서천군 서천읍",
  4477031000: "충청남도 서천군 마서면",
  4477032000: "충청남도 서천군 화양면",
  4477033000: "충청남도 서천군 기산면",
  4477034000: "충청남도 서천군 한산면",
  4477035000: "충청남도 서천군 마산면",
  4477036000: "충청남도 서천군 시초면",
  4477037000: "충청남도 서천군 문산면",
  4477038000: "충청남도 서천군 판교면",
  4477039000: "충청남도 서천군 종천면",
  4477040000: "충청남도 서천군 비인면",
  4477041000: "충청남도 서천군 서면",
  4479000000: "충청남도 청양군",
  4479025000: "충청남도 청양군 청양읍",
  4479031000: "충청남도 청양군 운곡면",
  4479032000: "충청남도 청양군 대치면",
  4479033000: "충청남도 청양군 정산면",
  4479034000: "충청남도 청양군 목면",
  4479035000: "충청남도 청양군 청남면",
  4479036000: "충청남도 청양군 장평면",
  4479037000: "충청남도 청양군 남양면",
  4479038000: "충청남도 청양군 화성면",
  4479039000: "충청남도 청양군 비봉면",
  4480000000: "충청남도 홍성군",
  4480025000: "충청남도 홍성군 홍성읍",
  4480025300: "충청남도 홍성군 광천읍",
  4480025600: "충청남도 홍성군 홍북읍",
  4480032000: "충청남도 홍성군 금마면",
  4480033000: "충청남도 홍성군 홍동면",
  4480034000: "충청남도 홍성군 장곡면",
  4480035000: "충청남도 홍성군 은하면",
  4480036000: "충청남도 홍성군 결성면",
  4480037000: "충청남도 홍성군 서부면",
  4480038000: "충청남도 홍성군 갈산면",
  4480039000: "충청남도 홍성군 구항면",
  4481000000: "충청남도 예산군",
  4481025000: "충청남도 예산군 예산읍",
  4481025300: "충청남도 예산군 삽교읍",
  4481031000: "충청남도 예산군 대술면",
  4481032000: "충청남도 예산군 신양면",
  4481033000: "충청남도 예산군 광시면",
  4481034000: "충청남도 예산군 대흥면",
  4481035000: "충청남도 예산군 응봉면",
  4481036000: "충청남도 예산군 덕산면",
  4481037000: "충청남도 예산군 봉산면",
  4481038000: "충청남도 예산군 고덕면",
  4481039000: "충청남도 예산군 신암면",
  4481040000: "충청남도 예산군 오가면",
  4482500000: "충청남도 태안군",
  4482525000: "충청남도 태안군 태안읍",
  4482525300: "충청남도 태안군 안면읍",
  4482531000: "충청남도 태안군 고남면",
  4482532000: "충청남도 태안군 남면",
  4482533000: "충청남도 태안군 근흥면",
  4482534000: "충청남도 태안군 소원면",
  4482535000: "충청남도 태안군 원북면",
  4482536000: "충청남도 태안군 이원면",
  4500000000: "전라북도",
  4511000000: "전라북도 전주시",
  4511100000: "전라북도 전주시 완산구",
  4511110100: "전라북도 전주시 완산구 중앙동1가",
  4511110200: "전라북도 전주시 완산구 중앙동2가",
  4511110300: "전라북도 전주시 완산구 중앙동3가",
  4511110400: "전라북도 전주시 완산구 중앙동4가",
  4511110500: "전라북도 전주시 완산구 경원동1가",
  4511110600: "전라북도 전주시 완산구 경원동2가",
  4511110700: "전라북도 전주시 완산구 경원동3가",
  4511110800: "전라북도 전주시 완산구 풍남동1가",
  4511110900: "전라북도 전주시 완산구 풍남동2가",
  4511111000: "전라북도 전주시 완산구 풍남동3가",
  4511111100: "전라북도 전주시 완산구 전동",
  4511111200: "전라북도 전주시 완산구 전동3가",
  4511111300: "전라북도 전주시 완산구 다가동1가",
  4511111400: "전라북도 전주시 완산구 다가동2가",
  4511111500: "전라북도 전주시 완산구 다가동3가",
  4511111600: "전라북도 전주시 완산구 다가동4가",
  4511111700: "전라북도 전주시 완산구 고사동",
  4511111800: "전라북도 전주시 완산구 교동",
  4511111900: "전라북도 전주시 완산구 태평동",
  4511112000: "전라북도 전주시 완산구 중노송동",
  4511112100: "전라북도 전주시 완산구 남노송동",
  4511112200: "전라북도 전주시 완산구 동완산동",
  4511112300: "전라북도 전주시 완산구 서완산동1가",
  4511112400: "전라북도 전주시 완산구 서완산동2가",
  4511112500: "전라북도 전주시 완산구 동서학동",
  4511112600: "전라북도 전주시 완산구 서서학동",
  4511112700: "전라북도 전주시 완산구 중화산동1가",
  4511112800: "전라북도 전주시 완산구 중화산동2가",
  4511112900: "전라북도 전주시 완산구 서신동",
  4511113000: "전라북도 전주시 완산구 석구동",
  4511113100: "전라북도 전주시 완산구 원당동",
  4511113200: "전라북도 전주시 완산구 평화동1가",
  4511113300: "전라북도 전주시 완산구 평화동2가",
  4511113400: "전라북도 전주시 완산구 평화동3가",
  4511113500: "전라북도 전주시 완산구 중인동",
  4511113600: "전라북도 전주시 완산구 용복동",
  4511113700: "전라북도 전주시 완산구 삼천동1가",
  4511113800: "전라북도 전주시 완산구 삼천동2가",
  4511113900: "전라북도 전주시 완산구 삼천동3가",
  4511114000: "전라북도 전주시 완산구 효자동1가",
  4511114100: "전라북도 전주시 완산구 효자동2가",
  4511114200: "전라북도 전주시 완산구 효자동3가",
  4511114300: "전라북도 전주시 완산구 대성동",
  4511114400: "전라북도 전주시 완산구 색장동",
  4511114500: "전라북도 전주시 완산구 상림동",
  4511114700: "전라북도 전주시 완산구 서노송동",
  4511300000: "전라북도 전주시 덕진구",
  4511310200: "전라북도 전주시 덕진구 진북동",
  4511310300: "전라북도 전주시 덕진구 인후동1가",
  4511310400: "전라북도 전주시 덕진구 인후동2가",
  4511310500: "전라북도 전주시 덕진구 덕진동1가",
  4511310600: "전라북도 전주시 덕진구 덕진동2가",
  4511310700: "전라북도 전주시 덕진구 금암동",
  4511310800: "전라북도 전주시 덕진구 팔복동1가",
  4511310900: "전라북도 전주시 덕진구 팔복동2가",
  4511311000: "전라북도 전주시 덕진구 팔복동3가",
  4511311100: "전라북도 전주시 덕진구 산정동",
  4511311200: "전라북도 전주시 덕진구 금상동",
  4511311300: "전라북도 전주시 덕진구 우아동1가",
  4511311400: "전라북도 전주시 덕진구 우아동2가",
  4511311500: "전라북도 전주시 덕진구 우아동3가",
  4511311600: "전라북도 전주시 덕진구 호성동1가",
  4511311700: "전라북도 전주시 덕진구 호성동2가",
  4511311800: "전라북도 전주시 덕진구 호성동3가",
  4511311900: "전라북도 전주시 덕진구 전미동1가",
  4511312000: "전라북도 전주시 덕진구 전미동2가",
  4511312100: "전라북도 전주시 덕진구 송천동1가",
  4511312200: "전라북도 전주시 덕진구 송천동2가",
  4511312300: "전라북도 전주시 덕진구 반월동",
  4511312400: "전라북도 전주시 덕진구 화전동",
  4511312500: "전라북도 전주시 덕진구 용정동",
  4511312600: "전라북도 전주시 덕진구 성덕동",
  4511312700: "전라북도 전주시 덕진구 원동",
  4511312900: "전라북도 전주시 덕진구 고랑동",
  4511313000: "전라북도 전주시 덕진구 여의동",
  4511313100: "전라북도 전주시 덕진구 만성동",
  4511313200: "전라북도 전주시 덕진구 장동",
  4511313300: "전라북도 전주시 덕진구 팔복동4가",
  4511313400: "전라북도 전주시 덕진구 도도동",
  4511313500: "전라북도 전주시 덕진구 강흥동",
  4511313600: "전라북도 전주시 덕진구 도덕동",
  4511313700: "전라북도 전주시 덕진구 남정동",
  4511313800: "전라북도 전주시 덕진구 중동",
  4511313900: "전라북도 전주시 덕진구 여의동2가",
  4513000000: "전라북도 군산시",
  4513010100: "전라북도 군산시 해망동",
  4513010200: "전라북도 군산시 신흥동",
  4513010300: "전라북도 군산시 금동",
  4513010400: "전라북도 군산시 월명동",
  4513010500: "전라북도 군산시 신창동",
  4513010600: "전라북도 군산시 오룡동",
  4513010700: "전라북도 군산시 금광동",
  4513010800: "전라북도 군산시 신풍동",
  4513010900: "전라북도 군산시 송풍동",
  4513011000: "전라북도 군산시 문화동",
  4513011100: "전라북도 군산시 삼학동",
  4513011200: "전라북도 군산시 선양동",
  4513011300: "전라북도 군산시 둔율동",
  4513011400: "전라북도 군산시 창성동",
  4513011500: "전라북도 군산시 명산동",
  4513011600: "전라북도 군산시 송창동",
  4513011700: "전라북도 군산시 개복동",
  4513011800: "전라북도 군산시 중앙로1가",
  4513011900: "전라북도 군산시 영화동",
  4513012000: "전라북도 군산시 장미동",
  4513012100: "전라북도 군산시 중앙로2가",
  4513012200: "전라북도 군산시 영동",
  4513012300: "전라북도 군산시 신영동",
  4513012400: "전라북도 군산시 죽성동",
  4513012500: "전라북도 군산시 평화동",
  4513012600: "전라북도 군산시 중앙로3가",
  4513012700: "전라북도 군산시 대명동",
  4513012800: "전라북도 군산시 장재동",
  4513012900: "전라북도 군산시 미원동",
  4513013000: "전라북도 군산시 중동",
  4513013100: "전라북도 군산시 금암동",
  4513013200: "전라북도 군산시 동흥남동",
  4513013300: "전라북도 군산시 서흥남동",
  4513013400: "전라북도 군산시 조촌동",
  4513013500: "전라북도 군산시 경장동",
  4513013600: "전라북도 군산시 경암동",
  4513013700: "전라북도 군산시 구암동",
  4513013800: "전라북도 군산시 내흥동",
  4513013900: "전라북도 군산시 개정동",
  4513014000: "전라북도 군산시 사정동",
  4513014100: "전라북도 군산시 수송동",
  4513014200: "전라북도 군산시 미장동",
  4513014300: "전라북도 군산시 지곡동",
  4513014400: "전라북도 군산시 나운동",
  4513014500: "전라북도 군산시 미룡동",
  4513014600: "전라북도 군산시 소룡동",
  4513014700: "전라북도 군산시 오식도동",
  4513014800: "전라북도 군산시 비응도동",
  4513014900: "전라북도 군산시 신관동",
  4513015000: "전라북도 군산시 개사동",
  4513015100: "전라북도 군산시 산북동",
  4513015200: "전라북도 군산시 내초동",
  4513025000: "전라북도 군산시 옥구읍",
  4513031000: "전라북도 군산시 옥산면",
  4513032000: "전라북도 군산시 회현면",
  4513033000: "전라북도 군산시 임피면",
  4513034000: "전라북도 군산시 서수면",
  4513035000: "전라북도 군산시 대야면",
  4513036000: "전라북도 군산시 개정면",
  4513037000: "전라북도 군산시 성산면",
  4513038000: "전라북도 군산시 나포면",
  4513039000: "전라북도 군산시 옥도면",
  4513040000: "전라북도 군산시 옥서면",
  4514000000: "전라북도 익산시",
  4514010100: "전라북도 익산시 창인동1가",
  4514010200: "전라북도 익산시 창인동2가",
  4514010300: "전라북도 익산시 중앙동1가",
  4514010400: "전라북도 익산시 중앙동2가",
  4514010500: "전라북도 익산시 중앙동3가",
  4514010600: "전라북도 익산시 평화동",
  4514010700: "전라북도 익산시 갈산동",
  4514010800: "전라북도 익산시 주현동",
  4514010900: "전라북도 익산시 인화동1가",
  4514011000: "전라북도 익산시 인화동2가",
  4514011100: "전라북도 익산시 동산동",
  4514011200: "전라북도 익산시 마동",
  4514011300: "전라북도 익산시 남중동",
  4514011400: "전라북도 익산시 모현동1가",
  4514011500: "전라북도 익산시 모현동2가",
  4514011600: "전라북도 익산시 송학동",
  4514011700: "전라북도 익산시 목천동",
  4514011800: "전라북도 익산시 만석동",
  4514011900: "전라북도 익산시 현영동",
  4514012000: "전라북도 익산시 신용동",
  4514012100: "전라북도 익산시 신동",
  4514012200: "전라북도 익산시 영등동",
  4514012300: "전라북도 익산시 어양동",
  4514012400: "전라북도 익산시 신흥동",
  4514012500: "전라북도 익산시 금강동",
  4514012600: "전라북도 익산시 석탄동",
  4514012700: "전라북도 익산시 팔봉동",
  4514012800: "전라북도 익산시 덕기동",
  4514012900: "전라북도 익산시 석왕동",
  4514013000: "전라북도 익산시 은기동",
  4514013100: "전라북도 익산시 정족동",
  4514013200: "전라북도 익산시 임상동",
  4514013300: "전라북도 익산시 월성동",
  4514013400: "전라북도 익산시 부송동",
  4514013500: "전라북도 익산시 용제동",
  4514013600: "전라북도 익산시 석암동",
  4514025000: "전라북도 익산시 함열읍",
  4514031000: "전라북도 익산시 오산면",
  4514032000: "전라북도 익산시 황등면",
  4514033000: "전라북도 익산시 함라면",
  4514034000: "전라북도 익산시 웅포면",
  4514035000: "전라북도 익산시 성당면",
  4514036000: "전라북도 익산시 용안면",
  4514037000: "전라북도 익산시 낭산면",
  4514038000: "전라북도 익산시 망성면",
  4514039000: "전라북도 익산시 여산면",
  4514040000: "전라북도 익산시 금마면",
  4514041000: "전라북도 익산시 왕궁면",
  4514042000: "전라북도 익산시 춘포면",
  4514043000: "전라북도 익산시 삼기면",
  4514044000: "전라북도 익산시 용동면",
  4518000000: "전라북도 정읍시",
  4518010100: "전라북도 정읍시 수성동",
  4518010200: "전라북도 정읍시 장명동",
  4518010300: "전라북도 정읍시 상동",
  4518010400: "전라북도 정읍시 시기동",
  4518010500: "전라북도 정읍시 연지동",
  4518010600: "전라북도 정읍시 농소동",
  4518010700: "전라북도 정읍시 하모동",
  4518010800: "전라북도 정읍시 상평동",
  4518010900: "전라북도 정읍시 과교동",
  4518011000: "전라북도 정읍시 삼산동",
  4518011100: "전라북도 정읍시 진산동",
  4518011200: "전라북도 정읍시 금붕동",
  4518011300: "전라북도 정읍시 송산동",
  4518011400: "전라북도 정읍시 신월동",
  4518011500: "전라북도 정읍시 용산동",
  4518011600: "전라북도 정읍시 교암동",
  4518011700: "전라북도 정읍시 부전동",
  4518011800: "전라북도 정읍시 쌍암동",
  4518011900: "전라북도 정읍시 내장동",
  4518012000: "전라북도 정읍시 영파동",
  4518012100: "전라북도 정읍시 하북동",
  4518012200: "전라북도 정읍시 구룡동",
  4518012300: "전라북도 정읍시 흑암동",
  4518012400: "전라북도 정읍시 용계동",
  4518012500: "전라북도 정읍시 공평동",
  4518012600: "전라북도 정읍시 망제동",
  4518012700: "전라북도 정읍시 신정동",
  4518025000: "전라북도 정읍시 신태인읍",
  4518031000: "전라북도 정읍시 북면",
  4518032000: "전라북도 정읍시 입암면",
  4518033000: "전라북도 정읍시 소성면",
  4518034000: "전라북도 정읍시 고부면",
  4518035000: "전라북도 정읍시 영원면",
  4518036000: "전라북도 정읍시 덕천면",
  4518037000: "전라북도 정읍시 이평면",
  4518038000: "전라북도 정읍시 정우면",
  4518039000: "전라북도 정읍시 태인면",
  4518040000: "전라북도 정읍시 감곡면",
  4518041000: "전라북도 정읍시 옹동면",
  4518042000: "전라북도 정읍시 칠보면",
  4518043000: "전라북도 정읍시 산내면",
  4518044000: "전라북도 정읍시 산외면",
  4519000000: "전라북도 남원시",
  4519010100: "전라북도 남원시 동충동",
  4519010200: "전라북도 남원시 하정동",
  4519010300: "전라북도 남원시 죽항동",
  4519010400: "전라북도 남원시 쌍교동",
  4519010500: "전라북도 남원시 천거동",
  4519010600: "전라북도 남원시 금동",
  4519010700: "전라북도 남원시 조산동",
  4519010800: "전라북도 남원시 왕정동",
  4519010900: "전라북도 남원시 신정동",
  4519011000: "전라북도 남원시 화정동",
  4519011100: "전라북도 남원시 향교동",
  4519011200: "전라북도 남원시 용정동",
  4519011300: "전라북도 남원시 광치동",
  4519011400: "전라북도 남원시 내척동",
  4519011500: "전라북도 남원시 산곡동",
  4519011600: "전라북도 남원시 도통동",
  4519011700: "전라북도 남원시 월락동",
  4519011800: "전라북도 남원시 고죽동",
  4519011900: "전라북도 남원시 식정동",
  4519012000: "전라북도 남원시 갈치동",
  4519012100: "전라북도 남원시 노암동",
  4519012200: "전라북도 남원시 어현동",
  4519012300: "전라북도 남원시 신촌동",
  4519025000: "전라북도 남원시 운봉읍",
  4519031000: "전라북도 남원시 주천면",
  4519032000: "전라북도 남원시 수지면",
  4519033000: "전라북도 남원시 송동면",
  4519034000: "전라북도 남원시 주생면",
  4519035000: "전라북도 남원시 금지면",
  4519036000: "전라북도 남원시 대강면",
  4519037000: "전라북도 남원시 대산면",
  4519038000: "전라북도 남원시 사매면",
  4519039000: "전라북도 남원시 덕과면",
  4519040000: "전라북도 남원시 보절면",
  4519041000: "전라북도 남원시 산동면",
  4519042000: "전라북도 남원시 이백면",
  4519045000: "전라북도 남원시 아영면",
  4519046000: "전라북도 남원시 산내면",
  4519047000: "전라북도 남원시 인월면",
  4521000000: "전라북도 김제시",
  4521010100: "전라북도 김제시 요촌동",
  4521010200: "전라북도 김제시 신풍동",
  4521010300: "전라북도 김제시 용동",
  4521010400: "전라북도 김제시 검산동",
  4521010500: "전라북도 김제시 순동",
  4521010600: "전라북도 김제시 백학동",
  4521010700: "전라북도 김제시 서암동",
  4521010800: "전라북도 김제시 신곡동",
  4521010900: "전라북도 김제시 교동",
  4521011000: "전라북도 김제시 옥산동",
  4521011100: "전라북도 김제시 갈공동",
  4521011200: "전라북도 김제시 하동",
  4521011300: "전라북도 김제시 흥사동",
  4521011400: "전라북도 김제시 상동동",
  4521011500: "전라북도 김제시 월성동",
  4521011600: "전라북도 김제시 황산동",
  4521011700: "전라북도 김제시 난봉동",
  4521011800: "전라북도 김제시 오정동",
  4521011900: "전라북도 김제시 복죽동",
  4521012000: "전라북도 김제시 입석동",
  4521012100: "전라북도 김제시 장화동",
  4521012200: "전라북도 김제시 신덕동",
  4521012300: "전라북도 김제시 월봉동",
  4521012400: "전라북도 김제시 신월동",
  4521012500: "전라북도 김제시 연정동",
  4521012600: "전라북도 김제시 명덕동",
  4521012700: "전라북도 김제시 제월동",
  4521012800: "전라북도 김제시 도장동",
  4521012900: "전라북도 김제시 서정동",
  4521013000: "전라북도 김제시 양전동",
  4521025000: "전라북도 김제시 만경읍",
  4521032000: "전라북도 김제시 죽산면",
  4521033000: "전라북도 김제시 백산면",
  4521034000: "전라북도 김제시 용지면",
  4521035000: "전라북도 김제시 백구면",
  4521036000: "전라북도 김제시 부량면",
  4521038000: "전라북도 김제시 공덕면",
  4521039000: "전라북도 김제시 청하면",
  4521040000: "전라북도 김제시 성덕면",
  4521041000: "전라북도 김제시 진봉면",
  4521042000: "전라북도 김제시 금구면",
  4521043000: "전라북도 김제시 봉남면",
  4521044000: "전라북도 김제시 황산면",
  4521045000: "전라북도 김제시 금산면",
  4521046000: "전라북도 김제시 광활면",
  4571000000: "전라북도 완주군",
  4571025000: "전라북도 완주군 삼례읍",
  4571025300: "전라북도 완주군 봉동읍",
  4571025600: "전라북도 완주군 용진읍",
  4571032000: "전라북도 완주군 상관면",
  4571033000: "전라북도 완주군 이서면",
  4571034000: "전라북도 완주군 소양면",
  4571035000: "전라북도 완주군 구이면",
  4571036000: "전라북도 완주군 고산면",
  4571037000: "전라북도 완주군 비봉면",
  4571038000: "전라북도 완주군 운주면",
  4571039000: "전라북도 완주군 화산면",
  4571040000: "전라북도 완주군 동상면",
  4571041000: "전라북도 완주군 경천면",
  4572000000: "전라북도 진안군",
  4572025000: "전라북도 진안군 진안읍",
  4572031000: "전라북도 진안군 용담면",
  4572032000: "전라북도 진안군 안천면",
  4572033000: "전라북도 진안군 동향면",
  4572034000: "전라북도 진안군 상전면",
  4572035000: "전라북도 진안군 백운면",
  4572036000: "전라북도 진안군 성수면",
  4572037000: "전라북도 진안군 마령면",
  4572038000: "전라북도 진안군 부귀면",
  4572039000: "전라북도 진안군 정천면",
  4572040000: "전라북도 진안군 주천면",
  4573000000: "전라북도 무주군",
  4573025000: "전라북도 무주군 무주읍",
  4573031000: "전라북도 무주군 무풍면",
  4573032000: "전라북도 무주군 설천면",
  4573033000: "전라북도 무주군 적상면",
  4573034000: "전라북도 무주군 안성면",
  4573035000: "전라북도 무주군 부남면",
  4574000000: "전라북도 장수군",
  4574025000: "전라북도 장수군 장수읍",
  4574031000: "전라북도 장수군 산서면",
  4574032000: "전라북도 장수군 번암면",
  4574033500: "전라북도 장수군 장계면",
  4574034000: "전라북도 장수군 천천면",
  4574035000: "전라북도 장수군 계남면",
  4574036000: "전라북도 장수군 계북면",
  4575000000: "전라북도 임실군",
  4575025000: "전라북도 임실군 임실읍",
  4575031000: "전라북도 임실군 청웅면",
  4575032000: "전라북도 임실군 운암면",
  4575033000: "전라북도 임실군 신평면",
  4575034000: "전라북도 임실군 성수면",
  4575035500: "전라북도 임실군 오수면",
  4575036000: "전라북도 임실군 신덕면",
  4575037000: "전라북도 임실군 삼계면",
  4575038000: "전라북도 임실군 관촌면",
  4575039000: "전라북도 임실군 강진면",
  4575040000: "전라북도 임실군 덕치면",
  4575041000: "전라북도 임실군 지사면",
  4577000000: "전라북도 순창군",
  4577025000: "전라북도 순창군 순창읍",
  4577031000: "전라북도 순창군 인계면",
  4577032000: "전라북도 순창군 동계면",
  4577033000: "전라북도 순창군 풍산면",
  4577034000: "전라북도 순창군 금과면",
  4577035000: "전라북도 순창군 팔덕면",
  4577036000: "전라북도 순창군 쌍치면",
  4577037000: "전라북도 순창군 복흥면",
  4577038000: "전라북도 순창군 적성면",
  4577039000: "전라북도 순창군 유등면",
  4577040000: "전라북도 순창군 구림면",
  4579000000: "전라북도 고창군",
  4579025000: "전라북도 고창군 고창읍",
  4579031000: "전라북도 고창군 고수면",
  4579032000: "전라북도 고창군 아산면",
  4579033000: "전라북도 고창군 무장면",
  4579034000: "전라북도 고창군 공음면",
  4579035000: "전라북도 고창군 상하면",
  4579036000: "전라북도 고창군 해리면",
  4579037000: "전라북도 고창군 성송면",
  4579038000: "전라북도 고창군 대산면",
  4579039000: "전라북도 고창군 심원면",
  4579040000: "전라북도 고창군 흥덕면",
  4579041000: "전라북도 고창군 성내면",
  4579042000: "전라북도 고창군 신림면",
  4579043000: "전라북도 고창군 부안면",
  4580000000: "전라북도 부안군",
  4580025000: "전라북도 부안군 부안읍",
  4580031000: "전라북도 부안군 주산면",
  4580032000: "전라북도 부안군 동진면",
  4580033000: "전라북도 부안군 행안면",
  4580034000: "전라북도 부안군 계화면",
  4580035000: "전라북도 부안군 보안면",
  4580036000: "전라북도 부안군 변산면",
  4580037000: "전라북도 부안군 진서면",
  4580038000: "전라북도 부안군 백산면",
  4580039000: "전라북도 부안군 상서면",
  4580040000: "전라북도 부안군 하서면",
  4580041000: "전라북도 부안군 줄포면",
  4580042000: "전라북도 부안군 위도면",
  4600000000: "전라남도",
  4611000000: "전라남도 목포시",
  4611010100: "전라남도 목포시 용당동",
  4611010200: "전라남도 목포시 산정동",
  4611010300: "전라남도 목포시 연산동",
  4611010400: "전라남도 목포시 대성동",
  4611010500: "전라남도 목포시 양동",
  4611010600: "전라남도 목포시 북교동",
  4611010700: "전라남도 목포시 남교동",
  4611010800: "전라남도 목포시 호남동",
  4611010900: "전라남도 목포시 대안동",
  4611011000: "전라남도 목포시 창평동",
  4611011100: "전라남도 목포시 명륜동",
  4611011200: "전라남도 목포시 죽동",
  4611011300: "전라남도 목포시 무안동",
  4611011400: "전라남도 목포시 측후동",
  4611011500: "전라남도 목포시 상락동1가",
  4611011600: "전라남도 목포시 상락동2가",
  4611011700: "전라남도 목포시 복만동",
  4611011800: "전라남도 목포시 동명동",
  4611011900: "전라남도 목포시 광동1가",
  4611012000: "전라남도 목포시 광동2가",
  4611012100: "전라남도 목포시 광동3가",
  4611012200: "전라남도 목포시 영해동1가",
  4611012300: "전라남도 목포시 영해동2가",
  4611012400: "전라남도 목포시 행복동1가",
  4611012500: "전라남도 목포시 행복동2가",
  4611012600: "전라남도 목포시 축복동1가",
  4611012700: "전라남도 목포시 축복동2가",
  4611012800: "전라남도 목포시 축복동3가",
  4611012900: "전라남도 목포시 보광동1가",
  4611013000: "전라남도 목포시 보광동2가",
  4611013100: "전라남도 목포시 보광동3가",
  4611013200: "전라남도 목포시 유달동",
  4611013300: "전라남도 목포시 대의동1가",
  4611013400: "전라남도 목포시 대의동2가",
  4611013500: "전라남도 목포시 대의동3가",
  4611013600: "전라남도 목포시 중앙동1가",
  4611013700: "전라남도 목포시 중앙동2가",
  4611013800: "전라남도 목포시 중앙동3가",
  4611013900: "전라남도 목포시 만호동",
  4611014000: "전라남도 목포시 수강동1가",
  4611014100: "전라남도 목포시 수강동2가",
  4611014200: "전라남도 목포시 해안동1가",
  4611014300: "전라남도 목포시 해안동2가",
  4611014400: "전라남도 목포시 해안동3가",
  4611014500: "전라남도 목포시 해안동4가",
  4611014600: "전라남도 목포시 항동",
  4611014700: "전라남도 목포시 중동1가",
  4611014800: "전라남도 목포시 중동2가",
  4611014900: "전라남도 목포시 유동",
  4611015000: "전라남도 목포시 금동1가",
  4611015100: "전라남도 목포시 금동2가",
  4611015200: "전라남도 목포시 경동1가",
  4611015300: "전라남도 목포시 경동2가",
  4611015400: "전라남도 목포시 서산동",
  4611015500: "전라남도 목포시 금화동",
  4611015600: "전라남도 목포시 온금동",
  4611015700: "전라남도 목포시 죽교동",
  4611015800: "전라남도 목포시 상동",
  4611015900: "전라남도 목포시 용해동",
  4611016000: "전라남도 목포시 석현동",
  4611016100: "전라남도 목포시 달동",
  4611016200: "전라남도 목포시 율도동",
  4611016300: "전라남도 목포시 대양동",
  4611016400: "전라남도 목포시 옥암동",
  4613000000: "전라남도 여수시",
  4613010100: "전라남도 여수시 종화동",
  4613010200: "전라남도 여수시 수정동",
  4613010300: "전라남도 여수시 공화동",
  4613010400: "전라남도 여수시 관문동",
  4613010500: "전라남도 여수시 고소동",
  4613010600: "전라남도 여수시 동산동",
  4613010700: "전라남도 여수시 중앙동",
  4613010800: "전라남도 여수시 교동",
  4613010900: "전라남도 여수시 군자동",
  4613011000: "전라남도 여수시 충무동",
  4613011100: "전라남도 여수시 연등동",
  4613011200: "전라남도 여수시 광무동",
  4613011300: "전라남도 여수시 서교동",
  4613011400: "전라남도 여수시 봉강동",
  4613011500: "전라남도 여수시 봉산동",
  4613011600: "전라남도 여수시 남산동",
  4613011700: "전라남도 여수시 국동",
  4613011800: "전라남도 여수시 신월동",
  4613011900: "전라남도 여수시 여서동",
  4613012000: "전라남도 여수시 문수동",
  4613012100: "전라남도 여수시 오림동",
  4613012200: "전라남도 여수시 미평동",
  4613012300: "전라남도 여수시 둔덕동",
  4613012400: "전라남도 여수시 오천동",
  4613012500: "전라남도 여수시 만흥동",
  4613012600: "전라남도 여수시 덕충동",
  4613012700: "전라남도 여수시 경호동",
  4613012800: "전라남도 여수시 학동",
  4613012900: "전라남도 여수시 학용동",
  4613013000: "전라남도 여수시 안산동",
  4613013100: "전라남도 여수시 소호동",
  4613013200: "전라남도 여수시 시전동",
  4613013300: "전라남도 여수시 신기동",
  4613013400: "전라남도 여수시 웅천동",
  4613013500: "전라남도 여수시 선원동",
  4613013600: "전라남도 여수시 여천동",
  4613013700: "전라남도 여수시 화장동",
  4613013800: "전라남도 여수시 주삼동",
  4613013900: "전라남도 여수시 봉계동",
  4613014000: "전라남도 여수시 해산동",
  4613014100: "전라남도 여수시 화치동",
  4613014200: "전라남도 여수시 월하동",
  4613014300: "전라남도 여수시 평여동",
  4613014400: "전라남도 여수시 중흥동",
  4613014500: "전라남도 여수시 적량동",
  4613014600: "전라남도 여수시 월내동",
  4613014700: "전라남도 여수시 묘도동",
  4613014800: "전라남도 여수시 낙포동",
  4613014900: "전라남도 여수시 신덕동",
  4613015000: "전라남도 여수시 상암동",
  4613015100: "전라남도 여수시 호명동",
  4613025000: "전라남도 여수시 돌산읍",
  4613031000: "전라남도 여수시 소라면",
  4613032000: "전라남도 여수시 율촌면",
  4613033000: "전라남도 여수시 화양면",
  4613034000: "전라남도 여수시 남면",
  4613035000: "전라남도 여수시 화정면",
  4613036000: "전라남도 여수시 삼산면",
  4615000000: "전라남도 순천시",
  4615010100: "전라남도 순천시 삼거동",
  4615010200: "전라남도 순천시 와룡동",
  4615010300: "전라남도 순천시 영동",
  4615010400: "전라남도 순천시 옥천동",
  4615010500: "전라남도 순천시 행동",
  4615010600: "전라남도 순천시 금곡동",
  4615010700: "전라남도 순천시 매곡동",
  4615010800: "전라남도 순천시 석현동",
  4615010900: "전라남도 순천시 가곡동",
  4615011000: "전라남도 순천시 용당동",
  4615011100: "전라남도 순천시 조곡동",
  4615011200: "전라남도 순천시 생목동",
  4615011300: "전라남도 순천시 덕암동",
  4615011400: "전라남도 순천시 연향동",
  4615011500: "전라남도 순천시 풍덕동",
  4615011600: "전라남도 순천시 남정동",
  4615011700: "전라남도 순천시 인제동",
  4615011800: "전라남도 순천시 저전동",
  4615011900: "전라남도 순천시 장천동",
  4615012000: "전라남도 순천시 남내동",
  4615012100: "전라남도 순천시 중앙동",
  4615012200: "전라남도 순천시 동외동",
  4615012300: "전라남도 순천시 교량동",
  4615012400: "전라남도 순천시 대룡동",
  4615012500: "전라남도 순천시 홍내동",
  4615012600: "전라남도 순천시 오천동",
  4615012700: "전라남도 순천시 덕월동",
  4615012800: "전라남도 순천시 야흥동",
  4615012900: "전라남도 순천시 인월동",
  4615013000: "전라남도 순천시 안풍동",
  4615013100: "전라남도 순천시 대대동",
  4615013200: "전라남도 순천시 왕지동",
  4615013300: "전라남도 순천시 조례동",
  4615025000: "전라남도 순천시 승주읍",
  4615031000: "전라남도 순천시 해룡면",
  4615032000: "전라남도 순천시 서면",
  4615033000: "전라남도 순천시 황전면",
  4615034000: "전라남도 순천시 월등면",
  4615035000: "전라남도 순천시 주암면",
  4615036000: "전라남도 순천시 송광면",
  4615037000: "전라남도 순천시 외서면",
  4615038000: "전라남도 순천시 낙안면",
  4615039000: "전라남도 순천시 별량면",
  4615040000: "전라남도 순천시 상사면",
  4617000000: "전라남도 나주시",
  4617010100: "전라남도 나주시 토계동",
  4617010200: "전라남도 나주시 송월동",
  4617010300: "전라남도 나주시 안창동",
  4617010400: "전라남도 나주시 삼영동",
  4617010500: "전라남도 나주시 교동",
  4617010600: "전라남도 나주시 서내동",
  4617010700: "전라남도 나주시 산정동",
  4617010800: "전라남도 나주시 경현동",
  4617010900: "전라남도 나주시 보산동",
  4617011000: "전라남도 나주시 금계동",
  4617011100: "전라남도 나주시 금성동",
  4617011200: "전라남도 나주시 남내동",
  4617011300: "전라남도 나주시 과원동",
  4617011400: "전라남도 나주시 성북동",
  4617011500: "전라남도 나주시 중앙동",
  4617011600: "전라남도 나주시 대호동",
  4617011700: "전라남도 나주시 송촌동",
  4617011800: "전라남도 나주시 석현동",
  4617011900: "전라남도 나주시 청동",
  4617012000: "전라남도 나주시 남외동",
  4617012100: "전라남도 나주시 죽림동",
  4617012200: "전라남도 나주시 삼도동",
  4617012300: "전라남도 나주시 영산동",
  4617012400: "전라남도 나주시 용산동",
  4617012500: "전라남도 나주시 관정동",
  4617012600: "전라남도 나주시 평산동",
  4617012700: "전라남도 나주시 부덕동",
  4617012800: "전라남도 나주시 이창동",
  4617012900: "전라남도 나주시 대기동",
  4617013000: "전라남도 나주시 운곡동",
  4617013100: "전라남도 나주시 동수동",
  4617013200: "전라남도 나주시 오량동",
  4617013300: "전라남도 나주시 진포동",
  4617013400: "전라남도 나주시 빛가람동",
  4617025000: "전라남도 나주시 남평읍",
  4617031000: "전라남도 나주시 세지면",
  4617032000: "전라남도 나주시 왕곡면",
  4617033000: "전라남도 나주시 반남면",
  4617034000: "전라남도 나주시 공산면",
  4617035000: "전라남도 나주시 동강면",
  4617036000: "전라남도 나주시 다시면",
  4617037000: "전라남도 나주시 문평면",
  4617038000: "전라남도 나주시 노안면",
  4617039000: "전라남도 나주시 금천면",
  4617040000: "전라남도 나주시 산포면",
  4617042000: "전라남도 나주시 다도면",
  4617043000: "전라남도 나주시 봉황면",
  4623000000: "전라남도 광양시",
  4623010100: "전라남도 광양시 황금동",
  4623010200: "전라남도 광양시 황길동",
  4623010300: "전라남도 광양시 도이동",
  4623010400: "전라남도 광양시 성황동",
  4623010500: "전라남도 광양시 중군동",
  4623010600: "전라남도 광양시 중동",
  4623010700: "전라남도 광양시 마동",
  4623010800: "전라남도 광양시 광영동",
  4623010900: "전라남도 광양시 태인동",
  4623011000: "전라남도 광양시 금호동",
  4623025000: "전라남도 광양시 광양읍",
  4623031000: "전라남도 광양시 봉강면",
  4623032000: "전라남도 광양시 옥룡면",
  4623033000: "전라남도 광양시 옥곡면",
  4623034000: "전라남도 광양시 진상면",
  4623035000: "전라남도 광양시 진월면",
  4623036000: "전라남도 광양시 다압면",
  4671000000: "전라남도 담양군",
  4671025000: "전라남도 담양군 담양읍",
  4671031000: "전라남도 담양군 봉산면",
  4671032000: "전라남도 담양군 고서면",
  4671033500: "전라남도 담양군 가사문학면",
  4671034000: "전라남도 담양군 창평면",
  4671035000: "전라남도 담양군 대덕면",
  4671036000: "전라남도 담양군 무정면",
  4671037000: "전라남도 담양군 금성면",
  4671038000: "전라남도 담양군 용면",
  4671039000: "전라남도 담양군 월산면",
  4671040000: "전라남도 담양군 수북면",
  4671041000: "전라남도 담양군 대전면",
  4672000000: "전라남도 곡성군",
  4672025000: "전라남도 곡성군 곡성읍",
  4672031000: "전라남도 곡성군 오곡면",
  4672032000: "전라남도 곡성군 삼기면",
  4672033000: "전라남도 곡성군 석곡면",
  4672034000: "전라남도 곡성군 목사동면",
  4672035000: "전라남도 곡성군 죽곡면",
  4672036000: "전라남도 곡성군 고달면",
  4672037000: "전라남도 곡성군 옥과면",
  4672038000: "전라남도 곡성군 입면",
  4672039000: "전라남도 곡성군 겸면",
  4672040000: "전라남도 곡성군 오산면",
  4673000000: "전라남도 구례군",
  4673025000: "전라남도 구례군 구례읍",
  4673031000: "전라남도 구례군 문척면",
  4673032000: "전라남도 구례군 간전면",
  4673033000: "전라남도 구례군 토지면",
  4673034000: "전라남도 구례군 마산면",
  4673035000: "전라남도 구례군 광의면",
  4673036000: "전라남도 구례군 용방면",
  4673037000: "전라남도 구례군 산동면",
  4677000000: "전라남도 고흥군",
  4677025000: "전라남도 고흥군 고흥읍",
  4677025300: "전라남도 고흥군 도양읍",
  4677031000: "전라남도 고흥군 풍양면",
  4677032000: "전라남도 고흥군 도덕면",
  4677033000: "전라남도 고흥군 금산면",
  4677034000: "전라남도 고흥군 도화면",
  4677035000: "전라남도 고흥군 포두면",
  4677036000: "전라남도 고흥군 봉래면",
  4677037000: "전라남도 고흥군 점암면",
  4677038000: "전라남도 고흥군 과역면",
  4677039000: "전라남도 고흥군 남양면",
  4677040000: "전라남도 고흥군 동강면",
  4677041000: "전라남도 고흥군 대서면",
  4677042000: "전라남도 고흥군 두원면",
  4677044000: "전라남도 고흥군 영남면",
  4677045000: "전라남도 고흥군 동일면",
  4678000000: "전라남도 보성군",
  4678025000: "전라남도 보성군 보성읍",
  4678025300: "전라남도 보성군 벌교읍",
  4678031000: "전라남도 보성군 노동면",
  4678032000: "전라남도 보성군 미력면",
  4678033000: "전라남도 보성군 겸백면",
  4678034000: "전라남도 보성군 율어면",
  4678035000: "전라남도 보성군 복내면",
  4678036000: "전라남도 보성군 문덕면",
  4678037000: "전라남도 보성군 조성면",
  4678038000: "전라남도 보성군 득량면",
  4678039000: "전라남도 보성군 회천면",
  4678040000: "전라남도 보성군 웅치면",
  4679000000: "전라남도 화순군",
  4679025000: "전라남도 화순군 화순읍",
  4679031000: "전라남도 화순군 한천면",
  4679032000: "전라남도 화순군 춘양면",
  4679033000: "전라남도 화순군 청풍면",
  4679034000: "전라남도 화순군 이양면",
  4679035000: "전라남도 화순군 능주면",
  4679036000: "전라남도 화순군 도곡면",
  4679037000: "전라남도 화순군 도암면",
  4679038000: "전라남도 화순군 이서면",
  4679039500: "전라남도 화순군 백아면",
  4679040000: "전라남도 화순군 동복면",
  4679041500: "전라남도 화순군 사평면",
  4679042000: "전라남도 화순군 동면",
  4680000000: "전라남도 장흥군",
  4680025000: "전라남도 장흥군 장흥읍",
  4680025300: "전라남도 장흥군 관산읍",
  4680025600: "전라남도 장흥군 대덕읍",
  4680031000: "전라남도 장흥군 용산면",
  4680032000: "전라남도 장흥군 안양면",
  4680033000: "전라남도 장흥군 장동면",
  4680034000: "전라남도 장흥군 장평면",
  4680035000: "전라남도 장흥군 유치면",
  4680036000: "전라남도 장흥군 부산면",
  4680037000: "전라남도 장흥군 회진면",
  4681000000: "전라남도 강진군",
  4681025000: "전라남도 강진군 강진읍",
  4681031000: "전라남도 강진군 군동면",
  4681032000: "전라남도 강진군 칠량면",
  4681033000: "전라남도 강진군 대구면",
  4681034000: "전라남도 강진군 도암면",
  4681035000: "전라남도 강진군 신전면",
  4681036000: "전라남도 강진군 성전면",
  4681037000: "전라남도 강진군 작천면",
  4681038000: "전라남도 강진군 병영면",
  4681039000: "전라남도 강진군 옴천면",
  4681040000: "전라남도 강진군 마량면",
  4682000000: "전라남도 해남군",
  4682025000: "전라남도 해남군 해남읍",
  4682031000: "전라남도 해남군 삼산면",
  4682032000: "전라남도 해남군 화산면",
  4682033000: "전라남도 해남군 현산면",
  4682034000: "전라남도 해남군 송지면",
  4682035000: "전라남도 해남군 북평면",
  4682036000: "전라남도 해남군 북일면",
  4682037000: "전라남도 해남군 옥천면",
  4682038000: "전라남도 해남군 계곡면",
  4682039000: "전라남도 해남군 마산면",
  4682040000: "전라남도 해남군 황산면",
  4682041000: "전라남도 해남군 산이면",
  4682042000: "전라남도 해남군 문내면",
  4682043000: "전라남도 해남군 화원면",
  4683000000: "전라남도 영암군",
  4683025000: "전라남도 영암군 영암읍",
  4683025300: "전라남도 영암군 삼호읍",
  4683031000: "전라남도 영암군 덕진면",
  4683032000: "전라남도 영암군 금정면",
  4683033000: "전라남도 영암군 신북면",
  4683034000: "전라남도 영암군 시종면",
  4683035000: "전라남도 영암군 도포면",
  4683036000: "전라남도 영암군 군서면",
  4683037000: "전라남도 영암군 서호면",
  4683038000: "전라남도 영암군 학산면",
  4683039000: "전라남도 영암군 미암면",
  4684000000: "전라남도 무안군",
  4684025000: "전라남도 무안군 무안읍",
  4684025300: "전라남도 무안군 일로읍",
  4684025600: "전라남도 무안군 삼향읍",
  4684032000: "전라남도 무안군 몽탄면",
  4684033000: "전라남도 무안군 청계면",
  4684034000: "전라남도 무안군 현경면",
  4684035000: "전라남도 무안군 망운면",
  4684036000: "전라남도 무안군 해제면",
  4684037000: "전라남도 무안군 운남면",
  4686000000: "전라남도 함평군",
  4686025000: "전라남도 함평군 함평읍",
  4686031000: "전라남도 함평군 손불면",
  4686032000: "전라남도 함평군 신광면",
  4686033000: "전라남도 함평군 학교면",
  4686034000: "전라남도 함평군 엄다면",
  4686035000: "전라남도 함평군 대동면",
  4686036000: "전라남도 함평군 나산면",
  4686037000: "전라남도 함평군 해보면",
  4686038000: "전라남도 함평군 월야면",
  4687000000: "전라남도 영광군",
  4687025000: "전라남도 영광군 영광읍",
  4687025300: "전라남도 영광군 백수읍",
  4687025600: "전라남도 영광군 홍농읍",
  4687031000: "전라남도 영광군 대마면",
  4687032000: "전라남도 영광군 묘량면",
  4687033000: "전라남도 영광군 불갑면",
  4687034000: "전라남도 영광군 군서면",
  4687035000: "전라남도 영광군 군남면",
  4687036000: "전라남도 영광군 염산면",
  4687037000: "전라남도 영광군 법성면",
  4687038000: "전라남도 영광군 낙월면",
  4688000000: "전라남도 장성군",
  4688025000: "전라남도 장성군 장성읍",
  4688031000: "전라남도 장성군 진원면",
  4688032000: "전라남도 장성군 남면",
  4688033000: "전라남도 장성군 동화면",
  4688034000: "전라남도 장성군 삼서면",
  4688035000: "전라남도 장성군 삼계면",
  4688036000: "전라남도 장성군 황룡면",
  4688037000: "전라남도 장성군 서삼면",
  4688038000: "전라남도 장성군 북일면",
  4688039000: "전라남도 장성군 북이면",
  4688040000: "전라남도 장성군 북하면",
  4689000000: "전라남도 완도군",
  4689025000: "전라남도 완도군 완도읍",
  4689025300: "전라남도 완도군 금일읍",
  4689025600: "전라남도 완도군 노화읍",
  4689031000: "전라남도 완도군 군외면",
  4689032000: "전라남도 완도군 신지면",
  4689033000: "전라남도 완도군 고금면",
  4689034000: "전라남도 완도군 약산면",
  4689035000: "전라남도 완도군 청산면",
  4689036000: "전라남도 완도군 소안면",
  4689037000: "전라남도 완도군 금당면",
  4689038000: "전라남도 완도군 보길면",
  4689039000: "전라남도 완도군 생일면",
  4690000000: "전라남도 진도군",
  4690025000: "전라남도 진도군 진도읍",
  4690031000: "전라남도 진도군 군내면",
  4690032000: "전라남도 진도군 고군면",
  4690033000: "전라남도 진도군 의신면",
  4690034000: "전라남도 진도군 임회면",
  4690035000: "전라남도 진도군 지산면",
  4690036000: "전라남도 진도군 조도면",
  4691000000: "전라남도 신안군",
  4691025000: "전라남도 신안군 지도읍",
  4691025300: "전라남도 신안군 압해읍",
  4691031000: "전라남도 신안군 증도면",
  4691032000: "전라남도 신안군 임자면",
  4691033000: "전라남도 신안군 자은면",
  4691034000: "전라남도 신안군 비금면",
  4691035000: "전라남도 신안군 도초면",
  4691036000: "전라남도 신안군 흑산면",
  4691037000: "전라남도 신안군 하의면",
  4691038000: "전라남도 신안군 신의면",
  4691039000: "전라남도 신안군 장산면",
  4691040000: "전라남도 신안군 안좌면",
  4691041000: "전라남도 신안군 팔금면",
  4691042000: "전라남도 신안군 암태면",
  4700000000: "경상북도",
  4711000000: "경상북도 포항시",
  4711100000: "경상북도 포항시 남구",
  4711110100: "경상북도 포항시 남구 상도동",
  4711110200: "경상북도 포항시 남구 대도동",
  4711110300: "경상북도 포항시 남구 해도동",
  4711110400: "경상북도 포항시 남구 송도동",
  4711110500: "경상북도 포항시 남구 청림동",
  4711110600: "경상북도 포항시 남구 일월동",
  4711110700: "경상북도 포항시 남구 송정동",
  4711110800: "경상북도 포항시 남구 송내동",
  4711110900: "경상북도 포항시 남구 괴동동",
  4711111000: "경상북도 포항시 남구 동촌동",
  4711111100: "경상북도 포항시 남구 장흥동",
  4711111200: "경상북도 포항시 남구 인덕동",
  4711111300: "경상북도 포항시 남구 호동",
  4711111400: "경상북도 포항시 남구 효자동",
  4711111500: "경상북도 포항시 남구 지곡동",
  4711111600: "경상북도 포항시 남구 대잠동",
  4711111700: "경상북도 포항시 남구 이동",
  4711125000: "경상북도 포항시 남구 구룡포읍",
  4711125300: "경상북도 포항시 남구 연일읍",
  4711125600: "경상북도 포항시 남구 오천읍",
  4711131000: "경상북도 포항시 남구 대송면",
  4711132000: "경상북도 포항시 남구 동해면",
  4711133000: "경상북도 포항시 남구 장기면",
  4711135000: "경상북도 포항시 남구 호미곶면",
  4711300000: "경상북도 포항시 북구",
  4711310100: "경상북도 포항시 북구 대흥동",
  4711310200: "경상북도 포항시 북구 신흥동",
  4711310300: "경상북도 포항시 북구 남빈동",
  4711310400: "경상북도 포항시 북구 상원동",
  4711310500: "경상북도 포항시 북구 여천동",
  4711310600: "경상북도 포항시 북구 중앙동",
  4711310700: "경상북도 포항시 북구 덕산동",
  4711310800: "경상북도 포항시 북구 덕수동",
  4711310900: "경상북도 포항시 북구 대신동",
  4711311000: "경상북도 포항시 북구 동빈1가",
  4711311100: "경상북도 포항시 북구 동빈2가",
  4711311200: "경상북도 포항시 북구 학산동",
  4711311300: "경상북도 포항시 북구 항구동",
  4711311400: "경상북도 포항시 북구 득량동",
  4711311500: "경상북도 포항시 북구 학잠동",
  4711311600: "경상북도 포항시 북구 죽도동",
  4711311700: "경상북도 포항시 북구 용흥동",
  4711311800: "경상북도 포항시 북구 우현동",
  4711311900: "경상북도 포항시 북구 창포동",
  4711312000: "경상북도 포항시 북구 두호동",
  4711312100: "경상북도 포항시 북구 장성동",
  4711312200: "경상북도 포항시 북구 양덕동",
  4711312300: "경상북도 포항시 북구 환호동",
  4711312400: "경상북도 포항시 북구 여남동",
  4711325000: "경상북도 포항시 북구 흥해읍",
  4711331000: "경상북도 포항시 북구 신광면",
  4711332000: "경상북도 포항시 북구 청하면",
  4711333000: "경상북도 포항시 북구 송라면",
  4711334000: "경상북도 포항시 북구 기계면",
  4711335000: "경상북도 포항시 북구 죽장면",
  4711336000: "경상북도 포항시 북구 기북면",
  4713000000: "경상북도 경주시",
  4713010100: "경상북도 경주시 동부동",
  4713010200: "경상북도 경주시 서부동",
  4713010300: "경상북도 경주시 북부동",
  4713010400: "경상북도 경주시 성동동",
  4713010500: "경상북도 경주시 황오동",
  4713010600: "경상북도 경주시 노동동",
  4713010700: "경상북도 경주시 노서동",
  4713010800: "경상북도 경주시 성건동",
  4713010900: "경상북도 경주시 사정동",
  4713011000: "경상북도 경주시 황남동",
  4713011100: "경상북도 경주시 교동",
  4713011200: "경상북도 경주시 인왕동",
  4713011300: "경상북도 경주시 탑동",
  4713011400: "경상북도 경주시 충효동",
  4713011500: "경상북도 경주시 서악동",
  4713011600: "경상북도 경주시 효현동",
  4713011700: "경상북도 경주시 광명동",
  4713011800: "경상북도 경주시 동방동",
  4713011900: "경상북도 경주시 도지동",
  4713012000: "경상북도 경주시 남산동",
  4713012100: "경상북도 경주시 배반동",
  4713012200: "경상북도 경주시 구황동",
  4713012300: "경상북도 경주시 보문동",
  4713012400: "경상북도 경주시 황성동",
  4713012500: "경상북도 경주시 용강동",
  4713012600: "경상북도 경주시 동천동",
  4713012700: "경상북도 경주시 평동",
  4713012800: "경상북도 경주시 조양동",
  4713012900: "경상북도 경주시 시동",
  4713013000: "경상북도 경주시 시래동",
  4713013100: "경상북도 경주시 구정동",
  4713013200: "경상북도 경주시 마동",
  4713013300: "경상북도 경주시 하동",
  4713013400: "경상북도 경주시 진현동",
  4713013500: "경상북도 경주시 천군동",
  4713013600: "경상북도 경주시 신평동",
  4713013700: "경상북도 경주시 덕동",
  4713013800: "경상북도 경주시 암곡동",
  4713013900: "경상북도 경주시 황용동",
  4713014000: "경상북도 경주시 북군동",
  4713014100: "경상북도 경주시 손곡동",
  4713014200: "경상북도 경주시 율동",
  4713014300: "경상북도 경주시 배동",
  4713014400: "경상북도 경주시 석장동",
  4713025000: "경상북도 경주시 감포읍",
  4713025300: "경상북도 경주시 안강읍",
  4713025600: "경상북도 경주시 건천읍",
  4713025900: "경상북도 경주시 외동읍",
  4713031500: "경상북도 경주시 문무대왕면",
  4713032000: "경상북도 경주시 양남면",
  4713033000: "경상북도 경주시 내남면",
  4713034000: "경상북도 경주시 산내면",
  4713035000: "경상북도 경주시 서면",
  4713036000: "경상북도 경주시 현곡면",
  4713037000: "경상북도 경주시 강동면",
  4713038000: "경상북도 경주시 천북면",
  4715000000: "경상북도 김천시",
  4715010100: "경상북도 김천시 감호동",
  4715010200: "경상북도 김천시 용두동",
  4715010300: "경상북도 김천시 모암동",
  4715010400: "경상북도 김천시 성내동",
  4715010500: "경상북도 김천시 평화동",
  4715010600: "경상북도 김천시 남산동",
  4715010700: "경상북도 김천시 황금동",
  4715010800: "경상북도 김천시 신음동",
  4715010900: "경상북도 김천시 교동",
  4715011000: "경상북도 김천시 삼락동",
  4715011100: "경상북도 김천시 문당동",
  4715011200: "경상북도 김천시 다수동",
  4715011300: "경상북도 김천시 백옥동",
  4715011400: "경상북도 김천시 부곡동",
  4715011500: "경상북도 김천시 지좌동",
  4715011600: "경상북도 김천시 덕곡동",
  4715011700: "경상북도 김천시 대광동",
  4715011800: "경상북도 김천시 응명동",
  4715011900: "경상북도 김천시 양천동",
  4715012000: "경상북도 김천시 율곡동",
  4715025000: "경상북도 김천시 아포읍",
  4715031000: "경상북도 김천시 농소면",
  4715032000: "경상북도 김천시 남면",
  4715034000: "경상북도 김천시 개령면",
  4715035000: "경상북도 김천시 감문면",
  4715036000: "경상북도 김천시 어모면",
  4715037000: "경상북도 김천시 봉산면",
  4715038000: "경상북도 김천시 대항면",
  4715039000: "경상북도 김천시 감천면",
  4715040000: "경상북도 김천시 조마면",
  4715041000: "경상북도 김천시 구성면",
  4715042000: "경상북도 김천시 지례면",
  4715043000: "경상북도 김천시 부항면",
  4715044000: "경상북도 김천시 대덕면",
  4715045000: "경상북도 김천시 증산면",
  4717000000: "경상북도 안동시",
  4717010100: "경상북도 안동시 삼산동",
  4717010200: "경상북도 안동시 서부동",
  4717010300: "경상북도 안동시 북문동",
  4717010400: "경상북도 안동시 명륜동",
  4717010500: "경상북도 안동시 신안동",
  4717010600: "경상북도 안동시 율세동",
  4717010700: "경상북도 안동시 옥정동",
  4717010800: "경상북도 안동시 신세동",
  4717010900: "경상북도 안동시 법흥동",
  4717011000: "경상북도 안동시 용상동",
  4717011100: "경상북도 안동시 동문동",
  4717011200: "경상북도 안동시 동부동",
  4717011300: "경상북도 안동시 운흥동",
  4717011400: "경상북도 안동시 천리동",
  4717011500: "경상북도 안동시 남부동",
  4717011600: "경상북도 안동시 남문동",
  4717011700: "경상북도 안동시 안흥동",
  4717011800: "경상북도 안동시 대석동",
  4717011900: "경상북도 안동시 옥야동",
  4717012000: "경상북도 안동시 광석동",
  4717012100: "경상북도 안동시 당북동",
  4717012200: "경상북도 안동시 태화동",
  4717012300: "경상북도 안동시 화성동",
  4717012400: "경상북도 안동시 목성동",
  4717012500: "경상북도 안동시 법상동",
  4717012600: "경상북도 안동시 금곡동",
  4717012700: "경상북도 안동시 평화동",
  4717012800: "경상북도 안동시 안기동",
  4717012900: "경상북도 안동시 운안동",
  4717013000: "경상북도 안동시 성곡동",
  4717013100: "경상북도 안동시 상아동",
  4717013200: "경상북도 안동시 안막동",
  4717013300: "경상북도 안동시 옥동",
  4717013400: "경상북도 안동시 이천동",
  4717013500: "경상북도 안동시 노하동",
  4717013600: "경상북도 안동시 송현동",
  4717013700: "경상북도 안동시 송천동",
  4717013800: "경상북도 안동시 석동동",
  4717013900: "경상북도 안동시 정상동",
  4717014000: "경상북도 안동시 정하동",
  4717014100: "경상북도 안동시 수상동",
  4717014200: "경상북도 안동시 수하동",
  4717025000: "경상북도 안동시 풍산읍",
  4717031000: "경상북도 안동시 와룡면",
  4717032000: "경상북도 안동시 북후면",
  4717033000: "경상북도 안동시 서후면",
  4717034000: "경상북도 안동시 풍천면",
  4717035000: "경상북도 안동시 일직면",
  4717036000: "경상북도 안동시 남후면",
  4717037000: "경상북도 안동시 남선면",
  4717038000: "경상북도 안동시 임하면",
  4717039000: "경상북도 안동시 길안면",
  4717040000: "경상북도 안동시 임동면",
  4717041000: "경상북도 안동시 예안면",
  4717042000: "경상북도 안동시 도산면",
  4717043000: "경상북도 안동시 녹전면",
  4719000000: "경상북도 구미시",
  4719010100: "경상북도 구미시 원평동",
  4719010200: "경상북도 구미시 지산동",
  4719010300: "경상북도 구미시 도량동",
  4719010400: "경상북도 구미시 봉곡동",
  4719010500: "경상북도 구미시 부곡동",
  4719010600: "경상북도 구미시 선기동",
  4719010700: "경상북도 구미시 수점동",
  4719010800: "경상북도 구미시 남통동",
  4719010900: "경상북도 구미시 형곡동",
  4719011000: "경상북도 구미시 송정동",
  4719011100: "경상북도 구미시 신평동",
  4719011200: "경상북도 구미시 비산동",
  4719011300: "경상북도 구미시 공단동",
  4719011400: "경상북도 구미시 광평동",
  4719011500: "경상북도 구미시 사곡동",
  4719011600: "경상북도 구미시 상모동",
  4719011700: "경상북도 구미시 임은동",
  4719011800: "경상북도 구미시 오태동",
  4719011900: "경상북도 구미시 신동",
  4719012000: "경상북도 구미시 구평동",
  4719012100: "경상북도 구미시 황상동",
  4719012200: "경상북도 구미시 인의동",
  4719012300: "경상북도 구미시 진평동",
  4719012400: "경상북도 구미시 시미동",
  4719012500: "경상북도 구미시 임수동",
  4719012600: "경상북도 구미시 양호동",
  4719012700: "경상북도 구미시 거의동",
  4719012800: "경상북도 구미시 옥계동",
  4719012900: "경상북도 구미시 구포동",
  4719013000: "경상북도 구미시 금전동",
  4719025000: "경상북도 구미시 선산읍",
  4719025300: "경상북도 구미시 고아읍",
  4719025600: "경상북도 구미시 산동읍",
  4719031000: "경상북도 구미시 무을면",
  4719032000: "경상북도 구미시 옥성면",
  4719033000: "경상북도 구미시 도개면",
  4719034000: "경상북도 구미시 해평면",
  4719036000: "경상북도 구미시 장천면",
  4721000000: "경상북도 영주시",
  4721010100: "경상북도 영주시 영주동",
  4721010200: "경상북도 영주시 상망동",
  4721010300: "경상북도 영주시 하망동",
  4721010400: "경상북도 영주시 휴천동",
  4721010500: "경상북도 영주시 가흥동",
  4721010600: "경상북도 영주시 문정동",
  4721010700: "경상북도 영주시 고현동",
  4721010800: "경상북도 영주시 창진동",
  4721010900: "경상북도 영주시 상줄동",
  4721011000: "경상북도 영주시 조와동",
  4721011100: "경상북도 영주시 조암동",
  4721011200: "경상북도 영주시 적서동",
  4721011300: "경상북도 영주시 아지동",
  4721025000: "경상북도 영주시 풍기읍",
  4721031000: "경상북도 영주시 이산면",
  4721032000: "경상북도 영주시 평은면",
  4721033000: "경상북도 영주시 문수면",
  4721034000: "경상북도 영주시 장수면",
  4721035000: "경상북도 영주시 안정면",
  4721036000: "경상북도 영주시 봉현면",
  4721037000: "경상북도 영주시 순흥면",
  4721038000: "경상북도 영주시 단산면",
  4721039000: "경상북도 영주시 부석면",
  4723000000: "경상북도 영천시",
  4723010100: "경상북도 영천시 조교동",
  4723010200: "경상북도 영천시 망정동",
  4723010300: "경상북도 영천시 야사동",
  4723010400: "경상북도 영천시 문내동",
  4723010500: "경상북도 영천시 문외동",
  4723010600: "경상북도 영천시 창구동",
  4723010700: "경상북도 영천시 교촌동",
  4723010800: "경상북도 영천시 과전동",
  4723010900: "경상북도 영천시 성내동",
  4723011000: "경상북도 영천시 화룡동",
  4723011100: "경상북도 영천시 도동",
  4723011200: "경상북도 영천시 금노동",
  4723011300: "경상북도 영천시 완산동",
  4723011400: "경상북도 영천시 범어동",
  4723011500: "경상북도 영천시 작산동",
  4723011600: "경상북도 영천시 봉동",
  4723011700: "경상북도 영천시 본촌동",
  4723011800: "경상북도 영천시 채신동",
  4723011900: "경상북도 영천시 괴연동",
  4723012000: "경상북도 영천시 대전동",
  4723012100: "경상북도 영천시 녹전동",
  4723012200: "경상북도 영천시 도림동",
  4723012300: "경상북도 영천시 오미동",
  4723012400: "경상북도 영천시 오수동",
  4723012500: "경상북도 영천시 쌍계동",
  4723012600: "경상북도 영천시 도남동",
  4723012700: "경상북도 영천시 매산동",
  4723012800: "경상북도 영천시 언하동",
  4723012900: "경상북도 영천시 신기동",
  4723013000: "경상북도 영천시 서산동",
  4723025000: "경상북도 영천시 금호읍",
  4723031000: "경상북도 영천시 청통면",
  4723032000: "경상북도 영천시 신녕면",
  4723033000: "경상북도 영천시 화산면",
  4723034000: "경상북도 영천시 화북면",
  4723035000: "경상북도 영천시 화남면",
  4723036000: "경상북도 영천시 자양면",
  4723037000: "경상북도 영천시 임고면",
  4723038000: "경상북도 영천시 고경면",
  4723039000: "경상북도 영천시 북안면",
  4723040000: "경상북도 영천시 대창면",
  4725000000: "경상북도 상주시",
  4725010100: "경상북도 상주시 성하동",
  4725010200: "경상북도 상주시 성동동",
  4725010300: "경상북도 상주시 인봉동",
  4725010400: "경상북도 상주시 복룡동",
  4725010500: "경상북도 상주시 냉림동",
  4725010600: "경상북도 상주시 서성동",
  4725010700: "경상북도 상주시 남성동",
  4725010800: "경상북도 상주시 서문동",
  4725010900: "경상북도 상주시 무양동",
  4725011000: "경상북도 상주시 낙양동",
  4725011100: "경상북도 상주시 개운동",
  4725011200: "경상북도 상주시 신봉동",
  4725011300: "경상북도 상주시 가장동",
  4725011400: "경상북도 상주시 양촌동",
  4725011500: "경상북도 상주시 지천동",
  4725011600: "경상북도 상주시 오대동",
  4725011700: "경상북도 상주시 흥각동",
  4725011800: "경상북도 상주시 거동동",
  4725011900: "경상북도 상주시 인평동",
  4725012000: "경상북도 상주시 서곡동",
  4725012100: "경상북도 상주시 화개동",
  4725012200: "경상북도 상주시 외답동",
  4725012300: "경상북도 상주시 헌신동",
  4725012400: "경상북도 상주시 병성동",
  4725012500: "경상북도 상주시 도남동",
  4725012600: "경상북도 상주시 낙상동",
  4725012700: "경상북도 상주시 중덕동",
  4725012800: "경상북도 상주시 초산동",
  4725012900: "경상북도 상주시 화산동",
  4725013000: "경상북도 상주시 계산동",
  4725013100: "경상북도 상주시 부원동",
  4725013200: "경상북도 상주시 죽전동",
  4725013300: "경상북도 상주시 만산동",
  4725013400: "경상북도 상주시 연원동",
  4725013500: "경상북도 상주시 남장동",
  4725013600: "경상북도 상주시 남적동",
  4725025000: "경상북도 상주시 함창읍",
  4725031000: "경상북도 상주시 중동면",
  4725032500: "경상북도 상주시 사벌국면",
  4725033000: "경상북도 상주시 낙동면",
  4725034000: "경상북도 상주시 청리면",
  4725035000: "경상북도 상주시 공성면",
  4725036000: "경상북도 상주시 외남면",
  4725037000: "경상북도 상주시 내서면",
  4725038000: "경상북도 상주시 모동면",
  4725039000: "경상북도 상주시 모서면",
  4725040000: "경상북도 상주시 화동면",
  4725041000: "경상북도 상주시 화서면",
  4725042000: "경상북도 상주시 화북면",
  4725043000: "경상북도 상주시 외서면",
  4725044000: "경상북도 상주시 은척면",
  4725045000: "경상북도 상주시 공검면",
  4725046000: "경상북도 상주시 이안면",
  4725047000: "경상북도 상주시 화남면",
  4728000000: "경상북도 문경시",
  4728010100: "경상북도 문경시 점촌동",
  4728010200: "경상북도 문경시 영신동",
  4728010300: "경상북도 문경시 흥덕동",
  4728010400: "경상북도 문경시 우지동",
  4728010500: "경상북도 문경시 창동",
  4728010600: "경상북도 문경시 신기동",
  4728010700: "경상북도 문경시 불정동",
  4728010800: "경상북도 문경시 유곡동",
  4728010900: "경상북도 문경시 공평동",
  4728011000: "경상북도 문경시 모전동",
  4728011100: "경상북도 문경시 윤직동",
  4728025000: "경상북도 문경시 문경읍",
  4728025300: "경상북도 문경시 가은읍",
  4728031000: "경상북도 문경시 영순면",
  4728032000: "경상북도 문경시 산양면",
  4728033000: "경상북도 문경시 호계면",
  4728034000: "경상북도 문경시 산북면",
  4728035000: "경상북도 문경시 동로면",
  4728036000: "경상북도 문경시 마성면",
  4728037000: "경상북도 문경시 농암면",
  4729000000: "경상북도 경산시",
  4729010100: "경상북도 경산시 삼남동",
  4729010200: "경상북도 경산시 삼북동",
  4729010300: "경상북도 경산시 서상동",
  4729010400: "경상북도 경산시 신교동",
  4729010500: "경상북도 경산시 상방동",
  4729010600: "경상북도 경산시 백천동",
  4729010700: "경상북도 경산시 옥곡동",
  4729010800: "경상북도 경산시 사정동",
  4729010900: "경상북도 경산시 옥산동",
  4729011000: "경상북도 경산시 중산동",
  4729011100: "경상북도 경산시 정평동",
  4729011200: "경상북도 경산시 대평동",
  4729011300: "경상북도 경산시 대정동",
  4729011400: "경상북도 경산시 임당동",
  4729011500: "경상북도 경산시 대동",
  4729011600: "경상북도 경산시 계양동",
  4729011700: "경상북도 경산시 중방동",
  4729011800: "경상북도 경산시 조영동",
  4729011900: "경상북도 경산시 남방동",
  4729012000: "경상북도 경산시 내동",
  4729012100: "경상북도 경산시 여천동",
  4729012200: "경상북도 경산시 유곡동",
  4729012300: "경상북도 경산시 신천동",
  4729012400: "경상북도 경산시 점촌동",
  4729012500: "경상북도 경산시 평산동",
  4729012600: "경상북도 경산시 사동",
  4729012700: "경상북도 경산시 삼풍동",
  4729012800: "경상북도 경산시 갑제동",
  4729025000: "경상북도 경산시 하양읍",
  4729025300: "경상북도 경산시 진량읍",
  4729025600: "경상북도 경산시 압량읍",
  4729031000: "경상북도 경산시 와촌면",
  4729033000: "경상북도 경산시 자인면",
  4729034000: "경상북도 경산시 용성면",
  4729035000: "경상북도 경산시 남산면",
  4729037000: "경상북도 경산시 남천면",
  4772000000: "경상북도 군위군",
  4772025000: "경상북도 군위군 군위읍",
  4772031000: "경상북도 군위군 소보면",
  4772032000: "경상북도 군위군 효령면",
  4772033000: "경상북도 군위군 부계면",
  4772034000: "경상북도 군위군 우보면",
  4772035000: "경상북도 군위군 의흥면",
  4772036000: "경상북도 군위군 산성면",
  4772038000: "경상북도 군위군 삼국유사면",
  4773000000: "경상북도 의성군",
  4773025000: "경상북도 의성군 의성읍",
  4773031000: "경상북도 의성군 단촌면",
  4773032000: "경상북도 의성군 점곡면",
  4773033000: "경상북도 의성군 옥산면",
  4773034000: "경상북도 의성군 사곡면",
  4773035000: "경상북도 의성군 춘산면",
  4773036000: "경상북도 의성군 가음면",
  4773037000: "경상북도 의성군 금성면",
  4773038000: "경상북도 의성군 봉양면",
  4773039000: "경상북도 의성군 비안면",
  4773040000: "경상북도 의성군 구천면",
  4773041000: "경상북도 의성군 단밀면",
  4773042000: "경상북도 의성군 단북면",
  4773043000: "경상북도 의성군 안계면",
  4773044000: "경상북도 의성군 다인면",
  4773045000: "경상북도 의성군 신평면",
  4773046000: "경상북도 의성군 안평면",
  4773047000: "경상북도 의성군 안사면",
  4775000000: "경상북도 청송군",
  4775025000: "경상북도 청송군 청송읍",
  4775031500: "경상북도 청송군 주왕산면",
  4775032000: "경상북도 청송군 부남면",
  4775033000: "경상북도 청송군 현동면",
  4775034000: "경상북도 청송군 현서면",
  4775035000: "경상북도 청송군 안덕면",
  4775036000: "경상북도 청송군 파천면",
  4775037000: "경상북도 청송군 진보면",
  4776000000: "경상북도 영양군",
  4776025000: "경상북도 영양군 영양읍",
  4776031000: "경상북도 영양군 입암면",
  4776032000: "경상북도 영양군 청기면",
  4776033000: "경상북도 영양군 일월면",
  4776034000: "경상북도 영양군 수비면",
  4776035000: "경상북도 영양군 석보면",
  4777000000: "경상북도 영덕군",
  4777025000: "경상북도 영덕군 영덕읍",
  4777031000: "경상북도 영덕군 강구면",
  4777032000: "경상북도 영덕군 남정면",
  4777033000: "경상북도 영덕군 달산면",
  4777034000: "경상북도 영덕군 지품면",
  4777035000: "경상북도 영덕군 축산면",
  4777036000: "경상북도 영덕군 영해면",
  4777037000: "경상북도 영덕군 병곡면",
  4777038000: "경상북도 영덕군 창수면",
  4782000000: "경상북도 청도군",
  4782025000: "경상북도 청도군 화양읍",
  4782025300: "경상북도 청도군 청도읍",
  4782031000: "경상북도 청도군 각남면",
  4782032000: "경상북도 청도군 풍각면",
  4782033000: "경상북도 청도군 각북면",
  4782034000: "경상북도 청도군 이서면",
  4782035000: "경상북도 청도군 운문면",
  4782036000: "경상북도 청도군 금천면",
  4782037000: "경상북도 청도군 매전면",
  4783000000: "경상북도 고령군",
  4783025300: "경상북도 고령군 대가야읍",
  4783031000: "경상북도 고령군 덕곡면",
  4783032000: "경상북도 고령군 운수면",
  4783033000: "경상북도 고령군 성산면",
  4783034000: "경상북도 고령군 다산면",
  4783035000: "경상북도 고령군 개진면",
  4783036000: "경상북도 고령군 우곡면",
  4783037000: "경상북도 고령군 쌍림면",
  4784000000: "경상북도 성주군",
  4784025000: "경상북도 성주군 성주읍",
  4784031000: "경상북도 성주군 선남면",
  4784032000: "경상북도 성주군 용암면",
  4784033000: "경상북도 성주군 수륜면",
  4784034000: "경상북도 성주군 가천면",
  4784035000: "경상북도 성주군 금수면",
  4784036000: "경상북도 성주군 대가면",
  4784037000: "경상북도 성주군 벽진면",
  4784038000: "경상북도 성주군 초전면",
  4784039000: "경상북도 성주군 월항면",
  4785000000: "경상북도 칠곡군",
  4785025000: "경상북도 칠곡군 왜관읍",
  4785025300: "경상북도 칠곡군 북삼읍",
  4785025600: "경상북도 칠곡군 석적읍",
  4785031000: "경상북도 칠곡군 지천면",
  4785032000: "경상북도 칠곡군 동명면",
  4785033000: "경상북도 칠곡군 가산면",
  4785036000: "경상북도 칠곡군 약목면",
  4785037000: "경상북도 칠곡군 기산면",
  4790000000: "경상북도 예천군",
  4790025000: "경상북도 예천군 예천읍",
  4790031000: "경상북도 예천군 용문면",
  4790034000: "경상북도 예천군 감천면",
  4790035000: "경상북도 예천군 보문면",
  4790036000: "경상북도 예천군 호명면",
  4790037000: "경상북도 예천군 유천면",
  4790038000: "경상북도 예천군 용궁면",
  4790039000: "경상북도 예천군 개포면",
  4790040000: "경상북도 예천군 지보면",
  4790041000: "경상북도 예천군 풍양면",
  4790042000: "경상북도 예천군 효자면",
  4790043000: "경상북도 예천군 은풍면",
  4792000000: "경상북도 봉화군",
  4792025000: "경상북도 봉화군 봉화읍",
  4792031000: "경상북도 봉화군 물야면",
  4792032000: "경상북도 봉화군 봉성면",
  4792033000: "경상북도 봉화군 법전면",
  4792034000: "경상북도 봉화군 춘양면",
  4792035000: "경상북도 봉화군 소천면",
  4792036000: "경상북도 봉화군 재산면",
  4792037000: "경상북도 봉화군 명호면",
  4792038000: "경상북도 봉화군 상운면",
  4792039000: "경상북도 봉화군 석포면",
  4793000000: "경상북도 울진군",
  4793025000: "경상북도 울진군 울진읍",
  4793025300: "경상북도 울진군 평해읍",
  4793031000: "경상북도 울진군 북면",
  4793033000: "경상북도 울진군 근남면",
  4793035000: "경상북도 울진군 기성면",
  4793036000: "경상북도 울진군 온정면",
  4793037000: "경상북도 울진군 죽변면",
  4793038000: "경상북도 울진군 후포면",
  4793039000: "경상북도 울진군 금강송면",
  4793040000: "경상북도 울진군 매화면",
  4794000000: "경상북도 울릉군",
  4794025000: "경상북도 울릉군 울릉읍",
  4794031000: "경상북도 울릉군 서면",
  4794032000: "경상북도 울릉군 북면",
  4800000000: "경상남도",
  4812000000: "경상남도 창원시",
  4812100000: "경상남도 창원시 의창구",
  4812110100: "경상남도 창원시 의창구 북동",
  4812110200: "경상남도 창원시 의창구 중동",
  4812110300: "경상남도 창원시 의창구 서상동",
  4812110400: "경상남도 창원시 의창구 소답동",
  4812110500: "경상남도 창원시 의창구 도계동",
  4812110600: "경상남도 창원시 의창구 동정동",
  4812110700: "경상남도 창원시 의창구 소계동",
  4812110800: "경상남도 창원시 의창구 용동",
  4812111200: "경상남도 창원시 의창구 덕정동",
  4812111300: "경상남도 창원시 의창구 지귀동",
  4812111400: "경상남도 창원시 의창구 서곡동",
  4812111600: "경상남도 창원시 의창구 봉림동",
  4812111700: "경상남도 창원시 의창구 퇴촌동",
  4812111800: "경상남도 창원시 의창구 명곡동",
  4812111900: "경상남도 창원시 의창구 반계동",
  4812112000: "경상남도 창원시 의창구 사화동",
  4812112100: "경상남도 창원시 의창구 차용동",
  4812112200: "경상남도 창원시 의창구 내리동",
  4812112400: "경상남도 창원시 의창구 명서동",
  4812112500: "경상남도 창원시 의창구 사림동",
  4812112700: "경상남도 창원시 의창구 봉곡동",
  4812112900: "경상남도 창원시 의창구 팔용동",
  4812125000: "경상남도 창원시 의창구 동읍",
  4812131000: "경상남도 창원시 의창구 북면",
  4812132000: "경상남도 창원시 의창구 대산면",
  4812300000: "경상남도 창원시 성산구",
  4812310100: "경상남도 창원시 성산구 토월동",
  4812310200: "경상남도 창원시 성산구 사파정동",
  4812310300: "경상남도 창원시 성산구 가음정동",
  4812310400: "경상남도 창원시 성산구 외동",
  4812310500: "경상남도 창원시 성산구 대방동",
  4812310600: "경상남도 창원시 성산구 남산동",
  4812310700: "경상남도 창원시 성산구 삼정자동",
  4812310800: "경상남도 창원시 성산구 천선동",
  4812310900: "경상남도 창원시 성산구 불모산동",
  4812311000: "경상남도 창원시 성산구 안민동",
  4812311100: "경상남도 창원시 성산구 내동",
  4812311200: "경상남도 창원시 성산구 남지동",
  4812311300: "경상남도 창원시 성산구 상복동",
  4812311400: "경상남도 창원시 성산구 완암동",
  4812311500: "경상남도 창원시 성산구 창곡동",
  4812311600: "경상남도 창원시 성산구 월림동",
  4812311700: "경상남도 창원시 성산구 적현동",
  4812311800: "경상남도 창원시 성산구 양곡동",
  4812311900: "경상남도 창원시 성산구 반송동",
  4812312000: "경상남도 창원시 성산구 귀산동",
  4812312100: "경상남도 창원시 성산구 귀곡동",
  4812312200: "경상남도 창원시 성산구 귀현동",
  4812312300: "경상남도 창원시 성산구 신촌동",
  4812312400: "경상남도 창원시 성산구 반지동",
  4812312500: "경상남도 창원시 성산구 중앙동",
  4812312600: "경상남도 창원시 성산구 반림동",
  4812312700: "경상남도 창원시 성산구 상남동",
  4812312800: "경상남도 창원시 성산구 성주동",
  4812312900: "경상남도 창원시 성산구 웅남동",
  4812313000: "경상남도 창원시 성산구 사파동",
  4812313100: "경상남도 창원시 성산구 가음동",
  4812313200: "경상남도 창원시 성산구 성산동",
  4812313300: "경상남도 창원시 성산구 남양동",
  4812313400: "경상남도 창원시 성산구 용지동",
  4812313500: "경상남도 창원시 성산구 용호동",
  4812313600: "경상남도 창원시 성산구 신월동",
  4812313700: "경상남도 창원시 성산구 대원동",
  4812313800: "경상남도 창원시 성산구 두대동",
  4812313900: "경상남도 창원시 성산구 삼동동",
  4812314000: "경상남도 창원시 성산구 덕정동",
  4812314100: "경상남도 창원시 성산구 퇴촌동",
  4812500000: "경상남도 창원시 마산합포구",
  4812510100: "경상남도 창원시 마산합포구 가포동",
  4812510200: "경상남도 창원시 마산합포구 교방동",
  4812510300: "경상남도 창원시 마산합포구 교원동",
  4812510400: "경상남도 창원시 마산합포구 남성동",
  4812510500: "경상남도 창원시 마산합포구 대내동",
  4812510600: "경상남도 창원시 마산합포구 대성동1가",
  4812510700: "경상남도 창원시 마산합포구 대성동2가",
  4812510800: "경상남도 창원시 마산합포구 대외동",
  4812510900: "경상남도 창원시 마산합포구 대창동",
  4812511000: "경상남도 창원시 마산합포구 덕동동",
  4812511100: "경상남도 창원시 마산합포구 동성동",
  4812511200: "경상남도 창원시 마산합포구 두월동1가",
  4812511300: "경상남도 창원시 마산합포구 두월동2가",
  4812511400: "경상남도 창원시 마산합포구 두월동3가",
  4812511500: "경상남도 창원시 마산합포구 문화동",
  4812511600: "경상남도 창원시 마산합포구 반월동",
  4812511700: "경상남도 창원시 마산합포구 부림동",
  4812511800: "경상남도 창원시 마산합포구 산호동",
  4812511900: "경상남도 창원시 마산합포구 상남동",
  4812512000: "경상남도 창원시 마산합포구 서성동",
  4812512100: "경상남도 창원시 마산합포구 성호동",
  4812512200: "경상남도 창원시 마산합포구 수성동",
  4812512300: "경상남도 창원시 마산합포구 신월동",
  4812512400: "경상남도 창원시 마산합포구 신창동",
  4812512500: "경상남도 창원시 마산합포구 신포동1가",
  4812512600: "경상남도 창원시 마산합포구 신포동2가",
  4812512700: "경상남도 창원시 마산합포구 신흥동",
  4812512800: "경상남도 창원시 마산합포구 완월동",
  4812512900: "경상남도 창원시 마산합포구 월남동1가",
  4812513000: "경상남도 창원시 마산합포구 월남동2가",
  4812513100: "경상남도 창원시 마산합포구 월남동3가",
  4812513200: "경상남도 창원시 마산합포구 월남동4가",
  4812513300: "경상남도 창원시 마산합포구 월남동5가",
  4812513400: "경상남도 창원시 마산합포구 월영동",
  4812513500: "경상남도 창원시 마산합포구 월포동",
  4812513600: "경상남도 창원시 마산합포구 예곡동",
  4812513700: "경상남도 창원시 마산합포구 오동동",
  4812513800: "경상남도 창원시 마산합포구 우산동",
  4812513900: "경상남도 창원시 마산합포구 유록동",
  4812514000: "경상남도 창원시 마산합포구 자산동",
  4812514100: "경상남도 창원시 마산합포구 장군동1가",
  4812514200: "경상남도 창원시 마산합포구 장군동2가",
  4812514300: "경상남도 창원시 마산합포구 장군동3가",
  4812514400: "경상남도 창원시 마산합포구 장군동4가",
  4812514500: "경상남도 창원시 마산합포구 장군동5가",
  4812514600: "경상남도 창원시 마산합포구 중성동",
  4812514700: "경상남도 창원시 마산합포구 중앙동1가",
  4812514800: "경상남도 창원시 마산합포구 중앙동2가",
  4812514900: "경상남도 창원시 마산합포구 중앙동3가",
  4812515000: "경상남도 창원시 마산합포구 창동",
  4812515100: "경상남도 창원시 마산합포구 창포동1가",
  4812515200: "경상남도 창원시 마산합포구 창포동2가",
  4812515300: "경상남도 창원시 마산합포구 창포동3가",
  4812515400: "경상남도 창원시 마산합포구 청계동",
  4812515500: "경상남도 창원시 마산합포구 추산동",
  4812515600: "경상남도 창원시 마산합포구 평화동",
  4812515700: "경상남도 창원시 마산합포구 화영동",
  4812515800: "경상남도 창원시 마산합포구 해운동",
  4812515900: "경상남도 창원시 마산합포구 현동",
  4812516000: "경상남도 창원시 마산합포구 홍문동",
  4812531000: "경상남도 창원시 마산합포구 구산면",
  4812532000: "경상남도 창원시 마산합포구 진동면",
  4812533000: "경상남도 창원시 마산합포구 진북면",
  4812534000: "경상남도 창원시 마산합포구 진전면",
  4812700000: "경상남도 창원시 마산회원구",
  4812710100: "경상남도 창원시 마산회원구 구암동",
  4812710200: "경상남도 창원시 마산회원구 두척동",
  4812710300: "경상남도 창원시 마산회원구 봉암동",
  4812710400: "경상남도 창원시 마산회원구 석전동",
  4812710500: "경상남도 창원시 마산회원구 양덕동",
  4812710600: "경상남도 창원시 마산회원구 합성동",
  4812710700: "경상남도 창원시 마산회원구 회성동",
  4812710800: "경상남도 창원시 마산회원구 회원동",
  4812725000: "경상남도 창원시 마산회원구 내서읍",
  4812900000: "경상남도 창원시 진해구",
  4812910100: "경상남도 창원시 진해구 동상동",
  4812910200: "경상남도 창원시 진해구 도천동",
  4812910300: "경상남도 창원시 진해구 도만동",
  4812910400: "경상남도 창원시 진해구 신흥동",
  4812910500: "경상남도 창원시 진해구 현동",
  4812910600: "경상남도 창원시 진해구 비봉동",
  4812910700: "경상남도 창원시 진해구 태평동",
  4812910800: "경상남도 창원시 진해구 충의동",
  4812910900: "경상남도 창원시 진해구 무송동",
  4812911000: "경상남도 창원시 진해구 인의동",
  4812911100: "경상남도 창원시 진해구 숭인동",
  4812911200: "경상남도 창원시 진해구 대영동",
  4812911300: "경상남도 창원시 진해구 남빈동",
  4812911400: "경상남도 창원시 진해구 앵곡동",
  4812911500: "경상남도 창원시 진해구 제황산동",
  4812911600: "경상남도 창원시 진해구 속천동",
  4812911700: "경상남도 창원시 진해구 대죽동",
  4812911800: "경상남도 창원시 진해구 안곡동",
  4812911900: "경상남도 창원시 진해구 수송동",
  4812912000: "경상남도 창원시 진해구 회현동",
  4812912100: "경상남도 창원시 진해구 익선동",
  4812912200: "경상남도 창원시 진해구 창선동",
  4812912300: "경상남도 창원시 진해구 대천동",
  4812912400: "경상남도 창원시 진해구 광화동",
  4812912500: "경상남도 창원시 진해구 통신동",
  4812912600: "경상남도 창원시 진해구 중앙동",
  4812912700: "경상남도 창원시 진해구 부흥동",
  4812912800: "경상남도 창원시 진해구 중평동",
  4812912900: "경상남도 창원시 진해구 근화동",
  4812913000: "경상남도 창원시 진해구 송죽동",
  4812913100: "경상남도 창원시 진해구 화천동",
  4812913200: "경상남도 창원시 진해구 송학동",
  4812913300: "경상남도 창원시 진해구 대흥동",
  4812913400: "경상남도 창원시 진해구 평안동",
  4812913500: "경상남도 창원시 진해구 충무동",
  4812913600: "경상남도 창원시 진해구 인사동",
  4812913700: "경상남도 창원시 진해구 여좌동",
  4812913800: "경상남도 창원시 진해구 태백동",
  4812913900: "경상남도 창원시 진해구 경화동",
  4812914000: "경상남도 창원시 진해구 석동",
  4812914100: "경상남도 창원시 진해구 이동",
  4812914200: "경상남도 창원시 진해구 자은동",
  4812914300: "경상남도 창원시 진해구 덕산동",
  4812914400: "경상남도 창원시 진해구 풍호동",
  4812914500: "경상남도 창원시 진해구 장천동",
  4812914600: "경상남도 창원시 진해구 행암동",
  4812914700: "경상남도 창원시 진해구 북부동",
  4812914800: "경상남도 창원시 진해구 성내동",
  4812914900: "경상남도 창원시 진해구 서중동",
  4812915000: "경상남도 창원시 진해구 남문동",
  4812915100: "경상남도 창원시 진해구 제덕동",
  4812915200: "경상남도 창원시 진해구 수도동",
  4812915300: "경상남도 창원시 진해구 연도동",
  4812915400: "경상남도 창원시 진해구 명동",
  4812915500: "경상남도 창원시 진해구 죽곡동",
  4812915600: "경상남도 창원시 진해구 원포동",
  4812915700: "경상남도 창원시 진해구 남양동",
  4812915800: "경상남도 창원시 진해구 마천동",
  4812915900: "경상남도 창원시 진해구 소사동",
  4812916000: "경상남도 창원시 진해구 대장동",
  4812916100: "경상남도 창원시 진해구 두동",
  4812916200: "경상남도 창원시 진해구 청안동",
  4812916300: "경상남도 창원시 진해구 안골동",
  4812916400: "경상남도 창원시 진해구 용원동",
  4812916500: "경상남도 창원시 진해구 가주동",
  4817000000: "경상남도 진주시",
  4817010100: "경상남도 진주시 망경동",
  4817010200: "경상남도 진주시 주약동",
  4817010300: "경상남도 진주시 강남동",
  4817010400: "경상남도 진주시 칠암동",
  4817010500: "경상남도 진주시 본성동",
  4817010600: "경상남도 진주시 동성동",
  4817010700: "경상남도 진주시 남성동",
  4817010800: "경상남도 진주시 인사동",
  4817010900: "경상남도 진주시 대안동",
  4817011000: "경상남도 진주시 평안동",
  4817011100: "경상남도 진주시 중안동",
  4817011200: "경상남도 진주시 계동",
  4817011300: "경상남도 진주시 봉곡동",
  4817011400: "경상남도 진주시 상봉동",
  4817011500: "경상남도 진주시 봉래동",
  4817011600: "경상남도 진주시 수정동",
  4817011700: "경상남도 진주시 장대동",
  4817011800: "경상남도 진주시 옥봉동",
  4817011900: "경상남도 진주시 상대동",
  4817012000: "경상남도 진주시 하대동",
  4817012100: "경상남도 진주시 상평동",
  4817012200: "경상남도 진주시 초전동",
  4817012300: "경상남도 진주시 장재동",
  4817012400: "경상남도 진주시 하촌동",
  4817012500: "경상남도 진주시 신안동",
  4817012600: "경상남도 진주시 평거동",
  4817012700: "경상남도 진주시 이현동",
  4817012800: "경상남도 진주시 유곡동",
  4817012900: "경상남도 진주시 판문동",
  4817013000: "경상남도 진주시 귀곡동",
  4817013100: "경상남도 진주시 가좌동",
  4817013200: "경상남도 진주시 호탄동",
  4817013700: "경상남도 진주시 충무공동",
  4817025000: "경상남도 진주시 문산읍",
  4817031000: "경상남도 진주시 내동면",
  4817032000: "경상남도 진주시 정촌면",
  4817033000: "경상남도 진주시 금곡면",
  4817035000: "경상남도 진주시 진성면",
  4817036000: "경상남도 진주시 일반성면",
  4817037000: "경상남도 진주시 이반성면",
  4817038000: "경상남도 진주시 사봉면",
  4817039000: "경상남도 진주시 지수면",
  4817040000: "경상남도 진주시 대곡면",
  4817041000: "경상남도 진주시 금산면",
  4817042000: "경상남도 진주시 집현면",
  4817043000: "경상남도 진주시 미천면",
  4817044000: "경상남도 진주시 명석면",
  4817045000: "경상남도 진주시 대평면",
  4817046000: "경상남도 진주시 수곡면",
  4822000000: "경상남도 통영시",
  4822010100: "경상남도 통영시 도천동",
  4822010200: "경상남도 통영시 서호동",
  4822010300: "경상남도 통영시 명정동",
  4822010400: "경상남도 통영시 항남동",
  4822010500: "경상남도 통영시 중앙동",
  4822010600: "경상남도 통영시 문화동",
  4822010700: "경상남도 통영시 태평동",
  4822010800: "경상남도 통영시 동호동",
  4822010900: "경상남도 통영시 정량동",
  4822011000: "경상남도 통영시 북신동",
  4822011100: "경상남도 통영시 무전동",
  4822011200: "경상남도 통영시 평림동",
  4822011300: "경상남도 통영시 인평동",
  4822011400: "경상남도 통영시 당동",
  4822011500: "경상남도 통영시 미수동",
  4822011600: "경상남도 통영시 봉평동",
  4822011700: "경상남도 통영시 도남동",
  4822025000: "경상남도 통영시 산양읍",
  4822031000: "경상남도 통영시 용남면",
  4822033000: "경상남도 통영시 도산면",
  4822034000: "경상남도 통영시 광도면",
  4822035000: "경상남도 통영시 욕지면",
  4822036000: "경상남도 통영시 한산면",
  4822037000: "경상남도 통영시 사량면",
  4824000000: "경상남도 사천시",
  4824010100: "경상남도 사천시 동동",
  4824010200: "경상남도 사천시 서동",
  4824010300: "경상남도 사천시 선구동",
  4824010400: "경상남도 사천시 동금동",
  4824010500: "경상남도 사천시 서금동",
  4824010600: "경상남도 사천시 동림동",
  4824010700: "경상남도 사천시 좌룡동",
  4824010800: "경상남도 사천시 벌리동",
  4824010900: "경상남도 사천시 용강동",
  4824011000: "경상남도 사천시 와룡동",
  4824011100: "경상남도 사천시 봉남동",
  4824011200: "경상남도 사천시 이금동",
  4824011300: "경상남도 사천시 이홀동",
  4824011400: "경상남도 사천시 궁지동",
  4824011500: "경상남도 사천시 사등동",
  4824011600: "경상남도 사천시 향촌동",
  4824011700: "경상남도 사천시 대방동",
  4824011800: "경상남도 사천시 실안동",
  4824011900: "경상남도 사천시 마도동",
  4824012000: "경상남도 사천시 늑도동",
  4824012100: "경상남도 사천시 신수동",
  4824012200: "경상남도 사천시 백천동",
  4824012300: "경상남도 사천시 신벽동",
  4824012400: "경상남도 사천시 노룡동",
  4824012500: "경상남도 사천시 대포동",
  4824012600: "경상남도 사천시 송포동",
  4824012700: "경상남도 사천시 죽림동",
  4824025000: "경상남도 사천시 사천읍",
  4824031000: "경상남도 사천시 정동면",
  4824032000: "경상남도 사천시 사남면",
  4824033000: "경상남도 사천시 용현면",
  4824034000: "경상남도 사천시 축동면",
  4824035000: "경상남도 사천시 곤양면",
  4824036000: "경상남도 사천시 곤명면",
  4824037000: "경상남도 사천시 서포면",
  4825000000: "경상남도 김해시",
  4825010100: "경상남도 김해시 동상동",
  4825010200: "경상남도 김해시 서상동",
  4825010300: "경상남도 김해시 부원동",
  4825010400: "경상남도 김해시 봉황동",
  4825010500: "경상남도 김해시 대성동",
  4825010600: "경상남도 김해시 구산동",
  4825010700: "경상남도 김해시 삼계동",
  4825010800: "경상남도 김해시 내동",
  4825010900: "경상남도 김해시 외동",
  4825011000: "경상남도 김해시 흥동",
  4825011100: "경상남도 김해시 풍유동",
  4825011200: "경상남도 김해시 명법동",
  4825011300: "경상남도 김해시 이동",
  4825011400: "경상남도 김해시 화목동",
  4825011500: "경상남도 김해시 전하동",
  4825011600: "경상남도 김해시 강동",
  4825011700: "경상남도 김해시 삼정동",
  4825011800: "경상남도 김해시 어방동",
  4825011900: "경상남도 김해시 삼방동",
  4825012000: "경상남도 김해시 안동",
  4825012100: "경상남도 김해시 지내동",
  4825012200: "경상남도 김해시 불암동",
  4825012300: "경상남도 김해시 유하동",
  4825012400: "경상남도 김해시 내덕동",
  4825012500: "경상남도 김해시 부곡동",
  4825012600: "경상남도 김해시 무계동",
  4825012700: "경상남도 김해시 신문동",
  4825012800: "경상남도 김해시 삼문동",
  4825012900: "경상남도 김해시 대청동",
  4825013000: "경상남도 김해시 관동동",
  4825013100: "경상남도 김해시 율하동",
  4825013200: "경상남도 김해시 장유동",
  4825013300: "경상남도 김해시 응달동",
  4825013400: "경상남도 김해시 수가동",
  4825025000: "경상남도 김해시 진영읍",
  4825032000: "경상남도 김해시 주촌면",
  4825033000: "경상남도 김해시 진례면",
  4825034000: "경상남도 김해시 한림면",
  4825035000: "경상남도 김해시 생림면",
  4825036000: "경상남도 김해시 상동면",
  4825037000: "경상남도 김해시 대동면",
  4827000000: "경상남도 밀양시",
  4827010100: "경상남도 밀양시 내일동",
  4827010200: "경상남도 밀양시 내이동",
  4827010300: "경상남도 밀양시 교동",
  4827010400: "경상남도 밀양시 삼문동",
  4827010500: "경상남도 밀양시 남포동",
  4827010600: "경상남도 밀양시 용평동",
  4827010700: "경상남도 밀양시 활성동",
  4827010800: "경상남도 밀양시 가곡동",
  4827025000: "경상남도 밀양시 삼랑진읍",
  4827025300: "경상남도 밀양시 하남읍",
  4827031000: "경상남도 밀양시 부북면",
  4827032000: "경상남도 밀양시 상동면",
  4827033000: "경상남도 밀양시 산외면",
  4827034000: "경상남도 밀양시 산내면",
  4827035000: "경상남도 밀양시 단장면",
  4827036000: "경상남도 밀양시 상남면",
  4827037000: "경상남도 밀양시 초동면",
  4827038000: "경상남도 밀양시 무안면",
  4827039000: "경상남도 밀양시 청도면",
  4831000000: "경상남도 거제시",
  4831010100: "경상남도 거제시 능포동",
  4831010200: "경상남도 거제시 장승포동",
  4831010300: "경상남도 거제시 두모동",
  4831010400: "경상남도 거제시 아양동",
  4831010500: "경상남도 거제시 아주동",
  4831010600: "경상남도 거제시 옥포동",
  4831010700: "경상남도 거제시 덕포동",
  4831010800: "경상남도 거제시 장평동",
  4831010900: "경상남도 거제시 고현동",
  4831011000: "경상남도 거제시 상동동",
  4831011100: "경상남도 거제시 문동동",
  4831011200: "경상남도 거제시 삼거동",
  4831011300: "경상남도 거제시 양정동",
  4831011400: "경상남도 거제시 수월동",
  4831031000: "경상남도 거제시 일운면",
  4831032000: "경상남도 거제시 동부면",
  4831033000: "경상남도 거제시 남부면",
  4831034000: "경상남도 거제시 거제면",
  4831035000: "경상남도 거제시 둔덕면",
  4831036000: "경상남도 거제시 사등면",
  4831037000: "경상남도 거제시 연초면",
  4831038000: "경상남도 거제시 하청면",
  4831039000: "경상남도 거제시 장목면",
  4833000000: "경상남도 양산시",
  4833010100: "경상남도 양산시 다방동",
  4833010200: "경상남도 양산시 남부동",
  4833010300: "경상남도 양산시 중부동",
  4833010400: "경상남도 양산시 북부동",
  4833010500: "경상남도 양산시 명곡동",
  4833010600: "경상남도 양산시 신기동",
  4833010700: "경상남도 양산시 북정동",
  4833010800: "경상남도 양산시 산막동",
  4833010900: "경상남도 양산시 호계동",
  4833011000: "경상남도 양산시 교동",
  4833011100: "경상남도 양산시 유산동",
  4833011200: "경상남도 양산시 어곡동",
  4833011300: "경상남도 양산시 용당동",
  4833011400: "경상남도 양산시 삼호동",
  4833011500: "경상남도 양산시 명동",
  4833011600: "경상남도 양산시 주남동",
  4833011700: "경상남도 양산시 소주동",
  4833011800: "경상남도 양산시 주진동",
  4833011900: "경상남도 양산시 평산동",
  4833012000: "경상남도 양산시 덕계동",
  4833012100: "경상남도 양산시 매곡동",
  4833025300: "경상남도 양산시 물금읍",
  4833031000: "경상남도 양산시 동면",
  4833032000: "경상남도 양산시 원동면",
  4833033000: "경상남도 양산시 상북면",
  4833034000: "경상남도 양산시 하북면",
  4872000000: "경상남도 의령군",
  4872025000: "경상남도 의령군 의령읍",
  4872031000: "경상남도 의령군 가례면",
  4872032000: "경상남도 의령군 칠곡면",
  4872033000: "경상남도 의령군 대의면",
  4872034000: "경상남도 의령군 화정면",
  4872035000: "경상남도 의령군 용덕면",
  4872036000: "경상남도 의령군 정곡면",
  4872037000: "경상남도 의령군 지정면",
  4872038000: "경상남도 의령군 낙서면",
  4872039000: "경상남도 의령군 부림면",
  4872040000: "경상남도 의령군 봉수면",
  4872041500: "경상남도 의령군 궁류면",
  4872042000: "경상남도 의령군 유곡면",
  4873000000: "경상남도 함안군",
  4873025000: "경상남도 함안군 가야읍",
  4873025300: "경상남도 함안군 칠원읍",
  4873031000: "경상남도 함안군 함안면",
  4873032000: "경상남도 함안군 군북면",
  4873033000: "경상남도 함안군 법수면",
  4873034000: "경상남도 함안군 대산면",
  4873035000: "경상남도 함안군 칠서면",
  4873036000: "경상남도 함안군 칠북면",
  4873038000: "경상남도 함안군 산인면",
  4873039000: "경상남도 함안군 여항면",
  4874000000: "경상남도 창녕군",
  4874025000: "경상남도 창녕군 창녕읍",
  4874025300: "경상남도 창녕군 남지읍",
  4874031000: "경상남도 창녕군 고암면",
  4874032000: "경상남도 창녕군 성산면",
  4874033000: "경상남도 창녕군 대합면",
  4874034000: "경상남도 창녕군 이방면",
  4874035000: "경상남도 창녕군 유어면",
  4874036000: "경상남도 창녕군 대지면",
  4874037000: "경상남도 창녕군 계성면",
  4874038000: "경상남도 창녕군 영산면",
  4874039000: "경상남도 창녕군 장마면",
  4874040000: "경상남도 창녕군 도천면",
  4874041000: "경상남도 창녕군 길곡면",
  4874042000: "경상남도 창녕군 부곡면",
  4882000000: "경상남도 고성군",
  4882025000: "경상남도 고성군 고성읍",
  4882031000: "경상남도 고성군 삼산면",
  4882032000: "경상남도 고성군 하일면",
  4882033000: "경상남도 고성군 하이면",
  4882034000: "경상남도 고성군 상리면",
  4882035000: "경상남도 고성군 대가면",
  4882036000: "경상남도 고성군 영현면",
  4882037000: "경상남도 고성군 영오면",
  4882038000: "경상남도 고성군 개천면",
  4882039000: "경상남도 고성군 구만면",
  4882040000: "경상남도 고성군 회화면",
  4882041000: "경상남도 고성군 마암면",
  4882042000: "경상남도 고성군 동해면",
  4882043000: "경상남도 고성군 거류면",
  4884000000: "경상남도 남해군",
  4884025000: "경상남도 남해군 남해읍",
  4884031000: "경상남도 남해군 이동면",
  4884032000: "경상남도 남해군 상주면",
  4884033000: "경상남도 남해군 삼동면",
  4884034000: "경상남도 남해군 미조면",
  4884035000: "경상남도 남해군 남면",
  4884036000: "경상남도 남해군 서면",
  4884037000: "경상남도 남해군 고현면",
  4884038000: "경상남도 남해군 설천면",
  4884039000: "경상남도 남해군 창선면",
  4885000000: "경상남도 하동군",
  4885025000: "경상남도 하동군 하동읍",
  4885031000: "경상남도 하동군 화개면",
  4885032000: "경상남도 하동군 악양면",
  4885033000: "경상남도 하동군 적량면",
  4885034000: "경상남도 하동군 횡천면",
  4885035000: "경상남도 하동군 고전면",
  4885036000: "경상남도 하동군 금남면",
  4885037000: "경상남도 하동군 진교면",
  4885038000: "경상남도 하동군 양보면",
  4885039000: "경상남도 하동군 북천면",
  4885040000: "경상남도 하동군 청암면",
  4885041000: "경상남도 하동군 옥종면",
  4885042000: "경상남도 하동군 금성면",
  4886000000: "경상남도 산청군",
  4886025000: "경상남도 산청군 산청읍",
  4886031000: "경상남도 산청군 차황면",
  4886032000: "경상남도 산청군 오부면",
  4886033000: "경상남도 산청군 생초면",
  4886034000: "경상남도 산청군 금서면",
  4886035000: "경상남도 산청군 삼장면",
  4886036000: "경상남도 산청군 시천면",
  4886037000: "경상남도 산청군 단성면",
  4886038000: "경상남도 산청군 신안면",
  4886039000: "경상남도 산청군 생비량면",
  4886040000: "경상남도 산청군 신등면",
  4887000000: "경상남도 함양군",
  4887025000: "경상남도 함양군 함양읍",
  4887031000: "경상남도 함양군 마천면",
  4887032000: "경상남도 함양군 휴천면",
  4887033000: "경상남도 함양군 유림면",
  5011011500: "제주특별자치도 제주시 삼양삼동",
  5011011600: "제주특별자치도 제주시 봉개동",
  5011011700: "제주특별자치도 제주시 아라일동",
  5011011800: "제주특별자치도 제주시 아라이동",
  5011011900: "제주특별자치도 제주시 오라일동",
  5011012000: "제주특별자치도 제주시 오라이동",
  5011012100: "제주특별자치도 제주시 오라삼동",
  5011012200: "제주특별자치도 제주시 노형동",
  5011012300: "제주특별자치도 제주시 외도일동",
  5011012400: "제주특별자치도 제주시 외도이동",
  5011012500: "제주특별자치도 제주시 이호일동",
  5011012600: "제주특별자치도 제주시 이호이동",
  5011012700: "제주특별자치도 제주시 도두일동",
  5011012800: "제주특별자치도 제주시 도두이동",
  5011012900: "제주특별자치도 제주시 도남동",
  5011013000: "제주특별자치도 제주시 도련일동",
  5011013100: "제주특별자치도 제주시 도련이동",
  5011013200: "제주특별자치도 제주시 용강동",
  5011013300: "제주특별자치도 제주시 회천동",
  5011013400: "제주특별자치도 제주시 오등동",
  5011013500: "제주특별자치도 제주시 월평동",
  5011013600: "제주특별자치도 제주시 영평동",
  5011013700: "제주특별자치도 제주시 연동",
  5011013800: "제주특별자치도 제주시 도평동",
  5011013900: "제주특별자치도 제주시 해안동",
  5011014000: "제주특별자치도 제주시 내도동",
  5011025000: "제주특별자치도 제주시 한림읍",
  5011025300: "제주특별자치도 제주시 애월읍",
  5011025600: "제주특별자치도 제주시 구좌읍",
  5011025900: "제주특별자치도 제주시 조천읍",
  5011031000: "제주특별자치도 제주시 한경면",
  5011032000: "제주특별자치도 제주시 추자면",
  5011033000: "제주특별자치도 제주시 우도면",
  5013000000: "제주특별자치도 서귀포시",
  5013010100: "제주특별자치도 서귀포시 서귀동",
  5013010200: "제주특별자치도 서귀포시 법환동",
  5013010300: "제주특별자치도 서귀포시 서호동",
  5013010400: "제주특별자치도 서귀포시 호근동",
  5013010500: "제주특별자치도 서귀포시 동홍동",
  5013010600: "제주특별자치도 서귀포시 서홍동",
  5013010700: "제주특별자치도 서귀포시 상효동",
  5013010800: "제주특별자치도 서귀포시 하효동",
  5013010900: "제주특별자치도 서귀포시 신효동",
  5013011000: "제주특별자치도 서귀포시 보목동",
  5013011100: "제주특별자치도 서귀포시 토평동",
  5013011200: "제주특별자치도 서귀포시 중문동",
  5013011300: "제주특별자치도 서귀포시 회수동",
  5013011400: "제주특별자치도 서귀포시 대포동",
  5013011500: "제주특별자치도 서귀포시 월평동",
  5013011600: "제주특별자치도 서귀포시 강정동",
  5013011700: "제주특별자치도 서귀포시 도순동",
  5013011800: "제주특별자치도 서귀포시 하원동",
  5013011900: "제주특별자치도 서귀포시 색달동",
  5013012000: "제주특별자치도 서귀포시 상예동",
  5013012100: "제주특별자치도 서귀포시 하예동",
  5013012200: "제주특별자치도 서귀포시 영남동",
  5013025000: "제주특별자치도 서귀포시 대정읍",
  5013025300: "제주특별자치도 서귀포시 남원읍",
  5013025900: "제주특별자치도 서귀포시 성산읍",
  5013031000: "제주특별자치도 서귀포시 안덕면",
  5013032000: "제주특별자치도 서귀포시 표선면",
  9900000000: "해당 없음",
};

export const firstDistrict: { [key: number]: string } = {
  1000000000: "전체",
  1100000000: "서울특별시",
  2600000000: "부산광역시",
  2700000000: "대구광역시",
  2800000000: "인천광역시",
  2900000000: "광주광역시",
  3000000000: "대전광역시",
  3100000000: "울산광역시",
  3611000000: "세종특별자치시",
  4100000000: "경기도",
  4200000000: "강원도",
  4300000000: "충청북도",
  4400000000: "충청남도",
  4500000000: "전라북도",
  4600000000: "전라남도",
  4700000000: "경상북도",
  4800000000: "경상남도",
  5000000000: "제주특별자치도",
  9900000000: "해당 없음",
};

export const secondDistrict: { [key: number]: { [key: number]: string } } = {
  1100000000: {
    1100000000: "전체",
    1111000000: "종로구",
    1114000000: "중구",
    1117000000: "용산구",
    1120000000: "성동구",
    1123000000: "동대문구",
    1126000000: "중랑구",
    1129000000: "성북구",
    1132000000: "도봉구",
    1135000000: "노원구",
    1138000000: "은평구",
    1141000000: "서대문구",
    1144000000: "마포구",
    1147000000: "양천구",
    1150000000: "강서구",
    1153000000: "구로구",
    1156000000: "영등포구",
    1159000000: "동작구",
    1162000000: "관악구",
    1165000000: "서초구",
    1168000000: "강남구",
    1171000000: "송파구",
    1174000000: "강동구",
  },
  2600000000: {
    2600000000: "전체",
    2611000000: "중구",
    2614000000: "서구",
    2617000000: "동구",
    2620000000: "영도구",
    2623000000: "부산진구",
    2626000000: "동래구",
    2629000000: "남구",
    2632000000: "북구",
    2635000000: "해운대구",
    2638000000: "사하구",
    2641000000: "금정구",
    2644000000: "강서구",
    2647000000: "연제구",
    2650000000: "수영구",
    2653000000: "사상구",
    2671000000: "기장군",
  },
  2700000000: {
    2700000000: "전체",
    2711000000: "중구",
    2714000000: "동구",
    2717000000: "서구",
    2720000000: "남구",
    2723000000: "북구",
    2726000000: "수성구",
    2729000000: "달서구",
    2771000000: "달성군",
  },
  2800000000: {
    2800000000: "전체",
    2811000000: "중구",
    2814000000: "동구",
    2820000000: "남동구",
    2826000000: "서구",
    2871000000: "강화군",
    2872000000: "옹진군",
  },
  2900000000: {
    2900000000: "전체",
    2911000000: "동구",
    2914000000: "서구",
    2917000000: "북구",
    2920000000: "광산구",
  },
  3000000000: {
    3000000000: "전체",
    3011000000: "동구",
    3014000000: "중구",
    3017000000: "서구",
    3020000000: "유성구",
    3023000000: "대덕구",
  },
  3100000000: {
    3100000000: "전체",
    3111000000: "중구",
    3114000000: "남구",
    3117000000: "동구",
    3120000000: "북구",
    3171000000: "울주군",
  },
  3611000000: {
    3611000000: "전체",
    3611011000: "어진동",
    3611012000: "누리동",
    3611025000: "조치원읍",
    3611031000: "연기면",
    3611032000: "연동면",
    3611033000: "부강면",
    3611034000: "금남면",
    3611035000: "장군면",
    3611036000: "연서면",
    3611037000: "전의면",
    3611038000: "전동면",
    3611039000: "소정면",
  },
  4100000000: {
    4111000000: "전체",
    4113000000: "성남시",
    4115000000: "의정부시",
    4117000000: "안양시",
    4119000000: "부천시",
    4121000000: "광명시",
    4122000000: "평택시",
    4125000000: "동두천시",
    4127000000: "안산시",
    4128000000: "고양시",
    4129000000: "과천시",
    4131000000: "구리시",
    4136000000: "남양주시",
    4137000000: "오산시",
    4139000000: "시흥시",
    4141000000: "군포시",
    4143000000: "의왕시",
    4145000000: "하남시",
    4146000000: "용인시",
    4148000000: "파주시",
    4150000000: "이천시",
    4155000000: "안성시",
    4157000000: "김포시",
    4159000000: "화성시",
    4161000000: "광주시",
    4163000000: "양주시",
    4165000000: "포천시",
    4167000000: "여주시",
    4180000000: "연천군",
    4182000000: "가평군",
    4183000000: "양평군",
  },
  4200000000: {
    4211000000: "춘천시",
    4213000000: "원주시",
    4215000000: "강릉시",
    4217000000: "동해시",
    4219000000: "태백시",
    4221000000: "속초시",
    4223000000: "삼척시",
    4272000000: "홍천군",
    4273000000: "횡성군",
    4275000000: "영월군",
    4276000000: "평창군",
    4277000000: "정선군",
    4278000000: "철원군",
    4279000000: "화천군",
    4280000000: "양구군",
    4281000000: "인제군",
    4282000000: "고성군",
    4283000000: "양양군",
  },
  4300000000: {
    4311000000: "청주시",
    4313000000: "충주시",
    4315000000: "제천시",
    4372000000: "보은군",
    4373000000: "옥천군",
    4374000000: "영동군",
    4375000000: "진천군",
    4376000000: "괴산군",
    4377000000: "음성군",
    4380000000: "단양군",
  },
  4400000000: {
    4400000000: "전체",
    4413000000: "천안시",
    4415000000: "공주시",
    4418000000: "보령시",
    4420000000: "아산시",
    4421000000: "서산시",
    4423000000: "논산시",
    4425000000: "계룡시",
    4427000000: "당진시",
    4471000000: "금산군",
    4476000000: "부여군",
    4477000000: "서천군",
    4479000000: "청양군",
    4480000000: "홍성군",
    4481000000: "예산군",
  },
  4500000000: {
    4500000000: "전체",
    4511000000: "전주시",
    4513000000: "군산시",
    4514000000: "익산시",
    4518000000: "정읍시",
    4519000000: "남원시",
    4521000000: "김제시",
    4571000000: "완주군",
    4572000000: "진안군",
    4573000000: "무주군",
    4574000000: "장수군",
    4575000000: "임실군",
    4577000000: "순창군",
    4579000000: "고창군",
    4580000000: "부안군",
  },
  4600000000: {
    4600000000: "전체",
    4611000000: "목포시",
    4613000000: "여수시",
    4615000000: "순천시",
    4617000000: "나주시",
    4623000000: "광양시",
    4671000000: "담양군",
    4672000000: "곡성군",
    4673000000: "구례군",
    4677000000: "고흥군",
    4678000000: "보성군",
    4679000000: "화순군",
    4680000000: "장흥군",
    4681000000: "강진군",
    4682000000: "해남군",
    4683000000: "영암군",
    4684000000: "무안군",
    4686000000: "함평군",
    4687000000: "영광군",
    4688000000: "장성군",
    4689000000: "완도군",
    4690000000: "진도군",
    4691000000: "신안군",
  },
  4700000000: {
    4700000000: "전체",
    4711000000: "포항시",
    4713000000: "경주시",
    4715000000: "김천시",
    4717000000: "안동시",
    4719000000: "구미시",
    4721000000: "영주시",
    4723000000: "영천시",
    4725000000: "상주시",
    4728000000: "문경시",
    4729000000: "경산시",
    4772000000: "군위군",
    4773000000: "의성군",
    4775000000: "청송군",
    4776000000: "영양군",
    4777000000: "영덕군",
    4782000000: "청도군",
    4783000000: "고령군",
    4784000000: "성주군",
    4785000000: "칠곡군",
    4790000000: "예천군",
    4792000000: "봉화군",
    4793000000: "울진군",
    4794000000: "울릉군",
  },
  4800000000: {
    4800000000: "전체",
    4812000000: "창원시",
    4817000000: "진주시",
    4822000000: "통영시",
    4824000000: "사천시",
    4825000000: "김해시",
    4827000000: "밀양시",
    4831000000: "거제시",
    4833000000: "양산시",
    4872000000: "의령군",
    4873000000: "함안군",
    4874000000: "창녕군",
    4882000000: "고성군",
    4884000000: "남해군",
    4885000000: "하동군",
    4886000000: "산청군",
    4887000000: "함양군",
    4888000000: "거창군",
    4889000000: "합천군",
  },
  5000000000: {
    5000000000: "전체",
    5011000000: "제주시",
    5013000000: "서귀포시",
  },
};

export const thirdDistrict: {
  [key: number]: { [key: number]: { [key: number]: string } };
} = {
  1100000000: {
    1111000000: {
      1111000000: "전체",
      1111010100: "청운동",
      1111010200: "신교동",
      1111010300: "궁정동",
      1111010400: "효자동",
      1111010500: "창성동",
      1111010600: "통의동",
      1111010700: "적선동",
      1111010800: "통인동",
      1111010900: "누상동",
      1111011000: "누하동",
      1111011100: "옥인동",
      1111011200: "체부동",
      1111011300: "필운동",
      1111011400: "내자동",
      1111011500: "사직동",
      1111011600: "도렴동",
      1111011700: "당주동",
      1111011800: "내수동",
      1111011900: "세종로",
      1111012000: "신문로1가",
      1111012100: "신문로2가",
      1111012200: "청진동",
      1111012300: "서린동",
      1111012400: "수송동",
      1111012500: "중학동",
      1111012600: "종로1가",
      1111012700: "공평동",
      1111012800: "관훈동",
      1111012900: "견지동",
      1111013000: "와룡동",
      1111013100: "권농동",
      1111013200: "운니동",
      1111013300: "익선동",
      1111013400: "경운동",
      1111013500: "관철동",
      1111013600: "인사동",
      1111013700: "낙원동",
      1111013800: "종로2가",
      1111013900: "팔판동",
      1111014000: "삼청동",
      1111014100: "안국동",
      1111014200: "소격동",
      1111014300: "화동",
      1111014400: "사간동",
      1111014500: "송현동",
      1111014600: "가회동",
      1111014700: "재동",
      1111014800: "계동",
      1111014900: "원서동",
      1111015000: "훈정동",
      1111015100: "묘동",
      1111015200: "봉익동",
      1111015300: "돈의동",
      1111015400: "장사동",
      1111015500: "관수동",
      1111015600: "종로3가",
      1111015700: "인의동",
      1111015800: "예지동",
      1111015900: "원남동",
      1111016000: "연지동",
      1111016100: "종로4가",
      1111016200: "효제동",
      1111016300: "종로5가",
      1111016400: "종로6가",
      1111016500: "이화동",
      1111016600: "연건동",
      1111016700: "충신동",
      1111016800: "동숭동",
      1111016900: "혜화동",
      1111017000: "명륜1가",
      1111017100: "명륜2가",
      1111017200: "명륜4가",
      1111017300: "명륜3가",
      1111017400: "창신동",
      1111017500: "숭인동",
      1111017600: "교남동",
      1111017700: "평동",
      1111017800: "송월동",
      1111017900: "홍파동",
      1111018000: "교북동",
      1111018100: "행촌동",
      1111018200: "구기동",
      1111018300: "평창동",
      1111018400: "부암동",
      1111018500: "홍지동",
      1111018600: "신영동",
      1111018700: "무악동",
    },
    1114000000: {
      1114000000: "전체",
      1114010100: "무교동",
      1114010200: "다동",
      1114010300: "태평로1가",
      1114010400: "을지로1가",
      1114010500: "을지로2가",
      1114010600: "남대문로1가",
      1114010700: "삼각동",
      1114010800: "수하동",
      1114010900: "장교동",
      1114011000: "수표동",
      1114011100: "소공동",
      1114011200: "남창동",
      1114011300: "북창동",
      1114011400: "태평로2가",
      1114011500: "남대문로2가",
      1114011600: "남대문로3가",
      1114011700: "남대문로4가",
      1114011800: "남대문로5가",
      1114011900: "봉래동1가",
      1114012000: "봉래동2가",
      1114012100: "회현동1가",
      1114012200: "회현동2가",
      1114012300: "회현동3가",
      1114012400: "충무로1가",
      1114012500: "충무로2가",
      1114012600: "명동1가",
      1114012700: "명동2가",
      1114012800: "남산동1가",
      1114012900: "남산동2가",
      1114013000: "남산동3가",
      1114013100: "저동1가",
      1114013200: "충무로4가",
      1114013300: "충무로5가",
      1114013400: "인현동2가",
      1114013500: "예관동",
      1114013600: "묵정동",
      1114013700: "필동1가",
      1114013800: "필동2가",
      1114013900: "필동3가",
      1114014000: "남학동",
      1114014100: "주자동",
      1114014200: "예장동",
      1114014300: "장충동1가",
      1114014400: "장충동2가",
      1114014500: "광희동1가",
      1114014600: "광희동2가",
      1114014700: "쌍림동",
      1114014800: "을지로6가",
      1114014900: "을지로7가",
      1114015000: "을지로4가",
      1114015100: "을지로5가",
      1114015200: "주교동",
      1114015300: "방산동",
      1114015400: "오장동",
      1114015500: "을지로3가",
      1114015600: "입정동",
      1114015700: "산림동",
      1114015800: "충무로3가",
      1114015900: "초동",
      1114016000: "인현동1가",
      1114016100: "저동2가",
      1114016200: "신당동",
      1114016300: "흥인동",
      1114016400: "무학동",
      1114016500: "황학동",
      1114016600: "서소문동",
      1114016700: "정동",
      1114016800: "순화동",
      1114016900: "의주로1가",
      1114017000: "충정로1가",
      1114017100: "중림동",
      1114017200: "의주로2가",
      1114017300: "만리동1가",
      1114017400: "만리동2가",
    },
    1117000000: {
      1117000000: "전체",
      1117010100: "후암동",
      1117010200: "용산동2가",
      1117010300: "용산동4가",
      1117010400: "갈월동",
      1117010500: "남영동",
      1117010600: "용산동1가",
      1117010700: "동자동",
      1117010800: "서계동",
      1117010900: "청파동1가",
      1117011000: "청파동2가",
      1117011100: "청파동3가",
      1117011200: "원효로1가",
      1117011300: "원효로2가",
      1117011400: "신창동",
      1117011500: "산천동",
      1117011600: "청암동",
      1117011700: "원효로3가",
      1117011800: "원효로4가",
      1117011900: "효창동",
      1117012000: "도원동",
      1117012100: "용문동",
      1117012200: "문배동",
      1117012300: "신계동",
      1117012400: "한강로1가",
      1117012500: "한강로2가",
      1117012600: "용산동3가",
      1117012700: "용산동5가",
      1117012800: "한강로3가",
      1117012900: "이촌동",
      1117013000: "이태원동",
      1117013100: "한남동",
      1117013200: "동빙고동",
      1117013300: "서빙고동",
      1117013400: "주성동",
      1117013500: "용산동6가",
      1117013600: "보광동",
    },
    1120000000: {
      1120000000: "전체",
      1120010100: "상왕십리동",
      1120010200: "하왕십리동",
      1120010300: "홍익동",
      1120010400: "도선동",
      1120010500: "마장동",
      1120010600: "사근동",
      1120010700: "행당동",
      1120010800: "응봉동",
      1120010900: "금호동1가",
      1120011000: "금호동2가",
      1120011100: "금호동3가",
      1120011200: "금호동4가",
      1120011300: "옥수동",
      1120011400: "성수동1가",
      1120011500: "성수동2가",
      1120011800: "송정동",
      1120012200: "용답동",
    },
    1121500000: {
      1121500000: "전체",
      1121510100: "중곡동",
      1121510200: "능동",
      1121510300: "구의동",
      1121510400: "광장동",
      1121510500: "자양동",
      1121510700: "화양동",
      1121510900: "군자동",
    },
    1123000000: {
      1123000000: "전체",
      1123010100: "신설동",
      1123010200: "용두동",
      1123010300: "제기동",
      1123010400: "전농동",
      1123010500: "답십리동",
      1123010600: "장안동",
      1123010700: "청량리동",
      1123010800: "회기동",
      1123010900: "휘경동",
      1123011000: "이문동",
    },
    1126000000: {
      1126000000: "전체",
      1126010100: "면목동",
      1126010200: "상봉동",
      1126010300: "중화동",
      1126010400: "묵동",
      1126010500: "망우동",
      1126010600: "신내동",
    },
    1129000000: {
      1129000000: "전체",
      1129010100: "성북동",
      1129010200: "성북동1가",
      1129010300: "돈암동",
      1129010400: "동소문동1가",
      1129010500: "동소문동2가",
      1129010600: "동소문동3가",
      1129010700: "동소문동4가",
      1129010800: "동소문동5가",
      1129010900: "동소문동6가",
      1129011000: "동소문동7가",
      1129011100: "삼선동1가",
      1129011200: "삼선동2가",
      1129011300: "삼선동3가",
      1129011400: "삼선동4가",
      1129011500: "삼선동5가",
      1129011600: "동선동1가",
      1129011700: "동선동2가",
      1129011800: "동선동3가",
      1129011900: "동선동4가",
      1129012000: "동선동5가",
      1129012100: "안암동1가",
      1129012200: "안암동2가",
      1129012300: "안암동3가",
      1129012400: "안암동4가",
      1129012500: "안암동5가",
      1129012600: "보문동4가",
      1129012700: "보문동5가",
      1129012800: "보문동6가",
      1129012900: "보문동7가",
      1129013000: "보문동1가",
      1129013100: "보문동2가",
      1129013200: "보문동3가",
      1129013300: "정릉동",
      1129013400: "길음동",
      1129013500: "종암동",
      1129013600: "하월곡동",
      1129013700: "상월곡동",
      1129013800: "장위동",
      1129013900: "석관동",
    },
    1132000000: {
      1132000000: "전체",
      1132010500: "쌍문동",
      1132010600: "방학동",
      1132010700: "창동",
      1132010800: "도봉동",
    },
    1135000000: {
      1135000000: "전체",
      1135010200: "월계동",
      1135010300: "공릉동",
      1135010400: "하계동",
      1135010500: "상계동",
      1135010600: "중계동",
    },
    1138000000: {
      1138000000: "전체",
      1138010100: "수색동",
      1138010200: "녹번동",
      1138010300: "불광동",
      1138010400: "갈현동",
      1138010500: "구산동",
      1138010600: "대조동",
      1138010700: "응암동",
      1138010800: "역촌동",
      1138010900: "신사동",
      1138011000: "증산동",
      1138011400: "진관동",
    },
    1141000000: {
      1141000000: "전체",
      1141010100: "충정로2가",
      1141010200: "충정로3가",
      1141010300: "합동",
      1141010400: "미근동",
      1141010500: "냉천동",
      1141010600: "천연동",
      1141010700: "옥천동",
      1141010800: "영천동",
      1141010900: "현저동",
      1141011000: "북아현동",
      1141011100: "홍제동",
      1141011200: "대현동",
      1141011300: "대신동",
      1141011400: "신촌동",
      1141011500: "봉원동",
      1141011600: "창천동",
      1141011700: "연희동",
      1141011800: "홍은동",
      1141011900: "북가좌동",
      1141012000: "남가좌동",
    },
    1144000000: {
      1144000000: "전체",
      1144010100: "아현동",
      1144010200: "공덕동",
      1144010300: "신공덕동",
      1144010400: "도화동",
      1144010500: "용강동",
      1144010600: "토정동",
      1144010700: "마포동",
      1144010800: "대흥동",
      1144010900: "염리동",
      1144011000: "노고산동",
      1144011100: "신수동",
      1144011200: "현석동",
      1144011300: "구수동",
      1144011400: "창전동",
      1144011500: "상수동",
      1144011600: "하중동",
      1144011700: "신정동",
      1144011800: "당인동",
      1144012000: "서교동",
      1144012100: "동교동",
      1144012200: "합정동",
      1144012300: "망원동",
      1144012400: "연남동",
      1144012500: "성산동",
      1144012600: "중동",
      1144012700: "상암동",
    },
    1147000000: {
      1147000000: "전체",
      1147010100: "신정동",
      1147010200: "목동",
      1147010300: "신월동",
    },
    1150000000: {
      1150000000: "전체",
      1150010100: "염창동",
      1150010200: "등촌동",
      1150010300: "화곡동",
      1150010400: "가양동",
      1150010500: "마곡동",
      1150010600: "내발산동",
      1150010700: "외발산동",
      1150010800: "공항동",
      1150010900: "방화동",
      1150011000: "개화동",
      1150011100: "과해동",
      1150011200: "오곡동",
      1150011300: "오쇠동",
    },
    1153000000: {
      1153000000: "전체",
      1153010100: "신도림동",
      1153010200: "구로동",
      1153010300: "가리봉동",
      1153010600: "고척동",
      1153010700: "개봉동",
      1153010800: "오류동",
      1153010900: "궁동",
      1153011000: "온수동",
      1153011100: "천왕동",
      1153011200: "항동",
    },
    1154500000: {
      1154500000: "전체",
      1154510100: "가산동",
      1154510200: "독산동",
      1154510300: "시흥동",
    },
    1156000000: {
      1156000000: "전체",
      1156010100: "영등포동",
      1156010200: "영등포동1가",
      1156010300: "영등포동2가",
      1156010400: "영등포동3가",
      1156010500: "영등포동4가",
      1156010600: "영등포동5가",
      1156010700: "영등포동6가",
      1156010800: "영등포동7가",
      1156010900: "영등포동8가",
      1156011000: "여의도동",
      1156011100: "당산동1가",
      1156011200: "당산동2가",
      1156011300: "당산동3가",
      1156011400: "당산동4가",
      1156011500: "당산동5가",
      1156011600: "당산동6가",
      1156011700: "당산동",
      1156011800: "도림동",
      1156011900: "문래동1가",
      1156012000: "문래동2가",
      1156012100: "문래동3가",
      1156012200: "문래동4가",
      1156012300: "문래동5가",
      1156012400: "문래동6가",
      1156012500: "양평동1가",
      1156012600: "양평동2가",
      1156012700: "양평동3가",
      1156012800: "양평동4가",
      1156012900: "양평동5가",
      1156013000: "양평동6가",
      1156013100: "양화동",
      1156013200: "신길동",
      1156013300: "대림동",
      1156013400: "양평동",
    },
    1159000000: {
      1159000000: "전체",
      1159010100: "노량진동",
      1159010200: "상도동",
      1159010300: "상도1동",
      1159010400: "본동",
      1159010500: "흑석동",
      1159010600: "동작동",
      1159010700: "사당동",
      1159010800: "대방동",
      1159010900: "신대방동",
    },
    1162000000: {
      1162000000: "전체",
      1162010100: "봉천동",
      1162010200: "신림동",
      1162010300: "남현동",
    },
    1165000000: {
      1165000000: "전체",
      1165010100: "방배동",
      1165010200: "양재동",
      1165010300: "우면동",
      1165010400: "원지동",
      1165010600: "잠원동",
      1165010700: "반포동",
      1165010800: "서초동",
      1165010900: "내곡동",
      1165011000: "염곡동",
      1165011100: "신원동",
    },
    1168000000: {
      1168000000: "전체",
      1168010100: "역삼동",
      1168010300: "개포동",
      1168010400: "청담동",
      1168010500: "삼성동",
      1168010600: "대치동",
      1168010700: "신사동",
      1168010800: "논현동",
      1168011000: "압구정동",
      1168011100: "세곡동",
      1168011200: "자곡동",
      1168011300: "율현동",
      1168011400: "일원동",
      1168011500: "수서동",
      1168011800: "도곡동",
    },
    1171000000: {
      1171000000: "전체",
      1171010100: "잠실동",
      1171010200: "신천동",
      1171010300: "풍납동",
      1171010400: "송파동",
      1171010500: "석촌동",
      1171010600: "삼전동",
      1171010700: "가락동",
      1171010800: "문정동",
      1171010900: "장지동",
      1171011100: "방이동",
      1171011200: "오금동",
      1171011300: "거여동",
      1171011400: "마천동",
    },
    1174000000: {
      1174000000: "전체",
      1174010100: "명일동",
      1174010200: "고덕동",
      1174010300: "상일동",
      1174010500: "길동",
      1174010600: "둔촌동",
      1174010700: "암사동",
      1174010800: "성내동",
      1174010900: "천호동",
      1174011000: "강일동",
    },
  },
  2600000000: {
    2611000000: {
      2611000000: "전체",
      2611010100: "영주동",
      2611010200: "대창동1가",
      2611010300: "대창동2가",
      2611010400: "중앙동1가",
      2611010500: "중앙동2가",
      2611010600: "중앙동3가",
      2611010700: "중앙동4가",
      2611010800: "중앙동5가",
      2611010900: "중앙동6가",
      2611011000: "중앙동7가",
      2611011100: "동광동1가",
      2611011200: "동광동2가",
      2611011300: "동광동3가",
      2611011400: "동광동4가",
      2611011500: "동광동5가",
      2611011600: "대청동1가",
      2611011700: "대청동2가",
      2611011800: "대청동3가",
      2611011900: "대청동4가",
      2611012000: "보수동1가",
      2611012100: "보수동2가",
      2611012200: "보수동3가",
      2611012300: "부평동1가",
      2611012400: "부평동2가",
      2611012500: "부평동3가",
      2611012600: "부평동4가",
      2611012700: "신창동1가",
      2611012800: "신창동2가",
      2611012900: "신창동3가",
      2611013000: "신창동4가",
      2611013100: "창선동1가",
      2611013200: "창선동2가",
      2611013300: "광복동1가",
      2611013400: "광복동2가",
      2611013500: "광복동3가",
      2611013600: "남포동1가",
      2611013700: "남포동2가",
      2611013800: "남포동3가",
      2611013900: "남포동4가",
      2611014000: "남포동5가",
      2611014100: "남포동6가",
    },
    2614000000: {
      2614000000: "전체",
      2614010100: "동대신동1가",
      2614010200: "동대신동2가",
      2614010300: "동대신동3가",
      2614010400: "서대신동1가",
      2614010500: "서대신동2가",
      2614010600: "서대신동3가",
      2614010700: "부용동1가",
      2614010800: "부용동2가",
      2614010900: "부민동1가",
      2614011000: "부민동2가",
      2614011100: "부민동3가",
      2614011200: "토성동1가",
      2614011300: "토성동2가",
      2614011400: "토성동3가",
      2614011500: "아미동1가",
      2614011600: "아미동2가",
      2614011700: "토성동4가",
      2614011800: "토성동5가",
      2614011900: "초장동",
      2614012000: "충무동1가",
      2614012100: "충무동2가",
      2614012200: "충무동3가",
      2614012300: "남부민동",
      2614012400: "암남동",
    },
    2617000000: {
      2617000000: "전체",
      2617010100: "초량동",
      2617010200: "수정동",
      2617010300: "좌천동",
      2617010400: "범일동",
    },
    2620000000: {
      2620000000: "전체",
      2620010100: "대교동1가",
      2620010200: "대교동2가",
      2620010300: "대평동1가",
      2620010400: "대평동2가",
      2620010500: "남항동1가",
      2620010600: "남항동2가",
      2620010700: "남항동3가",
      2620010800: "영선동1가",
      2620010900: "영선동2가",
      2620011000: "영선동3가",
      2620011100: "영선동4가",
      2620011200: "신선동1가",
      2620011300: "신선동2가",
      2620011400: "신선동3가",
      2620011500: "봉래동1가",
      2620011600: "봉래동2가",
      2620011700: "봉래동3가",
      2620011800: "봉래동4가",
      2620011900: "봉래동5가",
      2620012000: "청학동",
      2620012100: "동삼동",
    },
    2623000000: {
      2623000000: "전체",
      2623010100: "양정동",
      2623010200: "전포동",
      2623010300: "부전동",
      2623010400: "범천동",
      2623010500: "범전동",
      2623010600: "연지동",
      2623010700: "초읍동",
      2623010800: "부암동",
      2623010900: "당감동",
      2623011000: "가야동",
      2623011100: "개금동",
    },
    2626000000: {
      2626000000: "전체",
      2626010100: "명장동",
      2626010200: "안락동",
      2626010300: "칠산동",
      2626010400: "낙민동",
      2626010500: "복천동",
      2626010600: "수안동",
      2626010700: "명륜동",
      2626010800: "온천동",
      2626010900: "사직동",
    },
    2629000000: {
      2629000000: "전체",
      2629010600: "대연동",
      2629010700: "용호동",
      2629010800: "용당동",
      2629010900: "문현동",
      2629011000: "우암동",
      2629011100: "감만동",
    },
    2632000000: {
      2632000000: "전체",
      2632010100: "금곡동",
      2632010200: "화명동",
      2632010300: "만덕동",
      2632010400: "덕천동",
      2632010500: "구포동",
    },
    2635000000: {
      2635000000: "전체",
      2635010100: "반송동",
      2635010200: "석대동",
      2635010300: "반여동",
      2635010400: "재송동",
      2635010500: "우동",
      2635010600: "중동",
      2635010700: "좌동",
      2635010800: "송정동",
    },
    2638000000: {
      2638000000: "전체",
      2638010100: "괴정동",
      2638010200: "당리동",
      2638010300: "하단동",
      2638010400: "신평동",
      2638010500: "장림동",
      2638010600: "다대동",
      2638010700: "구평동",
      2638010800: "감천동",
    },
    2641000000: {
      2641000000: "전체",
      2641010100: "두구동",
      2641010200: "노포동",
      2641010300: "청룡동",
      2641010400: "남산동",
      2641010500: "선동",
      2641010600: "오륜동",
      2641010700: "구서동",
      2641010800: "장전동",
      2641010900: "부곡동",
      2641011000: "서동",
      2641011100: "금사동",
      2641011200: "회동동",
      2641011300: "금성동",
    },
    2644000000: {
      2644000000: "전체",
      2644010100: "대저1동",
      2644010200: "대저2동",
      2644010300: "강동동",
      2644010400: "명지동",
      2644010500: "죽림동",
      2644010600: "식만동",
      2644010700: "죽동동",
      2644010800: "봉림동",
      2644010900: "송정동",
      2644011000: "화전동",
      2644011100: "녹산동",
      2644011200: "생곡동",
      2644011300: "구랑동",
      2644011400: "지사동",
      2644011500: "미음동",
      2644011600: "범방동",
      2644011700: "신호동",
      2644011800: "동선동",
      2644011900: "성북동",
      2644012000: "눌차동",
      2644012100: "천성동",
      2644012200: "대항동",
    },
    2647000000: {
      2647000000: "전체",
      2647010100: "거제동",
      2647010200: "연산동",
    },
    2650000000: {
      2650000000: "전체",
      2650010100: "망미동",
      2650010200: "수영동",
      2650010300: "민락동",
      2650010400: "광안동",
      2650010500: "남천동",
    },
    2653000000: {
      2653000000: "전체",
      2653010100: "삼락동",
      2653010200: "모라동",
      2653010300: "덕포동",
      2653010400: "괘법동",
      2653010500: "감전동",
      2653010600: "주례동",
      2653010700: "학장동",
      2653010800: "엄궁동",
    },
    2671000000: {
      2671000000: "전체",
      2671025000: "기장읍",
      2671025300: "장안읍",
      2671025600: "정관읍",
      2671025900: "일광읍",
      2671033000: "철마면",
    },
  },
  2700000000: {
    2711000000: {
      2711000000: "전체",
      2711010100: "동인동1가",
      2711010200: "동인동2가",
      2711010300: "동인동3가",
      2711010400: "동인동4가",
      2711010500: "삼덕동1가",
      2711010600: "삼덕동2가",
      2711010700: "삼덕동3가",
      2711010800: "봉산동",
      2711010900: "장관동",
      2711011000: "상서동",
      2711011100: "수동",
      2711011200: "덕산동",
      2711011300: "종로1가",
      2711011400: "종로2가",
      2711011500: "사일동",
      2711011600: "동일동",
      2711011700: "남일동",
      2711011800: "전동",
      2711011900: "동성로3가",
      2711012000: "동문동",
      2711012100: "문화동",
      2711012200: "공평동",
      2711012300: "동성로2가",
      2711012400: "태평로1가",
      2711012500: "교동",
      2711012600: "용덕동",
      2711012700: "상덕동",
      2711012800: "완전동",
      2711012900: "도원동",
      2711013000: "수창동",
      2711013100: "태평로3가",
      2711013200: "인교동",
      2711013300: "서야동",
      2711013400: "서성로1가",
      2711013500: "시장북로",
      2711013600: "하서동",
      2711013700: "남성로",
      2711013800: "계산동1가",
      2711013900: "계산동2가",
      2711014000: "동산동",
      2711014100: "서문로2가",
      2711014200: "서성로2가",
      2711014300: "포정동",
      2711014400: "서문로1가",
      2711014500: "서내동",
      2711014600: "북성로2가",
      2711014700: "대안동",
      2711014800: "동성로1가",
      2711014900: "태평로2가",
      2711015000: "북성로1가",
      2711015100: "화전동",
      2711015200: "향촌동",
      2711015300: "북내동",
      2711015400: "대신동",
      2711015500: "달성동",
      2711015600: "남산동",
      2711015700: "대봉동",
    },
    2714000000: {
      2714000000: "전체",
      2714010100: "신암동",
      2714010200: "신천동",
      2714010300: "효목동",
      2714010400: "평광동",
      2714010500: "봉무동",
      2714010600: "불로동",
      2714010700: "도동",
      2714010800: "지저동",
      2714010900: "입석동",
      2714011000: "검사동",
      2714011100: "방촌동",
      2714011200: "둔산동",
      2714011300: "부동",
      2714011400: "신평동",
      2714011500: "서호동",
      2714011600: "동호동",
      2714011700: "신기동",
      2714011800: "율하동",
      2714011900: "용계동",
      2714012000: "율암동",
      2714012100: "상매동",
      2714012200: "매여동",
      2714012300: "각산동",
      2714012400: "신서동",
      2714012500: "동내동",
      2714012600: "괴전동",
      2714012700: "금강동",
      2714012800: "대림동",
      2714012900: "사복동",
      2714013000: "숙천동",
      2714013100: "내곡동",
      2714013200: "능성동",
      2714013300: "진인동",
      2714013400: "도학동",
      2714013500: "백안동",
      2714013600: "미곡동",
      2714013700: "용수동",
      2714013800: "신무동",
      2714013900: "미대동",
      2714014000: "내동",
      2714014100: "신용동",
      2714014200: "중대동",
      2714014300: "송정동",
      2714014400: "덕곡동",
      2714014500: "지묘동",
    },
    2717000000: {
      2717000000: "전체",
      2717010100: "내당동",
      2717010200: "비산동",
      2717010300: "평리동",
      2717010400: "상리동",
      2717010500: "중리동",
      2717010600: "이현동",
      2717010700: "원대동1가",
      2717010800: "원대동2가",
      2717010900: "원대동3가",
    },
    2720000000: {
      2720000000: "전체",
      2720010100: "이천동",
      2720010200: "봉덕동",
      2720010300: "대명동",
    },
    2723000000: {
      2723000000: "전체",
      2723010100: "칠성동1가",
      2723010200: "칠성동2가",
      2723010300: "고성동1가",
      2723010400: "고성동2가",
      2723010500: "고성동3가",
      2723010600: "침산동",
      2723010700: "노원동1가",
      2723010800: "노원동2가",
      2723010900: "노원동3가",
      2723011000: "대현동",
      2723011100: "산격동",
      2723011200: "복현동",
      2723011300: "검단동",
      2723011400: "동변동",
      2723011500: "서변동",
      2723011600: "조야동",
      2723011700: "노곡동",
      2723011800: "읍내동",
      2723011900: "동호동",
      2723012000: "학정동",
      2723012100: "도남동",
      2723012200: "국우동",
      2723012300: "구암동",
      2723012400: "동천동",
      2723012500: "관음동",
      2723012600: "태전동",
      2723012700: "매천동",
      2723012800: "팔달동",
      2723012900: "금호동",
      2723013000: "사수동",
      2723013100: "연경동",
    },
    2726000000: {
      2726000000: "전체",
      2726010100: "범어동",
      2726010200: "만촌동",
      2726010300: "수성동1가",
      2726010400: "수성동2가",
      2726010500: "수성동3가",
      2726010600: "수성동4가",
      2726010700: "황금동",
      2726010800: "중동",
      2726010900: "상동",
      2726011000: "파동",
      2726011100: "두산동",
      2726011200: "지산동",
      2726011300: "범물동",
      2726011400: "시지동",
      2726011500: "매호동",
      2726011600: "성동",
      2726011700: "사월동",
      2726011800: "신매동",
      2726011900: "욱수동",
      2726012000: "노변동",
      2726012200: "삼덕동",
      2726012300: "연호동",
      2726012400: "이천동",
      2726012500: "고모동",
      2726012600: "가천동",
      2726012700: "대흥동",
    },
    2729000000: {
      2729000000: "전체",
      2729010100: "성당동",
      2729010200: "두류동",
      2729010400: "파호동",
      2729010500: "호림동",
      2729010600: "갈산동",
      2729010700: "신당동",
      2729010800: "이곡동",
      2729010900: "장동",
      2729011000: "장기동",
      2729011100: "용산동",
      2729011200: "죽전동",
      2729011300: "감삼동",
      2729011400: "본리동",
      2729011500: "상인동",
      2729011600: "도원동",
      2729011700: "진천동",
      2729011800: "유천동",
      2729011900: "대천동",
      2729012000: "월성동",
      2729012100: "월암동",
      2729012200: "송현동",
      2729012300: "대곡동",
      2729012400: "본동",
      2729012500: "호산동",
    },
    2771000000: {
      2771000000: "전체",
      2771025000: "화원읍",
      2771025300: "논공읍",
      2771025600: "다사읍",
      2771025900: "유가읍",
      2771026200: "옥포읍",
      2771026500: "현풍읍",
      2771031000: "가창면",
      2771033000: "하빈면",
      2771038000: "구지면",
    },
  },
  2800000000: {
    2811000000: {
      2811000000: "전체",
      2811010100: "중앙동1가",
      2811010200: "중앙동2가",
      2811010300: "중앙동3가",
      2811010400: "중앙동4가",
      2811010500: "해안동1가",
      2811010600: "해안동2가",
      2811010700: "해안동3가",
      2811010800: "해안동4가",
      2811010900: "관동1가",
      2811011000: "관동2가",
      2811011100: "관동3가",
      2811011200: "항동1가",
      2811011300: "항동2가",
      2811011400: "항동3가",
      2811011500: "항동4가",
      2811011600: "항동5가",
      2811011700: "항동6가",
      2811011800: "항동7가",
      2811011900: "송학동1가",
      2811012000: "송학동2가",
      2811012100: "송학동3가",
      2811012200: "사동",
      2811012300: "신생동",
      2811012400: "신포동",
      2811012500: "답동",
      2811012600: "신흥동1가",
      2811012700: "신흥동2가",
      2811012800: "신흥동3가",
      2811012900: "선화동",
      2811013000: "유동",
      2811013100: "율목동",
      2811013200: "도원동",
      2811013300: "내동",
      2811013400: "경동",
      2811013500: "용동",
      2811013600: "인현동",
      2811013700: "전동",
      2811013800: "북성동1가",
      2811013900: "북성동2가",
      2811014000: "북성동3가",
      2811014100: "선린동",
      2811014200: "송월동1가",
      2811014300: "송월동2가",
      2811014400: "송월동3가",
      2811014500: "중산동",
      2811014600: "운남동",
      2811014700: "운서동",
      2811014800: "운북동",
      2811014900: "을왕동",
      2811015000: "남북동",
      2811015100: "덕교동",
      2811015200: "무의동",
    },
    2814000000: {
      2814000000: "전체",
      2814010100: "만석동",
      2814010200: "화수동",
      2814010300: "송현동",
      2814010400: "화평동",
      2814010500: "창영동",
      2814010600: "금곡동",
      2814010700: "송림동",
    },
    2817700000: {
      2817700000: "전체",
      2817710100: "숭의동",
      2817710200: "용현동",
      2817710300: "학익동",
      2817710400: "도화동",
      2817710500: "주안동",
      2817710600: "관교동",
      2817710700: "문학동",
    },
    2818500000: {
      2818500000: "전체",
      2818510100: "옥련동",
      2818510200: "선학동",
      2818510300: "연수동",
      2818510400: "청학동",
      2818510500: "동춘동",
      2818510600: "송도동",
    },
    2820000000: {
      2820000000: "전체",
      2820010100: "구월동",
      2820010200: "간석동",
      2820010300: "만수동",
      2820010400: "장수동",
      2820010500: "서창동",
      2820010600: "운연동",
      2820010700: "남촌동",
      2820010800: "수산동",
      2820010900: "도림동",
      2820011000: "논현동",
      2820011100: "고잔동",
    },
    2823700000: {
      2823700000: "전체",
      2823710100: "부평동",
      2823710200: "십정동",
      2823710300: "산곡동",
      2823710400: "청천동",
      2823710500: "삼산동",
      2823710600: "갈산동",
      2823710700: "부개동",
      2823710800: "일신동",
      2823710900: "구산동",
    },
    2824500000: {
      2824500000: "전체",
      2824510100: "효성동",
      2824510200: "계산동",
      2824510300: "작전동",
      2824510400: "서운동",
      2824510500: "임학동",
      2824510600: "용종동",
      2824510700: "병방동",
      2824510800: "방축동",
      2824510900: "박촌동",
      2824511000: "동양동",
      2824511100: "귤현동",
      2824511200: "상야동",
      2824511300: "하야동",
      2824511400: "평동",
      2824511500: "노오지동",
      2824511600: "선주지동",
      2824511700: "이화동",
      2824511800: "오류동",
      2824511900: "갈현동",
      2824512000: "둑실동",
      2824512100: "목상동",
      2824512200: "다남동",
      2824512300: "장기동",
    },
    2826000000: {
      2826000000: "전체",
      2826010100: "백석동",
      2826010200: "시천동",
      2826010300: "검암동",
      2826010400: "경서동",
      2826010500: "공촌동",
      2826010600: "연희동",
      2826010700: "심곡동",
      2826010800: "가정동",
      2826010900: "신현동",
      2826011000: "석남동",
      2826011100: "원창동",
      2826011200: "가좌동",
      2826011300: "마전동",
      2826011400: "당하동",
      2826011500: "원당동",
      2826011700: "대곡동",
      2826011800: "금곡동",
      2826011900: "오류동",
      2826012000: "왕길동",
      2826012100: "불로동",
      2826012200: "청라동",
    },
    2871000000: {
      2871000000: "전체",
      2871025000: "강화읍",
      2871031000: "선원면",
      2871032000: "불은면",
      2871033000: "길상면",
      2871034000: "화도면",
      2871035000: "양도면",
      2871036000: "내가면",
      2871037000: "하점면",
      2871038000: "양사면",
      2871039000: "송해면",
      2871040000: "교동면",
      2871041000: "삼산면",
      2871042000: "서도면",
    },
    2872000000: {
      2872000000: "전체",
      2872031000: "북도면",
      2872033000: "백령면",
      2872034000: "대청면",
      2872035000: "덕적면",
      2872036000: "영흥면",
      2872037000: "자월면",
      2872038000: "연평면",
    },
  },
  2900000000: {
    2911000000: {
      2911000000: "전체",
      2911010100: "대인동",
      2911010200: "금남로5가",
      2911010300: "충장로5가",
      2911010400: "수기동",
      2911010500: "대의동",
      2911010600: "궁동",
      2911010700: "장동",
      2911010800: "동명동",
      2911010900: "계림동",
      2911011000: "산수동",
      2911011100: "지산동",
      2911011200: "남동",
      2911011300: "광산동",
      2911011400: "금동",
      2911011500: "호남동",
      2911011600: "불로동",
      2911011700: "황금동",
      2911011800: "서석동",
      2911011900: "소태동",
      2911012000: "용연동",
      2911012100: "운림동",
      2911012200: "학동",
      2911012300: "월남동",
      2911012400: "선교동",
      2911012500: "내남동",
      2911012600: "용산동",
      2911012700: "충장로1가",
      2911012800: "충장로2가",
      2911012900: "충장로3가",
      2911013000: "충장로4가",
      2911013100: "금남로1가",
      2911013200: "금남로2가",
      2911013300: "금남로3가",
      2911013400: "금남로4가",
    },
    2914000000: {
      2914000000: "전체",
      2914010400: "양동",
      2914010600: "농성동",
      2914011500: "광천동",
      2914011600: "유촌동",
      2914011700: "덕흥동",
      2914011800: "쌍촌동",
      2914011900: "화정동",
      2914012000: "치평동",
      2914012100: "내방동",
      2914012500: "서창동",
      2914012600: "세하동",
      2914012700: "용두동",
      2914012800: "풍암동",
      2914012900: "벽진동",
      2914013000: "금호동",
      2914013100: "마륵동",
      2914013200: "매월동",
      2914013300: "동천동",
    },
    2915500000: {
      2915500000: "전체",
      2915510100: "사동",
      2915510200: "구동",
      2915510300: "서동",
      2915510400: "월산동",
      2915510500: "백운동",
      2915510600: "주월동",
      2915510700: "노대동",
      2915510800: "진월동",
      2915510900: "덕남동",
      2915511000: "행암동",
      2915511100: "임암동",
      2915511200: "송하동",
      2915511300: "양림동",
      2915511400: "방림동",
      2915511500: "봉선동",
      2915511600: "구소동",
      2915511700: "양촌동",
      2915511800: "도금동",
      2915511900: "승촌동",
      2915512000: "지석동",
      2915512100: "압촌동",
      2915512200: "화장동",
      2915512300: "칠석동",
      2915512400: "석정동",
      2915512500: "신장동",
      2915512600: "양과동",
      2915512700: "이장동",
      2915512800: "대지동",
      2915512900: "원산동",
      2915513000: "월성동",
    },
    2917000000: {
      2917000000: "전체",
      2917010100: "중흥동",
      2917010200: "유동",
      2917010300: "누문동",
      2917010400: "북동",
      2917010500: "임동",
      2917010600: "신안동",
      2917010700: "용봉동",
      2917010800: "동림동",
      2917010900: "운암동",
      2917011000: "우산동",
      2917011100: "풍향동",
      2917011200: "문흥동",
      2917011300: "각화동",
      2917011400: "두암동",
      2917011500: "오치동",
      2917011600: "삼각동",
      2917011700: "매곡동",
      2917011800: "충효동",
      2917011900: "덕의동",
      2917012000: "금곡동",
      2917012100: "망월동",
      2917012200: "청풍동",
      2917012300: "화암동",
      2917012400: "장등동",
      2917012500: "운정동",
      2917012600: "본촌동",
      2917012700: "일곡동",
      2917012800: "양산동",
      2917012900: "연제동",
      2917013000: "신용동",
      2917013100: "용두동",
      2917013200: "지야동",
      2917013300: "태령동",
      2917013400: "수곡동",
      2917013500: "효령동",
      2917013600: "용전동",
      2917013700: "용강동",
      2917013800: "생용동",
      2917013900: "월출동",
      2917014000: "대촌동",
      2917014100: "오룡동",
    },
    2920000000: {
      2920000000: "전체",
      2920010100: "송정동",
      2920010200: "도산동",
      2920010300: "도호동",
      2920010400: "신촌동",
      2920010500: "서봉동",
      2920010600: "운수동",
      2920010700: "선암동",
      2920010800: "소촌동",
      2920010900: "우산동",
      2920011000: "황룡동",
      2920011100: "박호동",
      2920011200: "비아동",
      2920011300: "도천동",
      2920011400: "수완동",
      2920011500: "월계동",
      2920011600: "쌍암동",
      2920011700: "산월동",
      2920011800: "신창동",
      2920011900: "신가동",
      2920012000: "운남동",
      2920012100: "안청동",
      2920012200: "진곡동",
      2920012300: "장덕동",
      2920012400: "흑석동",
      2920012500: "하남동",
      2920012600: "장수동",
      2920012700: "산정동",
      2920012800: "월곡동",
      2920012900: "등임동",
      2920013000: "산막동",
      2920013100: "고룡동",
      2920013200: "신룡동",
      2920013300: "두정동",
      2920013400: "임곡동",
      2920013500: "광산동",
      2920013600: "오산동",
      2920013700: "사호동",
      2920013800: "하산동",
      2920013900: "유계동",
      2920014000: "본덕동",
      2920014100: "용봉동",
      2920014200: "요기동",
      2920014300: "복룡동",
      2920014400: "송대동",
      2920014500: "옥동",
      2920014600: "월전동",
      2920014700: "장록동",
      2920014800: "송촌동",
      2920014900: "지죽동",
      2920015000: "용동",
      2920015100: "용곡동",
      2920015200: "지정동",
      2920015300: "명화동",
      2920015400: "동산동",
      2920015500: "연산동",
      2920015600: "도덕동",
      2920015700: "송산동",
      2920015800: "지평동",
      2920015900: "오운동",
      2920016000: "삼거동",
      2920016100: "양동",
      2920016200: "내산동",
      2920016300: "대산동",
      2920016400: "송학동",
      2920016500: "신동",
      2920016600: "삼도동",
      2920016700: "남산동",
      2920016800: "송치동",
      2920016900: "산수동",
      2920017000: "선동",
      2920017100: "지산동",
      2920017200: "왕동",
      2920017300: "북산동",
      2920017400: "명도동",
      2920017500: "동호동",
      2920017600: "덕림동",
      2920017700: "양산동",
      2920017800: "동림동",
      2920020200: "오선동",
    },
  },
  3000000000: {
    3011000000: {
      3011000000: "전체",
      3011010100: "원동",
      3011010200: "인동",
      3011010300: "효동",
      3011010400: "천동",
      3011010500: "가오동",
      3011010600: "신흥동",
      3011010700: "판암동",
      3011010800: "삼정동",
      3011010900: "용운동",
      3011011000: "대동",
      3011011100: "자양동",
      3011011200: "신안동",
      3011011300: "소제동",
      3011011400: "가양동",
      3011011500: "용전동",
      3011011600: "성남동",
      3011011700: "홍도동",
      3011011800: "삼성동",
      3011011900: "정동",
      3011012000: "중동",
      3011012100: "추동",
      3011012200: "비룡동",
      3011012300: "주산동",
      3011012400: "용계동",
      3011012500: "마산동",
      3011012600: "효평동",
      3011012700: "직동",
      3011012800: "세천동",
      3011012900: "신상동",
      3011013000: "신하동",
      3011013100: "신촌동",
      3011013200: "사성동",
      3011013300: "내탑동",
      3011013400: "오동",
      3011013500: "주촌동",
      3011013600: "낭월동",
      3011013700: "대별동",
      3011013800: "이사동",
      3011013900: "대성동",
      3011014000: "장척동",
      3011014100: "소호동",
      3011014200: "구도동",
      3011014300: "삼괴동",
      3011014400: "상소동",
      3011014500: "하소동",
    },
    3014000000: {
      3014000000: "전체",
      3014010100: "은행동",
      3014010200: "선화동",
      3014010300: "목동",
      3014010400: "중촌동",
      3014010500: "대흥동",
      3014010600: "문창동",
      3014010700: "석교동",
      3014010800: "호동",
      3014010900: "옥계동",
      3014011000: "대사동",
      3014011100: "부사동",
      3014011200: "용두동",
      3014011300: "오류동",
      3014011400: "태평동",
      3014011500: "유천동",
      3014011600: "문화동",
      3014011700: "산성동",
      3014011800: "사정동",
      3014011900: "안영동",
      3014012000: "무수동",
      3014012100: "구완동",
      3014012200: "침산동",
      3014012300: "목달동",
      3014012400: "정생동",
      3014012500: "어남동",
      3014012600: "금동",
    },
    3017000000: {
      3017000000: "전체",
      3017010100: "복수동",
      3017010200: "변동",
      3017010300: "도마동",
      3017010400: "정림동",
      3017010500: "용문동",
      3017010600: "탄방동",
      3017010800: "괴정동",
      3017010900: "가장동",
      3017011000: "내동",
      3017011100: "갈마동",
      3017011200: "둔산동",
      3017011300: "월평동",
      3017011400: "가수원동",
      3017011500: "도안동",
      3017011600: "관저동",
      3017011700: "흑석동",
      3017011800: "매노동",
      3017011900: "산직동",
      3017012000: "장안동",
      3017012100: "평촌동",
      3017012200: "오동",
      3017012300: "우명동",
      3017012400: "원정동",
      3017012500: "용촌동",
      3017012600: "봉곡동",
      3017012700: "괴곡동",
      3017012800: "만년동",
    },
    3020000000: {
      3020000000: "전체",
      3020010100: "원내동",
      3020010200: "교촌동",
      3020010300: "대정동",
      3020010400: "용계동",
      3020010500: "학하동",
      3020010600: "계산동",
      3020010700: "성북동",
      3020010800: "세동",
      3020010900: "송정동",
      3020011000: "방동",
      3020011100: "봉명동",
      3020011200: "구암동",
      3020011300: "덕명동",
      3020011400: "원신흥동",
      3020011500: "상대동",
      3020011600: "복용동",
      3020011700: "장대동",
      3020011800: "갑동",
      3020011900: "노은동",
      3020012000: "지족동",
      3020012100: "죽동",
      3020012200: "궁동",
      3020012300: "어은동",
      3020012400: "구성동",
      3020012500: "신성동",
      3020012600: "가정동",
      3020012700: "도룡동",
      3020012800: "장동",
      3020012900: "방현동",
      3020013000: "화암동",
      3020013100: "덕진동",
      3020013200: "하기동",
      3020013300: "추목동",
      3020013400: "자운동",
      3020013500: "신봉동",
      3020013600: "수남동",
      3020013700: "안산동",
      3020013800: "외삼동",
      3020013900: "반석동",
      3020014000: "문지동",
      3020014100: "전민동",
      3020014200: "원촌동",
      3020014300: "탑립동",
      3020014400: "용산동",
      3020014500: "봉산동",
      3020014600: "관평동",
      3020014700: "송강동",
      3020014800: "금고동",
      3020014900: "대동",
      3020015000: "금탄동",
      3020015100: "신동",
      3020015200: "둔곡동",
      3020015300: "구룡동",
    },
    3023000000: {
      3023000000: "전체",
      3023010100: "오정동",
      3023010200: "대화동",
      3023010300: "읍내동",
      3023010400: "연축동",
      3023010500: "신대동",
      3023010600: "와동",
      3023010700: "송촌동",
      3023010800: "법동",
      3023010900: "중리동",
      3023011000: "비래동",
      3023011100: "석봉동",
      3023011200: "목상동",
      3023011300: "문평동",
      3023011400: "신일동",
      3023011500: "덕암동",
      3023011600: "상서동",
      3023011700: "평촌동",
      3023011800: "장동",
      3023011900: "용호동",
      3023012000: "이현동",
      3023012100: "갈전동",
      3023012200: "부수동",
      3023012300: "황호동",
      3023012400: "삼정동",
      3023012500: "미호동",
      3023012600: "신탄진동",
    },
  },
  3100000000: {
    3111000000: {
      3111000000: "전체",
      3111010100: "학성동",
      3111010200: "학산동",
      3111010300: "복산동",
      3111010400: "북정동",
      3111010500: "옥교동",
      3111010600: "성남동",
      3111010700: "교동",
      3111010800: "우정동",
      3111010900: "성안동",
      3111011000: "유곡동",
      3111011100: "태화동",
      3111011200: "다운동",
      3111011300: "동동",
      3111011400: "서동",
      3111011500: "남외동",
      3111011600: "장현동",
      3111011700: "약사동",
      3111011800: "반구동",
    },
    3114000000: {
      3114000000: "전체",
      3114010100: "무거동",
      3114010200: "옥동",
      3114010300: "두왕동",
      3114010400: "신정동",
      3114010500: "달동",
      3114010600: "삼산동",
      3114010700: "여천동",
      3114010800: "야음동",
      3114010900: "선암동",
      3114011000: "상개동",
      3114011100: "부곡동",
      3114011200: "고사동",
      3114011300: "성암동",
      3114011400: "황성동",
      3114011500: "용연동",
      3114011600: "남화동",
      3114011700: "용잠동",
      3114011800: "장생포동",
      3114011900: "매암동",
    },
    3117000000: {
      3117000000: "전체",
      3117010100: "방어동",
      3117010200: "화정동",
      3117010300: "일산동",
      3117010400: "전하동",
      3117010500: "미포동",
      3117010600: "주전동",
      3117010700: "동부동",
      3117010800: "서부동",
    },
    3120000000: {
      3120000000: "전체",
      3120010100: "창평동",
      3120010200: "호계동",
      3120010300: "매곡동",
      3120010400: "가대동",
      3120010500: "신천동",
      3120010600: "중산동",
      3120010700: "상안동",
      3120010800: "천곡동",
      3120010900: "달천동",
      3120011000: "시례동",
      3120011100: "무룡동",
      3120011200: "구유동",
      3120011300: "정자동",
      3120011400: "신명동",
      3120011500: "대안동",
      3120011600: "당사동",
      3120011700: "신현동",
      3120011800: "산하동",
      3120011900: "어물동",
      3120012000: "명촌동",
      3120012100: "진장동",
      3120012200: "연암동",
      3120012300: "효문동",
      3120012400: "양정동",
      3120012500: "화봉동",
      3120012600: "송정동",
      3120012700: "염포동",
    },
    3171000000: {
      3171000000: "전체",
      3171025000: "온산읍",
      3171025300: "언양읍",
      3171025600: "온양읍",
      3171025900: "범서읍",
      3171026200: "청량읍",
      3171026500: "삼남읍",
      3171031000: "서생면",
      3171034000: "웅촌면",
      3171036000: "두동면",
      3171037000: "두서면",
      3171038000: "상북면",
      3171040000: "삼동면",
    },
  },
  4100000000: {
    4111000000: {
      4111000000: "전체",
      4111100000: "장안구",
      4111112900: "장안구 파장동",
      4111113000: "장안구 정자동",
      4111113100: "장안구 이목동",
      4111113200: "장안구 율전동",
      4111113300: "장안구 천천동",
      4111113400: "장안구 영화동",
      4111113500: "장안구 송죽동",
      4111113600: "장안구 조원동",
      4111113700: "장안구 연무동",
      4111113800: "장안구 상광교동",
      4111113900: "장안구 하광교동",
      4111300000: "권선구",
      4111312600: "권선구 세류동",
      4111312700: "권선구 평동",
      4111312800: "권선구 고색동",
      4111312900: "권선구 오목천동",
      4111313000: "권선구 평리동",
      4111313100: "권선구 서둔동",
      4111313200: "권선구 구운동",
      4111313300: "권선구 탑동",
      4111313400: "권선구 금곡동",
      4111313500: "권선구 호매실동",
      4111313600: "권선구 곡반정동",
      4111313700: "권선구 권선동",
      4111313800: "권선구 장지동",
      4111313900: "권선구 대황교동",
      4111314000: "권선구 입북동",
      4111314100: "권선구 당수동",
      4111500000: "팔달구",
      4111512000: "팔달구 팔달로1가",
      4111512100: "팔달구 팔달로2가",
      4111512200: "팔달구 팔달로3가",
      4111512300: "팔달구 남창동",
      4111512400: "팔달구 영동",
      4111512500: "팔달구 중동",
      4111512600: "팔달구 구천동",
      4111512700: "팔달구 남수동",
      4111512800: "팔달구 매향동",
      4111512900: "팔달구 북수동",
      4111513000: "팔달구 신풍동",
      4111513100: "팔달구 장안동",
      4111513200: "팔달구 교동",
      4111513300: "팔달구 매교동",
      4111513400: "팔달구 매산로1가",
      4111513500: "팔달구 매산로2가",
      4111513600: "팔달구 매산로3가",
      4111513700: "팔달구 고등동",
      4111513800: "팔달구 화서동",
      4111513900: "팔달구 지동",
      4111514000: "팔달구 우만동",
      4111514100: "팔달구 인계동",
      4111700000: "영통구",
      4111710100: "영통구 매탄동",
      4111710200: "영통구 원천동",
      4111710300: "영통구 이의동",
      4111710400: "영통구 하동",
      4111710500: "영통구 영통동",
      4111710600: "영통구 신동",
      4111710700: "영통구 망포동",
    },
    4113000000: {
      4113000000: "전체",
      4113100000: "수정구",
      4113110100: "수정구 신흥동",
      4113110200: "수정구 태평동",
      4113110300: "수정구 수진동",
      4113110400: "수정구 단대동",
      4113110500: "수정구 산성동",
      4113110600: "수정구 양지동",
      4113110700: "수정구 복정동",
      4113110800: "수정구 창곡동",
      4113110900: "수정구 신촌동",
      4113111000: "수정구 오야동",
      4113111100: "수정구 심곡동",
      4113111200: "수정구 고등동",
      4113111300: "수정구 상적동",
      4113111400: "수정구 둔전동",
      4113111500: "수정구 시흥동",
      4113111600: "수정구 금토동",
      4113111700: "수정구 사송동",
      4113300000: "중원구",
      4113310100: "중원구 성남동",
      4113310300: "중원구 금광동",
      4113310400: "중원구 은행동",
      4113310500: "중원구 상대원동",
      4113310600: "중원구 여수동",
      4113310700: "중원구 도촌동",
      4113310800: "중원구 갈현동",
      4113310900: "중원구 하대원동",
      4113313200: "중원구 중앙동",
      4113500000: "분당구",
      4113510100: "분당구 분당동",
      4113510200: "분당구 수내동",
      4113510300: "분당구 정자동",
      4113510400: "분당구 율동",
      4113510500: "분당구 서현동",
      4113510600: "분당구 이매동",
      4113510700: "분당구 야탑동",
      4113510800: "분당구 판교동",
      4113510900: "분당구 삼평동",
      4113511000: "분당구 백현동",
      4113511100: "분당구 금곡동",
      4113511200: "분당구 궁내동",
      4113511300: "분당구 동원동",
      4113511400: "분당구 구미동",
      4113511500: "분당구 운중동",
      4113511600: "분당구 대장동",
      4113511700: "분당구 석운동",
      4113511800: "분당구 하산운동",
    },
    4115000000: {
      4115000000: "전체",
      4115010100: "의정부동",
      4115010200: "호원동",
      4115010300: "장암동",
      4115010400: "신곡동",
      4115010500: "용현동",
      4115010600: "민락동",
      4115010700: "낙양동",
      4115010800: "자일동",
      4115010900: "금오동",
      4115011000: "가능동",
      4115011100: "녹양동",
      4115011200: "고산동",
      4115011300: "산곡동",
    },
    4117000000: {
      4117000000: "전체",
      4117100000: "만안구",
      4117110100: "만안구 안양동",
      4117110200: "만안구 석수동",
      4117110300: "만안구 박달동",
      4117300000: "동안구",
      4117310100: "동안구 비산동",
      4117310200: "동안구 관양동",
      4117310300: "동안구 평촌동",
      4117310400: "동안구 호계동",
    },
    4119000000: {
      4119000000: "전체",
      4119010100: "원미동",
      4119010200: "심곡동",
      4119010300: "춘의동",
      4119010400: "도당동",
      4119010500: "약대동",
      4119010600: "소사동",
      4119010700: "역곡동",
      4119010800: "중동",
      4119010900: "상동",
      4119011000: "소사본동",
      4119011100: "심곡본동",
      4119011200: "범박동",
      4119011300: "괴안동",
      4119011400: "송내동",
      4119011500: "옥길동",
      4119011600: "계수동",
      4119011700: "오정동",
      4119011800: "여월동",
      4119011900: "작동",
      4119012000: "원종동",
      4119012100: "고강동",
      4119012200: "대장동",
      4119012300: "삼정동",
      4119012400: "내동",
    },
    4121000000: {
      4121000000: "전체",
      4121010100: "광명동",
      4121010200: "철산동",
      4121010300: "하안동",
      4121010400: "소하동",
      4121010500: "노온사동",
      4121010600: "일직동",
      4121010700: "가학동",
      4121010800: "옥길동",
    },
    4122000000: {
      4122000000: "전체",
      4122010100: "서정동",
      4122010200: "장당동",
      4122010300: "모곡동",
      4122010400: "칠괴동",
      4122010500: "칠원동",
      4122010600: "도일동",
      4122010700: "가재동",
      4122010800: "장안동",
      4122010900: "이충동",
      4122011000: "지산동",
      4122011100: "독곡동",
      4122011200: "신장동",
      4122011300: "평택동",
      4122011400: "통복동",
      4122011500: "군문동",
      4122011600: "유천동",
      4122011700: "합정동",
      4122011800: "비전동",
      4122011900: "동삭동",
      4122012000: "세교동",
      4122012100: "지제동",
      4122012200: "신대동",
      4122012300: "소사동",
      4122012400: "용이동",
      4122012500: "월곡동",
      4122012600: "청룡동",
      4122012700: "죽백동",
      4122012800: "고덕동",
      4122025000: "팽성읍",
      4122025300: "안중읍",
      4122025600: "포승읍",
      4122025900: "청북읍",
      4122031000: "진위면",
      4122032000: "서탄면",
      4122033000: "고덕면",
      4122034000: "오성면",
      4122037000: "현덕면",
    },
    4125000000: {
      4125000000: "전체",
      4125010100: "송내동",
      4125010200: "지행동",
      4125010300: "생연동",
      4125010400: "광암동",
      4125010500: "걸산동",
      4125010600: "보산동",
      4125010700: "동두천동",
      4125010800: "안흥동",
      4125010900: "상봉암동",
      4125011000: "하봉암동",
      4125011100: "탑동동",
      4125011200: "상패동",
    },
    4127000000: {
      4127000000: "전체",
      4127100000: "상록구",
      4127110100: "상록구 일동",
      4127110200: "상록구 이동",
      4127110300: "상록구 사동",
      4127110400: "상록구 본오동",
      4127110500: "상록구 팔곡이동",
      4127110600: "상록구 양상동",
      4127110700: "상록구 부곡동",
      4127110800: "상록구 성포동",
      4127110900: "상록구 월피동",
      4127111000: "상록구 팔곡일동",
      4127111100: "상록구 건건동",
      4127111200: "상록구 사사동",
      4127111300: "상록구 수암동",
      4127111400: "상록구 장상동",
      4127111500: "상록구 장하동",
      4127300000: "단원구",
      4127310100: "단원구 고잔동",
      4127310200: "단원구 와동",
      4127310300: "단원구 신길동",
      4127310400: "단원구 성곡동",
      4127310500: "단원구 원시동",
      4127310600: "단원구 목내동",
      4127310700: "단원구 초지동",
      4127310800: "단원구 원곡동",
      4127310900: "단원구 선부동",
      4127311000: "단원구 대부동동",
      4127311100: "단원구 대부북동",
      4127311200: "단원구 대부남동",
      4127311300: "단원구 선감동",
      4127311400: "단원구 풍도동",
      4127311500: "단원구 화정동",
    },
    4128000000: {
      4128000000: "전체",
      4128100000: "덕양구",
      4128110100: "덕양구 주교동",
      4128110200: "덕양구 원당동",
      4128110300: "덕양구 신원동",
      4128110400: "덕양구 원흥동",
      4128110500: "덕양구 도내동",
      4128110600: "덕양구 성사동",
      4128110700: "덕양구 북한동",
      4128110800: "덕양구 효자동",
      4128110900: "덕양구 지축동",
      4128111000: "덕양구 오금동",
      4128111100: "덕양구 삼송동",
      4128111200: "덕양구 동산동",
      4128111300: "덕양구 용두동",
      4128111400: "덕양구 벽제동",
      4128111500: "덕양구 선유동",
      4128111600: "덕양구 고양동",
      4128111700: "덕양구 대자동",
      4128111800: "덕양구 관산동",
      4128111900: "덕양구 내유동",
      4128112000: "덕양구 토당동",
      4128112100: "덕양구 내곡동",
      4128112200: "덕양구 대장동",
      4128112300: "덕양구 화정동",
      4128112400: "덕양구 강매동",
      4128112500: "덕양구 행주내동",
      4128112600: "덕양구 행주외동",
      4128112700: "덕양구 신평동",
      4128112800: "덕양구 행신동",
      4128112900: "덕양구 화전동",
      4128113000: "덕양구 현천동",
      4128113100: "덕양구 덕은동",
      4128113200: "덕양구 향동동",
      4128500000: "일산동구",
      4128510100: "일산동구 식사동",
      4128510200: "일산동구 중산동",
      4128510300: "일산동구 정발산동",
      4128510400: "일산동구 장항동",
      4128510500: "일산동구 마두동",
      4128510600: "일산동구 백석동",
      4128510700: "일산동구 풍동",
      4128510800: "일산동구 산황동",
      4128510900: "일산동구 사리현동",
      4128511000: "일산동구 지영동",
      4128511100: "일산동구 설문동",
      4128511200: "일산동구 문봉동",
      4128511300: "일산동구 성석동",
      4128700000: "일산서구",
      4128710100: "일산서구 일산동",
      4128710200: "일산서구 주엽동",
      4128710300: "일산서구 탄현동",
      4128710400: "일산서구 대화동",
      4128710500: "일산서구 덕이동",
      4128710600: "일산서구 가좌동",
      4128710700: "일산서구 구산동",
      4128710800: "일산서구 법곳동",
    },
    4129000000: {
      4129000000: "전체",
      4129010100: "관문동",
      4129010200: "문원동",
      4129010300: "갈현동",
      4129010400: "막계동",
      4129010500: "과천동",
      4129010600: "주암동",
      4129010700: "중앙동",
      4129010800: "원문동",
      4129010900: "별양동",
      4129011000: "부림동",
    },
    4131000000: {
      4131000000: "전체",
      4131010100: "갈매동",
      4131010200: "사노동",
      4131010300: "인창동",
      4131010400: "교문동",
      4131010500: "수택동",
      4131010600: "아천동",
      4131010700: "토평동",
    },
    4136000000: {
      4136000000: "전체",
      4136010100: "호평동",
      4136010200: "평내동",
      4136010300: "금곡동",
      4136010400: "일패동",
      4136010500: "이패동",
      4136010600: "삼패동",
      4136010800: "수석동",
      4136010900: "지금동",
      4136011000: "도농동",
      4136011100: "별내동",
      4136011200: "다산동",
      4136025000: "와부읍",
      4136025300: "진접읍",
      4136025600: "화도읍",
      4136025900: "진건읍",
      4136026200: "오남읍",
      4136026500: "퇴계원읍",
      4136031000: "별내면",
      4136034000: "수동면",
      4136036000: "조안면",
    },
    4137000000: {
      4137000000: "전체",
      4137010100: "오산동",
      4137010200: "부산동",
      4137010300: "원동",
      4137010400: "궐동",
      4137010500: "청학동",
      4137010600: "가장동",
      4137010700: "금암동",
      4137010800: "수청동",
      4137010900: "은계동",
      4137011000: "내삼미동",
      4137011100: "외삼미동",
      4137011200: "양산동",
      4137011300: "세교동",
      4137011400: "지곶동",
      4137011500: "서랑동",
      4137011600: "서동",
      4137011700: "벌음동",
      4137011800: "두곡동",
      4137011900: "탑동",
      4137012000: "누읍동",
      4137012100: "가수동",
      4137012200: "고현동",
      4137012300: "청호동",
      4137012400: "갈곶동",
    },
    4139000000: {
      4139000000: "전체",
      4139010100: "대야동",
      4139010200: "신천동",
      4139010300: "방산동",
      4139010400: "포동",
      4139010500: "미산동",
      4139010600: "은행동",
      4139010700: "안현동",
      4139010800: "매화동",
      4139010900: "도창동",
      4139011000: "금이동",
      4139011200: "과림동",
      4139011300: "계수동",
      4139011700: "화정동",
      4139011800: "능곡동",
      4139011900: "하중동",
      4139012000: "하상동",
      4139012100: "광석동",
      4139012200: "물왕동",
      4139012300: "산현동",
      4139012400: "조남동",
      4139012500: "논곡동",
      4139012600: "목감동",
      4139012700: "거모동",
      4139012800: "군자동",
      4139012900: "장현동",
      4139013000: "장곡동",
      4139013100: "월곶동",
      4139013200: "정왕동",
      4139013300: "죽율동",
      4139013400: "무지내동",
      4139013500: "배곧동",
    },
    4141000000: {
      4141000000: "전체",
      4141010100: "당동",
      4141010200: "당정동",
      4141010300: "부곡동",
      4141010400: "산본동",
      4141010500: "금정동",
      4141010600: "둔대동",
      4141010700: "속달동",
      4141010800: "대야미동",
      4141010900: "도마교동",
    },
    4143000000: {
      4143000000: "전체",
      4143010100: "고천동",
      4143010200: "이동",
      4143010300: "삼동",
      4143010400: "왕곡동",
      4143010500: "오전동",
      4143010600: "학의동",
      4143010700: "내손동",
      4143010800: "청계동",
      4143010900: "포일동",
      4143011000: "월암동",
      4143011100: "초평동",
    },
    4145000000: {
      4145000000: "전체",
      4145010100: "천현동",
      4145010200: "하산곡동",
      4145010300: "창우동",
      4145010400: "배알미동",
      4145010500: "상산곡동",
      4145010600: "신장동",
      4145010700: "당정동",
      4145010800: "덕풍동",
      4145010900: "망월동",
      4145011000: "풍산동",
      4145011100: "미사동",
      4145011200: "선동",
      4145011300: "감북동",
      4145011400: "감일동",
      4145011500: "감이동",
      4145011600: "학암동",
      4145011700: "교산동",
      4145011800: "춘궁동",
      4145011900: "하사창동",
      4145012000: "상사창동",
      4145012100: "항동",
      4145012200: "초일동",
      4145012300: "초이동",
      4145012400: "광암동",
    },
    4146000000: {
      4146000000: "전체",
      4146100000: "처인구",
      4146110100: "처인구 김량장동",
      4146110200: "처인구 역북동",
      4146110300: "처인구 삼가동",
      4146110400: "처인구 남동",
      4146110500: "처인구 유방동",
      4146110600: "처인구 고림동",
      4146110700: "처인구 마평동",
      4146110800: "처인구 운학동",
      4146110900: "처인구 호동",
      4146111000: "처인구 해곡동",
      4146125000: "처인구 포곡읍",
      4146125300: "처인구 모현읍",
      4146125600: "처인구 이동읍",
      4146125900: "처인구 남사읍",
      4146134000: "처인구 원삼면",
      4146135000: "처인구 백암면",
      4146136000: "처인구 양지면",
      4146300000: "기흥구",
      4146310100: "기흥구 신갈동",
      4146310200: "기흥구 구갈동",
      4146310300: "기흥구 상갈동",
      4146310400: "기흥구 하갈동",
      4146310500: "기흥구 보라동",
      4146310600: "기흥구 지곡동",
      4146310700: "기흥구 공세동",
      4146310800: "기흥구 고매동",
      4146310900: "기흥구 농서동",
      4146311000: "기흥구 서천동",
      4146311100: "기흥구 영덕동",
      4146311200: "기흥구 언남동",
      4146311300: "기흥구 마북동",
      4146311400: "기흥구 청덕동",
      4146311500: "기흥구 동백동",
      4146311600: "기흥구 중동",
      4146311700: "기흥구 상하동",
      4146311800: "기흥구 보정동",
      4146500000: "수지구",
      4146510100: "수지구 풍덕천동",
      4146510200: "수지구 죽전동",
      4146510300: "수지구 동천동",
      4146510400: "수지구 고기동",
      4146510500: "수지구 신봉동",
      4146510600: "수지구 성복동",
      4146510700: "수지구 상현동",
    },
    4148000000: {
      4148000000: "전체",
      4148010100: "금촌동",
      4148010200: "아동동",
      4148010400: "야동동",
      4148010500: "검산동",
      4148010600: "맥금동",
      4148010700: "교하동",
      4148010800: "야당동",
      4148010900: "다율동",
      4148011000: "오도동",
      4148011100: "상지석동",
      4148011200: "산남동",
      4148011300: "동패동",
      4148011400: "당하동",
      4148011500: "문발동",
      4148011600: "송촌동",
      4148011700: "목동동",
      4148011800: "하지석동",
      4148011900: "서패동",
      4148012000: "신촌동",
      4148012100: "연다산동",
      4148012200: "와동동",
      4148012300: "금릉동",
      4148025000: "문산읍",
      4148025300: "파주읍",
      4148025600: "법원읍",
      4148026200: "조리읍",
      4148031000: "월롱면",
      4148032000: "탄현면",
      4148035000: "광탄면",
      4148036000: "파평면",
      4148037000: "적성면",
      4148038000: "군내면",
      4148039000: "장단면",
      4148040000: "진동면",
      4148041000: "진서면",
    },
    4150000000: {
      4150000000: "전체",
      4150010100: "창전동",
      4150010200: "관고동",
      4150010300: "중리동",
      4150010400: "증일동",
      4150010500: "율현동",
      4150010600: "진리동",
      4150010700: "안흥동",
      4150010800: "갈산동",
      4150010900: "증포동",
      4150011000: "송정동",
      4150011100: "사음동",
      4150011200: "단월동",
      4150011300: "대포동",
      4150011400: "고담동",
      4150011500: "장록동",
      4150025000: "장호원읍",
      4150025300: "부발읍",
      4150031000: "신둔면",
      4150032000: "백사면",
      4150033000: "호법면",
      4150034000: "마장면",
      4150035000: "대월면",
      4150036000: "모가면",
      4150037000: "설성면",
      4150038000: "율면",
    },
    4155000000: {
      4155000000: "전체",
      4155010100: "봉산동",
      4155010200: "숭인동",
      4155010300: "영동",
      4155010400: "봉남동",
      4155010500: "구포동",
      4155010600: "동본동",
      4155010700: "명륜동",
      4155010800: "옥천동",
      4155010900: "낙원동",
      4155011000: "창전동",
      4155011100: "성남동",
      4155011200: "신흥동",
      4155011300: "인지동",
      4155011400: "금산동",
      4155011500: "연지동",
      4155011600: "대천동",
      4155011700: "서인동",
      4155011800: "석정동",
      4155011900: "아양동",
      4155012000: "금석동",
      4155012100: "계동",
      4155012200: "옥산동",
      4155012300: "사곡동",
      4155012400: "도기동",
      4155012500: "당왕동",
      4155012600: "가사동",
      4155012700: "가현동",
      4155012800: "신건지동",
      4155012900: "신소현동",
      4155013000: "신모산동",
      4155013100: "현수동",
      4155013200: "발화동",
      4155013300: "중리동",
      4155025000: "공도읍",
      4155031000: "보개면",
      4155032000: "금광면",
      4155033000: "서운면",
      4155034000: "미양면",
      4155035000: "대덕면",
      4155036000: "양성면",
      4155038000: "원곡면",
      4155039000: "일죽면",
      4155040000: "죽산면",
      4155041000: "삼죽면",
      4155042000: "고삼면",
    },
    4157000000: {
      4157000000: "전체",
      4157010100: "북변동",
      4157010200: "걸포동",
      4157010300: "운양동",
      4157010400: "장기동",
      4157010500: "감정동",
      4157010600: "사우동",
      4157010700: "풍무동",
      4157010800: "마산동",
      4157010900: "구래동",
      4157025000: "통진읍",
      4157025300: "고촌읍",
      4157025600: "양촌읍",
      4157034000: "대곶면",
      4157035000: "월곶면",
      4157036000: "하성면",
    },
    4159000000: {
      4159000000: "전체",
      4159011600: "진안동",
      4159011700: "병점동",
      4159011800: "능동",
      4159011900: "기산동",
      4159012000: "반월동",
      4159012100: "반정동",
      4159012200: "황계동",
      4159012300: "배양동",
      4159012400: "기안동",
      4159012500: "송산동",
      4159012600: "안녕동",
      4159012700: "반송동",
      4159012800: "석우동",
      4159012900: "오산동",
      4159013000: "청계동",
      4159013100: "영천동",
      4159013200: "중동",
      4159013300: "신동",
      4159013400: "목동",
      4159013500: "산척동",
      4159013600: "장지동",
      4159013700: "송동",
      4159013800: "방교동",
      4159013900: "금곡동",
      4159014000: "새솔동",
      4159025300: "봉담읍",
      4159025600: "우정읍",
      4159025900: "향남읍",
      4159026200: "남양읍",
      4159031000: "매송면",
      4159032000: "비봉면",
      4159033000: "마도면",
      4159034000: "송산면",
      4159035000: "서신면",
      4159036000: "팔탄면",
      4159037000: "장안면",
      4159040000: "양감면",
      4159041000: "정남면",
    },
    4161000000: {
      4161000000: "전체",
      4161010100: "경안동",
      4161010200: "쌍령동",
      4161010300: "송정동",
      4161010400: "회덕동",
      4161010500: "탄벌동",
      4161010600: "목현동",
      4161010700: "삼동",
      4161010800: "중대동",
      4161010900: "직동",
      4161011000: "태전동",
      4161011100: "장지동",
      4161011200: "역동",
      4161011300: "목동",
      4161025000: "오포읍",
      4161025300: "초월읍",
      4161025900: "곤지암읍",
      4161033000: "도척면",
      4161034000: "퇴촌면",
      4161035000: "남종면",
      4161037000: "남한산성면",
    },
    4163000000: {
      4163000000: "전체",
      4163010100: "유양동",
      4163010200: "어둔동",
      4163010300: "남방동",
      4163010400: "마전동",
      4163010500: "산북동",
      4163010600: "광사동",
      4163010700: "만송동",
      4163010800: "삼숭동",
      4163010900: "고읍동",
      4163011000: "덕정동",
      4163011100: "봉양동",
      4163011200: "회암동",
      4163011300: "율정동",
      4163011400: "옥정동",
      4163011500: "고암동",
      4163011600: "덕계동",
      4163011700: "회정동",
      4163025000: "백석읍",
      4163031000: "은현면",
      4163032000: "남면",
      4163033000: "광적면",
      4163034000: "장흥면",
    },
    4165000000: {
      4165000000: "전체",
      4165010100: "신읍동",
      4165010200: "어룡동",
      4165010300: "자작동",
      4165010400: "선단동",
      4165010500: "설운동",
      4165010600: "동교동",
      4165025000: "소흘읍",
      4165031000: "군내면",
      4165032000: "내촌면",
      4165033000: "가산면",
      4165034000: "신북면",
      4165035000: "창수면",
      4165036000: "영중면",
      4165037000: "일동면",
      4165038000: "이동면",
      4165039000: "영북면",
      4165040000: "관인면",
      4165041000: "화현면",
    },
    4167000000: {
      4167000000: "전체",
      4167010100: "상동",
      4167010200: "홍문동",
      4167010300: "창동",
      4167010400: "우만동",
      4167010500: "단현동",
      4167010600: "신진동",
      4167010700: "하동",
      4167010800: "교동",
      4167010900: "월송동",
      4167011000: "가업동",
      4167011100: "연라동",
      4167011200: "상거동",
      4167011300: "하거동",
      4167011400: "삼교동",
      4167011500: "점봉동",
      4167011600: "능현동",
      4167011700: "멱곡동",
      4167011800: "연양동",
      4167011900: "매룡동",
      4167012000: "천송동",
      4167012100: "오학동",
      4167012200: "현암동",
      4167012300: "오금동",
      4167025000: "가남읍",
      4167031000: "점동면",
      4167032000: "흥천면",
      4167033000: "금사면",
      4167034500: "세종대왕면",
      4167035000: "대신면",
      4167036000: "북내면",
      4167037000: "강천면",
      4167038000: "산북면",
    },
    4180000000: {
      4180000000: "전체",
      4180025000: "연천읍",
      4180025300: "전곡읍",
      4180031000: "군남면",
      4180032000: "청산면",
      4180033000: "백학면",
      4180034000: "미산면",
      4180035000: "왕징면",
      4180036000: "신서면",
      4180037000: "중면",
      4180038000: "장남면",
    },
    4182000000: {
      4182000000: "전체",
      4182025000: "가평읍",
      4182031000: "설악면",
      4182032500: "청평면",
      4182033000: "상면",
      4182034500: "조종면",
      4182035000: "북면",
    },
    4183000000: {
      4183000000: "전체",
      4183025000: "양평읍",
      4183031000: "강상면",
      4183032000: "강하면",
      4183033000: "양서면",
      4183034000: "옥천면",
      4183035000: "서종면",
      4183036000: "단월면",
      4183037000: "청운면",
      4183038000: "양동면",
      4183039500: "지평면",
      4183040000: "용문면",
      4183041000: "개군면",
    },
  },
  4200000000: {
    4211000000: {
      4211000000: "전체",
      4211010100: "봉의동",
      4211010200: "요선동",
      4211010300: "낙원동",
      4211010400: "중앙로1가",
      4211010500: "중앙로2가",
      4211010600: "중앙로3가",
      4211010700: "옥천동",
      4211010800: "조양동",
      4211010900: "죽림동",
      4211011000: "운교동",
      4211011100: "약사동",
      4211011200: "효자동",
      4211011300: "소양로1가",
      4211011400: "소양로2가",
      4211011500: "소양로3가",
      4211011600: "소양로4가",
      4211011700: "근화동",
      4211011800: "우두동",
      4211011900: "사농동",
      4211012000: "후평동",
      4211012100: "온의동",
      4211012200: "교동",
      4211012300: "퇴계동",
      4211012400: "석사동",
      4211012500: "삼천동",
      4211012600: "칠전동",
      4211012700: "송암동",
      4211012800: "신동",
      4211012900: "중도동",
      4211025000: "신북읍",
      4211031000: "동면",
      4211032000: "동산면",
      4211033000: "신동면",
      4211034000: "남면",
      4211035000: "서면",
      4211036000: "사북면",
      4211038000: "북산면",
      4211039000: "동내면",
      4211040000: "남산면",
    },
    4213000000: {
      4213000000: "전체",
      4213010100: "중앙동",
      4213010200: "평원동",
      4213010300: "원동",
      4213010400: "인동",
      4213010500: "개운동",
      4213010600: "명륜동",
      4213010700: "단구동",
      4213010800: "일산동",
      4213010900: "학성동",
      4213011000: "단계동",
      4213011100: "우산동",
      4213011200: "태장동",
      4213011300: "봉산동",
      4213011400: "행구동",
      4213011500: "무실동",
      4213011600: "관설동",
      4213011700: "반곡동",
      4213011800: "가현동",
      4213025000: "문막읍",
      4213031000: "소초면",
      4213032000: "호저면",
      4213033000: "지정면",
      4213035000: "부론면",
      4213036000: "귀래면",
      4213037000: "흥업면",
      4213038000: "판부면",
      4213039000: "신림면",
    },
    4215000000: {
      4215000000: "전체",
      4215010100: "홍제동",
      4215010200: "남문동",
      4215010300: "명주동",
      4215010400: "성내동",
      4215010500: "임당동",
      4215010600: "금학동",
      4215010700: "용강동",
      4215010800: "성남동",
      4215010900: "옥천동",
      4215011000: "교동",
      4215011100: "포남동",
      4215011200: "초당동",
      4215011300: "강문동",
      4215011400: "송정동",
      4215011500: "견소동",
      4215011600: "내곡동",
      4215011700: "회산동",
      4215011800: "장현동",
      4215011900: "박월동",
      4215012000: "담산동",
      4215012100: "노암동",
      4215012200: "유산동",
      4215012300: "월호평동",
      4215012400: "신석동",
      4215012500: "입암동",
      4215012600: "청량동",
      4215012700: "두산동",
      4215012800: "학동",
      4215012900: "병산동",
      4215013000: "남항진동",
      4215013100: "유천동",
      4215013200: "지변동",
      4215013300: "죽헌동",
      4215013400: "대전동",
      4215013500: "운정동",
      4215013600: "난곡동",
      4215013700: "저동",
      4215013800: "안현동",
      4215013900: "운산동",
      4215025000: "주문진읍",
      4215031000: "성산면",
      4215032000: "왕산면",
      4215033000: "구정면",
      4215034000: "강동면",
      4215035000: "옥계면",
      4215036000: "사천면",
      4215037000: "연곡면",
    },
    4217000000: {
      4217000000: "전체",
      4217010100: "천곡동",
      4217010200: "평릉동",
      4217010300: "송정동",
      4217010400: "용정동",
      4217010500: "지흥동",
      4217010600: "효가동",
      4217010700: "동회동",
      4217010800: "나안동",
      4217010900: "쇄운동",
      4217011000: "부곡동",
      4217011100: "발한동",
      4217011200: "북평동",
      4217011300: "구미동",
      4217011400: "추암동",
      4217011500: "구호동",
      4217011600: "단봉동",
      4217011700: "지가동",
      4217011800: "이도동",
      4217011900: "귀운동",
      4217012000: "대구동",
      4217012100: "호현동",
      4217012200: "내동",
      4217012300: "묵호진동",
      4217012400: "삼화동",
      4217012500: "이기동",
      4217012600: "이로동",
      4217012700: "어달동",
      4217012800: "대진동",
      4217012900: "망상동",
      4217013000: "심곡동",
      4217013100: "초구동",
      4217013200: "괴란동",
      4217013300: "만우동",
      4217013400: "신흥동",
      4217013500: "비천동",
      4217013600: "달방동",
    },
    4219000000: {
      4219000000: "전체",
      4219010100: "황지동",
      4219010200: "장성동",
      4219010300: "금천동",
      4219010400: "철암동",
      4219010500: "문곡동",
      4219010600: "동점동",
      4219010700: "소도동",
      4219010800: "혈동",
      4219010900: "화전동",
      4219011000: "적각동",
      4219011100: "창죽동",
      4219011200: "통동",
      4219011300: "백산동",
      4219011400: "원동",
      4219011500: "상사미동",
      4219011600: "하사미동",
      4219011700: "조탄동",
    },
    4221000000: {
      4221000000: "전체",
      4221010100: "영랑동",
      4221010200: "동명동",
      4221010300: "중앙동",
      4221010400: "금호동",
      4221010500: "청학동",
      4221010600: "교동",
      4221010700: "노학동",
      4221010800: "조양동",
      4221010900: "청호동",
      4221011000: "대포동",
      4221011100: "도문동",
      4221011200: "설악동",
      4221011300: "장사동",
    },
    4223000000: {
      4223000000: "전체",
      4223010100: "성내동",
      4223010200: "성북동",
      4223010300: "읍상동",
      4223010400: "읍중동",
      4223010500: "당저동",
      4223010600: "교동",
      4223010700: "갈천동",
      4223010800: "증산동",
      4223010900: "우지동",
      4223011000: "마달동",
      4223011100: "자원동",
      4223011200: "평전동",
      4223011300: "등봉동",
      4223011400: "도경동",
      4223011500: "마평동",
      4223011600: "오사동",
      4223011700: "건지동",
      4223011800: "원당동",
      4223011900: "성남동",
      4223012000: "남양동",
      4223012100: "사직동",
      4223012200: "오분동",
      4223012300: "적노동",
      4223012400: "조비동",
      4223012500: "정상동",
      4223012600: "정하동",
      4223012700: "근산동",
      4223025000: "도계읍",
      4223025300: "원덕읍",
      4223031000: "근덕면",
      4223032000: "하장면",
      4223033000: "노곡면",
      4223034000: "미로면",
      4223035000: "가곡면",
      4223036000: "신기면",
    },
    4272000000: {
      4272000000: "전체",
      4272025000: "홍천읍",
      4272031000: "화촌면",
      4272032000: "두촌면",
      4272033000: "내촌면",
      4272034000: "서석면",
      4272035200: "영귀미면",
      4272036000: "남면",
      4272037000: "서면",
      4272038000: "북방면",
      4272039000: "내면",
    },
    4273000000: {
      4273000000: "전체",
      4273025000: "횡성읍",
      4273031000: "우천면",
      4273032000: "안흥면",
      4273033000: "둔내면",
      4273034000: "갑천면",
      4273035000: "청일면",
      4273036000: "공근면",
      4273037000: "서원면",
      4273038000: "강림면",
    },
    4275000000: {
      4275000000: "전체",
      4275025000: "영월읍",
      4275025300: "상동읍",
      4275031200: "산솔면",
      4275032500: "김삿갓면",
      4275033000: "북면",
      4275034000: "남면",
      4275035500: "한반도면",
      4275036000: "주천면",
      4275038000: "무릉도원면",
    },
    4276000000: {
      4276000000: "전체",
      4276025000: "평창읍",
      4276031000: "미탄면",
      4276032000: "방림면",
      4276033000: "대화면",
      4276034000: "봉평면",
      4276035000: "용평면",
      4276036000: "진부면",
      4276038000: "대관령면",
    },
    4277000000: {
      4277000000: "전체",
      4277025000: "정선읍",
      4277025300: "고한읍",
      4277025600: "사북읍",
      4277025900: "신동읍",
      4277032000: "남면",
      4277034000: "북평면",
      4277035000: "임계면",
      4277036000: "화암면",
      4277037000: "여량면",
    },
    4278000000: {
      4278000000: "전체",
      4278025000: "철원읍",
      4278025300: "김화읍",
      4278025600: "갈말읍",
      4278025900: "동송읍",
      4278031000: "서면",
      4278032000: "근남면",
      4278033000: "근북면",
      4278034000: "근동면",
      4278035000: "원동면",
      4278036000: "원남면",
      4278037000: "임남면",
    },
    4279000000: {
      4279000000: "전체",
      4279025000: "화천읍",
      4279031000: "간동면",
      4279032000: "하남면",
      4279033000: "상서면",
      4279034000: "사내면",
    },
    4280000000: {
      4280000000: "전체",
      4280025000: "양구읍",
      4280031500: "국토정중앙면",
      4280032000: "동면",
      4280033000: "방산면",
      4280034000: "해안면",
    },
    4281000000: {
      4281000000: "전체",
      4281025000: "인제읍",
      4281031000: "남면",
      4281032000: "북면",
      4281033000: "기린면",
      4281034000: "서화면",
      4281035000: "상남면",
    },
    4282000000: {
      4282000000: "전체",
      4282025000: "간성읍",
      4282025300: "거진읍",
      4282031000: "현내면",
      4282032000: "죽왕면",
      4282033000: "토성면",
      4282034000: "수동면",
    },
    4283000000: {
      4283000000: "전체",
      4283025000: "양양읍",
      4283031000: "서면",
      4283032000: "손양면",
      4283033000: "현북면",
      4283034000: "현남면",
      4283035000: "강현면",
    },
  },
  4300000000: {
    4311000000: {
      4311000000: "전체",
      4311100000: "상당구",
      4311110100: "상당구 영동",
      4311110200: "상당구 북문로1가",
      4311110300: "상당구 북문로2가",
      4311110400: "상당구 북문로3가",
      4311110500: "상당구 남문로1가",
      4311110600: "상당구 남문로2가",
      4311110700: "상당구 문화동",
      4311110800: "상당구 서운동",
      4311110900: "상당구 서문동",
      4311111000: "상당구 남주동",
      4311111100: "상당구 석교동",
      4311111200: "상당구 수동",
      4311111700: "상당구 탑동",
      4311111800: "상당구 대성동",
      4311111900: "상당구 영운동",
      4311112000: "상당구 금천동",
      4311112100: "상당구 용담동",
      4311112200: "상당구 명암동",
      4311112300: "상당구 산성동",
      4311112400: "상당구 용암동",
      4311112500: "상당구 용정동",
      4311112600: "상당구 방서동",
      4311112700: "상당구 평촌동",
      4311112800: "상당구 지북동",
      4311112900: "상당구 운동동",
      4311113000: "상당구 월오동",
      4311131000: "상당구 낭성면",
      4311132000: "상당구 미원면",
      4311133000: "상당구 가덕면",
      4311134000: "상당구 남일면",
      4311135000: "상당구 문의면",
      4311200000: "서원구",
      4311210100: "서원구 사직동",
      4311210200: "서원구 사창동",
      4311210300: "서원구 모충동",
      4311210400: "서원구 산남동",
      4311210500: "서원구 미평동",
      4311210600: "서원구 분평동",
      4311210700: "서원구 수곡동",
      4311210800: "서원구 성화동",
      4311210900: "서원구 개신동",
      4311211000: "서원구 죽림동",
      4311211100: "서원구 장성동",
      4311211200: "서원구 장암동",
      4311231000: "서원구 남이면",
      4311232000: "서원구 현도면",
      4311300000: "흥덕구",
      4311310400: "흥덕구 운천동",
      4311310500: "흥덕구 신봉동",
      4311311300: "흥덕구 가경동",
      4311311400: "흥덕구 복대동",
      4311311500: "흥덕구 봉명동",
      4311311600: "흥덕구 송정동",
      4311311700: "흥덕구 강서동",
      4311311800: "흥덕구 석곡동",
      4311311900: "흥덕구 휴암동",
      4311312000: "흥덕구 신전동",
      4311312100: "흥덕구 현암동",
      4311312200: "흥덕구 동막동",
      4311312300: "흥덕구 수의동",
      4311312400: "흥덕구 지동동",
      4311312500: "흥덕구 서촌동",
      4311312600: "흥덕구 신성동",
      4311312700: "흥덕구 평동",
      4311312800: "흥덕구 신대동",
      4311312900: "흥덕구 남촌동",
      4311313000: "흥덕구 내곡동",
      4311313100: "흥덕구 상신동",
      4311313200: "흥덕구 원평동",
      4311313300: "흥덕구 문암동",
      4311313400: "흥덕구 송절동",
      4311313500: "흥덕구 화계동",
      4311313600: "흥덕구 외북동",
      4311313700: "흥덕구 향정동",
      4311313800: "흥덕구 비하동",
      4311313900: "흥덕구 석소동",
      4311314000: "흥덕구 정봉동",
      4311314100: "흥덕구 신촌동",
      4311325000: "흥덕구 오송읍",
      4311331000: "흥덕구 강내면",
      4311332000: "흥덕구 옥산면",
      4311400000: "청원구",
      4311410100: "청원구 우암동",
      4311410200: "청원구 내덕동",
      4311410300: "청원구 율량동",
      4311410400: "청원구 사천동",
      4311410500: "청원구 주성동",
      4311410600: "청원구 주중동",
      4311410700: "청원구 정상동",
      4311410800: "청원구 정하동",
      4311410900: "청원구 정북동",
      4311411000: "청원구 오동동",
      4311411100: "청원구 외남동",
      4311411200: "청원구 외평동",
      4311411300: "청원구 외하동",
      4311425000: "청원구 내수읍",
      4311425300: "청원구 오창읍",
      4311431000: "청원구 북이면",
    },
    4313000000: {
      4313000000: "전체",
      4313010100: "성내동",
      4313010200: "성남동",
      4313010300: "성서동",
      4313010400: "충인동",
      4313010500: "교현동",
      4313010600: "용산동",
      4313010700: "호암동",
      4313010800: "직동",
      4313010900: "단월동",
      4313011000: "풍동",
      4313011100: "가주동",
      4313011200: "용관동",
      4313011300: "용두동",
      4313011400: "달천동",
      4313011500: "봉방동",
      4313011600: "칠금동",
      4313011800: "연수동",
      4313011900: "목행동",
      4313012000: "용탄동",
      4313012100: "종민동",
      4313012200: "안림동",
      4313012300: "목벌동",
      4313012400: "충의동",
      4313012500: "지현동",
      4313012700: "문화동",
      4313012800: "금릉동",
      4313025000: "주덕읍",
      4313031000: "살미면",
      4313032500: "수안보면",
      4313033500: "대소원면",
      4313035000: "신니면",
      4313036000: "노은면",
      4313037000: "앙성면",
      4313038500: "중앙탑면",
      4313039000: "금가면",
      4313040000: "동량면",
      4313041000: "산척면",
      4313042000: "엄정면",
      4313043000: "소태면",
    },
    4315000000: {
      4315000000: "전체",
      4315010100: "의림동",
      4315010200: "서부동",
      4315010300: "동현동",
      4315010400: "남천동",
      4315010500: "교동",
      4315010600: "중앙로1가",
      4315010700: "중앙로2가",
      4315010800: "명동",
      4315010900: "화산동",
      4315011000: "영천동",
      4315011100: "하소동",
      4315011200: "신월동",
      4315011300: "청전동",
      4315011400: "모산동",
      4315011500: "고암동",
      4315011600: "장락동",
      4315011700: "흑석동",
      4315011800: "두학동",
      4315011900: "고명동",
      4315012000: "신백동",
      4315012100: "강제동",
      4315012200: "명지동",
      4315012300: "산곡동",
      4315012400: "왕암동",
      4315012500: "천남동",
      4315012600: "신동",
      4315012700: "자작동",
      4315012800: "대랑동",
      4315025000: "봉양읍",
      4315031000: "금성면",
      4315032000: "청풍면",
      4315033000: "수산면",
      4315034000: "덕산면",
      4315035000: "한수면",
      4315036000: "백운면",
      4315038000: "송학면",
    },
    4372000000: {
      4372000000: "전체",
      4372025000: "보은읍",
      4372031500: "속리산면",
      4372032500: "장안면",
      4372033000: "마로면",
      4372034000: "탄부면",
      4372035000: "삼승면",
      4372036000: "수한면",
      4372037000: "회남면",
      4372038500: "회인면",
      4372039000: "내북면",
      4372040000: "산외면",
    },
    4373000000: {
      4373000000: "전체",
      4373025000: "옥천읍",
      4373031000: "동이면",
      4373032000: "안남면",
      4373033000: "안내면",
      4373034000: "청성면",
      4373035000: "청산면",
      4373036000: "이원면",
      4373037000: "군서면",
      4373038000: "군북면",
    },
    4374000000: {
      4374000000: "전체",
      4374025000: "영동읍",
      4374031000: "용산면",
      4374032000: "황간면",
      4374033500: "추풍령면",
      4374034000: "매곡면",
      4374035000: "상촌면",
      4374036000: "양강면",
      4374037000: "용화면",
      4374038000: "학산면",
      4374039000: "양산면",
      4374040000: "심천면",
    },
    4375000000: {
      4375000000: "전체",
      4375025000: "진천읍",
      4375025300: "덕산읍",
      4375032000: "초평면",
      4375033000: "문백면",
      4375034000: "백곡면",
      4375035000: "이월면",
      4375037000: "광혜원면",
    },
    4376000000: {
      4376000000: "전체",
      4376025000: "괴산읍",
      4376031000: "감물면",
      4376032000: "장연면",
      4376033000: "연풍면",
      4376034000: "칠성면",
      4376035000: "문광면",
      4376036000: "청천면",
      4376037000: "청안면",
      4376039000: "사리면",
      4376040000: "소수면",
      4376041000: "불정면",
    },
    4377000000: {
      4377000000: "전체",
      4377025000: "음성읍",
      4377025300: "금왕읍",
      4377031000: "소이면",
      4377032000: "원남면",
      4377033000: "맹동면",
      4377034000: "대소면",
      4377035000: "삼성면",
      4377036000: "생극면",
      4377037000: "감곡면",
    },
    4380000000: {
      4380000000: "전체",
      4380025000: "단양읍",
      4380025300: "매포읍",
      4380031000: "대강면",
      4380032000: "가곡면",
      4380033000: "영춘면",
      4380034000: "어상천면",
      4380035000: "적성면",
      4380036000: "단성면",
    },
  },
  4400000000: {
    4413000000: {
      4413000000: "전체",
      4413100000: "동남구",
      4413110100: "동남구 대흥동",
      4413110200: "동남구 성황동",
      4413110300: "동남구 문화동",
      4413110400: "동남구 사직동",
      4413110500: "동남구 영성동",
      4413110600: "동남구 오룡동",
      4413110700: "동남구 원성동",
      4413110800: "동남구 구성동",
      4413110900: "동남구 청수동",
      4413111000: "동남구 삼룡동",
      4413111100: "동남구 청당동",
      4413111200: "동남구 유량동",
      4413111300: "동남구 봉명동",
      4413111400: "동남구 다가동",
      4413111500: "동남구 용곡동",
      4413111600: "동남구 신방동",
      4413111700: "동남구 쌍용동",
      4413111800: "동남구 신부동",
      4413111900: "동남구 안서동",
      4413112000: "동남구 구룡동",
      4413125000: "동남구 목천읍",
      4413131000: "동남구 풍세면",
      4413132000: "동남구 광덕면",
      4413133000: "동남구 북면",
      4413134000: "동남구 성남면",
      4413135000: "동남구 수신면",
      4413136000: "동남구 병천면",
      4413137000: "동남구 동면",
      4413300000: "서북구",
      4413310100: "서북구 와촌동",
      4413310200: "서북구 성정동",
      4413310300: "서북구 백석동",
      4413310400: "서북구 두정동",
      4413310500: "서북구 성성동",
      4413310600: "서북구 차암동",
      4413310700: "서북구 쌍용동",
      4413310800: "서북구 불당동",
      4413310900: "서북구 업성동",
      4413311000: "서북구 신당동",
      4413311100: "서북구 부대동",
      4413325000: "서북구 성환읍",
      4413325300: "서북구 성거읍",
      4413325600: "서북구 직산읍",
      4413331000: "서북구 입장면",
    },
    4415000000: {
      4415000000: "전체",
      4415010100: "반죽동",
      4415010200: "봉황동",
      4415010300: "중학동",
      4415010400: "중동",
      4415010500: "산성동",
      4415010600: "교동",
      4415010700: "웅진동",
      4415010800: "금성동",
      4415010900: "옥룡동",
      4415011000: "금학동",
      4415011100: "봉정동",
      4415011200: "주미동",
      4415011300: "태봉동",
      4415011400: "오곡동",
      4415011500: "신기동",
      4415011600: "소학동",
      4415011700: "상왕동",
      4415011800: "무릉동",
      4415011900: "월송동",
      4415012000: "신관동",
      4415012100: "금흥동",
      4415012200: "쌍신동",
      4415012300: "월미동",
      4415012400: "검상동",
      4415012500: "석장리동",
      4415012600: "송선동",
      4415012700: "동현동",
      4415025000: "유구읍",
      4415031000: "이인면",
      4415032000: "탄천면",
      4415033000: "계룡면",
      4415034000: "반포면",
      4415036000: "의당면",
      4415037000: "정안면",
      4415038000: "우성면",
      4415039000: "사곡면",
      4415040000: "신풍면",
    },
    4418000000: {
      4418000000: "전체",
      4418010100: "대천동",
      4418010200: "죽정동",
      4418010300: "화산동",
      4418010400: "동대동",
      4418010500: "명천동",
      4418010600: "궁촌동",
      4418010700: "내항동",
      4418010800: "남곡동",
      4418010900: "요암동",
      4418011000: "신흑동",
      4418025000: "웅천읍",
      4418031000: "주포면",
      4418032000: "오천면",
      4418033000: "천북면",
      4418034000: "청소면",
      4418035000: "청라면",
      4418036000: "남포면",
      4418038000: "주산면",
      4418039000: "미산면",
      4418040000: "성주면",
      4418041000: "주교면",
    },
    4420000000: {
      4420000000: "전체",
      4420010100: "온천동",
      4420010200: "실옥동",
      4420010300: "방축동",
      4420010400: "기산동",
      4420010500: "초사동",
      4420010600: "신인동",
      4420010700: "법곡동",
      4420010800: "장존동",
      4420010900: "좌부동",
      4420011000: "읍내동",
      4420011100: "풍기동",
      4420011200: "용화동",
      4420011300: "모종동",
      4420011400: "권곡동",
      4420011500: "배미동",
      4420011600: "득산동",
      4420011700: "점양동",
      4420011800: "신동",
      4420011900: "남동",
      4420025000: "염치읍",
      4420025300: "배방읍",
      4420031000: "송악면",
      4420033000: "탕정면",
      4420035000: "음봉면",
      4420036000: "둔포면",
      4420037000: "영인면",
      4420038000: "인주면",
      4420039000: "선장면",
      4420040000: "도고면",
      4420041000: "신창면",
    },
    4421000000: {
      4421000000: "전체",
      4421010100: "읍내동",
      4421010200: "동문동",
      4421010300: "갈산동",
      4421010400: "온석동",
      4421010500: "잠홍동",
      4421010600: "수석동",
      4421010700: "석림동",
      4421010800: "석남동",
      4421010900: "예천동",
      4421011000: "죽성동",
      4421011100: "양대동",
      4421011200: "오남동",
      4421011300: "장동",
      4421011400: "덕지천동",
      4421025000: "대산읍",
      4421031000: "인지면",
      4421032000: "부석면",
      4421033000: "팔봉면",
      4421034000: "지곡면",
      4421036000: "성연면",
      4421037000: "음암면",
      4421038000: "운산면",
      4421039000: "해미면",
      4421040000: "고북면",
    },
    4423000000: {
      4423000000: "전체",
      4423010100: "화지동",
      4423010200: "반월동",
      4423010300: "대교동",
      4423010400: "부창동",
      4423010500: "취암동",
      4423010600: "등화동",
      4423010700: "지산동",
      4423010800: "덕지동",
      4423010900: "내동",
      4423011000: "강산동",
      4423011100: "관촉동",
      4423025000: "강경읍",
      4423025300: "연무읍",
      4423031000: "성동면",
      4423032000: "광석면",
      4423033000: "노성면",
      4423034000: "상월면",
      4423035000: "부적면",
      4423036000: "연산면",
      4423038000: "벌곡면",
      4423039000: "양촌면",
      4423040000: "가야곡면",
      4423041000: "은진면",
      4423042000: "채운면",
    },
    4425000000: {
      4425000000: "전체",
      4425010100: "금암동",
      4425031000: "두마면",
      4425031500: "엄사면",
      4425033000: "신도안면",
    },
    4427000000: {
      4427000000: "전체",
      4427010100: "읍내동",
      4427010200: "채운동",
      4427010300: "우두동",
      4427010400: "원당동",
      4427010500: "시곡동",
      4427010600: "수청동",
      4427010700: "대덕동",
      4427010800: "행정동",
      4427010900: "용연동",
      4427011000: "사기소동",
      4427011100: "구룡동",
      4427025000: "합덕읍",
      4427025300: "송악읍",
      4427031000: "고대면",
      4427032000: "석문면",
      4427033000: "대호지면",
      4427034000: "정미면",
      4427035000: "면천면",
      4427036000: "순성면",
      4427037000: "우강면",
      4427038000: "신평면",
      4427039000: "송산면",
    },
    4471000000: {
      4471000000: "전체",
      4471025000: "금산읍",
      4471031000: "금성면",
      4471032000: "제원면",
      4471033000: "부리면",
      4471034000: "군북면",
      4471035000: "남일면",
      4471036000: "남이면",
      4471037000: "진산면",
      4471038000: "복수면",
      4471039000: "추부면",
    },
    4476000000: {
      4476000000: "전체",
      4476025000: "부여읍",
      4476031000: "규암면",
      4476032000: "은산면",
      4476033000: "외산면",
      4476034000: "내산면",
      4476035000: "구룡면",
      4476036000: "홍산면",
      4476037000: "옥산면",
      4476038000: "남면",
      4476039000: "충화면",
      4476040000: "양화면",
      4476041000: "임천면",
      4476042000: "장암면",
      4476043000: "세도면",
      4476044000: "석성면",
      4476045000: "초촌면",
    },
    4477000000: {
      4477000000: "전체",
      4477025000: "장항읍",
      4477025300: "서천읍",
      4477031000: "마서면",
      4477032000: "화양면",
      4477033000: "기산면",
      4477034000: "한산면",
      4477035000: "마산면",
      4477036000: "시초면",
      4477037000: "문산면",
      4477038000: "판교면",
      4477039000: "종천면",
      4477040000: "비인면",
      4477041000: "서면",
    },
    4479000000: {
      4479000000: "전체",
      4479025000: "청양읍",
      4479031000: "운곡면",
      4479032000: "대치면",
      4479033000: "정산면",
      4479034000: "목면",
      4479035000: "청남면",
      4479036000: "장평면",
      4479037000: "남양면",
      4479038000: "화성면",
      4479039000: "비봉면",
    },
    4480000000: {
      4480000000: "전체",
      4480025000: "홍성읍",
      4480025300: "광천읍",
      4480025600: "홍북읍",
      4480032000: "금마면",
      4480033000: "홍동면",
      4480034000: "장곡면",
      4480035000: "은하면",
      4480036000: "결성면",
      4480037000: "서부면",
      4480038000: "갈산면",
      4480039000: "구항면",
    },
    4481000000: {
      4481000000: "전체",
      4481025000: "예산읍",
      4481025300: "삽교읍",
      4481031000: "대술면",
      4481032000: "신양면",
      4481033000: "광시면",
      4481034000: "대흥면",
      4481035000: "응봉면",
      4481036000: "덕산면",
      4481037000: "봉산면",
      4481038000: "고덕면",
      4481039000: "신암면",
      4481040000: "오가면",
    },
  },
  4500000000: {
    4511000000: {
      4511000000: "전체",
      4511100000: "완산구",
      4511110100: "완산구 중앙동1가",
      4511110200: "완산구 중앙동2가",
      4511110300: "완산구 중앙동3가",
      4511110400: "완산구 중앙동4가",
      4511110500: "완산구 경원동1가",
      4511110600: "완산구 경원동2가",
      4511110700: "완산구 경원동3가",
      4511110800: "완산구 풍남동1가",
      4511110900: "완산구 풍남동2가",
      4511111000: "완산구 풍남동3가",
      4511111100: "완산구 전동",
      4511111200: "완산구 전동3가",
      4511111300: "완산구 다가동1가",
      4511111400: "완산구 다가동2가",
      4511111500: "완산구 다가동3가",
      4511111600: "완산구 다가동4가",
      4511111700: "완산구 고사동",
      4511111800: "완산구 교동",
      4511111900: "완산구 태평동",
      4511112000: "완산구 중노송동",
      4511112100: "완산구 남노송동",
      4511112200: "완산구 동완산동",
      4511112300: "완산구 서완산동1가",
      4511112400: "완산구 서완산동2가",
      4511112500: "완산구 동서학동",
      4511112600: "완산구 서서학동",
      4511112700: "완산구 중화산동1가",
      4511112800: "완산구 중화산동2가",
      4511112900: "완산구 서신동",
      4511113000: "완산구 석구동",
      4511113100: "완산구 원당동",
      4511113200: "완산구 평화동1가",
      4511113300: "완산구 평화동2가",
      4511113400: "완산구 평화동3가",
      4511113500: "완산구 중인동",
      4511113600: "완산구 용복동",
      4511113700: "완산구 삼천동1가",
      4511113800: "완산구 삼천동2가",
      4511113900: "완산구 삼천동3가",
      4511114000: "완산구 효자동1가",
      4511114100: "완산구 효자동2가",
      4511114200: "완산구 효자동3가",
      4511114300: "완산구 대성동",
      4511114400: "완산구 색장동",
      4511114500: "완산구 상림동",
      4511114700: "완산구 서노송동",
      4511300000: "덕진구",
      4511310200: "덕진구 진북동",
      4511310300: "덕진구 인후동1가",
      4511310400: "덕진구 인후동2가",
      4511310500: "덕진구 덕진동1가",
      4511310600: "덕진구 덕진동2가",
      4511310700: "덕진구 금암동",
      4511310800: "덕진구 팔복동1가",
      4511310900: "덕진구 팔복동2가",
      4511311000: "덕진구 팔복동3가",
      4511311100: "덕진구 산정동",
      4511311200: "덕진구 금상동",
      4511311300: "덕진구 우아동1가",
      4511311400: "덕진구 우아동2가",
      4511311500: "덕진구 우아동3가",
      4511311600: "덕진구 호성동1가",
      4511311700: "덕진구 호성동2가",
      4511311800: "덕진구 호성동3가",
      4511311900: "덕진구 전미동1가",
      4511312000: "덕진구 전미동2가",
      4511312100: "덕진구 송천동1가",
      4511312200: "덕진구 송천동2가",
      4511312300: "덕진구 반월동",
      4511312400: "덕진구 화전동",
      4511312500: "덕진구 용정동",
      4511312600: "덕진구 성덕동",
      4511312700: "덕진구 원동",
      4511312900: "덕진구 고랑동",
      4511313000: "덕진구 여의동",
      4511313100: "덕진구 만성동",
      4511313200: "덕진구 장동",
      4511313300: "덕진구 팔복동4가",
      4511313400: "덕진구 도도동",
      4511313500: "덕진구 강흥동",
      4511313600: "덕진구 도덕동",
      4511313700: "덕진구 남정동",
      4511313800: "덕진구 중동",
      4511313900: "덕진구 여의동2가",
    },
    4513000000: {
      4513000000: "전체",
      4513010100: "해망동",
      4513010200: "신흥동",
      4513010300: "금동",
      4513010400: "월명동",
      4513010500: "신창동",
      4513010600: "오룡동",
      4513010700: "금광동",
      4513010800: "신풍동",
      4513010900: "송풍동",
      4513011000: "문화동",
      4513011100: "삼학동",
      4513011200: "선양동",
      4513011300: "둔율동",
      4513011400: "창성동",
      4513011500: "명산동",
      4513011600: "송창동",
      4513011700: "개복동",
      4513011800: "중앙로1가",
      4513011900: "영화동",
      4513012000: "장미동",
      4513012100: "중앙로2가",
      4513012200: "영동",
      4513012300: "신영동",
      4513012400: "죽성동",
      4513012500: "평화동",
      4513012600: "중앙로3가",
      4513012700: "대명동",
      4513012800: "장재동",
      4513012900: "미원동",
      4513013000: "중동",
      4513013100: "금암동",
      4513013200: "동흥남동",
      4513013300: "서흥남동",
      4513013400: "조촌동",
      4513013500: "경장동",
      4513013600: "경암동",
      4513013700: "구암동",
      4513013800: "내흥동",
      4513013900: "개정동",
      4513014000: "사정동",
      4513014100: "수송동",
      4513014200: "미장동",
      4513014300: "지곡동",
      4513014400: "나운동",
      4513014500: "미룡동",
      4513014600: "소룡동",
      4513014700: "오식도동",
      4513014800: "비응도동",
      4513014900: "신관동",
      4513015000: "개사동",
      4513015100: "산북동",
      4513015200: "내초동",
      4513025000: "옥구읍",
      4513031000: "옥산면",
      4513032000: "회현면",
      4513033000: "임피면",
      4513034000: "서수면",
      4513035000: "대야면",
      4513036000: "개정면",
      4513037000: "성산면",
      4513038000: "나포면",
      4513039000: "옥도면",
      4513040000: "옥서면",
    },
    4514000000: {
      4514000000: "전체",
      4514010100: "창인동1가",
      4514010200: "창인동2가",
      4514010300: "중앙동1가",
      4514010400: "중앙동2가",
      4514010500: "중앙동3가",
      4514010600: "평화동",
      4514010700: "갈산동",
      4514010800: "주현동",
      4514010900: "인화동1가",
      4514011000: "인화동2가",
      4514011100: "동산동",
      4514011200: "마동",
      4514011300: "남중동",
      4514011400: "모현동1가",
      4514011500: "모현동2가",
      4514011600: "송학동",
      4514011700: "목천동",
      4514011800: "만석동",
      4514011900: "현영동",
      4514012000: "신용동",
      4514012100: "신동",
      4514012200: "영등동",
      4514012300: "어양동",
      4514012400: "신흥동",
      4514012500: "금강동",
      4514012600: "석탄동",
      4514012700: "팔봉동",
      4514012800: "덕기동",
      4514012900: "석왕동",
      4514013000: "은기동",
      4514013100: "정족동",
      4514013200: "임상동",
      4514013300: "월성동",
      4514013400: "부송동",
      4514013500: "용제동",
      4514013600: "석암동",
      4514025000: "함열읍",
      4514031000: "오산면",
      4514032000: "황등면",
      4514033000: "함라면",
      4514034000: "웅포면",
      4514035000: "성당면",
      4514036000: "용안면",
      4514037000: "낭산면",
      4514038000: "망성면",
      4514039000: "여산면",
      4514040000: "금마면",
      4514041000: "왕궁면",
      4514042000: "춘포면",
      4514043000: "삼기면",
      4514044000: "용동면",
    },
    4518000000: {
      4518000000: "전체",
      4518010100: "수성동",
      4518010200: "장명동",
      4518010300: "상동",
      4518010400: "시기동",
      4518010500: "연지동",
      4518010600: "농소동",
      4518010700: "하모동",
      4518010800: "상평동",
      4518010900: "과교동",
      4518011000: "삼산동",
      4518011100: "진산동",
      4518011200: "금붕동",
      4518011300: "송산동",
      4518011400: "신월동",
      4518011500: "용산동",
      4518011600: "교암동",
      4518011700: "부전동",
      4518011800: "쌍암동",
      4518011900: "내장동",
      4518012000: "영파동",
      4518012100: "하북동",
      4518012200: "구룡동",
      4518012300: "흑암동",
      4518012400: "용계동",
      4518012500: "공평동",
      4518012600: "망제동",
      4518012700: "신정동",
      4518025000: "신태인읍",
      4518031000: "북면",
      4518032000: "입암면",
      4518033000: "소성면",
      4518034000: "고부면",
      4518035000: "영원면",
      4518036000: "덕천면",
      4518037000: "이평면",
      4518038000: "정우면",
      4518039000: "태인면",
      4518040000: "감곡면",
      4518041000: "옹동면",
      4518042000: "칠보면",
      4518043000: "산내면",
      4518044000: "산외면",
    },
    4519000000: {
      4519000000: "전체",
      4519010100: "동충동",
      4519010200: "하정동",
      4519010300: "죽항동",
      4519010400: "쌍교동",
      4519010500: "천거동",
      4519010600: "금동",
      4519010700: "조산동",
      4519010800: "왕정동",
      4519010900: "신정동",
      4519011000: "화정동",
      4519011100: "향교동",
      4519011200: "용정동",
      4519011300: "광치동",
      4519011400: "내척동",
      4519011500: "산곡동",
      4519011600: "도통동",
      4519011700: "월락동",
      4519011800: "고죽동",
      4519011900: "식정동",
      4519012000: "갈치동",
      4519012100: "노암동",
      4519012200: "어현동",
      4519012300: "신촌동",
      4519025000: "운봉읍",
      4519031000: "주천면",
      4519032000: "수지면",
      4519033000: "송동면",
      4519034000: "주생면",
      4519035000: "금지면",
      4519036000: "대강면",
      4519037000: "대산면",
      4519038000: "사매면",
      4519039000: "덕과면",
      4519040000: "보절면",
      4519041000: "산동면",
      4519042000: "이백면",
      4519045000: "아영면",
      4519046000: "산내면",
      4519047000: "인월면",
    },
    4521000000: {
      4521000000: "전체",
      4521010100: "요촌동",
      4521010200: "신풍동",
      4521010300: "용동",
      4521010400: "검산동",
      4521010500: "순동",
      4521010600: "백학동",
      4521010700: "서암동",
      4521010800: "신곡동",
      4521010900: "교동",
      4521011000: "옥산동",
      4521011100: "갈공동",
      4521011200: "하동",
      4521011300: "흥사동",
      4521011400: "상동동",
      4521011500: "월성동",
      4521011600: "황산동",
      4521011700: "난봉동",
      4521011800: "오정동",
      4521011900: "복죽동",
      4521012000: "입석동",
      4521012100: "장화동",
      4521012200: "신덕동",
      4521012300: "월봉동",
      4521012400: "신월동",
      4521012500: "연정동",
      4521012600: "명덕동",
      4521012700: "제월동",
      4521012800: "도장동",
      4521012900: "서정동",
      4521013000: "양전동",
      4521025000: "만경읍",
      4521032000: "죽산면",
      4521033000: "백산면",
      4521034000: "용지면",
      4521035000: "백구면",
      4521036000: "부량면",
      4521038000: "공덕면",
      4521039000: "청하면",
      4521040000: "성덕면",
      4521041000: "진봉면",
      4521042000: "금구면",
      4521043000: "봉남면",
      4521044000: "황산면",
      4521045000: "금산면",
      4521046000: "광활면",
    },
    4571000000: {
      4571000000: "전체",
      4571025000: "삼례읍",
      4571025300: "봉동읍",
      4571025600: "용진읍",
      4571032000: "상관면",
      4571033000: "이서면",
      4571034000: "소양면",
      4571035000: "구이면",
      4571036000: "고산면",
      4571037000: "비봉면",
      4571038000: "운주면",
      4571039000: "화산면",
      4571040000: "동상면",
      4571041000: "경천면",
    },
    4572000000: {
      4572000000: "전체",
      4572025000: "진안읍",
      4572031000: "용담면",
      4572032000: "안천면",
      4572033000: "동향면",
      4572034000: "상전면",
      4572035000: "백운면",
      4572036000: "성수면",
      4572037000: "마령면",
      4572038000: "부귀면",
      4572039000: "정천면",
      4572040000: "주천면",
    },
    4573000000: {
      4573000000: "전체",
      4573025000: "무주읍",
      4573031000: "무풍면",
      4573032000: "설천면",
      4573033000: "적상면",
      4573034000: "안성면",
      4573035000: "부남면",
    },
    4574000000: {
      4574000000: "전체",
      4574025000: "장수읍",
      4574031000: "산서면",
      4574032000: "번암면",
      4574033500: "장계면",
      4574034000: "천천면",
      4574035000: "계남면",
      4574036000: "계북면",
    },
    4575000000: {
      4575000000: "전체",
      4575025000: "임실읍",
      4575031000: "청웅면",
      4575032000: "운암면",
      4575033000: "신평면",
      4575034000: "성수면",
      4575035500: "오수면",
      4575036000: "신덕면",
      4575037000: "삼계면",
      4575038000: "관촌면",
      4575039000: "강진면",
      4575040000: "덕치면",
      4575041000: "지사면",
    },
    4577000000: {
      4577000000: "전체",
      4577025000: "순창읍",
      4577031000: "인계면",
      4577032000: "동계면",
      4577033000: "풍산면",
      4577034000: "금과면",
      4577035000: "팔덕면",
      4577036000: "쌍치면",
      4577037000: "복흥면",
      4577038000: "적성면",
      4577039000: "유등면",
      4577040000: "구림면",
    },
    4579000000: {
      4579000000: "전체",
      4579025000: "고창읍",
      4579031000: "고수면",
      4579032000: "아산면",
      4579033000: "무장면",
      4579034000: "공음면",
      4579035000: "상하면",
      4579036000: "해리면",
      4579037000: "성송면",
      4579038000: "대산면",
      4579039000: "심원면",
      4579040000: "흥덕면",
      4579041000: "성내면",
      4579042000: "신림면",
      4579043000: "부안면",
    },
    4580000000: {
      4580000000: "전체",
      4580025000: "부안읍",
      4580031000: "주산면",
      4580032000: "동진면",
      4580033000: "행안면",
      4580034000: "계화면",
      4580035000: "보안면",
      4580036000: "변산면",
      4580037000: "진서면",
      4580038000: "백산면",
      4580039000: "상서면",
      4580040000: "하서면",
      4580041000: "줄포면",
      4580042000: "위도면",
    },
  },
  4600000000: {
    4611000000: {
      4611000000: "전체",
      4611010100: "용당동",
      4611010200: "산정동",
      4611010300: "연산동",
      4611010400: "대성동",
      4611010500: "양동",
      4611010600: "북교동",
      4611010700: "남교동",
      4611010800: "호남동",
      4611010900: "대안동",
      4611011000: "창평동",
      4611011100: "명륜동",
      4611011200: "죽동",
      4611011300: "무안동",
      4611011400: "측후동",
      4611011500: "상락동1가",
      4611011600: "상락동2가",
      4611011700: "복만동",
      4611011800: "동명동",
      4611011900: "광동1가",
      4611012000: "광동2가",
      4611012100: "광동3가",
      4611012200: "영해동1가",
      4611012300: "영해동2가",
      4611012400: "행복동1가",
      4611012500: "행복동2가",
      4611012600: "축복동1가",
      4611012700: "축복동2가",
      4611012800: "축복동3가",
      4611012900: "보광동1가",
      4611013000: "보광동2가",
      4611013100: "보광동3가",
      4611013200: "유달동",
      4611013300: "대의동1가",
      4611013400: "대의동2가",
      4611013500: "대의동3가",
      4611013600: "중앙동1가",
      4611013700: "중앙동2가",
      4611013800: "중앙동3가",
      4611013900: "만호동",
      4611014000: "수강동1가",
      4611014100: "수강동2가",
      4611014200: "해안동1가",
      4611014300: "해안동2가",
      4611014400: "해안동3가",
      4611014500: "해안동4가",
      4611014600: "항동",
      4611014700: "중동1가",
      4611014800: "중동2가",
      4611014900: "유동",
      4611015000: "금동1가",
      4611015100: "금동2가",
      4611015200: "경동1가",
      4611015300: "경동2가",
      4611015400: "서산동",
      4611015500: "금화동",
      4611015600: "온금동",
      4611015700: "죽교동",
      4611015800: "상동",
      4611015900: "용해동",
      4611016000: "석현동",
      4611016100: "달동",
      4611016200: "율도동",
      4611016300: "대양동",
      4611016400: "옥암동",
    },
    4613000000: {
      4613000000: "전체",
      4613010100: "종화동",
      4613010200: "수정동",
      4613010300: "공화동",
      4613010400: "관문동",
      4613010500: "고소동",
      4613010600: "동산동",
      4613010700: "중앙동",
      4613010800: "교동",
      4613010900: "군자동",
      4613011000: "충무동",
      4613011100: "연등동",
      4613011200: "광무동",
      4613011300: "서교동",
      4613011400: "봉강동",
      4613011500: "봉산동",
      4613011600: "남산동",
      4613011700: "국동",
      4613011800: "신월동",
      4613011900: "여서동",
      4613012000: "문수동",
      4613012100: "오림동",
      4613012200: "미평동",
      4613012300: "둔덕동",
      4613012400: "오천동",
      4613012500: "만흥동",
      4613012600: "덕충동",
      4613012700: "경호동",
      4613012800: "학동",
      4613012900: "학용동",
      4613013000: "안산동",
      4613013100: "소호동",
      4613013200: "시전동",
      4613013300: "신기동",
      4613013400: "웅천동",
      4613013500: "선원동",
      4613013600: "여천동",
      4613013700: "화장동",
      4613013800: "주삼동",
      4613013900: "봉계동",
      4613014000: "해산동",
      4613014100: "화치동",
      4613014200: "월하동",
      4613014300: "평여동",
      4613014400: "중흥동",
      4613014500: "적량동",
      4613014600: "월내동",
      4613014700: "묘도동",
      4613014800: "낙포동",
      4613014900: "신덕동",
      4613015000: "상암동",
      4613015100: "호명동",
      4613025000: "돌산읍",
      4613031000: "소라면",
      4613032000: "율촌면",
      4613033000: "화양면",
      4613034000: "남면",
      4613035000: "화정면",
      4613036000: "삼산면",
    },
    4615000000: {
      4615000000: "전체",
      4615010100: "삼거동",
      4615010200: "와룡동",
      4615010300: "영동",
      4615010400: "옥천동",
      4615010500: "행동",
      4615010600: "금곡동",
      4615010700: "매곡동",
      4615010800: "석현동",
      4615010900: "가곡동",
      4615011000: "용당동",
      4615011100: "조곡동",
      4615011200: "생목동",
      4615011300: "덕암동",
      4615011400: "연향동",
      4615011500: "풍덕동",
      4615011600: "남정동",
      4615011700: "인제동",
      4615011800: "저전동",
      4615011900: "장천동",
      4615012000: "남내동",
      4615012100: "중앙동",
      4615012200: "동외동",
      4615012300: "교량동",
      4615012400: "대룡동",
      4615012500: "홍내동",
      4615012600: "오천동",
      4615012700: "덕월동",
      4615012800: "야흥동",
      4615012900: "인월동",
      4615013000: "안풍동",
      4615013100: "대대동",
      4615013200: "왕지동",
      4615013300: "조례동",
      4615025000: "승주읍",
      4615031000: "해룡면",
      4615032000: "서면",
      4615033000: "황전면",
      4615034000: "월등면",
      4615035000: "주암면",
      4615036000: "송광면",
      4615037000: "외서면",
      4615038000: "낙안면",
      4615039000: "별량면",
      4615040000: "상사면",
    },
    4617000000: {
      4617000000: "전체",
      4617010100: "토계동",
      4617010200: "송월동",
      4617010300: "안창동",
      4617010400: "삼영동",
      4617010500: "교동",
      4617010600: "서내동",
      4617010700: "산정동",
      4617010800: "경현동",
      4617010900: "보산동",
      4617011000: "금계동",
      4617011100: "금성동",
      4617011200: "남내동",
      4617011300: "과원동",
      4617011400: "성북동",
      4617011500: "중앙동",
      4617011600: "대호동",
      4617011700: "송촌동",
      4617011800: "석현동",
      4617011900: "청동",
      4617012000: "남외동",
      4617012100: "죽림동",
      4617012200: "삼도동",
      4617012300: "영산동",
      4617012400: "용산동",
      4617012500: "관정동",
      4617012600: "평산동",
      4617012700: "부덕동",
      4617012800: "이창동",
      4617012900: "대기동",
      4617013000: "운곡동",
      4617013100: "동수동",
      4617013200: "오량동",
      4617013300: "진포동",
      4617013400: "빛가람동",
      4617025000: "남평읍",
      4617031000: "세지면",
      4617032000: "왕곡면",
      4617033000: "반남면",
      4617034000: "공산면",
      4617035000: "동강면",
      4617036000: "다시면",
      4617037000: "문평면",
      4617038000: "노안면",
      4617039000: "금천면",
      4617040000: "산포면",
      4617042000: "다도면",
      4617043000: "봉황면",
    },
    4623000000: {
      4623000000: "전체",
      4623010100: "황금동",
      4623010200: "황길동",
      4623010300: "도이동",
      4623010400: "성황동",
      4623010500: "중군동",
      4623010600: "중동",
      4623010700: "마동",
      4623010800: "광영동",
      4623010900: "태인동",
      4623011000: "금호동",
      4623025000: "광양읍",
      4623031000: "봉강면",
      4623032000: "옥룡면",
      4623033000: "옥곡면",
      4623034000: "진상면",
      4623035000: "진월면",
      4623036000: "다압면",
    },
    4671000000: {
      4671000000: "전체",
      4671025000: "담양읍",
      4671031000: "봉산면",
      4671032000: "고서면",
      4671033500: "가사문학면",
      4671034000: "창평면",
      4671035000: "대덕면",
      4671036000: "무정면",
      4671037000: "금성면",
      4671038000: "용면",
      4671039000: "월산면",
      4671040000: "수북면",
      4671041000: "대전면",
    },
    4672000000: {
      4672000000: "전체",
      4672025000: "곡성읍",
      4672031000: "오곡면",
      4672032000: "삼기면",
      4672033000: "석곡면",
      4672034000: "목사동면",
      4672035000: "죽곡면",
      4672036000: "고달면",
      4672037000: "옥과면",
      4672038000: "입면",
      4672039000: "겸면",
      4672040000: "오산면",
    },
    4673000000: {
      4673000000: "전체",
      4673025000: "구례읍",
      4673031000: "문척면",
      4673032000: "간전면",
      4673033000: "토지면",
      4673034000: "마산면",
      4673035000: "광의면",
      4673036000: "용방면",
      4673037000: "산동면",
    },
    4677000000: {
      4677000000: "전체",
      4677025000: "고흥읍",
      4677025300: "도양읍",
      4677031000: "풍양면",
      4677032000: "도덕면",
      4677033000: "금산면",
      4677034000: "도화면",
      4677035000: "포두면",
      4677036000: "봉래면",
      4677037000: "점암면",
      4677038000: "과역면",
      4677039000: "남양면",
      4677040000: "동강면",
      4677041000: "대서면",
      4677042000: "두원면",
      4677044000: "영남면",
      4677045000: "동일면",
    },
    4678000000: {
      4678000000: "전체",
      4678025000: "보성읍",
      4678025300: "벌교읍",
      4678031000: "노동면",
      4678032000: "미력면",
      4678033000: "겸백면",
      4678034000: "율어면",
      4678035000: "복내면",
      4678036000: "문덕면",
      4678037000: "조성면",
      4678038000: "득량면",
      4678039000: "회천면",
      4678040000: "웅치면",
    },
    4679000000: {
      4679000000: "전체",
      4679025000: "화순읍",
      4679031000: "한천면",
      4679032000: "춘양면",
      4679033000: "청풍면",
      4679034000: "이양면",
      4679035000: "능주면",
      4679036000: "도곡면",
      4679037000: "도암면",
      4679038000: "이서면",
      4679039500: "백아면",
      4679040000: "동복면",
      4679041500: "사평면",
      4679042000: "동면",
    },
    4680000000: {
      4680000000: "전체",
      4680025000: "장흥읍",
      4680025300: "관산읍",
      4680025600: "대덕읍",
      4680031000: "용산면",
      4680032000: "안양면",
      4680033000: "장동면",
      4680034000: "장평면",
      4680035000: "유치면",
      4680036000: "부산면",
      4680037000: "회진면",
    },
    4681000000: {
      4681000000: "전체",
      4681025000: "강진읍",
      4681031000: "군동면",
      4681032000: "칠량면",
      4681033000: "대구면",
      4681034000: "도암면",
      4681035000: "신전면",
      4681036000: "성전면",
      4681037000: "작천면",
      4681038000: "병영면",
      4681039000: "옴천면",
      4681040000: "마량면",
    },
    4682000000: {
      4682000000: "전체",
      4682025000: "해남읍",
      4682031000: "삼산면",
      4682032000: "화산면",
      4682033000: "현산면",
      4682034000: "송지면",
      4682035000: "북평면",
      4682036000: "북일면",
      4682037000: "옥천면",
      4682038000: "계곡면",
      4682039000: "마산면",
      4682040000: "황산면",
      4682041000: "산이면",
      4682042000: "문내면",
      4682043000: "화원면",
    },
    4683000000: {
      4683000000: "전체",
      4683025000: "영암읍",
      4683025300: "삼호읍",
      4683031000: "덕진면",
      4683032000: "금정면",
      4683033000: "신북면",
      4683034000: "시종면",
      4683035000: "도포면",
      4683036000: "군서면",
      4683037000: "서호면",
      4683038000: "학산면",
      4683039000: "미암면",
    },
    4684000000: {
      4684000000: "전체",
      4684025000: "무안읍",
      4684025300: "일로읍",
      4684025600: "삼향읍",
      4684032000: "몽탄면",
      4684033000: "청계면",
      4684034000: "현경면",
      4684035000: "망운면",
      4684036000: "해제면",
      4684037000: "운남면",
    },
    4686000000: {
      4686000000: "전체",
      4686025000: "함평읍",
      4686031000: "손불면",
      4686032000: "신광면",
      4686033000: "학교면",
      4686034000: "엄다면",
      4686035000: "대동면",
      4686036000: "나산면",
      4686037000: "해보면",
      4686038000: "월야면",
    },
    4687000000: {
      4687000000: "전체",
      4687025000: "영광읍",
      4687025300: "백수읍",
      4687025600: "홍농읍",
      4687031000: "대마면",
      4687032000: "묘량면",
      4687033000: "불갑면",
      4687034000: "군서면",
      4687035000: "군남면",
      4687036000: "염산면",
      4687037000: "법성면",
      4687038000: "낙월면",
    },
    4688000000: {
      4688000000: "전체",
      4688025000: "장성읍",
      4688031000: "진원면",
      4688032000: "남면",
      4688033000: "동화면",
      4688034000: "삼서면",
      4688035000: "삼계면",
      4688036000: "황룡면",
      4688037000: "서삼면",
      4688038000: "북일면",
      4688039000: "북이면",
      4688040000: "북하면",
    },
    4689000000: {
      4689000000: "전체",
      4689025000: "완도읍",
      4689025300: "금일읍",
      4689025600: "노화읍",
      4689031000: "군외면",
      4689032000: "신지면",
      4689033000: "고금면",
      4689034000: "약산면",
      4689035000: "청산면",
      4689036000: "소안면",
      4689037000: "금당면",
      4689038000: "보길면",
      4689039000: "생일면",
    },
    4690000000: {
      4690000000: "전체",
      4690025000: "진도읍",
      4690031000: "군내면",
      4690032000: "고군면",
      4690033000: "의신면",
      4690034000: "임회면",
      4690035000: "지산면",
      4690036000: "조도면",
    },
    4691000000: {
      4691000000: "전체",
      4691025000: "지도읍",
      4691025300: "압해읍",
      4691031000: "증도면",
      4691032000: "임자면",
      4691033000: "자은면",
      4691034000: "비금면",
      4691035000: "도초면",
      4691036000: "흑산면",
      4691037000: "하의면",
      4691038000: "신의면",
      4691039000: "장산면",
      4691040000: "안좌면",
      4691041000: "팔금면",
      4691042000: "암태면",
    },
  },
  4700000000: {
    4711000000: {
      4711000000: "전체",
      4711100000: "남구",
      4711110100: "남구 상도동",
      4711110200: "남구 대도동",
      4711110300: "남구 해도동",
      4711110400: "남구 송도동",
      4711110500: "남구 청림동",
      4711110600: "남구 일월동",
      4711110700: "남구 송정동",
      4711110800: "남구 송내동",
      4711110900: "남구 괴동동",
      4711111000: "남구 동촌동",
      4711111100: "남구 장흥동",
      4711111200: "남구 인덕동",
      4711111300: "남구 호동",
      4711111400: "남구 효자동",
      4711111500: "남구 지곡동",
      4711111600: "남구 대잠동",
      4711111700: "남구 이동",
      4711125000: "남구 구룡포읍",
      4711125300: "남구 연일읍",
      4711125600: "남구 오천읍",
      4711131000: "남구 대송면",
      4711132000: "남구 동해면",
      4711133000: "남구 장기면",
      4711135000: "남구 호미곶면",
      4711300000: "북구",
      4711310100: "북구 대흥동",
      4711310200: "북구 신흥동",
      4711310300: "북구 남빈동",
      4711310400: "북구 상원동",
      4711310500: "북구 여천동",
      4711310600: "북구 중앙동",
      4711310700: "북구 덕산동",
      4711310800: "북구 덕수동",
      4711310900: "북구 대신동",
      4711311000: "북구 동빈1가",
      4711311100: "북구 동빈2가",
      4711311200: "북구 학산동",
      4711311300: "북구 항구동",
      4711311400: "북구 득량동",
      4711311500: "북구 학잠동",
      4711311600: "북구 죽도동",
      4711311700: "북구 용흥동",
      4711311800: "북구 우현동",
      4711311900: "북구 창포동",
      4711312000: "북구 두호동",
      4711312100: "북구 장성동",
      4711312200: "북구 양덕동",
      4711312300: "북구 환호동",
      4711312400: "북구 여남동",
      4711325000: "북구 흥해읍",
      4711331000: "북구 신광면",
      4711332000: "북구 청하면",
      4711333000: "북구 송라면",
      4711334000: "북구 기계면",
      4711335000: "북구 죽장면",
      4711336000: "북구 기북면",
    },
    4713000000: {
      4713000000: "전체",
      4713010100: "동부동",
      4713010200: "서부동",
      4713010300: "북부동",
      4713010400: "성동동",
      4713010500: "황오동",
      4713010600: "노동동",
      4713010700: "노서동",
      4713010800: "성건동",
      4713010900: "사정동",
      4713011000: "황남동",
      4713011100: "교동",
      4713011200: "인왕동",
      4713011300: "탑동",
      4713011400: "충효동",
      4713011500: "서악동",
      4713011600: "효현동",
      4713011700: "광명동",
      4713011800: "동방동",
      4713011900: "도지동",
      4713012000: "남산동",
      4713012100: "배반동",
      4713012200: "구황동",
      4713012300: "보문동",
      4713012400: "황성동",
      4713012500: "용강동",
      4713012600: "동천동",
      4713012700: "평동",
      4713012800: "조양동",
      4713012900: "시동",
      4713013000: "시래동",
      4713013100: "구정동",
      4713013200: "마동",
      4713013300: "하동",
      4713013400: "진현동",
      4713013500: "천군동",
      4713013600: "신평동",
      4713013700: "덕동",
      4713013800: "암곡동",
      4713013900: "황용동",
      4713014000: "북군동",
      4713014100: "손곡동",
      4713014200: "율동",
      4713014300: "배동",
      4713014400: "석장동",
      4713025000: "감포읍",
      4713025300: "안강읍",
      4713025600: "건천읍",
      4713025900: "외동읍",
      4713031500: "문무대왕면",
      4713032000: "양남면",
      4713033000: "내남면",
      4713034000: "산내면",
      4713035000: "서면",
      4713036000: "현곡면",
      4713037000: "강동면",
      4713038000: "천북면",
    },
    4715000000: {
      4715000000: "전체",
      4715010100: "감호동",
      4715010200: "용두동",
      4715010300: "모암동",
      4715010400: "성내동",
      4715010500: "평화동",
      4715010600: "남산동",
      4715010700: "황금동",
      4715010800: "신음동",
      4715010900: "교동",
      4715011000: "삼락동",
      4715011100: "문당동",
      4715011200: "다수동",
      4715011300: "백옥동",
      4715011400: "부곡동",
      4715011500: "지좌동",
      4715011600: "덕곡동",
      4715011700: "대광동",
      4715011800: "응명동",
      4715011900: "양천동",
      4715012000: "율곡동",
      4715025000: "아포읍",
      4715031000: "농소면",
      4715032000: "남면",
      4715034000: "개령면",
      4715035000: "감문면",
      4715036000: "어모면",
      4715037000: "봉산면",
      4715038000: "대항면",
      4715039000: "감천면",
      4715040000: "조마면",
      4715041000: "구성면",
      4715042000: "지례면",
      4715043000: "부항면",
      4715044000: "대덕면",
      4715045000: "증산면",
    },
    4717000000: {
      4717000000: "전체",
      4717010100: "삼산동",
      4717010200: "서부동",
      4717010300: "북문동",
      4717010400: "명륜동",
      4717010500: "신안동",
      4717010600: "율세동",
      4717010700: "옥정동",
      4717010800: "신세동",
      4717010900: "법흥동",
      4717011000: "용상동",
      4717011100: "동문동",
      4717011200: "동부동",
      4717011300: "운흥동",
      4717011400: "천리동",
      4717011500: "남부동",
      4717011600: "남문동",
      4717011700: "안흥동",
      4717011800: "대석동",
      4717011900: "옥야동",
      4717012000: "광석동",
      4717012100: "당북동",
      4717012200: "태화동",
      4717012300: "화성동",
      4717012400: "목성동",
      4717012500: "법상동",
      4717012600: "금곡동",
      4717012700: "평화동",
      4717012800: "안기동",
      4717012900: "운안동",
      4717013000: "성곡동",
      4717013100: "상아동",
      4717013200: "안막동",
      4717013300: "옥동",
      4717013400: "이천동",
      4717013500: "노하동",
      4717013600: "송현동",
      4717013700: "송천동",
      4717013800: "석동동",
      4717013900: "정상동",
      4717014000: "정하동",
      4717014100: "수상동",
      4717014200: "수하동",
      4717025000: "풍산읍",
      4717031000: "와룡면",
      4717032000: "북후면",
      4717033000: "서후면",
      4717034000: "풍천면",
      4717035000: "일직면",
      4717036000: "남후면",
      4717037000: "남선면",
      4717038000: "임하면",
      4717039000: "길안면",
      4717040000: "임동면",
      4717041000: "예안면",
      4717042000: "도산면",
      4717043000: "녹전면",
    },
    4719000000: {
      4719000000: "전체",
      4719010100: "원평동",
      4719010200: "지산동",
      4719010300: "도량동",
      4719010400: "봉곡동",
      4719010500: "부곡동",
      4719010600: "선기동",
      4719010700: "수점동",
      4719010800: "남통동",
      4719010900: "형곡동",
      4719011000: "송정동",
      4719011100: "신평동",
      4719011200: "비산동",
      4719011300: "공단동",
      4719011400: "광평동",
      4719011500: "사곡동",
      4719011600: "상모동",
      4719011700: "임은동",
      4719011800: "오태동",
      4719011900: "신동",
      4719012000: "구평동",
      4719012100: "황상동",
      4719012200: "인의동",
      4719012300: "진평동",
      4719012400: "시미동",
      4719012500: "임수동",
      4719012600: "양호동",
      4719012700: "거의동",
      4719012800: "옥계동",
      4719012900: "구포동",
      4719013000: "금전동",
      4719025000: "선산읍",
      4719025300: "고아읍",
      4719025600: "산동읍",
      4719031000: "무을면",
      4719032000: "옥성면",
      4719033000: "도개면",
      4719034000: "해평면",
      4719036000: "장천면",
    },
    4721000000: {
      4721000000: "전체",
      4721010100: "영주동",
      4721010200: "상망동",
      4721010300: "하망동",
      4721010400: "휴천동",
      4721010500: "가흥동",
      4721010600: "문정동",
      4721010700: "고현동",
      4721010800: "창진동",
      4721010900: "상줄동",
      4721011000: "조와동",
      4721011100: "조암동",
      4721011200: "적서동",
      4721011300: "아지동",
      4721025000: "풍기읍",
      4721031000: "이산면",
      4721032000: "평은면",
      4721033000: "문수면",
      4721034000: "장수면",
      4721035000: "안정면",
      4721036000: "봉현면",
      4721037000: "순흥면",
      4721038000: "단산면",
      4721039000: "부석면",
    },
    4723000000: {
      4723000000: "전체",
      4723010100: "조교동",
      4723010200: "망정동",
      4723010300: "야사동",
      4723010400: "문내동",
      4723010500: "문외동",
      4723010600: "창구동",
      4723010700: "교촌동",
      4723010800: "과전동",
      4723010900: "성내동",
      4723011000: "화룡동",
      4723011100: "도동",
      4723011200: "금노동",
      4723011300: "완산동",
      4723011400: "범어동",
      4723011500: "작산동",
      4723011600: "봉동",
      4723011700: "본촌동",
      4723011800: "채신동",
      4723011900: "괴연동",
      4723012000: "대전동",
      4723012100: "녹전동",
      4723012200: "도림동",
      4723012300: "오미동",
      4723012400: "오수동",
      4723012500: "쌍계동",
      4723012600: "도남동",
      4723012700: "매산동",
      4723012800: "언하동",
      4723012900: "신기동",
      4723013000: "서산동",
      4723025000: "금호읍",
      4723031000: "청통면",
      4723032000: "신녕면",
      4723033000: "화산면",
      4723034000: "화북면",
      4723035000: "화남면",
      4723036000: "자양면",
      4723037000: "임고면",
      4723038000: "고경면",
      4723039000: "북안면",
      4723040000: "대창면",
    },
    4725000000: {
      4725000000: "전체",
      4725010100: "성하동",
      4725010200: "성동동",
      4725010300: "인봉동",
      4725010400: "복룡동",
      4725010500: "냉림동",
      4725010600: "서성동",
      4725010700: "남성동",
      4725010800: "서문동",
      4725010900: "무양동",
      4725011000: "낙양동",
      4725011100: "개운동",
      4725011200: "신봉동",
      4725011300: "가장동",
      4725011400: "양촌동",
      4725011500: "지천동",
      4725011600: "오대동",
      4725011700: "흥각동",
      4725011800: "거동동",
      4725011900: "인평동",
      4725012000: "서곡동",
      4725012100: "화개동",
      4725012200: "외답동",
      4725012300: "헌신동",
      4725012400: "병성동",
      4725012500: "도남동",
      4725012600: "낙상동",
      4725012700: "중덕동",
      4725012800: "초산동",
      4725012900: "화산동",
      4725013000: "계산동",
      4725013100: "부원동",
      4725013200: "죽전동",
      4725013300: "만산동",
      4725013400: "연원동",
      4725013500: "남장동",
      4725013600: "남적동",
      4725025000: "함창읍",
      4725031000: "중동면",
      4725032500: "사벌국면",
      4725033000: "낙동면",
      4725034000: "청리면",
      4725035000: "공성면",
      4725036000: "외남면",
      4725037000: "내서면",
      4725038000: "모동면",
      4725039000: "모서면",
      4725040000: "화동면",
      4725041000: "화서면",
      4725042000: "화북면",
      4725043000: "외서면",
      4725044000: "은척면",
      4725045000: "공검면",
      4725046000: "이안면",
      4725047000: "화남면",
    },
    4728000000: {
      4728000000: "전체",
      4728010100: "점촌동",
      4728010200: "영신동",
      4728010300: "흥덕동",
      4728010400: "우지동",
      4728010500: "창동",
      4728010600: "신기동",
      4728010700: "불정동",
      4728010800: "유곡동",
      4728010900: "공평동",
      4728011000: "모전동",
      4728011100: "윤직동",
      4728025000: "문경읍",
      4728025300: "가은읍",
      4728031000: "영순면",
      4728032000: "산양면",
      4728033000: "호계면",
      4728034000: "산북면",
      4728035000: "동로면",
      4728036000: "마성면",
      4728037000: "농암면",
    },
    4729000000: {
      4729000000: "전체",
      4729010100: "삼남동",
      4729010200: "삼북동",
      4729010300: "서상동",
      4729010400: "신교동",
      4729010500: "상방동",
      4729010600: "백천동",
      4729010700: "옥곡동",
      4729010800: "사정동",
      4729010900: "옥산동",
      4729011000: "중산동",
      4729011100: "정평동",
      4729011200: "대평동",
      4729011300: "대정동",
      4729011400: "임당동",
      4729011500: "대동",
      4729011600: "계양동",
      4729011700: "중방동",
      4729011800: "조영동",
      4729011900: "남방동",
      4729012000: "내동",
      4729012100: "여천동",
      4729012200: "유곡동",
      4729012300: "신천동",
      4729012400: "점촌동",
      4729012500: "평산동",
      4729012600: "사동",
      4729012700: "삼풍동",
      4729012800: "갑제동",
      4729025000: "하양읍",
      4729025300: "진량읍",
      4729025600: "압량읍",
      4729031000: "와촌면",
      4729033000: "자인면",
      4729034000: "용성면",
      4729035000: "남산면",
      4729037000: "남천면",
    },
    4772000000: {
      4772000000: "전체",
      4772025000: "군위읍",
      4772031000: "소보면",
      4772032000: "효령면",
      4772033000: "부계면",
      4772034000: "우보면",
      4772035000: "의흥면",
      4772036000: "산성면",
      4772038000: "삼국유사면",
    },
    4773000000: {
      4773000000: "전체",
      4773025000: "의성읍",
      4773031000: "단촌면",
      4773032000: "점곡면",
      4773033000: "옥산면",
      4773034000: "사곡면",
      4773035000: "춘산면",
      4773036000: "가음면",
      4773037000: "금성면",
      4773038000: "봉양면",
      4773039000: "비안면",
      4773040000: "구천면",
      4773041000: "단밀면",
      4773042000: "단북면",
      4773043000: "안계면",
      4773044000: "다인면",
      4773045000: "신평면",
      4773046000: "안평면",
      4773047000: "안사면",
    },
    4775000000: {
      4775000000: "전체",
      4775025000: "청송읍",
      4775031500: "주왕산면",
      4775032000: "부남면",
      4775033000: "현동면",
      4775034000: "현서면",
      4775035000: "안덕면",
      4775036000: "파천면",
      4775037000: "진보면",
    },
    4776000000: {
      4776000000: "전체",
      4776025000: "영양읍",
      4776031000: "입암면",
      4776032000: "청기면",
      4776033000: "일월면",
      4776034000: "수비면",
      4776035000: "석보면",
    },
    4777000000: {
      4777000000: "전체",
      4777025000: "영덕읍",
      4777031000: "강구면",
      4777032000: "남정면",
      4777033000: "달산면",
      4777034000: "지품면",
      4777035000: "축산면",
      4777036000: "영해면",
      4777037000: "병곡면",
      4777038000: "창수면",
    },
    4782000000: {
      4782000000: "전체",
      4782025000: "화양읍",
      4782025300: "청도읍",
      4782031000: "각남면",
      4782032000: "풍각면",
      4782033000: "각북면",
      4782034000: "이서면",
      4782035000: "운문면",
      4782036000: "금천면",
      4782037000: "매전면",
    },
    4783000000: {
      4783000000: "전체",
      4783025300: "대가야읍",
      4783031000: "덕곡면",
      4783032000: "운수면",
      4783033000: "성산면",
      4783034000: "다산면",
      4783035000: "개진면",
      4783036000: "우곡면",
      4783037000: "쌍림면",
    },
    4784000000: {
      4784000000: "전체",
      4784025000: "성주읍",
      4784031000: "선남면",
      4784032000: "용암면",
      4784033000: "수륜면",
      4784034000: "가천면",
      4784035000: "금수면",
      4784036000: "대가면",
      4784037000: "벽진면",
      4784038000: "초전면",
      4784039000: "월항면",
    },
    4785000000: {
      4785000000: "전체",
      4785025000: "왜관읍",
      4785025300: "북삼읍",
      4785025600: "석적읍",
      4785031000: "지천면",
      4785032000: "동명면",
      4785033000: "가산면",
      4785036000: "약목면",
      4785037000: "기산면",
    },
    4790000000: {
      4790000000: "전체",
      4790025000: "예천읍",
      4790031000: "용문면",
      4790034000: "감천면",
      4790035000: "보문면",
      4790036000: "호명면",
      4790037000: "유천면",
      4790038000: "용궁면",
      4790039000: "개포면",
      4790040000: "지보면",
      4790041000: "풍양면",
      4790042000: "효자면",
      4790043000: "은풍면",
    },
    4792000000: {
      4792000000: "전체",
      4792025000: "봉화읍",
      4792031000: "물야면",
      4792032000: "봉성면",
      4792033000: "법전면",
      4792034000: "춘양면",
      4792035000: "소천면",
      4792036000: "재산면",
      4792037000: "명호면",
      4792038000: "상운면",
      4792039000: "석포면",
    },
    4793000000: {
      4793000000: "전체",
      4793025000: "울진읍",
      4793025300: "평해읍",
      4793031000: "북면",
      4793033000: "근남면",
      4793035000: "기성면",
      4793036000: "온정면",
      4793037000: "죽변면",
      4793038000: "후포면",
      4793039000: "금강송면",
      4793040000: "매화면",
    },
    4794000000: {
      4794000000: "전체",
      4794025000: "울릉읍",
      4794031000: "서면",
      4794032000: "북면",
    },
  },
  4800000000: {
    4812000000: {
      4812000000: "전체",
      4812100000: "의창구",
      4812110100: "의창구 북동",
      4812110200: "의창구 중동",
      4812110300: "의창구 서상동",
      4812110400: "의창구 소답동",
      4812110500: "의창구 도계동",
      4812110600: "의창구 동정동",
      4812110700: "의창구 소계동",
      4812110800: "의창구 용동",
      4812111200: "의창구 덕정동",
      4812111300: "의창구 지귀동",
      4812111400: "의창구 서곡동",
      4812111600: "의창구 봉림동",
      4812111700: "의창구 퇴촌동",
      4812111800: "의창구 명곡동",
      4812111900: "의창구 반계동",
      4812112000: "의창구 사화동",
      4812112100: "의창구 차용동",
      4812112200: "의창구 내리동",
      4812112400: "의창구 명서동",
      4812112500: "의창구 사림동",
      4812112700: "의창구 봉곡동",
      4812112900: "의창구 팔용동",
      4812125000: "의창구 동읍",
      4812131000: "의창구 북면",
      4812132000: "의창구 대산면",
      4812300000: "성산구",
      4812310100: "성산구 토월동",
      4812310200: "성산구 사파정동",
      4812310300: "성산구 가음정동",
      4812310400: "성산구 외동",
      4812310500: "성산구 대방동",
      4812310600: "성산구 남산동",
      4812310700: "성산구 삼정자동",
      4812310800: "성산구 천선동",
      4812310900: "성산구 불모산동",
      4812311000: "성산구 안민동",
      4812311100: "성산구 내동",
      4812311200: "성산구 남지동",
      4812311300: "성산구 상복동",
      4812311400: "성산구 완암동",
      4812311500: "성산구 창곡동",
      4812311600: "성산구 월림동",
      4812311700: "성산구 적현동",
      4812311800: "성산구 양곡동",
      4812311900: "성산구 반송동",
      4812312000: "성산구 귀산동",
      4812312100: "성산구 귀곡동",
      4812312200: "성산구 귀현동",
      4812312300: "성산구 신촌동",
      4812312400: "성산구 반지동",
      4812312500: "성산구 중앙동",
      4812312600: "성산구 반림동",
      4812312700: "성산구 상남동",
      4812312800: "성산구 성주동",
      4812312900: "성산구 웅남동",
      4812313000: "성산구 사파동",
      4812313100: "성산구 가음동",
      4812313200: "성산구 성산동",
      4812313300: "성산구 남양동",
      4812313400: "성산구 용지동",
      4812313500: "성산구 용호동",
      4812313600: "성산구 신월동",
      4812313700: "성산구 대원동",
      4812313800: "성산구 두대동",
      4812313900: "성산구 삼동동",
      4812314000: "성산구 덕정동",
      4812314100: "성산구 퇴촌동",
      4812500000: "마산합포구",
      4812510100: "마산합포구 가포동",
      4812510200: "마산합포구 교방동",
      4812510300: "마산합포구 교원동",
      4812510400: "마산합포구 남성동",
      4812510500: "마산합포구 대내동",
      4812510600: "마산합포구 대성동1가",
      4812510700: "마산합포구 대성동2가",
      4812510800: "마산합포구 대외동",
      4812510900: "마산합포구 대창동",
      4812511000: "마산합포구 덕동동",
      4812511100: "마산합포구 동성동",
      4812511200: "마산합포구 두월동1가",
      4812511300: "마산합포구 두월동2가",
      4812511400: "마산합포구 두월동3가",
      4812511500: "마산합포구 문화동",
      4812511600: "마산합포구 반월동",
      4812511700: "마산합포구 부림동",
      4812511800: "마산합포구 산호동",
      4812511900: "마산합포구 상남동",
      4812512000: "마산합포구 서성동",
      4812512100: "마산합포구 성호동",
      4812512200: "마산합포구 수성동",
      4812512300: "마산합포구 신월동",
      4812512400: "마산합포구 신창동",
      4812512500: "마산합포구 신포동1가",
      4812512600: "마산합포구 신포동2가",
      4812512700: "마산합포구 신흥동",
      4812512800: "마산합포구 완월동",
      4812512900: "마산합포구 월남동1가",
      4812513000: "마산합포구 월남동2가",
      4812513100: "마산합포구 월남동3가",
      4812513200: "마산합포구 월남동4가",
      4812513300: "마산합포구 월남동5가",
      4812513400: "마산합포구 월영동",
      4812513500: "마산합포구 월포동",
      4812513600: "마산합포구 예곡동",
      4812513700: "마산합포구 오동동",
      4812513800: "마산합포구 우산동",
      4812513900: "마산합포구 유록동",
      4812514000: "마산합포구 자산동",
      4812514100: "마산합포구 장군동1가",
      4812514200: "마산합포구 장군동2가",
      4812514300: "마산합포구 장군동3가",
      4812514400: "마산합포구 장군동4가",
      4812514500: "마산합포구 장군동5가",
      4812514600: "마산합포구 중성동",
      4812514700: "마산합포구 중앙동1가",
      4812514800: "마산합포구 중앙동2가",
      4812514900: "마산합포구 중앙동3가",
      4812515000: "마산합포구 창동",
      4812515100: "마산합포구 창포동1가",
      4812515200: "마산합포구 창포동2가",
      4812515300: "마산합포구 창포동3가",
      4812515400: "마산합포구 청계동",
      4812515500: "마산합포구 추산동",
      4812515600: "마산합포구 평화동",
      4812515700: "마산합포구 화영동",
      4812515800: "마산합포구 해운동",
      4812515900: "마산합포구 현동",
      4812516000: "마산합포구 홍문동",
      4812531000: "마산합포구 구산면",
      4812532000: "마산합포구 진동면",
      4812533000: "마산합포구 진북면",
      4812534000: "마산합포구 진전면",
      4812700000: "마산회원구",
      4812710100: "마산회원구 구암동",
      4812710200: "마산회원구 두척동",
      4812710300: "마산회원구 봉암동",
      4812710400: "마산회원구 석전동",
      4812710500: "마산회원구 양덕동",
      4812710600: "마산회원구 합성동",
      4812710700: "마산회원구 회성동",
      4812710800: "마산회원구 회원동",
      4812725000: "마산회원구 내서읍",
      4812900000: "진해구",
      4812910100: "진해구 동상동",
      4812910200: "진해구 도천동",
      4812910300: "진해구 도만동",
      4812910400: "진해구 신흥동",
      4812910500: "진해구 현동",
      4812910600: "진해구 비봉동",
      4812910700: "진해구 태평동",
      4812910800: "진해구 충의동",
      4812910900: "진해구 무송동",
      4812911000: "진해구 인의동",
      4812911100: "진해구 숭인동",
      4812911200: "진해구 대영동",
      4812911300: "진해구 남빈동",
      4812911400: "진해구 앵곡동",
      4812911500: "진해구 제황산동",
      4812911600: "진해구 속천동",
      4812911700: "진해구 대죽동",
      4812911800: "진해구 안곡동",
      4812911900: "진해구 수송동",
      4812912000: "진해구 회현동",
      4812912100: "진해구 익선동",
      4812912200: "진해구 창선동",
      4812912300: "진해구 대천동",
      4812912400: "진해구 광화동",
      4812912500: "진해구 통신동",
      4812912600: "진해구 중앙동",
      4812912700: "진해구 부흥동",
      4812912800: "진해구 중평동",
      4812912900: "진해구 근화동",
      4812913000: "진해구 송죽동",
      4812913100: "진해구 화천동",
      4812913200: "진해구 송학동",
      4812913300: "진해구 대흥동",
      4812913400: "진해구 평안동",
      4812913500: "진해구 충무동",
      4812913600: "진해구 인사동",
      4812913700: "진해구 여좌동",
      4812913800: "진해구 태백동",
      4812913900: "진해구 경화동",
      4812914000: "진해구 석동",
      4812914100: "진해구 이동",
      4812914200: "진해구 자은동",
      4812914300: "진해구 덕산동",
      4812914400: "진해구 풍호동",
      4812914500: "진해구 장천동",
      4812914600: "진해구 행암동",
      4812914700: "진해구 북부동",
      4812914800: "진해구 성내동",
      4812914900: "진해구 서중동",
      4812915000: "진해구 남문동",
      4812915100: "진해구 제덕동",
      4812915200: "진해구 수도동",
      4812915300: "진해구 연도동",
      4812915400: "진해구 명동",
      4812915500: "진해구 죽곡동",
      4812915600: "진해구 원포동",
      4812915700: "진해구 남양동",
      4812915800: "진해구 마천동",
      4812915900: "진해구 소사동",
      4812916000: "진해구 대장동",
      4812916100: "진해구 두동",
      4812916200: "진해구 청안동",
      4812916300: "진해구 안골동",
      4812916400: "진해구 용원동",
      4812916500: "진해구 가주동",
    },
    4817000000: {
      4817000000: "전체",
      4817010100: "망경동",
      4817010200: "주약동",
      4817010300: "강남동",
      4817010400: "칠암동",
      4817010500: "본성동",
      4817010600: "동성동",
      4817010700: "남성동",
      4817010800: "인사동",
      4817010900: "대안동",
      4817011000: "평안동",
      4817011100: "중안동",
      4817011200: "계동",
      4817011300: "봉곡동",
      4817011400: "상봉동",
      4817011500: "봉래동",
      4817011600: "수정동",
      4817011700: "장대동",
      4817011800: "옥봉동",
      4817011900: "상대동",
      4817012000: "하대동",
      4817012100: "상평동",
      4817012200: "초전동",
      4817012300: "장재동",
      4817012400: "하촌동",
      4817012500: "신안동",
      4817012600: "평거동",
      4817012700: "이현동",
      4817012800: "유곡동",
      4817012900: "판문동",
      4817013000: "귀곡동",
      4817013100: "가좌동",
      4817013200: "호탄동",
      4817013700: "충무공동",
      4817025000: "문산읍",
      4817031000: "내동면",
      4817032000: "정촌면",
      4817033000: "금곡면",
      4817035000: "진성면",
      4817036000: "일반성면",
      4817037000: "이반성면",
      4817038000: "사봉면",
      4817039000: "지수면",
      4817040000: "대곡면",
      4817041000: "금산면",
      4817042000: "집현면",
      4817043000: "미천면",
      4817044000: "명석면",
      4817045000: "대평면",
      4817046000: "수곡면",
    },
    4822000000: {
      4822000000: "전체",
      4822010100: "도천동",
      4822010200: "서호동",
      4822010300: "명정동",
      4822010400: "항남동",
      4822010500: "중앙동",
      4822010600: "문화동",
      4822010700: "태평동",
      4822010800: "동호동",
      4822010900: "정량동",
      4822011000: "북신동",
      4822011100: "무전동",
      4822011200: "평림동",
      4822011300: "인평동",
      4822011400: "당동",
      4822011500: "미수동",
      4822011600: "봉평동",
      4822011700: "도남동",
      4822025000: "산양읍",
      4822031000: "용남면",
      4822033000: "도산면",
      4822034000: "광도면",
      4822035000: "욕지면",
      4822036000: "한산면",
      4822037000: "사량면",
    },
    4824000000: {
      4824000000: "전체",
      4824010100: "동동",
      4824010200: "서동",
      4824010300: "선구동",
      4824010400: "동금동",
      4824010500: "서금동",
      4824010600: "동림동",
      4824010700: "좌룡동",
      4824010800: "벌리동",
      4824010900: "용강동",
      4824011000: "와룡동",
      4824011100: "봉남동",
      4824011200: "이금동",
      4824011300: "이홀동",
      4824011400: "궁지동",
      4824011500: "사등동",
      4824011600: "향촌동",
      4824011700: "대방동",
      4824011800: "실안동",
      4824011900: "마도동",
      4824012000: "늑도동",
      4824012100: "신수동",
      4824012200: "백천동",
      4824012300: "신벽동",
      4824012400: "노룡동",
      4824012500: "대포동",
      4824012600: "송포동",
      4824012700: "죽림동",
      4824025000: "사천읍",
      4824031000: "정동면",
      4824032000: "사남면",
      4824033000: "용현면",
      4824034000: "축동면",
      4824035000: "곤양면",
      4824036000: "곤명면",
      4824037000: "서포면",
    },
    4825000000: {
      4825000000: "전체",
      4825010100: "동상동",
      4825010200: "서상동",
      4825010300: "부원동",
      4825010400: "봉황동",
      4825010500: "대성동",
      4825010600: "구산동",
      4825010700: "삼계동",
      4825010800: "내동",
      4825010900: "외동",
      4825011000: "흥동",
      4825011100: "풍유동",
      4825011200: "명법동",
      4825011300: "이동",
      4825011400: "화목동",
      4825011500: "전하동",
      4825011600: "강동",
      4825011700: "삼정동",
      4825011800: "어방동",
      4825011900: "삼방동",
      4825012000: "안동",
      4825012100: "지내동",
      4825012200: "불암동",
      4825012300: "유하동",
      4825012400: "내덕동",
      4825012500: "부곡동",
      4825012600: "무계동",
      4825012700: "신문동",
      4825012800: "삼문동",
      4825012900: "대청동",
      4825013000: "관동동",
      4825013100: "율하동",
      4825013200: "장유동",
      4825013300: "응달동",
      4825013400: "수가동",
      4825025000: "진영읍",
      4825032000: "주촌면",
      4825033000: "진례면",
      4825034000: "한림면",
      4825035000: "생림면",
      4825036000: "상동면",
      4825037000: "대동면",
    },
    4827000000: {
      4827000000: "전체",
      4827010100: "내일동",
      4827010200: "내이동",
      4827010300: "교동",
      4827010400: "삼문동",
      4827010500: "남포동",
      4827010600: "용평동",
      4827010700: "활성동",
      4827010800: "가곡동",
      4827025000: "삼랑진읍",
      4827025300: "하남읍",
      4827031000: "부북면",
      4827032000: "상동면",
      4827033000: "산외면",
      4827034000: "산내면",
      4827035000: "단장면",
      4827036000: "상남면",
      4827037000: "초동면",
      4827038000: "무안면",
      4827039000: "청도면",
    },
    4831000000: {
      4831000000: "전체",
      4831010100: "능포동",
      4831010200: "장승포동",
      4831010300: "두모동",
      4831010400: "아양동",
      4831010500: "아주동",
      4831010600: "옥포동",
      4831010700: "덕포동",
      4831010800: "장평동",
      4831010900: "고현동",
      4831011000: "상동동",
      4831011100: "문동동",
      4831011200: "삼거동",
      4831011300: "양정동",
      4831011400: "수월동",
      4831031000: "일운면",
      4831032000: "동부면",
      4831033000: "남부면",
      4831034000: "거제면",
      4831035000: "둔덕면",
      4831036000: "사등면",
      4831037000: "연초면",
      4831038000: "하청면",
      4831039000: "장목면",
    },
    4833000000: {
      4833000000: "전체",
      4833010100: "다방동",
      4833010200: "남부동",
      4833010300: "중부동",
      4833010400: "북부동",
      4833010500: "명곡동",
      4833010600: "신기동",
      4833010700: "북정동",
      4833010800: "산막동",
      4833010900: "호계동",
      4833011000: "교동",
      4833011100: "유산동",
      4833011200: "어곡동",
      4833011300: "용당동",
      4833011400: "삼호동",
      4833011500: "명동",
      4833011600: "주남동",
      4833011700: "소주동",
      4833011800: "주진동",
      4833011900: "평산동",
      4833012000: "덕계동",
      4833012100: "매곡동",
      4833025300: "물금읍",
      4833031000: "동면",
      4833032000: "원동면",
      4833033000: "상북면",
      4833034000: "하북면",
    },
    4872000000: {
      4872000000: "전체",
      4872025000: "의령읍",
      4872031000: "가례면",
      4872032000: "칠곡면",
      4872033000: "대의면",
      4872034000: "화정면",
      4872035000: "용덕면",
      4872036000: "정곡면",
      4872037000: "지정면",
      4872038000: "낙서면",
      4872039000: "부림면",
      4872040000: "봉수면",
      4872041500: "궁류면",
      4872042000: "유곡면",
    },
    4873000000: {
      4873000000: "전체",
      4873025000: "가야읍",
      4873025300: "칠원읍",
      4873031000: "함안면",
      4873032000: "군북면",
      4873033000: "법수면",
      4873034000: "대산면",
      4873035000: "칠서면",
      4873036000: "칠북면",
      4873038000: "산인면",
      4873039000: "여항면",
    },
    4874000000: {
      4874000000: "전체",
      4874025000: "창녕읍",
      4874025300: "남지읍",
      4874031000: "고암면",
      4874032000: "성산면",
      4874033000: "대합면",
      4874034000: "이방면",
      4874035000: "유어면",
      4874036000: "대지면",
      4874037000: "계성면",
      4874038000: "영산면",
      4874039000: "장마면",
      4874040000: "도천면",
      4874041000: "길곡면",
      4874042000: "부곡면",
    },
    4882000000: {
      4882000000: "전체",
      4882025000: "고성읍",
      4882031000: "삼산면",
      4882032000: "하일면",
      4882033000: "하이면",
      4882034000: "상리면",
      4882035000: "대가면",
      4882036000: "영현면",
      4882037000: "영오면",
      4882038000: "개천면",
      4882039000: "구만면",
      4882040000: "회화면",
      4882041000: "마암면",
      4882042000: "동해면",
      4882043000: "거류면",
    },
    4884000000: {
      4884000000: "전체",
      4884025000: "남해읍",
      4884031000: "이동면",
      4884032000: "상주면",
      4884033000: "삼동면",
      4884034000: "미조면",
      4884035000: "남면",
      4884036000: "서면",
      4884037000: "고현면",
      4884038000: "설천면",
      4884039000: "창선면",
    },
    4885000000: {
      4885000000: "전체",
      4885025000: "하동읍",
      4885031000: "화개면",
      4885032000: "악양면",
      4885033000: "적량면",
      4885034000: "횡천면",
      4885035000: "고전면",
      4885036000: "금남면",
      4885037000: "진교면",
      4885038000: "양보면",
      4885039000: "북천면",
      4885040000: "청암면",
      4885041000: "옥종면",
      4885042000: "금성면",
    },
    4886000000: {
      4886000000: "전체",
      4886025000: "산청읍",
      4886031000: "차황면",
      4886032000: "오부면",
      4886033000: "생초면",
      4886034000: "금서면",
      4886035000: "삼장면",
      4886036000: "시천면",
      4886037000: "단성면",
      4886038000: "신안면",
      4886039000: "생비량면",
      4886040000: "신등면",
    },
    4887000000: {
      4887000000: "전체",
      4887025000: "함양읍",
      4887031000: "마천면",
      4887032000: "휴천면",
      4887033000: "유림면",
      4887034000: "수동면",
      4887035000: "지곡면",
      4887036000: "안의면",
      4887037000: "서하면",
      4887038000: "서상면",
      4887039000: "백전면",
      4887040000: "병곡면",
    },
    4888000000: {
      4888000000: "전체",
      4888025000: "거창읍",
      4888031000: "주상면",
      4888032000: "웅양면",
      4888033000: "고제면",
      4888034000: "북상면",
      4888035000: "위천면",
      4888036000: "마리면",
      4888037000: "남상면",
      4888038000: "남하면",
      4888039000: "신원면",
      4888040000: "가조면",
      4888041000: "가북면",
    },
    4889000000: {
      4889000000: "전체",
      4889025000: "합천읍",
      4889031000: "봉산면",
      4889032000: "묘산면",
      4889033000: "가야면",
      4889034000: "야로면",
      4889035000: "율곡면",
      4889036000: "초계면",
      4889037000: "쌍책면",
      4889038000: "덕곡면",
      4889039000: "청덕면",
      4889040000: "적중면",
      4889041000: "대양면",
      4889042000: "쌍백면",
      4889043000: "삼가면",
      4889044000: "가회면",
      4889045000: "대병면",
      4889046000: "용주면",
    },
  },
  5000000000: {
    5011000000: {
      5011000000: "전체",
      5011010100: "일도일동",
      5011010200: "일도이동",
      5011010300: "이도일동",
      5011010400: "이도이동",
      5011010500: "삼도일동",
      5011010600: "삼도이동",
      5011010700: "건입동",
      5011010800: "용담일동",
      5011010900: "용담이동",
      5011011000: "용담삼동",
      5011011100: "화북일동",
      5011011200: "화북이동",
      5011011300: "삼양일동",
      5011011400: "삼양이동",
      5011011500: "삼양삼동",
      5011011600: "봉개동",
      5011011700: "아라일동",
      5011011800: "아라이동",
      5011011900: "오라일동",
      5011012000: "오라이동",
      5011012100: "오라삼동",
      5011012200: "노형동",
      5011012300: "외도일동",
      5011012400: "외도이동",
      5011012500: "이호일동",
      5011012600: "이호이동",
      5011012700: "도두일동",
      5011012800: "도두이동",
      5011012900: "도남동",
      5011013000: "도련일동",
      5011013100: "도련이동",
      5011013200: "용강동",
      5011013300: "회천동",
      5011013400: "오등동",
      5011013500: "월평동",
      5011013600: "영평동",
      5011013700: "연동",
      5011013800: "도평동",
      5011013900: "해안동",
      5011014000: "내도동",
      5011025000: "한림읍",
      5011025300: "애월읍",
      5011025600: "구좌읍",
      5011025900: "조천읍",
      5011031000: "한경면",
      5011032000: "추자면",
      5011033000: "우도면",
    },
    5013000000: {
      5013000000: "전체",
      5013010100: "서귀동",
      5013010200: "법환동",
      5013010300: "서호동",
      5013010400: "호근동",
      5013010500: "동홍동",
      5013010600: "서홍동",
      5013010700: "상효동",
      5013010800: "하효동",
      5013010900: "신효동",
      5013011000: "보목동",
      5013011100: "토평동",
      5013011200: "중문동",
      5013011300: "회수동",
      5013011400: "대포동",
      5013011500: "월평동",
      5013011600: "강정동",
      5013011700: "도순동",
      5013011800: "하원동",
      5013011900: "색달동",
      5013012000: "상예동",
      5013012100: "하예동",
      5013012200: "영남동",
      5013025000: "대정읍",
      5013025300: "남원읍",
      5013025900: "성산읍",
      5013031000: "안덕면",
      5013032000: "표선면",
    },
  },
};
