import { put, takeLatest, call } from "redux-saga/effects";
import dayjs from "dayjs";

import * as dashboardAction from "store/reducer/dashboardSlice";
import * as toastAction from "store/reducer/toastSlice";
import * as API from "../api/service/dashboardApi";
import { AxiosResponse } from "axios";
import { status } from "assets/static/status";
import { downloadExcel } from "utils/helper/excelDown";

import type {
  LiveNUncollectedParams,
  LiveTableList,
  UncollectedTableList,
} from "types/dashboard";
import { formatYYYYMMDD, formatPhoneWithDash } from "utils/helper/formatter";

interface Action<T> {
  payload: T;
  type: string;
}

function* liveSaga(action: Action<LiveNUncollectedParams>) {
  try {
    const { data }: AxiosResponse = yield call(API.fetchLive, action.payload);

    const dataList = data.list.map((item: LiveTableList) => {
      for (const key in item) {
        switch (key) {
          case "delayTime":
            if(item.delayTime === '-') break;
            const delayTimeH = item.delayTime.split(":")[0];
            const delayTimeM = item.delayTime.split(":")[1];
            item.delayTime = `${delayTimeH}h ${delayTimeM}m`;
            break;
          case "reorderCnt":
            if (typeof item.reorderCnt === "string") {
              item.reorderCnt = parseInt(item.reorderCnt);
            }
            break;
          case "time":
            const h = item.time.split(":")[0];
            const m = item.time.split(":")[1];
            item.time = `${h}:${m}`;
            break;

          case "pickedupAt":
          case "returnDate":
            item[key] = item[key] ? dayjs(item[key]).format("YYYY-MM-DD") : "-";
            break;

          case "status":
            item.status = status[item.status];
            break;

          default:
            !item[key] && (item[key] = "-");
        }
      }

      return item;
    });

    yield put({
      type: dashboardAction.liveSuccess,
      payload: { list: dataList, pageInfo: data.pageInfo },
    });
  } catch (err) {
    yield put({
      type: dashboardAction.liveFailure,
      payload: err,
    });
  }
}

export function* watchLive() {
  yield takeLatest(dashboardAction.live.type, liveSaga);
}

function* liveExcelSaga(action: Action<LiveNUncollectedParams>) {
  try {
    const { data }: AxiosResponse = yield call(API.fetchLive, action.payload);

    const excelData = data.list.map((item: any) => {
      const obj = { ...item };
      obj.containers = obj.containers[0];
      obj.pickedupAt = formatYYYYMMDD(obj.pickedupAt);
      obj.returnDate = formatYYYYMMDD(obj.returnDate);
      obj.phone = formatPhoneWithDash(obj.phone);

      return obj;
    });

    downloadExcel(excelData, "live");

    yield put({ type: dashboardAction.liveExcelSuccess });
  } catch (err) {
    yield put({
      type: dashboardAction.liveExcelFailure,
      payload: err,
    });
  }
}

export function* watchLiveExcel() {
  yield takeLatest(dashboardAction.liveExcel.type, liveExcelSaga);
}

function* uncollectedSaga(action: Action<LiveNUncollectedParams>) {
  try {
    const { data }: AxiosResponse = yield call(
      API.fetchLivePhone,
      action.payload,
    );

    const dataList = data.list.map((item: UncollectedTableList) => {
      for (const key in item) {
        switch (key) {
          case "delayTime":
            if(item.delayTime === '-') break;
            const delayTimeH = item.delayTime.split(":")[0];
            const delayTimeM = item.delayTime.split(":")[1];
            item.delayTime = `${delayTimeH}h ${delayTimeM}m`;
            break;

          case "time":
            const timeH = item.time.split(":")[0];
            const timeM = item.time.split(":")[1];
            item.time = `${timeH}:${timeM}`;
            break;

          case "pickedupAt":
          case "returnDate":
            item[key] = item[key] ? dayjs(item[key]).format("YYYY-MM-DD") : "-";
            break;

          case "status":
            item.status = status[item.status];
            break;

          default:
            !item[key] && (item[key] = "-");
        }
      }

      return item;
    });

    yield put({
      type: dashboardAction.uncollectedSuccess,
      payload: {
        list: dataList,
        pageInfo: data.pageInfo,
      },
    });
  } catch (err) {
    yield put({
      type: dashboardAction.uncollectedFailure,
      payload: err,
    });
  }
}

export function* watchUncollected() {
  yield takeLatest(dashboardAction.uncollected.type, uncollectedSaga);
}

function* uncollectedExcelSaga(action: Action<LiveNUncollectedParams>) {
  try {
    const { data }: AxiosResponse = yield call(
      API.fetchUncollected,
      action.payload,
    );

    const excelData = data.list.map((item: any) => {
      const obj = { ...item };
      obj.phone = formatPhoneWithDash(obj.phone);

      return obj;
    });

    downloadExcel(excelData, "uncollected");

    yield put({ type: dashboardAction.uncollectedExcelSuccess });
  } catch (err) {
    yield put({ type: dashboardAction.uncollectedExcelFailure, payload: err });
  }
}

export function* watchUncollectedExcel() {
  yield takeLatest(dashboardAction.uncollectedExcel.type, uncollectedExcelSaga);
}

function* changeStatusSaga(
  action: Action<{ orderid: string; status: string }>,
) {
  try {
    yield call(API.fetchChangeStatus, action.payload);

    yield put({ type: dashboardAction.changeStatusSuccess });

    yield put({
      type: toastAction.addToast,
      payload: { content: "처리상태가 변경되었습니다" },
    });
  } catch (err) {
    yield put({ type: dashboardAction.changeStatusFailure, payload: err });
  }
}

export function* watchChangeStatus() {
  yield takeLatest(dashboardAction.changeStatus.type, changeStatusSaga);
}
