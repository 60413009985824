import React from "react";
import styled from "styled-components";

import Layout from "./Layout";

interface DetailLayoutProps {
  children: React.ReactNode;
}

export default function DetailLayout({ children }: DetailLayoutProps) {
  return (
    <Root>
      <Wrapper>{children}</Wrapper>
    </Root>
  );
}

const Root = styled(Layout)`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: 100%;
`;
