import { useState, useCallback } from "react";
import { Dayjs } from "dayjs";

import { useDatePicker } from "hooks/index";

const useCalendar = (initDate: Dayjs) => {
  const [selectedDate, setSelectedDate] = useState<string | Dayjs>();

  const {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    changePrevYear,
    changeNextYear,
  } = useDatePicker(initDate);

  const handleSelectDate = useCallback((date: string | Dayjs) => {
    setSelectedDate(date);
  }, []);

  return {
    monthYear,
    selectedDate,
    handleSelectDate,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    changePrevYear,
    changeNextYear,
  };
};

export default useCalendar;
