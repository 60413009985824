import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";

import type { XYTableXList } from "types/common";

interface Props {
  xList: XYTableXList;
  className?: string;
  children: React.ReactNode;
}

interface TableProps {
  className?: string;
  children: React.ReactNode;
}

interface LoadingTableProps {
  className?: string;
  xlist: { [key: string]: string };
  ylist: { [key: string]: string | number };
}

const XYTable = ({ xList, className, children }: Props) => {
  return (
    <Root className={className}>
      <thead>
        <HeadRow>
          <th>구분</th>
          {Object.values(xList).map((value, i) => (
            <th key={i}>{value}</th>
          ))}
        </HeadRow>
      </thead>
      <tbody>{children}</tbody>
    </Root>
  );
};

XYTable.BodyRow = function BodyRow({ children }: TableProps) {
  return <TableBodyRow>{children}</TableBodyRow>;
};

XYTable.Th = function Th({ children }: TableProps) {
  return <TableHeader>{children}</TableHeader>;
};

XYTable.LoadingTable = function LoadingTable({
  className,
  xlist,
  ylist,
}: LoadingTableProps) {
  return (
    <>
      {new Array(Object.keys(ylist).length).fill(null).map((_, i) => (
        <LoadingTableBodyRow key={i} className={className}>
          <LoadingTableTh>{Object.values(ylist)[i]}</LoadingTableTh>
          {Object.keys(xlist).map((item) => {
            return (
              <TableTd key={item} isLoading>
                <Skeleton />
              </TableTd>
            );
          })}
        </LoadingTableBodyRow>
      ))}
    </>
  );
};

export default XYTable;

const Root = styled.table`
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
`;

const Tr = styled.tr`
  height: 36px;
  display: grid;
`;

const HeadRow = styled(Tr)`
  height: 36px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  font: ${({ theme }) => theme.text.medium_14};
  color: ${({ theme }) => theme.color.gray_40};
  background-color: ${({ theme }) => theme.color.gray_10};

  & > th {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }

  & > :last-child {
    border-right: 0;
  }
`;

const TableBodyRow = styled(Tr)`
  height: 44px;
  font: ${({ theme }) => theme.text.regular_14};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};

  & > td {
    display: flex;
    justify-content: right;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
    padding-right: 12px;
  }

  :nth-child(10) {
    border-bottom: 0;
  }

  & > :last-child {
    border-right: 0;
  }
`;

const TableHeader = styled.th`
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  padding-left: 12px;
  background-color: ${({ theme }) => theme.color.gray_10};
`;

const LoadingTableBodyRow = styled(Tr)`
  height: 44px;
  font: ${({ theme }) => theme.text.regular_14};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};

  & > td {
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
    padding-right: 12px;
  }

  :nth-child(10) {
    border-bottom: 0;
  }

  & > :last-child {
    border-right: 0;
  }
`;

const TableTd = styled.td<{ isLoading: boolean }>`
  display: block;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  line-height: 44px;
  text-align: center;
  padding: ${({ isLoading }) => isLoading && "0px 12px"};
`;

const LoadingTableTh = styled(TableHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
`;
