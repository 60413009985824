import React from "react";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

interface DateProps {
  className?: string;
  date: Dayjs;
  selectedDate?: Dayjs | string;
  disabled?: boolean;
  isDisabledBeforeToday?: boolean;
  handleSelectDate: (date: Dayjs) => () => void;
}

const Date = ({
  className,
  date,
  selectedDate,
  disabled,
  isDisabledBeforeToday,
  handleSelectDate,
}: DateProps) => {
  const isToday = dayjs().isSame(date, "d");
  const isSelectedDate = selectedDate && dayjs(selectedDate)?.isSame(date, "d");
  const isBeforeToday = dayjs().isAfter(date, "d");

  return (
    <Root className={className}>
      <DateBtn
        type="button"
        aria-current={isToday ? "date" : "false"}
        aria-selected={!!isSelectedDate}
        disabled={disabled || (isDisabledBeforeToday && isBeforeToday)}
        onClick={handleSelectDate(date)}
      >
        <time>{date.format("D")}</time>
      </DateBtn>
    </Root>
  );
};

export default Date;

const Root = styled.li`
  width: 32px;
  height: 32px;
`;

const DateBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  &[aria-selected="true"] {
    &::before {
      border-radius: 10px;
      background-color: ${({ theme }) => theme.color.active};
    }

    && > time {
      font-weight: 700;
      color: ${({ theme }) => theme.color.white};
    }
  }

  &[aria-current="date"] > time {
    font-weight: 700;
    color: ${({ theme }) => theme.color.active};
  }

  &::before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  &:not([disabled])&:not([aria-selected="true"]) {
    &:hover::before {
      background-color: ${({ theme }) => theme.color.gray_10};
    }
  }

  & > time {
    font: ${({ theme }) => theme.text.regular_14};
    z-index: 2;
  }
`;
