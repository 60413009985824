import { useState, useEffect, useRef, useCallback } from "react";

const useDropdownBtn = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleClickDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCloseDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleCloseDropdown = (e: MouseEvent) => {
      if (!dropdownRef.current) return;

      if (isOpen && !dropdownRef.current.contains(e.target as HTMLElement)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("mousedown", handleCloseDropdown);

    return () => {
      window.addEventListener("mousedown", handleCloseDropdown);
    };
  }, [isOpen]);

  return { isOpen, dropdownRef, handleClickDropdown, handleCloseDropdown };
};

export default useDropdownBtn;
