import dayjs from "dayjs";

export const transSnakeToCamel = (text: string) => {
  return text.replace(/([_][a-z])/gi, (t) => t.toUpperCase().replace("_", ""));
};

export const transCamelToSnake = (text: string) => {
  return text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};

export const formatCellWidth = (cellWidth: number, totalWidth = 1338) => {
  const width = cellWidth / totalWidth;
  return `${(width * 100).toFixed(4)}%`;
};

export const numberComma = (number: number) => {
  // 숫자에 천단위마다 콤마 찍기
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formatYYYYMMDD = (date: string) => {
  if (!date) return "";

  return dayjs(date).format("YYYY-MM-DD");
};

export const formatPhoneWithDash = (phone: string) => {
  if (!phone) return "";

  return phone
    .replace(
      /(^(01[1|6|7|8|9|0])|^02|^0505|^(0(2[1|6|7|8|9]|3[1-3]|4[1-4]|5[0-5]|6[1-4]|70)))/g,
      "$1-",
    )
    .replace(/(\d{4}$)/, "-$1");
};
