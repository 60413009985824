import { useState, useEffect, useCallback } from "react";

const useAccountInput = (initValue: string) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleFocusInput = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleBlurInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) return;

      setIsFocus(false);
    },
    [],
  );

  useEffect(() => {
    if (initValue) {
      setIsFocus(true);
    }
  }, [initValue]);

  return {
    isFocus,
    handleFocusInput,
    handleBlurInput,
  };
};

export default useAccountInput;
