import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { liveExcel, uncollectedExcel } from "store/reducer/dashboardSlice";
import { RootState } from "store/configureStore";

const useExcelBtn = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const isLiveExcelLoading = useSelector(
    (state: RootState) => state.dashboard.liveExcel.isLoading,
  );
  const isUncollectedExcelLoading = useSelector(
    (state: RootState) => state.dashboard.uncollectedExcel.isLoading,
  );

  const type = searchParams.get("type");

  const handleExcelDown = useCallback(() => {
    switch (type) {
      case "live":
        dispatch({
          type: liveExcel.type,
          payload: {
            ...Object.fromEntries(searchParams),
            excel: true,
          },
        });
        break;
      case "uncollected":
        dispatch({
          type: uncollectedExcel.type,
          payload: {
            ...Object.fromEntries(searchParams),
            excel: true,
          },
        });
        break;
    }
  }, [dispatch, searchParams, type]);

  return { isLiveExcelLoading, isUncollectedExcelLoading, handleExcelDown };
};

export default useExcelBtn;
