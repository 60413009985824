import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import styled from "styled-components";
import { ToastPortal } from "../modal";

import ToastItem from "./ToastItem";

const Toast = () => {
  const toasts = useSelector((state: RootState) => state.toast.toast);

  return (
    <ToastPortal>
      <Root>
        {toasts.map((toast) => (
          <ToastItem key={toast.id} {...toast} />
        ))}
      </Root>
    </ToastPortal>
  );
};

export default Toast;

const Root = styled.div`
  position: fixed;
  top: 73px;
  left: 50%;
  height: max-content;
  z-index: ${({ theme }) => theme.zIndex.MODAL};
  pointer-events: none;
  transform: translateX(-50%);

  & > div:not(:first-of-type) {
    margin-top: 8px;
  }
`;
