import React from "react";
import styled from "styled-components";

import type { TabList } from "types/common";
import useTab from "./hooks/useTab";

const Tab = ({ tabList, render }: TabList) => {
  const { currentType, handleClickTab } = useTab(tabList);

  return (
    <Root>
      <MenuWrapper>
        {tabList.map((tab, i) => (
          <li key={i}>
            <Menu
              type="button"
              isType={currentType === tab.type}
              onClick={handleClickTab(i)}
            >
              {tab.content}
            </Menu>
          </li>
        ))}
      </MenuWrapper>
      {currentType && render[currentType]}
    </Root>
  );
};

export default Tab;

const Root = styled.div``;

const MenuWrapper = styled.ul`
  display: flex;
  min-width: 1340px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
  margin-bottom: 32px;
`;

const Menu = styled.button<{ isType: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ theme, isType }) =>
    isType ? `2px solid ${theme.color.gray_60}` : theme.color.gray_20};
  padding: 10px 32px;
  font: ${({ theme }) => theme.text.semibold_18};
  color: ${({ theme }) => theme.color.gray_60};
  opacity: ${({ isType }) => !isType && 0.4};

  :hover {
    opacity: 1;
  }
`;
