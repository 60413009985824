import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { live, changeStatus } from "store/reducer/dashboardSlice";
import { RootState } from "store/configureStore";

const useLive = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const liveState = useSelector(
    (state: RootState) => state.dashboard.live.data,
  );
  const isLoading = useSelector(
    (state: RootState) => state.dashboard.live.isLoading,
  );
  const isDone = useSelector((state: RootState) => state.dashboard.live.isDone);

  const isDoneChangeStatus = useSelector(
    (state: RootState) => state.dashboard.changeStatus.isDone,
  );

  const chageStatus = useCallback(
    (key: string) => (status: string) => {
      dispatch({ type: changeStatus.type, payload: { key, status } });
    },
    [],
  );

  useEffect(() => {
    dispatch({
      type: live.type,
      payload: {
        ...Object.fromEntries(searchParams),
        ...(searchParams.get("firstDistrict") && { loc: 1 }),
        ...(searchParams.get("secondDistrict") && { loc: 2 }),
        ...(searchParams.get("thirdDistrict") && { loc: 3 }),
        perPage: 30,
      },
    });
  }, [searchParams]);

  useEffect(() => {
    if (!isDoneChangeStatus) return;

    dispatch({
      type: live.type,
      payload: { 
        ...Object.fromEntries(searchParams), 
        ...(searchParams.get("firstDistrict") && { loc: 1 }),
        ...(searchParams.get("secondDistrict") && { loc: 2 }),
        ...(searchParams.get("thirdDistrict") && { loc: 3 }), 
        perPage: 30 },
    });
  }, [isDoneChangeStatus]);

  return {
    liveState,
    isLoading,
    isDone,
    chageStatus
  };
};

export default useLive;
