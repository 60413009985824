import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { RootState } from "store/configureStore";

import { login } from "store/reducer/authSlice";
import { makeCryptoFunction } from "utils/helper/crypto";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoginErr = useSelector((state: RootState) => state.auth.login.error);

  const { register, watch, handleSubmit } = useForm();

  const isActiveLoginBtn = watch("email") && watch("password");

  const handleLogin = () => {
    dispatch({
      type: login.type,
      payload: {
        email: watch("email"),
        password: makeCryptoFunction(watch("password")),
        navigate,
      },
    });
  };

  return {
    register,
    watch,
    isActiveLoginBtn,
    isLoginErr,
    handleLogin: handleSubmit(handleLogin),
  };
};

export default useLogin;
