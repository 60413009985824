interface Status {
  WAITING: string;
  REQUESTED: string;
  COMPLETED: string;
  LOST: string;
  ERROR: string;
  CS: string;
  [key: string]: string;
}

export const status: Status = {
  WAITING: "대기",
  REQUESTED: "수거요청",
  COMPLETED: "완료",
  LOST: "분실",
  ERROR: "오류",
  CS: "CS",
};

interface DashboardStatus {
  LOST: string;
  ERROR: string;
  CS: string;
  [key: string]: string | number;
}

export const dashboardStatus: DashboardStatus = {
  REQUESTED: "수거요청",
  COMPLETED: "완료",
  LOST: "분실",
  ERROR: "오류",
  CS: "CS",
};
