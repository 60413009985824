import React from "react";
import styled from "styled-components";

import SectionLayout from "layout/SectionLayout";
import DistrictDropdown from "components/common/dropdown/DistrictDropdown";
import DatePicker from "components/common/datePicker/DatePicker";
import { SearchInput } from "components/common/input";
import { CalendarIcon } from "assets/icon/index";
import useFilter from "hooks/useFilter";
import useDistrictDropdown from "components/common/dropdown/hooks/useDistrictDropdown";

const Filter = ({mode = 'all'}) => {
  const {
    reg,
    first,
    second,
    third,
    handleReset: handleDistrictReset,
    getDistrictDropdownProps,
  } = useDistrictDropdown();

  const {
    date,
    setDate,
    searchValue,
    handleSearch,
    handleChangeInput,
    handleClickSubmit,
    handleClickReset,
  } = useFilter(reg, first, second, third, handleDistrictReset);

  return (
    <SectionLayout heading="필터">
      {
        mode === 'all' &&
        <SearchInput
          searchValue={searchValue}
          handleSearch={handleSearch}
          handleChangeInput={handleChangeInput}
        />
      }
      <Root>
        <ListWrapper>
          <ConditionWrapper>
            <ConditionHeading>지역</ConditionHeading>
            <DistrictDropdown {...getDistrictDropdownProps({ reg })} />
          </ConditionWrapper>
          <ConditionWrapper>
            <ConditionHeading>일자</ConditionHeading>
            <DatePicker
              initValue={date}
              cb={setDate}
              trigger={({ handleToggleDialogOpen, isDialogOpen }) => (
                <DatePickerWrapper
                  aria-haspopup="true"
                  aria-pressed={isDialogOpen}
                  onClick={handleToggleDialogOpen}
                >
                  {date ? date : "일자를 선택해 주세요."}
                  <CustomCalendarIcon />
                </DatePickerWrapper>
              )}
            />
          </ConditionWrapper>
        </ListWrapper>
        <BtnWrapper>
          <ResetBtn type="button" onClick={handleClickReset}>
            필터 초기화
          </ResetBtn>
          <SubmitBtn type="button" onClick={handleClickSubmit}>
            검색
          </SubmitBtn>
        </BtnWrapper>
      </Root>
    </SectionLayout>
  );
};

export default Filter;

const Root = styled.div`
  min-width: 1340px;
  margin-bottom: 32px;

  & > :not(:first-child) {
    border-left: none;
  }
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 48px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};

  & > :first-child {
    border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  }
`;

const ConditionWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(auto, 100%);
`;

const ConditionHeading = styled.h3`
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-right: 1px solid ${({ theme }) => theme.color.gray_20};
  background-color: ${({ theme }) => theme.color.gray_10};
  font: ${({ theme }) => theme.text.medium_15};
  color: ${({ theme }) => theme.color.gray_40};
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

const SubmitBtn = styled.button`
  margin-top: 12px;
  padding: 8px 16px;
  font: ${({ theme }) => theme.text.semibold_15};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.active};

  :hover {
    opacity: 0.6;
  }
`;

const ResetBtn = styled(SubmitBtn)`
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  color: ${({ theme }) => theme.color.gray_30};
  font-weight: 500;
  background-color: ${({ theme }) => theme.color.white};

  :hover {
    background-color: ${({ theme }) => theme.color.gray_10};
    opacity: 1;
  }
`;

const DatePickerWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 376px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font: ${({ theme }) => theme.text.regular_15};
  color: ${({ theme }) => theme.color.gray_50};
`;

const CustomCalendarIcon = styled(CalendarIcon)`
  fill: ${({ theme }) => theme.color.gray_50};
  opacity: 0.4;
`;
