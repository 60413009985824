import React, { useRef } from "react";
import styled from "styled-components";

import useHeader from "./hooks/useHeader";
import useScrollXFixedEl from "hooks/useScrollXFixedEl";
import { navList } from "assets/static/navLink";
import { HeaderIcon } from "assets/icon/index";

const Header = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { isActive, handleClickMenu, handleLogout } = useHeader();

  useScrollXFixedEl(headerRef);

  return (
    <Root ref={headerRef}>
      <ContentWrapper>
        <HeaderIcon />
        <MenuWrapper>
          {navList.map((menu, i) => {
            return (
              <li key={i}>
                <Menu
                  isActive={isActive(menu.path)}
                  onClick={handleClickMenu(menu.path)}
                >
                  {menu.content}
                </Menu>
              </li>
            );
          })}
        </MenuWrapper>
        <LogoutBtn onClick={handleLogout}>로그아웃</LogoutBtn>
      </ContentWrapper>
    </Root>
  );
};

export default Header;

const Root = styled.header`
  position: fixed;
  min-width: 1440px;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.color.gray_60};
  z-index: ${({ theme }) => theme.zIndex.HEADER_FOOTER};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 1340px;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  padding: 0 50px;
`;

const MenuWrapper = styled.ul`
  display: flex;
  column-gap: 37px;
`;

const Menu = styled.button<{ isActive: boolean }>`
  font: ${({ theme }) => theme.text.semibold_18};
  color: ${({ theme }) => theme.color.white};
  opacity: ${({ isActive }) => !isActive && 0.3};

  :hover {
    opacity: 1;
  }
`;

const LogoutBtn = styled.button`
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.white};
  line-height: 20px;
  background-color: inherit;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;
