import React from "react";
import styled, { css } from "styled-components";

import {
  NotFoundIcon,
  ChromeIcon,
  FirefoxIcon,
  EdgeIcon,
  SafariIcon,
} from "assets/icon/index";

const IESupport = () => {
  const title = "Browser not supported";
  const desc =
    "You're using a web browser we don't support. \n Please use one of these options to imporve your experience.";

  const browsers = {
    "Google Chrome": <ChromeIcon />,
    "Mozilla Firefox": <FirefoxIcon />,
    "Microsoft Edge": <EdgeIcon />,
    Safari: <SafariIcon />,
  };

  return (
    <Root>
      <ContentWrapper>
        <NotFoundIcon />
        <ContentItem>
          <dt>{title}</dt>
          <dd>{desc}</dd>
        </ContentItem>
      </ContentWrapper>
      <BrowserWrapper>
        {Object.entries(browsers).map(([browser, icon]) => (
          <BrowserItem key={browser}>
            {icon}
            <span>{browser}</span>
          </BrowserItem>
        ))}
      </BrowserWrapper>
    </Root>
  );
};

export default IESupport;

const flex_mixin = css`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
`;

const Root = styled.main`
  ${flex_mixin};
  width: 100%;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  ${flex_mixin};
`;

const ContentItem = styled.dl`
  ${flex_mixin};
  margin: 20px 0 80px;
  dt {
    margin-bottom: 8px;
    font: ${({ theme }) => theme.text.bold_18};
    color: ${({ theme }) => theme.color.black};
  }
  dd {
    font: ${({ theme }) => theme.text.regular_14};
    color: ${({ theme }) => theme.color.gray_70};
    text-align: center;
    white-space: pre-line;
  }
`;
const BrowserWrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
`;

const BrowserItem = styled.div`
  ${flex_mixin}
  :not(:last-of-type) {
    margin-right: 72px;
  }
  & > span {
    margin-top: 20px;
    font: ${({ theme }) => theme.text.medium_13};
    color: ${({ theme }) => theme.color.gray_80};
  }
`;
