import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import { Layout } from "layout/index";
import { LAYOUT_TYPE, PATH } from "utils/constants";
import { isAuth } from "utils/helper/checkAuth";

const AuthRoute = () => {
  const location = useLocation();

  return isAuth() ? (
    <Layout type={LAYOUT_TYPE.PRIVATE}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={PATH.root} state={{ from: location }} />
  );
};

export default AuthRoute;
