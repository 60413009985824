import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serviceAssessment } from "store/reducer/statisticsSlice";
import { RootState } from "store/configureStore";

const useServiceAssessment = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const serviceAssessmentData = useSelector(
    (state: RootState) => state.statistics.serviceAssessment.data,
  );

  useEffect(() => {
    if (!searchParams.get("year")) return;

    dispatch({
      type: serviceAssessment.type,
      payload: { ...Object.fromEntries(searchParams) },
    });
  }, [searchParams]);

  return { serviceAssessmentData };
};

export default useServiceAssessment;
