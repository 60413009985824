import React from "react";
import styled from "styled-components";

import { FilterArrowIcon, MinusIcon } from "assets/icon";

interface SortBtnProps {
  sortState: string;
  handleSort: () => void;
}

const SortBtn = ({ sortState, handleSort }: SortBtnProps) => {
  const iconRender = (sortState: string) => {
    switch (sortState) {
      case "-":
        return <MinusIcon />;
      case "asc":
        return <AscArrow />;
      case "desc":
        return <FilterArrowIcon />;
    }
  };

  return (
    <Root type="button" onClick={handleSort}>
      {iconRender(sortState)}
    </Root>
  );
};

export default SortBtn;

const Root = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 3px;
`;

const AscArrow = styled(FilterArrowIcon)`
  transform: rotate(180deg);
`;
