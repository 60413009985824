import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configureStore";

import {
  multipleContainer,
  multipleContainerReset,
} from "store/reducer/statisticsSlice";

const useUsePerformance = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const multipleContainerState = useSelector(
    (state: RootState) => state.statistics.multipleContainer.data,
  );

  useEffect(() => {
    if (!searchParams.get("year")) return;

    dispatch({
      type: multipleContainer.type,
      payload: { ...Object.fromEntries(searchParams) },
    });

    return () => {
      dispatch({ type: multipleContainerReset.type });
    };
  }, [searchParams]);

  return { multipleContainerState };
};

export default useUsePerformance;
