import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useSortBtn = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [createdSortState, setCreateStateSort] = useState(
    searchParams.get("createdSort")
      ? searchParams.get("createdSort")
      : searchParams.get("completedSort")
      ? "-"
      : "desc",
  );
  const [completedSortState, setCompletedStateSort] = useState(
    searchParams.get("completedSort")
      ? searchParams.get("completedSort")
      : searchParams.get("createdSort")
      ? "-"
      : "-",
  );

  const changeCreatedSortState = (state: string) => {
    switch (state) {
      case "-":
        return "desc";
      case "desc":
        return "asc";
      case "asc":
      default:
        return "desc";
    }
  };

  const changeCompletedSortState = (state: string) => {
    switch (state) {
      case "-":
      default:
        return "desc";
      case "desc":
        return "asc";
      case "asc":
        return "desc";
    }
  };

  const handleCreatedSort = useCallback(() => {
    const query: Record<string, string> = {
      ...Object.fromEntries(searchParams),
      createdSort: changeCreatedSortState(createdSortState as string),
    };

    if (query["completedSort"]) {
      delete query.completedSort;
    }

    setCreateStateSort(changeCreatedSortState(createdSortState as string));
    setCompletedStateSort("-");
    setSearchParams(query);
  }, [searchParams, setSearchParams, createdSortState]);

  const handleCompletedSort = useCallback(() => {
    const query: Record<string, string> = {
      ...Object.fromEntries(searchParams),
      completedSort: changeCompletedSortState(completedSortState as string),
    };

    if (query["createdSort"]) {
      delete query.createdSort;
    }

    setCompletedStateSort(changeCreatedSortState(completedSortState as string));
    setCreateStateSort("-");
    setSearchParams(query);
  }, [searchParams, setSearchParams, completedSortState]);

  useEffect(() => {
    if (
      !searchParams.get("createdSort") &&
      !searchParams.get("completedSort")
    ) {
      setCreateStateSort("desc");
      setCompletedStateSort("-");
    }
  }, [searchParams]);

  return {
    createdSortState,
    completedSortState,
    handleCreatedSort,
    handleCompletedSort,
  };
};

export default useSortBtn;
