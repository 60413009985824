import { useEffect } from "react";

const useOnClickOutside = (ref: any, handler: any, exceptEl: any) => {
  useEffect(() => {
    const listener = (e: any) => {
      const el = ref?.current;
      const isIncludeEl = !el || el.contains(e.target);
      const isIncludeExceptEl = exceptEl && exceptEl?.contains(e.target);

      if (isIncludeEl || isIncludeExceptEl) return;
      handler();
    };
    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
