import React from "react";
import { Outlet } from "react-router-dom";

import { Layout } from "layout/index";
import { LAYOUT_TYPE } from "utils/constants";

const PublicRoute = () => {
  return (
    <Layout type={LAYOUT_TYPE.PUBLIC}>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
