import axios, { Axios, AxiosError } from "axios";

import Cookies from "js-cookie";

export const baseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  // url, body, header
  baseURL: baseUrl,
});

const createInstance = () => {
  return setInterceptors(instance);
};

const getAccessToken = async <T>(error: AxiosError<T>) => {
  // accessToken 유효기간 만료 시 재요청하는 함수
  const refreshToken = Cookies.get("REPLHADA_ADMIN_REFRESH_TOKEN");
  try {
    const {
      data: { accessToken },
    } = await axios.post(
      `${baseUrl}/auth/refresh`,
      {},
      {
        headers: { authorization: "bearer " + refreshToken },
      },
    );

    Cookies.set("REPLHADA_ADMIN_ACCESS_TOKEN", accessToken);

    return instance(error.config);
  } catch (error: any) {
    if (error.response.data.code === "Invalid refresh token") {
      // accessToken 만료로 refreshToken을 사용해서 재요청했으나
      // refreshToken까지 만료되어 재로그인을 유도하는 로직
      alert("로그인 세션이 만료되었습니다.");
      Cookies.remove("REPLHADA_ADMIN_ACCESS_TOKEN");
      Cookies.remove("REPLHADA_ADMIN_REFRESH_TOKEN");
      window.location.reload();
    }
  }
};

const setInterceptors = (instance: Axios) => {
  instance.interceptors.request.use(
    function (config) {
      // 요청을 보내기 전에 수행할 일
      const accesstoken = Cookies.get("REPLHADA_ADMIN_ACCESS_TOKEN");

      if (accesstoken) {
        config.headers!["authorization"] = "bearer " + accesstoken;
      }

      return config;
    },

    function (error) {
      // 오류 요청을 보내기전 수행할 일
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    function (response) {
      // 응답 데이터를 가공
      return response;
    },
    async function (error) {
      // 오류 응답을 처리
      const errorCode = error.response.data.code;

      if (errorCode === "Already login") {
        alert("중복 로그인 되었습니다.");
        Cookies.remove("REPLHADA_ADMIN_ACCESS_TOKEN");
        Cookies.remove("REPLHADA_ADMIN_REFRESH_TOKEN");
        window.location.reload();
      }

      if (errorCode === "Invalid access token") {
        return getAccessToken<AxiosError>(error);
      } else {
        return Promise.reject(error);
      }
    },
  );

  return instance;
};

export const ax = createInstance();
