import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Login {
  isLoading: boolean;
  isDone: boolean;
  error: null | Error;
}

interface Logout {
  isLoading: boolean;
  isDone: boolean;
  error: null | Error;
}

const initialState: { login: Login; logout: Logout } = {
  login: { isLoading: false, isDone: false, error: null },
  logout: { isLoading: false, isDone: false, error: null },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.login.isLoading = true;
      state.login.isDone = false;
      state.login.error = null;
    },
    loginSuccess(state) {
      state.login.isLoading = false;
      state.login.isDone = true;
      state.login.error = null;
    },
    loginFailure(state, action: PayloadAction<Error>) {
      state.login.isLoading = false;
      state.login.isDone = true;
      state.login.error = action.payload;
    },
    logout(state, action) {
      state.logout.isLoading = true;
      state.logout.isDone = false;
      state.logout.error = null;
    },
    logoutSuccess(state) {
      state.logout.isLoading = false;
      state.logout.isDone = true;
      state.logout.error = null;
    },
    logoutFailure(state, action: PayloadAction<Error>) {
      state.logout.isLoading = false;
      state.logout.isDone = true;
      state.logout.error = action.payload;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFailure,
  logout,
  logoutSuccess,
  logoutFailure,
} = authSlice.actions;
export default authSlice.reducer;
