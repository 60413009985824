import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type {
  ReducerState,
  DownExcel,
  FetchListData,
  Reducer,
} from "types/reducer";
import type { LiveTableList, UncollectedTableList } from "types/dashboard";

const initialState: {
  live: Reducer<FetchListData<LiveTableList>>;
  liveExcel: Reducer<DownExcel<LiveTableList>>;
  uncollected: Reducer<FetchListData<UncollectedTableList>>;
  uncollectedExcel: Reducer<DownExcel<UncollectedTableList>>;
  changeStatus: ReducerState;
} = {
  live: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
  liveExcel: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
  uncollected: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
  uncollectedExcel: {
    isLoading: false,
    isDone: false,
    error: null,
    data: null,
  },
  changeStatus: {
    isLoading: false,
    isDone: false,
    error: null,
  },
};

const dashboardSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    live(state, action) {
      state.live.isLoading = true;
      state.live.isDone = false;
      state.live.error = null;
    },
    liveSuccess(state, action: PayloadAction<FetchListData<LiveTableList>>) {
      state.live.isLoading = false;
      state.live.isDone = true;
      state.live.error = null;
      state.live.data = action.payload;
    },
    liveFailure(state, action: PayloadAction<Error>) {
      state.live.isLoading = false;
      state.live.isDone = true;
      state.live.error = action.payload;
    },
    liveExcel(state, action) {
      state.liveExcel.isLoading = true;
      state.liveExcel.isDone = false;
      state.liveExcel.error = null;
    },
    liveExcelSuccess(state) {
      state.liveExcel.isLoading = false;
      state.liveExcel.isDone = true;
      state.liveExcel.error = null;
    },
    liveExcelFailure(state, action: PayloadAction<Error>) {
      state.liveExcel.isLoading = false;
      state.liveExcel.isDone = true;
      state.liveExcel.error = action.payload;
    },
    uncollected(state, action) {
      state.uncollected.isLoading = true;
      state.uncollected.isDone = false;
      state.uncollected.error = null;
    },
    uncollectedSuccess(
      state,
      action: PayloadAction<FetchListData<UncollectedTableList>>,
    ) {
      state.uncollected.isLoading = false;
      state.uncollected.isDone = true;
      state.uncollected.error = null;
      state.uncollected.data = action.payload;
    },
    uncollectedFailure(state, action: PayloadAction<Error>) {
      state.uncollected.isLoading = false;
      state.uncollected.isDone = true;
      state.uncollected.error = action.payload;
    },
    uncollectedExcel(state, action) {
      state.uncollectedExcel.isLoading = true;
      state.uncollectedExcel.isDone = false;
      state.uncollectedExcel.error = null;
    },
    uncollectedExcelSuccess(state) {
      state.uncollectedExcel.isLoading = false;
      state.uncollectedExcel.isDone = true;
      state.uncollectedExcel.error = null;
    },
    uncollectedExcelFailure(state, action: PayloadAction<Error>) {
      state.uncollectedExcel.isLoading = false;
      state.uncollectedExcel.isDone = true;
      state.uncollectedExcel.error = action.payload;
    },
    changeStatus(state, action) {
      state.changeStatus.isLoading = true;
      state.changeStatus.isDone = false;
      state.changeStatus.error = null;
    },
    changeStatusSuccess(state) {
      state.changeStatus.isLoading = false;
      state.changeStatus.isDone = true;
      state.changeStatus.error = null;
    },
    changeStatusFailure(state, action: PayloadAction<Error>) {
      state.changeStatus.isLoading = false;
      state.changeStatus.isDone = true;
      state.changeStatus.error = action.payload;
    },
  },
});

export const {
  live,
  liveSuccess,
  liveFailure,
  liveExcel,
  liveExcelSuccess,
  liveExcelFailure,
  uncollected,
  uncollectedSuccess,
  uncollectedFailure,
  uncollectedExcel,
  uncollectedExcelSuccess,
  uncollectedExcelFailure,
  changeStatus,
  changeStatusSuccess,
  changeStatusFailure,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
