export const liveXList: { [key: string]: string } = {
  no: "번호",
  date: "일자",
  time: "시간",
  orderId: "Order id",
  phone: "폰번호",
  addr: "주소",
  containers: "다회용기 수량",
  delayTime: "수거 지연 시간",
  pickedupAt: "수거요청일",
  returnDate: "완료일",
  doorlockPassword: "비밀번호 불러오기",
  reorderCnt: "재주문수",
  rating: "Rating",
  status: "처리상태",
};

export const livePhoneOrderList: { [key: string]: string } = {
  no: "번호",
  date: "일자",
  time: "시간",
  // orderId: "Order id",
  phone: "폰번호",
  addr: "주소",
  containers: "다회용기 수량",
  delayTime: "수거 지연 시간",
  pickedupAt: "수거요청일",
  returnDate: "완료일",
  doorlockPassword: "비밀번호 불러오기",
  // reorderCnt: "재주문수",
  // rating: "Rating",
  // status: "처리상태",
};

export const uncollectedList = {
  num: "번호",
  date: "일자",
  time: "시간",
  orderId: "Order id",
  phoneNumber: "폰번호",
  addr: "주소",
  delayTime: "수거 지연 시간",
  status: "처리상태",
};

export const multipleContainerXList = {
  month1: "1월",
  month2: "2월",
  month3: "3월",
  month4: "4월",
  month5: "5월",
  month6: "6월",
  month7: "7월",
  month8: "8월",
  month9: "9월",
  month10: "10월",
  month11: "11월",
  month12: "12월",
  total: "누계",
};

export const multipleContainerYList = {
  a: "a) 정상 수거요청",
  b: "b) CS",
  c: "c) 미수거",
  e: "총 실적 (a+b+c)",
  f: "다회용기 사용수량",
  g: "분실 건",
  h: "분실 다회용기 수량",
};

export const serviceAssessmentXList = {
  month1: "1월",
  month2: "2월",
  month3: "3월",
  month4: "4월",
  month5: "5월",
  month6: "6월",
  month7: "7월",
  month8: "8월",
  month9: "9월",
  month10: "10월",
  month11: "11월",
  month12: "12월",
  total: "누계",
};

export const serviceAssessmentYList = {
  rating5: "5",
  rating4: "4",
  rating3: "3",
  rating2: "2",
  rating1: "1",
  rating0: "0",
};

export const cumulativeDataXList = {
  number: "건수",
  percentage: "비율(%)",
  note: "비고",
};

export const cumulativeDataYList = {
  reorder: "재주문",
  lost: "분실",
  delay: "지연율(48시간 경과)",
};

export const qrModalList = {
  qrId: "수량",
};
