import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Login, Expired, NotFound, Dashboard, Statistics } from "pages/index";
import { AuthRoute, PublicRoute } from "components/common/route/index";
import { PATH } from "utils/constants";

export default function CustomRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="*" element={<NotFound />} />
          <Route path={PATH.root} element={<Login />} />
          <Route path={PATH.expired} element={<Expired />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route path={PATH.dashboard} element={<Dashboard />} />
          <Route path={PATH.statistics} element={<Statistics />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
