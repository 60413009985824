import React from "react";
import styled from "styled-components";

import { PageLayout } from "layout/index";
import { Tab } from "components/common/tab/index";
import { statisticsTabList } from "assets/static/tab";
import MultipleContainer from "./containers/multipleContainer/MultipleContainer";
import ServiceAssessment from "./containers/serviceAssessment/ServiceAssessment";
import CumulativeData from "./containers/cumulativeData/CumulativeData";

const Statistics = () => {
  return (
    <PageLayout>
      <Heading>통계</Heading>
      <Explanation>
        Order 접수 3시간 후 수거요청 알림 메세지가 발송되며, <br /> 배달 접수
        시간 기준 48시간 경과 시 실시간에서 미수거로 데이터가 이동됩니다.
      </Explanation>
      <Tab
        tabList={statisticsTabList}
        render={{
          multipleContainer: <MultipleContainer />,
          serviceAssessment: <ServiceAssessment />,
          cumulativeData: <CumulativeData />,
        }}
      />
    </PageLayout>
  );
};

export default Statistics;

const Heading = styled.h3`
  margin-bottom: 10px;
  font: ${({ theme }) => theme.text.semibold_26};
`;

const Explanation = styled.p`
  margin-bottom: 32px;
  font: ${({ theme }) => theme.text.regular_14};
  color: ${({ theme }) => theme.color.gray_30};
  line-height: 20px;
`;
