import { useEffect, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import type { Tab } from "types/common";

const useTab = (tabList: Tab[]) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentType = searchParams.get("type");

  const handleClickTab = useCallback(
    (index: number) => () => {
      setSearchParams({ type: tabList[index].type, loc: "0" });
    },
    [tabList],
  );

  useEffect(() => {
    if (!searchParams.get("type")) {
      if (pathname === "/dashboard") {
        setSearchParams({ type: "live", loc: "0" });
      } else {
        setSearchParams({ type: "multipleContainer", loc: "0" });
      }
    }
  }, [searchParams]);

  return { currentType, handleClickTab };
};

export default useTab;
