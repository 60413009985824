import React, { useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

import { modalClose, modalOpen } from "store/reducer/commonSlice";

const useModal = () => {
  const dispatch = useDispatch();

  const closeBtnRef = useRef(null);

  const handleClickOpenModal = useCallback(
    (payload: { modalComponent: React.ReactNode }) => () => {
      dispatch({ type: modalOpen.type, payload });
    },
    [],
  );

  const handleClickCloseModal = useCallback(() => {
    dispatch({ type: modalClose.type });
  }, []);

  const handleClickDim = useCallback((e: any) => {
    const { target, currentTarget } = e;
    target === currentTarget && dispatch({ type: modalClose.type });
  }, []);

  return {
    closeBtnRef,
    handleClickOpenModal,
    handleClickCloseModal,
    handleClickDim,
  };
};

export default useModal;
