import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body{
    height: 100%; 
  }
  html{
    font-size: 62.5% // 10px
  }

  #root{
    display: flex;
    width: 100%;
    height: 100%;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';     
  }

  button{
    /* outline: none; */
    border: none;
    cursor:pointer;
    background-color: inherit;
  }
  input, button, textarea {
    font-size: 62.5% // 10px  
  }
  input, button, textarea{
    font-family: 'Spoqa Han Sans Neo', 'sans-serif'; 
  }
  a{
    text-decoration: none;
    color:black;
  }
`;
