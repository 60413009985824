import { DefaultTheme } from "styled-components";

const zIndex = {
  MAIN: 10,
  NAV: 100,
  HEADER_FOOTER: 1000,
  MODAL: 10000,
};

const color = {
  white: "#ffffff",
  black: "#000000",
  active: "#F4BF00",
  disable: "#E0E0E0",
  gray_10: "#F5F5F5",
  gray_20: "#E0E0E0",
  gray_30: "#7B7B7B",
  gray_40: "#626262",
  gray_50: "#424242",
  gray_60: "#222222",
  gray_70: "#7A848D",
  gray_80: "#4E5B67",
  blue: "#274AFF",
  error: "#FF7171",
  xyTable_total: "#F4BF091A",
  xyTable_lost: "#FFF4F4",
};

const text = {
  regular_13: "400 1.3rem/1.3846 'pretendard', sans-serif",
  regular_14: "400 1.4rem/1.4285 'pretendard', sans-serif",
  regular_15: "400 1.5rem/1.6 'pretendard', sans-serif",
  regular_18: "400 1.5rem/1.3333 'pretendard', sans-serif",
  medium_12: "500 1.2rem/1.3333 'pretendard', sans-serif",
  medium_13: "500 1.3rem/1.3846 'pretendard', sans-serif",
  medium_14: "500 1.4rem/1.4285 'pretendard', sans-serif",
  medium_15: "500 1.5rem/1.6 'pretendard', sans-serif",
  semibold_14: "600 1.4rem/1.4285 'pretendard', sans-serif",
  semibold_15: "600 1.5rem/1.6 'pretendard', sans-serif",
  semibold_18: "600 1.8rem/1.3333 'pretendard', sans-serif",
  semibold_20: "600 1.8rem/1.4 'pretendard', sans-serif",
  semibold_26: "600 2.6rem/1.3846 'pretendard', sans-serif",
  bold_14: "700 1.4rem/1.4285 'pretendard', sans-serif",
  bold_18: "700 1.8rem/1.4444 'pretendard', sans-serif",
};

export type Color = typeof color;
export type ZIndex = typeof zIndex;
export type Text = typeof text;

export const theme: DefaultTheme = {
  color,
  text,
  zIndex,
};

// export default { zIndex, color, text };
