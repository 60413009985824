import React from "react";
import styled from "styled-components";

import ClipLoader from "react-spinners/ClipLoader";

interface Props {
  isLoading: boolean;
  handleExcelDown: () => void;
}

const ExcelBtn = ({ isLoading, handleExcelDown }: Props) => {
  return (
    <Root type="button" disabled={isLoading} onClick={handleExcelDown}>
      {isLoading ? <ClipLoader size={15} color="#7B7B7B" /> : "엑셀 다운로드"}
    </Root>
  );
};

export default ExcelBtn;

const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.gray_20};
  font: ${({ theme }) => theme.text.regular_15};
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray_30};

  :hover {
    background-color: ${({ theme }) => theme.color.gray_10};
  }
`;
