import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";

import { removeToast } from "store/reducer/toastSlice";
import { ApproveIcon } from "assets/icon/index";

const TRANSITION_DURATION = 1000;
const TOAST_DURATION = 3000;

interface ToastItemProps {
  id: string;
  content: string;
}

const ToastItem = ({ id, content }: ToastItemProps) => {
  const dispatch = useDispatch();

  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const existTimeout = setTimeout(() => {
      setIsClosing(true);
    }, TOAST_DURATION);

    const expireToastTimeout = setTimeout(() => {
      dispatch({ type: removeToast.type, payload: { id } });
    }, TOAST_DURATION + TRANSITION_DURATION);

    return () => {
      clearTimeout(existTimeout);
      clearTimeout(expireToastTimeout);
    };
  }, []);

  return (
    <Root isClosing={isClosing}>
      <Container role="status">
        <ApproveIcon />
        <pre>{content}</pre>
      </Container>
    </Root>
  );
};

export default ToastItem;

const scaleUp = keyframes`
  from { max-height: 0;}
  to {max-height: 100px;}
`;

const scaleDown = keyframes`
  from { max-height: 100px;}
  to { max-height: 0;}
 `;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-50%);}
  to { opacity: 1; transform: translateY(0)}
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0)}
  to { opacity: 0; transform: translateY(50%)}
 `;

const Root = styled.div<{ isClosing: boolean }>`
  max-height: 0;
  overflow: visible;
  animation: 0.6s forwards
    ${({ isClosing }) => (isClosing ? scaleDown : scaleUp)};

  & > div {
    animation: 0.3s forwards
      ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  max-width: 348px;
  border-radius: 10px;
  padding: 18px 20px 18px 18px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);

  & > svg {
    margin-right: 8px;
  }

  & > pre {
    font: ${({ theme }) => theme.text.medium_14};
    color: ${({ theme }) => theme.color.gray_50};
  }
`;
